import { isDecimal } from 'util/NumberUtil';

export async function convertBlobToRaw({ blob, range }) {
  const sliceFileContent = blob.slice(range?.begin, range?.end);
  const rawData = await readEcgBinaryBlob(sliceFileContent);

  return rawData.map(roundToFourthDecimalPlace);
}

/**
 * Blob 객체(raw binary file)를 읽고, decoding(convert2ByteIntoEcg)하여 ECG 데이터를 추출하는 역할
 *
 * @param {Blob} blob
 * @returns {Promise<number[]>}
 */
export async function readEcgBinaryBlob(blob) {
  const stream = blob.stream();
  const reader = stream.getReader();

  let result = [];
  let remainingBytes = new Uint8Array();
  let readableStreamReadResult = await reader.read();
  while (!readableStreamReadResult.done) {
    const value = concatenateUint8Arrays(
      remainingBytes,
      readableStreamReadResult.value
    );
    const { result: subResult, remainingBytes: tempArray } =
      convert2ByteIntoEcg(value);
    result = result.concat(subResult);
    remainingBytes = tempArray;

    readableStreamReadResult = await reader.read();
  }

  return result;
}

/**
 *  두 Uint8Array를 연결하여 새 Uint8Array를 반환
 *
 * @param {Uint8Array} array1
 * @param {Uint8Array} array2
 * @returns
 */
function concatenateUint8Arrays(array1, array2) {
  // 새로운 배열의 길이는 두 배열의 길이 합과 같습니다.
  const newArray = new Uint8Array(array1.length + array2.length);
  // 첫 번째 배열을 새 배열에 복사합니다.
  newArray.set(array1);
  // 두 번째 배열을 새 배열에 이어서 복사합니다.
  newArray.set(array2, array1.length);
  return newArray;
}

/**
 * 16 Float 값을 2byte로 비트 연산된 값을 복구
 *
 * @param {Uint8Array} bitwiseBytesFile
 * @returns {ReadBlobResult}
 */
export function convert2ByteIntoEcg(bitwiseBytesFile) {
  if (bitwiseBytesFile === null) {
    return {
      result: [],
      remainingBytes: new Uint8Array(),
    };
  }

  const result = [];
  const bitwiseBytesFileLength = bitwiseBytesFile.length;
  const readSize = 2;
  const readableLength =
    bitwiseBytesFileLength - (bitwiseBytesFileLength % readSize);
  const remainingBytes = bitwiseBytesFile.subarray(readableLength);

  for (let i = 0; i < bitwiseBytesFileLength; i += readSize) {
    const float16Value = getFloat16ValueFromTwoBytes(
      bitwiseBytesFile[i],
      bitwiseBytesFile[i + 1]
    );

    result.push(float16Value);
  }

  return { result, remainingBytes };
}

const SIGNIFICANT_BITS_DIVIDER = Math.pow(2, 10);
const EXPONENT_CACHE = Array.from({ length: 31 }, (_, i) =>
  Math.pow(2, Math.max(i, 1) - 15)
);

/**
 * 2byte 로 Bitwise 된 값에서 16bit float 값을 반환
 * IEEE 754 반정밀도 부동소수점 형식으로 변환합니다.
 * 부호, 지수, 분수 부분을 추출하고, 이를 기반으로 부동소수점 값을 계산하여 반환합니다.
 *
 * ref.: https://en.wikipedia.org/wiki/Half-precision_floating-point_format
 * @param {number} leftByte 배열의 왼쪽 byte 값
 * @param {number} rightByte 배열의 오른쪽 byte 값
 * @returns
 */
function getFloat16ValueFromTwoBytes(leftByte, rightByte) {
  const uint16 = parseInt(`${(leftByte & 0xff) + ((rightByte & 0xff) << 8)}`); // 2byte를 16bit로 변환
  const sign = uint16 & 0x8000 ? -1 : 1; // 부호 부분
  const exponent = (uint16 & 0x7c00) >> 10; // 지수 부분
  const fraction = uint16 & 0x03ff; // 분수 부분
  const exponentResult = EXPONENT_CACHE[exponent];

  let float16Value;
  if (exponent === 0) {
    if (fraction === 0) {
      float16Value = sign * 0; // Positive/negative zero
    } else {
      float16Value =
        sign * exponentResult * (fraction / SIGNIFICANT_BITS_DIVIDER);
    }
  } else if (exponent === 0x1f) {
    // exponent === 31(0x1f); 5개 Bit 가 모두 1인 상황
    float16Value = fraction === 0 ? (sign ? -Infinity : Infinity) : NaN;
  } else {
    float16Value =
      sign * exponentResult * (1 + fraction / SIGNIFICANT_BITS_DIVIDER);
  }

  return float16Value;
}

/**
 * @typedef ReadBlobResult
 * @prop {Array<number>} result
 * @prop {Uint8Array} remainingBytes
 */

export function convertWaveformIndexToByte(waveformIndex) {
  // 1waveform = 250 samples
  // 1sample = 2byte
  // convert 1waveform to byte
  // return waveformIndex * 2;
  const ecgSample = 250;
  const byteUnit = 2;
  const time = waveformIndex / ecgSample;
  let calculatedByteFromWfi = time * ecgSample * byteUnit;

  if (isDecimal(calculatedByteFromWfi)) {
    calculatedByteFromWfi = Math.trunc(calculatedByteFromWfi);

    if (calculatedByteFromWfi % 2 !== 0) {
      calculatedByteFromWfi += 1;
    }
  }
  // 0 wfi -> 0 byte
  // 1 wfi -> 500 byte
  // 250 wfi -> 50000 byte
  // 2500 wfi -> 500000 byte
  // 7500 wfi -> 1500000 byte
  return Math.max(calculatedByteFromWfi, 0);
}

export function roundToFourthDecimalPlace(num) {
  return Math.round(num * 10000) / 10000;
}

export async function getDecodedEcgFromBinaryBlob(blob) {
  const stream = blob.stream();
  const reader = stream.getReader();

  const result = [];
  let remainingBytes = new Uint8Array();
  let readableStreamReadResult = await reader.read();
  while (!readableStreamReadResult.done) {
    const value = concatenateUint8Arrays(
      remainingBytes,
      readableStreamReadResult.value
    );

    const { result: subResult, remainingBytes: tempArray } =
      convert2ByteIntoEcg(value);
    for (let i = 0, length = subResult.length; i < length; i++) {
      result.push(subResult[i]);
    }
    remainingBytes = tempArray;

    readableStreamReadResult = await reader.read();
  }

  return result;
}
