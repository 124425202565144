import { call, all, select } from 'redux-saga/effects';

import { shapeReviewFetchingOption } from 'constant/ShapeReviewConst';

import { transformWaveformIndexToMs } from 'util/EcgEvent/unitConversionUtils';

import { selectRecordingTime } from 'redux/duck/testResultDuck';

import {
  convertWaveformIndexToByte,
  convertBlobToRaw,
} from './BinaryFileLoadWorker';

class ShapeReviewPreSignedUrl {
  *fetchMultipleAPIs({ rawBlob, chartSampleSize, fetchingTargetWfiList }) {
    try {
      const { recordingStartMs } = yield select(selectRecordingTime);
      // 여러 API를 동시에 호출하고 모든 프라미스가 완료될 때까지 기다림
      const fetchingTargetList = fetchingTargetWfiList.map((wfi) =>
        call(this.getDataListFromRedux, {
          rawBlob,
          wfi,
          chartSampleSize,
          recordingStartMs,
        })
      );
      const decodingRawDataList = yield all(fetchingTargetList);

      return decodingRawDataList;
    } catch (error) {
      // 하나의 API 호출이라도 실패하면 에러를 처리합니다.
      console.error('Error fetching:', error);
    }
  }

  async getDataListFromRedux({
    rawBlob,
    wfi,
    chartSampleSize,
    recordingStartMs,
  }) {
    try {
      const onsetWaveformIndex = wfi - 1250;
      const terminationWaveformIndex = onsetWaveformIndex + chartSampleSize;
      const beginRange = convertWaveformIndexToByte(onsetWaveformIndex);
      const endRange = convertWaveformIndexToByte(terminationWaveformIndex);
      const rawECG = await convertBlobToRaw({
        blob: rawBlob,
        range: { begin: beginRange, end: endRange },
      });
      const onsetMs = transformWaveformIndexToMs(
        recordingStartMs,
        onsetWaveformIndex
      );
      const terminationMs = transformWaveformIndexToMs(
        recordingStartMs,
        terminationWaveformIndex
      );

      return {
        rawECG,
        onsetWaveformIndex,
        terminationWaveformIndex,
        onsetMs,
        terminationMs,
      };
    } catch (error) {
      console.error('Error decoding:', error);
    }
  }

  // shapeReviewConst.ts의 shapeReviewFetchingOption.fetchingOption.fetchingSize를 변경합니다.
  setShapeReviewFetchingSize(size: number = 600) {
    shapeReviewFetchingOption.setShapeReviewFetchingSize(size);
  }
}

export const shapeReviewPreSignedUrl = new ShapeReviewPreSignedUrl();
