import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  CHART_EDIT_CONST,
  ECG_CHART_UNIT,
  TEN_SEC_STRIP_DETAIL,
  PLOT_LINE_COLOR_MAP,
  HIGHCHART_UNIT,
  SELECTION_MARKER_TYPE,
  SELECTION_MARKER_OFFSET_CONST,
} from 'constant/ChartEditConst';
import { CLASS_CONST } from 'constant/ClassConst';
import { HR_REVIEW_HISTOGRAM_TYPE } from 'constant/HrReviewConst';
import { ComponentId } from 'constant/ComponentId';

import {
  getCaliperActionCreatorDuckMap,
  selectCaliperDuckMap,
  selectTenSecStripDuckMap,
} from 'util/selectTenSecDuckMap';
import ChartUtil, { isRightClick } from 'util/ChartUtil';
import {
  _renderSelectionHighlight,
  dragFeatureOfTensecStrip,
  getHasBeatLabelButtonBetweenDragRange,
  validateMousedown,
  validateMousemove,
  validateMouseupPosition,
  validateMouseupTarget,
} from 'util/DragUtil';
import { convertWaveformIndexToMs } from 'util/EventConstUtil';
import { getParentComponent } from 'util/Utility';
import { compareTenSecStripData } from 'util/TenSecStripDetailUtil';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import useElementSize from 'component/hook/useElementSize';
import useManagedEventMarker from 'component/hook/useManagedEventMarker';
import useAuthority from 'component/hook/useAuthority';
import useGetEventTypeOfWI from 'component/hook/useGetEventTypeOfWI';

import ShortTermChart from 'component/ui/chart/ShortTermChart';

import { setBeatContextmenuRequest } from 'redux/duck/testResultDuck';
import {
  setIsTenSecStripEditMode,
  setInitSelectedItemList,
} from 'redux/duck/shapeReviewDuck';
import { selectHrSelectedValueState } from 'redux/duck/hrReviewDuck';
import { optimisticTabList } from '@type/optimisticUpdate/type';

const SIDE_PANEL_WIDTH = 226;
const CLASS_CONST_CALIPER_GROUP = CLASS_CONST.CALIPER.GROUP;
const CLASS_CONST_CALIPER_MOUSE_TRACKER = CLASS_CONST.CALIPER.MOUSE_TRACKER;
const CLASS_CONST_CALIPER_CENTER_LEG_GROUP =
  CLASS_CONST.CALIPER.CENTER_LEG_GROUP;

const validateRule = {
  activateMouseTracker: ({
    tabType,
    editModeType,
    isCaliperMode,
    isOpenBeatContextmenu,
    isReportRepresentativeStripChangeMode,
  }) => {
    const isInitMode = editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.INIT;
    const isChangeBeatMode =
      editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT;
    const isAddBeatMode =
      editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
    const isShapeReview = tabType === TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW;
    // return false -> mouse tracker 동작 x
    return (
      // report 담기 단계 중 상태가 아닌 상태 && shape-review && 캘리퍼 모드 일때 -> 동작 가능
      (!isReportRepresentativeStripChangeMode &&
        isShapeReview &&
        isCaliperMode) ||
      // report 담기 단계 중 상태가 아닌 상태 &&
      // shape-review가 아닌 탭(hr-review, event-review, beat-review) &&
      // init상태, context menu가 없는 비트추가 상태, context menu가 없는 비트 변경상태, 캘리퍼 모드상태 일때 -> 동작 가능
      (!isReportRepresentativeStripChangeMode &&
        (isInitMode ||
          (isAddBeatMode && !isOpenBeatContextmenu) ||
          (isChangeBeatMode && !isOpenBeatContextmenu) ||
          isCaliperMode))
    );
  },

  activateDrag: ({
    tabType,
    editModeType,
    isCaliperMode,
    isReportRepresentativeStripChangeMode,
  }) => {
    // shapeReview, eventReview > report 담기 단계 중 대표 스트립 단계에서 10s strip을 사용하는데 이때는 편집을 할 수 없습니다.
    const isAddBeatMode =
      editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;

    // &&: all true -> validate true
    return (
      // 드래그 가능 시점:
      //   addBeatMode, caliperMode, reportRepresentativeStripChangeMode아닐 때
      !isAddBeatMode && !isCaliperMode && !isReportRepresentativeStripChangeMode
    );
  },
};

function TenSecStripDetailChartContainer(props, ref) {
  const {
    chartOption,
    setChartOption,
    //
    selectedBeatBtnInfoList,
    setSelectedBeatBtnInfoList,
    //
    selectedBeatOption,
    setSelectedBeatOption,
    //
    initTenSecStripDetail,
  } = props;

  const { editModeType, tabType, isCaliperMode, isTickMarksMode } = chartOption;
  const isOptimisticUpdate = optimisticTabList.includes(tabType);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { isReadOnly } = useAuthority();

  // redux selector
  const tenSecStripDetail = useShallowEqualSelector(
    (state) => selectTenSecStripDuckMap(state)[tabType],
    (prev, next) => {
      if (!isOptimisticUpdate) return false;

      const resultCompareArrays = compareTenSecStripData(
        prev.beatLabelButtonDataList,
        next.beatLabelButtonDataList
      );

      return resultCompareArrays;
    }
  );

  const {
    ecgRaw,
    beatLabelButtonDataList,
    onsetWaveformIdx,
    terminationWaveformIdx,
    beatsOrigin,
  } = tenSecStripDetail || {};

  const beatReviewSidePanelState = useShallowEqualSelector(
    (state) => state.beatReviewReducer.sidePanelState?.selectedEventList
  );
  const isOpenBeatContextmenu = useShallowEqualSelector(
    (state) => state.testResultReducer.eventReview.isOpenBeatContextmenu
  );
  const { recordingStartMs } = useShallowEqualSelector(
    (state) => state.testResultReducer.recordingTime
  );
  const leadOffList = useShallowEqualSelector(
    (state) => state.testResultReducer.timeEventsList.leadOff
  );
  const { histType } = useShallowEqualSelector(selectHrSelectedValueState);
  const caliperPlotLines = useShallowEqualSelector(
    (state) => selectCaliperDuckMap(state)[tabType].caliperPlotLines
  );

  // redux action creator
  const handleBeatContextmenu = (payload) =>
    !isReadOnly && dispatch(setBeatContextmenuRequest(payload));
  const handleCaliperPlotLines = (caliperPlotLines) =>
    dispatch(
      getCaliperActionCreatorDuckMap('setCaliperPlotLines', caliperPlotLines)[
        tabType
      ]()
    );

  const handleSetIsTenSecStripEditMode = (tenSecStripEditing) => {
    dispatch(setIsTenSecStripEditMode(tenSecStripEditing));
  };
  const handleSelectedItemList = ({ panelType, selectedItemList }) => {
    return dispatch(setInitSelectedItemList({ panelType, selectedItemList }));
  };

  const [chartWrapperWidth, bottomSideHeight] = useElementSize(
    [
      tabType === TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW
        ? window.innerWidth - 424 - SIDE_PANEL_WIDTH
        : window.innerWidth - SIDE_PANEL_WIDTH,
      20,
    ],
    tabType === TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW
      ? -424 - SIDE_PANEL_WIDTH
      : -SIDE_PANEL_WIDTH
  );

  const chartWrapperHeight = chartWrapperWidth / 8;
  const chartWrapperSize = { chartWrapperWidth, chartWrapperHeight };

  const chartRef = useRef();
  const clickFlagRef = useRef(false);
  const chartContainerRef = useRef(null);

  const typeOfCenterWI = useGetEventTypeOfWI({
    selectWaveformIndex:
      onsetWaveformIdx + ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX,
    onsetWaveformIndex: onsetWaveformIdx,
    terminationWaveformIndex: terminationWaveformIdx,
    beats: beatsOrigin,
  });

  const [dragFlag, setDragFlag] = useState(false);
  const [ecgRawState, setEcgRawState] = useState();
  const [beatContextmenuPosition, setBeatContextmenuPosition] = useState({
    positionX: 0,
    positionY: 0,
  });

  const useManagedEventMarkerResult = useManagedEventMarker(
    onsetWaveformIdx,
    terminationWaveformIdx,
    ecgRaw,
    chartRef.current?.chart,
    chartWrapperSize.chartWrapperWidth,
    beatsOrigin,
    //
    true,
    false,
    true
  );

  useImperativeHandle(ref, () => ({
    getTenSecStripDetailChartInst: () => {
      return chartRef.current;
    },
  }));

  // HR Review 10sec Strip HighLight (Blue Square)
  let highlightArea = { from: null, to: null };
  const isHrReviewTab = tabType === TEN_SEC_STRIP_DETAIL.TAB.HR_REVIEW;
  const isAddBeatMode =
    editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
  const isHighlightCase = isHrReviewTab && !isAddBeatMode && !isCaliperMode;
  if (isHighlightCase) {
    /*
      HR Histogram 은 Strip 전체에 Highlight 적용

      Ref.: MC-542
    */
    let from = 0;
    let to = ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX;

    const isRRIntervalHistogramTab = histType === HR_REVIEW_HISTOGRAM_TYPE.RR;
    if (!!beatLabelButtonDataList && isRRIntervalHistogramTab) {
      const toBeatLabelIndex = beatLabelButtonDataList.findIndex(
        (item) => item.isSelected
      );
      if (toBeatLabelIndex > 0) {
        from = beatLabelButtonDataList[toBeatLabelIndex - 1].xAxisPoint;
        to = beatLabelButtonDataList[toBeatLabelIndex].xAxisPoint;
      }
    }

    highlightArea = { from, to };
  }

  /******************/
  /*     setting    */
  /******************/
  // [setting] setEcgRawState
  useEffect(() => {
    setEcgRawState(ecgRaw);
  }, [ecgRaw]);
  // [setting] setChartOption - chart edit mode
  useEffect(() => {
    const isAddBeatMode =
      chartOption.editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
    const hasSelectedBeatBtnInfoList = selectedBeatBtnInfoList.length > 0;
    const isShapeReview = tabType === TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW;
    // update chart "edit mode" when selected beat button
    // "edit mode" CHANGE_BEAT 케이스가 버튼을 클릭으로 선택한 경우, 드래그로 선택한경우 두가지가 있어 한곳에서 관리
    if (!isAddBeatMode && hasSelectedBeatBtnInfoList) {
      setChartOption((prev) => {
        return {
          ...prev,
          editModeType: TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT,
        };
      });
    }
    // shape review에서 10s의 비트를 선택하거나, 비트를 추가하는 경우에 사이드패널의 편집을 방지하기 위한 편집 중 상태를 처리하기 위함
    if (isShapeReview) {
      handleSetIsTenSecStripEditMode(
        isAddBeatMode || hasSelectedBeatBtnInfoList
      );
    }
  }, [selectedBeatBtnInfoList]);

  /******************************************************************************/
  /*  render                                                                    */
  /*   ㄴ beat Label button(include click event function)                             */
  /*   ㄴ For Tick Marks                                                        */
  /*   ㄴ 10s strip mode별로 mouseTrackerElement, beatAddPlusButton on/off 설정 */
  /******************************************************************************/
  // [render] beat Label button(include click event function)
  useEffect(() => {
    const chartInst = chartRef.current.chart;
    _renderBeatLabelButton(
      chartInst,
      ChartUtil,
      beatLabelButtonDataList,
      theme
    );

    function _renderBeatLabelButton(
      chartInst,
      ChartUtil,
      beatLabelButtonDataList,
      theme
    ) {
      while (
        Array.isArray(chartInst.beatLabelButtonInstList) &&
        chartInst.beatLabelButtonInstList.length !== 0
      ) {
        chartInst?.beatLabelButtonInstList.pop().destroy();
      }

      if (!Array.isArray(chartInst.beatLabelButtonInstList)) {
        chartInst.beatLabelButtonInstList = [];
      }

      if (!beatLabelButtonDataList || beatLabelButtonDataList?.length === 0) {
        return;
      }

      beatLabelButtonDataList.forEach((beatLabelButtonData, index) => {
        const button = ChartUtil.renderBeatsEventButton(chartInst, {
          xAxisPoint: beatLabelButtonData.xAxisPoint,
          isSelected: selectedBeatBtnInfoList.some(
            (v) => v.waveformIndex === beatLabelButtonData.xAxisPoint
          ),
          beatType: beatLabelButtonData.beatType,
          title: beatLabelButtonData.title,
          clickCallbackFunction: onClickBeatButton(
            chartInst,
            beatLabelButtonData
          ),
          theme,
          // y: 20,
          tabType,
          isInteraction: !chartOption.isReportRepresentativeStripChangeMode,
        });

        button.position = beatLabelButtonData.position;
        button.beatLabelButtonMetaData = beatLabelButtonData;
        chartInst.beatLabelButtonInstList.push(button);

        // event button를 shift + drag로 선택 기능
        button.element.addEventListener('mouseleave', function (event) {
          const { shiftKey, metaKey, ctrlKey } = event;
          const { beatType } = beatLabelButtonData;
          const isEnableDragSelect = !(
            (shiftKey && metaKey) ||
            (shiftKey && ctrlKey)
          ); //  shift + metaKey, shift + ctrlKey가 아니면 drag select 비활성화

          const isEditMode =
            editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
          if (isEnableDragSelect || isCaliperMode || isEditMode) return;

          setSelectedBeatOption(beatType);
          // update state; clicked beat label button
          // 0: unselected, 2: selected
          button.setState(button.state === 0 ? 2 : 0);
          // update chart edit mode
          setChartOption((prev) => {
            return {
              ...prev,
              editModeType: TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT,
            };
          });

          // ### shift + click fn
          setSelectedBeatBtnInfoList((prev) => {
            const filteredSelectedBeatBtnInfoList = prev.filter(
              (v) => v.waveformIndex !== beatLabelButtonData.xAxisPoint
            );
            let result = [];

            if (button.state === 0) {
              result = [...filteredSelectedBeatBtnInfoList];
            } else {
              result = [
                ...filteredSelectedBeatBtnInfoList,
                { waveformIndex: beatLabelButtonData.xAxisPoint, beatType: 0 },
              ];
            }
            if (result.length === 0) {
              initTenSecStripDetail();
            }
            return result;
          });
        });
      });

      function onClickBeatButton(chartInst, beatLabelButtonMetaData) {
        return function (event) {
          const { shiftKey } = event;
          const { beatType } = beatLabelButtonMetaData;
          setSelectedBeatOption(beatType);
          // todo: jyoon - [refactor]
          // update state; clicked beat label button
          // 0: unselected, 2: selected
          this.setState(this.state === 0 ? 2 : 0);

          const isShapeReview =
            tabType === TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW;
          if (isShapeReview) {
            handleSelectedItemList({
              panelType: 'EVENTS',
              selectedItemList: new Map(),
            });
          }

          // available multiple button select with shiftKey
          if (shiftKey) {
            setSelectedBeatBtnInfoList((prev) => {
              const filteredSelectedBeatBtnInfoList = prev.filter(
                (v) => v.waveformIndex !== beatLabelButtonMetaData.xAxisPoint
              );
              let result = [];

              if (this.state === 0) {
                result = [...filteredSelectedBeatBtnInfoList];
              } else {
                result = [
                  ...filteredSelectedBeatBtnInfoList,
                  {
                    waveformIndex: beatLabelButtonMetaData.xAxisPoint,
                    beatType,
                  },
                ];
              }
              if (result.length === 0) {
                initTenSecStripDetail();
              }
              return result;
            });
          }

          if (!shiftKey) {
            // init state; all beat label buttons
            for (let buttonInst of chartInst.beatLabelButtonInstList) {
              buttonInst.setState(0);
            }

            // process of selected beat label button
            const $backgroundButtonEl =
              this.element.children[0].cloneNode(true);
            $backgroundButtonEl.setAttribute('fill', 'white');
            this.element.prepend($backgroundButtonEl);

            // update waveform index clicked button
            setSelectedBeatBtnInfoList((prev) => {
              let result = [];
              if (
                (prev.length === 1 &&
                  prev[0].waveformIndex !==
                    beatLabelButtonMetaData.xAxisPoint) ||
                prev.length !== 1
              ) {
                result = [
                  {
                    waveformIndex: beatLabelButtonMetaData.xAxisPoint,
                    beatType,
                  },
                ];
              } else {
                initTenSecStripDetail();
              }
              return result;
            });

            const chartEditUtil = ChartUtil.chartEdit(chartInst, { theme });
            // init selection strip(selection marker + selection highlight)
            chartEditUtil.removeSelectionHighlightAll();
            chartEditUtil.removeSelectionMarkerAllInTenSecStrip();
            handleBeatContextmenu(false); // close context menu open status
          }

          // init plotLine(vertical line)
          chartInst.xAxis[0].removePlotLine(
            CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE
          );
          // render plotLine(vertical line)
          chartInst.xAxis[0].addPlotLine({
            id: CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE,
            class: CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE,
            value: beatLabelButtonMetaData.xAxisPoint,
            width: 2,
            color: theme.color[PLOT_LINE_COLOR_MAP[beatType]],
            zIndex: 4,
          });

          if (shiftKey && this.state === 0) {
            // init plotLine(vertical line)
            chartInst.xAxis[0].removePlotLine(
              CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE
            );

            const clickedIndexOfSelectedBeatBtnInfoList =
              selectedBeatBtnInfoList.findIndex(
                (selectedBeatWaveformIndex) =>
                  selectedBeatWaveformIndex.waveformIndex ===
                  beatLabelButtonMetaData.xAxisPoint
              );

            let renderVerticalInfo;

            if (
              clickedIndexOfSelectedBeatBtnInfoList ===
              selectedBeatBtnInfoList.length - 1
            ) {
              renderVerticalInfo = selectedBeatBtnInfoList.at(-2);
            } else {
              renderVerticalInfo = selectedBeatBtnInfoList.at(-1);
            }

            renderVerticalInfo?.waveformIndex &&
              chartInst.xAxis[0].addPlotLine({
                id: CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE,
                class: CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE,
                value: renderVerticalInfo.waveformIndex,
                width: 2,
                color:
                  theme.color[PLOT_LINE_COLOR_MAP[renderVerticalInfo.beatType]],
                zIndex: 4,
              });
          }
        }; // end callback function
      }
    }
  }, [
    beatLabelButtonDataList,
    selectedBeatBtnInfoList,
    chartOption.isReportRepresentativeStripChangeMode,
    chartWrapperSize.chartWrapperWidth,
  ]);
  // [render] For Tick Marks
  useEffect(() => {
    const chart = chartRef?.current.chart;
    ChartUtil.caliperUtil.removeTickMarksGroup(chart); // remove TickMarksGroup

    if (caliperPlotLines.length !== 2 || !isTickMarksMode) return;

    ChartUtil.caliperUtil.renderTickMarks(chart, caliperPlotLines, theme);
  }, [caliperPlotLines, isTickMarksMode]);
  // [render] 10s strip mode별로 mouseTrackerElement, beatAddPlusButton on/off 설정
  useEffect(() => {
    const validateMouseTracker = validateRule.activateMouseTracker({
      tabType,
      editModeType,
      isCaliperMode,
      isOpenBeatContextmenu,
      isReportRepresentativeStripChangeMode:
        chartOption.isReportRepresentativeStripChangeMode,
    });

    if (!validateMouseTracker) return;

    const mouseTrackerElement =
      chartRef.current.container.current.querySelector(
        `.${CHART_EDIT_CONST.MOUSE_TRACKER}`
      );
    const beatAddPlusButton = mouseTrackerElement.querySelector(
      `.${CHART_EDIT_CONST.BEAT_ADD_PLUS_BUTTON}`
    );
    if (validateMouseTracker) {
      mouseTrackerElement.style.visibility = 'visible';
      beatAddPlusButton.style.visibility = 'hidden';
    }
    if (isCaliperMode && !isAddBeatMode) {
      mouseTrackerElement.style.visibility = 'hidden';
    }
    if (isAddBeatMode) {
      beatAddPlusButton.style.visibility = 'visible';
    }
  }, [isCaliperMode, isAddBeatMode]);

  /********************************************************/
  /* event Listener                                       */
  /*  ㄴ click - chartContainer                           */
  /*  ㄴ mousemove - Mouse tracker                        */
  /*  ㄴ mousedown, mousemove, mouseup - drag feature     */
  /********************************************************/
  // [event] click - chartContainer
  useEffect(() => {
    const chartInst = chartRef.current.chart;

    const onClick = (event) => {
      // ADD_BEAT 모드일 때만 동작
      const isAddBeatMode =
        editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
      const clickedWaveformIndex = Math.round(
        chartInst.xAxis[0].toValue(event.chartX)
      );
      const isLeadOff = leadOffList.some((leadOff) => {
        const globalWaveformIndex =
          recordingStartMs +
          convertWaveformIndexToMs(onsetWaveformIdx + clickedWaveformIndex);
        return (
          leadOff.onsetMs <= globalWaveformIndex &&
          leadOff.terminationMs >= globalWaveformIndex
        );
      });
      const hasBeatEditContextmenuComponent = getParentComponent(
        event.target,
        ComponentId.BEAT_EDIT_CONTEXTMENU
      );

      // 10s strip 하이차트 클릭 동작 방지
      if (
        isLeadOff ||
        !isAddBeatMode ||
        hasBeatEditContextmenuComponent // 비트 추가, 편집시 open 되는 contextmenu 클릭 경우
      ) {
        return;
      }

      setSelectedBeatBtnInfoList([
        { waveformIndex: clickedWaveformIndex, beatType: undefined },
      ]);

      // 차트 클릭과 차트 내부 그래프가 클릭 되는 경우 e.target의 위치를 다르게 잡아서
      // 그래프가 클릭된 경우 화면 밖에 컨텍스트 메뉴가 위치하게 되어 수정
      if (event.target.classList.contains('highcharts-tracker-line')) {
        setBeatContextmenuPosition({
          positionX: event.chartX + 40,
          positionY: event.chartY + 50,
        });
      } else {
        setBeatContextmenuPosition({
          positionX: event.offsetX + 40,
          positionY: event.offsetY + 50,
        });
      }

      handleBeatContextmenu(isOpenBeatContextmenu ? false : true);
      clickFlagRef.current = true;

      // drag 관련 설정
      if (editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT) {
        setDragFlag(false);
      }
      event.stopPropagation();
    };

    chartContainerRef.current.addEventListener('click', onClick);

    return () => {
      chartContainerRef.current &&
        chartContainerRef.current.removeEventListener('click', onClick);
    };
  }, [editModeType, isOpenBeatContextmenu]);
  // [event] mousemove - Mouse tracker
  useEffect(() => {
    const chartInst = chartRef.current.chart;
    const validateMouseTracker = validateRule.activateMouseTracker({
      tabType,
      editModeType,
      isCaliperMode,
      isOpenBeatContextmenu,
      isReportRepresentativeStripChangeMode:
        chartOption.isReportRepresentativeStripChangeMode,
    });
    if (!validateMouseTracker) return;

    function onMouseMove(e) {
      const clickedWaveformIndex = Math.round(
        chartInst.xAxis[0].toValue(e.chartX)
      );
      const isLeadOff = leadOffList.some(
        (leadOff) =>
          leadOff.onsetMs <=
            recordingStartMs + (onsetWaveformIdx + clickedWaveformIndex) * 4 &&
          leadOff.terminationMs >=
            recordingStartMs + (onsetWaveformIdx + clickedWaveformIndex) * 4
      );

      // Prevent Hover if [leadOff].includes(clickedWaveformIndex)
      if (isLeadOff) return;

      // caliper mode 여부에 따른 mouse tracker dom 설정
      [
        ...chartRef.current.container.current.querySelectorAll(
          isCaliperMode &&
            caliperPlotLines.length < 2 &&
            editModeType !== TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT
            ? `.${CLASS_CONST_CALIPER_MOUSE_TRACKER}`
            : `.${CHART_EDIT_CONST.MOUSE_TRACKER}`
        ),
      ].forEach(($, i) => {
        if (i === 0) {
          $.style.transform = `translate(${e.chartX}px)`;
        } else {
          $.style.transform = `translate(${
            e.chartX - $.getClientRects()[0].width / 2
          }px)`;
        }
      });
    }

    chartContainerRef.current.addEventListener('mousemove', onMouseMove);
    return () => {
      chartContainerRef.current &&
        chartContainerRef.current.removeEventListener('mousemove', onMouseMove);
    };
  }, [
    // isAddBeatMode,
    isCaliperMode,
    caliperPlotLines,
    editModeType,
    isOpenBeatContextmenu,
    chartOption.isReportRepresentativeStripChangeMode,
  ]);
  // [event] mousedown, mousemove, mouseup - drag(10s strip)
  // todo: jyoon(240708) - [refactor] drag case, strategy pattern으로 변경 필요
  useEffect(() => {
    const chartInst = chartRef.current.chart;
    const chartEditUtil = ChartUtil.chartEdit(chartInst, { theme });
    let mouseDownPointWfi, onsetClickedPosition, swappedPosition;
    let dragMetaData = {};

    const validateActivateDrag = validateRule.activateDrag({
      tabType,
      editModeType,
      isCaliperMode,
      isReportRepresentativeStripChangeMode:
        chartOption.isReportRepresentativeStripChangeMode,
    });
    if (!validateActivateDrag) {
      _removeSelectionMarkerAll();
      chartEditUtil.removeSelectionHighlightAll();
      return;
    }

    // todo: jyoon(240626) - [refactor]
    function onMousedown(event) {
      event.preventDefault();
      clickFlagRef.current = true;

      if (isOpenBeatContextmenu || isCaliperMode) return;

      const isAvailableMousedown = validateMousedown(event, dragMetaData);
      if (!isAvailableMousedown) {
        dragMetaData.isAvailableMousedown = isAvailableMousedown;
        return;
      }

      // drag전 선택된 beat button list를 unselected로 변경
      for (let beatLabelButtonInst of chartInst.beatLabelButtonInstList) {
        beatLabelButtonInst.setState(0);
      }

      if (isRightClick(event)) {
        // open context menu
        const onsetSelectionMarker = chartEditUtil.getSelectionMarker({
          chartType: CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP,
          selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
        });
        const terminationSelectionMarker = chartEditUtil.getSelectionMarker({
          chartType: CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP,
          selectionMarkerType: SELECTION_MARKER_TYPE.TERMINATION,
        });

        const hasOnsetTerminationSelectionStrip =
          onsetSelectionMarker &&
          terminationSelectionMarker &&
          onsetSelectionMarker.length > 0 &&
          terminationSelectionMarker.length > 0;

        if (hasOnsetTerminationSelectionStrip) {
          setBeatContextmenuPosition({
            positionX: event.offsetX + 40,
            positionY: event.offsetY + 50,
          });
          handleBeatContextmenu(true);
        }
        return;
      }

      mouseDownPointWfi = Math.round(chartInst.xAxis[0].toValue(event.layerX));
      onsetClickedPosition = mouseDownPointWfi;

      _removeSelectionMarkerAll();
      chartEditUtil.removeSelectionHighlightAll();
      // todo: jyoon(240708) - [refactor] 10sec strip도 30s strip과 동일하게 renderSelectionStrip으로 호출을 통해서 생성하도록 변경 필요
      // selection strip 생성이 30s strip에서만 해당하는 로직을 만듬
      // 30sec strip은 - chartEditUtil.renderSelectionStrip으로 호출을 통해서 생성함
      //               - renderSelectionStrip내부에서 renderSelectionMarker 호출 해서 생성함
      // 10sec strip은 - chartEditUtil.renderSelectionMarker로 호출을 통해서 생성함
      // 10sec strip도 30s strip과 동일하게 renderSelectionStrip으로 호출을 통해서 생성하도록 변경 필요
      chartEditUtil.renderSelectionMarker({
        xAxis: mouseDownPointWfi,
        type: HIGHCHART_UNIT.LOCATION,
        selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
        offsetInfo: SELECTION_MARKER_OFFSET_CONST.TENSEC_STRIP,
        withRepresentativeSelectMarker: false,
      });
    }
    function onMousemove(event) {
      const isAvailableMouseMove = validateMousemove({
        event,
        clickFlagRef,
        isOpenBeatContextmenu,
      });
      if (!isAvailableMouseMove) return;

      // 10s strip - drag feature(highlight, button select)
      dragFeatureOfTensecStrip({
        tensecStripChartInst: chartInst,
        //
        event,
        mouseDownPointWfi,
        initTenSecStripDetail,
        //
        setSelectedBeatBtnInfoList,
        setChartOption,
        setSelectedBeatOption,
        //
        chartEditUtil,
      });
    }
    function onMouseup(event) {
      clickFlagRef.current = false;
      event.preventDefault();

      /***********************************************************************************************/
      /* step1. validation1 - validate chart mouse up                                                */
      /* step1.1. validation2 - mouseUp 할 때 mouseDownPointWfi이 없거나                             */
      /*                     같은 곳을 클릭한 경우 return                                            */
      /* step1.2. validation3 - selection strip 구간에 beat button이 있는지 확인                      */
      /*                                                                                             */
      /* step2. set drag flag                                                                        */
      /* step3. render selection marker                                                              */
      /* step4. render selection highlight                                                           */
      /* step5. 선택된 beat button list를 selectedBeatBtnInfoList(실제 편집시 사용하는 state)에 세팅 */
      /* step6. context menu 관련 설정                                                               */
      /***********************************************************************************************/
      const mouseUpLayerX = event.layerX;
      const mouseUpPointWfi = Math.round(
        chartInst.xAxis[0].toValue(mouseUpLayerX)
      );
      const isShapeReview = tabType === TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW;

      // step1. validation1 - validate chart mouse up
      const isAvailableMouseupTarget = validateMouseupTarget({
        event,
        dragMetaData,
      });
      if (!isAvailableMouseupTarget) {
        initTenSecStripDetail();
        return;
      }

      // step1.1. validation2 - mouseUp 할 때 mouseDownPointWfi이 없거나 같은 곳을 클릭한 경우 return
      const isAvailableMousedownPosition = validateMouseupPosition({
        mouseDownPointWfi,
        mouseUpPointWfi,
      });
      if (!isAvailableMousedownPosition) return;

      // step1.2. validation3 - selection strip 구간에 beat button이 있는지 확인
      const hasBeatLabelButtonBetweenDragRange =
        getHasBeatLabelButtonBetweenDragRange(
          chartInst.beatLabelButtonInstList,
          {
            mouseDownPointWfi,
            mouseUpPointWfi,
          }
        );

      if (!hasBeatLabelButtonBetweenDragRange) {
        mouseDownPointWfi = null;
        chartEditUtil.removeSelectionHighlightAll();
        chartEditUtil.removeSelectionMarkerAllInTenSecStrip();
        return;
      }

      // step2. set drag flag
      setDragFlag(true);

      if (isShapeReview) {
        handleSelectedItemList({
          panelType: 'EVENTS',
          selectedItemList: new Map(),
        });
      }

      // step3. render selection marker
      mouseDownPointWfi = Math.round(chartInst.xAxis[0].toValue(event.layerX));
      if (onsetClickedPosition > mouseDownPointWfi) {
        swappedPosition = onsetClickedPosition;
        onsetClickedPosition = mouseDownPointWfi;
        mouseDownPointWfi = swappedPosition;
      }

      chartEditUtil.removeSelectionHighlightAll();
      chartEditUtil.renderSelectionMarker({
        xAxis: swappedPosition ? onsetClickedPosition : mouseDownPointWfi,
        type: HIGHCHART_UNIT.LOCATION,
        selectionMarkerType: SELECTION_MARKER_TYPE.TERMINATION,
        offsetInfo: SELECTION_MARKER_OFFSET_CONST.TENSEC_STRIP,
        withRepresentativeSelectMarker: false,
      });

      // step4. render selection highlight
      if (!isOpenBeatContextmenu) {
        _renderSelectionHighlight(chartEditUtil, {
          onsetWaveformIndex: onsetClickedPosition,
          terminationWaveformIndex: mouseDownPointWfi,
        });
      }

      // step5. 선택된 beat button list를 selectedBeatBtnInfoList(실제 편집시 사용하는 state)에 세팅
      const selectedBtnList = chartInst.beatLabelButtonInstList.filter(
        (beatLabelButtonInst) => beatLabelButtonInst.state === 2
      );
      const selectedBtnListFormatted = selectedBtnList.map((btnInst) => {
        return {
          waveformIndex: btnInst.beatLabelButtonMetaData.xAxisPoint,
          beatType: undefined,
        };
      });
      setSelectedBeatBtnInfoList((prev) => {
        return [...selectedBtnListFormatted];
      });

      // step6. context menu 관련 설정
      handleBeatContextmenu(true);
      setBeatContextmenuPosition({
        positionX: event.offsetX + 40,
        positionY: event.offsetY + 50,
      });
    }
    function onContextmenu(event) {
      event.preventDefault();
    }

    // selection marker제거기능 10s, 30s 모두 있음
    function _removeSelectionMarkerAll() {
      chartEditUtil.removeSelectionMarkerAllInTenSecStrip();
    }

    // shape review에서 편집 요청 후 완료 전에 drag(onMousemove)를 하고 있는경우 selectionMarker가 정상적으로 두개가 되지 않고
    // contextMenu가 노출이 되지 않기 떄문에 marker 와 highlight를 지우고 다시 편집을 하도록 수정
    const selectionMarkers = document.querySelectorAll(
      `[class^="huinno-selectionMarker-TEN_SEC_STRIP"]`
    );
    const countSelectionMarker = selectionMarkers.length;

    chartContainerRef.current.addEventListener('contextmenu', onContextmenu);
    chartContainerRef.current.addEventListener('mousedown', onMousedown);
    chartContainerRef.current.addEventListener('mousemove', onMousemove);
    chartContainerRef.current.addEventListener('mouseup', onMouseup);

    if (
      countSelectionMarker === 1 &&
      tabType === TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW
    ) {
      initTenSecStripDetail();
      _removeSelectionMarkerAll();
      chartEditUtil.removeSelectionHighlightAll();
    }

    return () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.addEventListener(
          'contextmenu',
          onContextmenu
        );
        chartContainerRef.current.removeEventListener('mousedown', onMousedown);
        chartContainerRef.current.removeEventListener('mouseup', onMouseup);
        chartContainerRef.current.removeEventListener('mousemove', onMousemove);
      }
    };
  }, [
    isOpenBeatContextmenu,
    editModeType,
    isCaliperMode,
    tenSecStripDetail,
    selectedBeatBtnInfoList,
  ]);

  /***********************/
  /*     caliperMode     */
  /***********************/
  // Render Caliper Element
  useEffect(() => {
    if (caliperPlotLines.length === 0) return;
    const chartInst = chartRef.current.chart;

    if (!document.querySelector(`.${CLASS_CONST_CALIPER_GROUP}`)) {
      // Make Caliper Groups, Tick Marks Group
      ChartUtil.caliperUtil.makeGroups(chartInst);
    }

    // Render Left & Right Leg Groups Child Element
    ChartUtil.caliperUtil.renderLegElement(chartInst, caliperPlotLines, theme);

    if (
      caliperPlotLines.length === 2 &&
      !document.querySelector(`.${CLASS_CONST_CALIPER_CENTER_LEG_GROUP}`)
    ) {
      // Render Center Leg Group
      ChartUtil.caliperUtil.renderCenterLegGroup(
        chartInst,
        caliperPlotLines,
        theme
      );
    }
  }, [isCaliperMode, caliperPlotLines]);
  // Drag Func
  useEffect(() => {
    if (caliperPlotLines.length !== 2) return;

    const chartInst = chartRef.current.chart;
    const chartOriginFuncOfOnMouseMove = chartInst.container.onmousemove;

    ChartUtil.caliperUtil.drag(
      chartInst,
      caliperPlotLines,
      handleCaliperPlotLines,
      editModeType,
      chartOriginFuncOfOnMouseMove
    );

    return () => {
      if (chartInst.container?.onmousemove) {
        chartInst.container.onmousemove = chartOriginFuncOfOnMouseMove;
      }
    };
  }, [caliperPlotLines, editModeType]);
  // caliper Click Control
  // [event - click] chartContainer
  useEffect(() => {
    const eventHandler = (e) => {
      if (
        editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT ||
        !isCaliperMode
      ) {
        return;
      }

      if (caliperPlotLines.length < 2) {
        handleCaliperPlotLines(
          [...caliperPlotLines, e.chartX].sort((a, b) => a - b)
        );
      }
    };

    chartContainerRef.current.addEventListener('click', eventHandler);
    return () => {
      chartContainerRef.current &&
        chartContainerRef.current.removeEventListener('click', eventHandler);
    };
  }, [isCaliperMode, caliperPlotLines]);
  // Caliper Interval이 차트 영역 밖을 벗어나지 않도록 보정
  useEffect(() => {
    const chartInst = chartRef?.current.chart;
    if (!chartInst.caliperCenterLegGroup) return;

    ChartUtil.caliperUtil.adjustIntervalCoordWithinBoundary(
      chartInst,
      caliperPlotLines
    );
  }, [caliperPlotLines]);

  // redux selector

  return (
    <ShortTermChart
      // props
      chartOption={chartOption}
      setChartOption={setChartOption}
      selectedBeatBtnInfoList={selectedBeatBtnInfoList}
      setSelectedBeatBtnInfoList={setSelectedBeatBtnInfoList}
      recordingStartMs={recordingStartMs}
      // local state
      chartRef={chartRef}
      chartContainerRef={chartContainerRef}
      clickFlagRef={clickFlagRef}
      dragFlag={dragFlag}
      chartWrapperSize={chartWrapperSize}
      theme={theme}
      initTenSecStripDetail={initTenSecStripDetail}
      highlightArea={highlightArea}
      typeOfCenterWI={typeOfCenterWI}
      // redux state
      ecgRaw={ecgRawState}
      onsetWaveformIdx={onsetWaveformIdx}
      beatLabelButtonDataList={beatLabelButtonDataList}
      beatReviewSidePanelState={beatReviewSidePanelState}
      beatContextmenuPositionSetting={{
        beatContextmenuPosition,
        setBeatContextmenuPosition,
      }}
      selectedBeatOptionSetting={{
        selectedBeatOption,
        setSelectedBeatOption,
      }}
      // redux action creator
      isOpenBeatContextmenuSetting={{
        isOpenBeatContextmenu,
        handleBeatContextmenu,
      }}
      caliperPlotLines={caliperPlotLines}
      setCaliperPlotLines={handleCaliperPlotLines}
    />
  );
}

export default forwardRef(TenSecStripDetailChartContainer);
