import { useDispatch } from 'react-redux';

import { EVENT_GROUP_TYPE, SIDE_PANEL_EVENT_GROUP } from 'constant/EventConst';
import {
  ShapeReviewSectionArea,
  ShapeReviewState,
} from 'constant/ShapeReviewConst';
import { ECG_CHART_UNIT } from 'constant/ChartEditConst';
import LocalStorageKey from 'constant/LocalStorageKey';

import { roundToSecondDecimalPlace } from 'util/Utility';
import { getUpdateBeatActionCreatorDuckMap } from 'util/selectTenSecDuckMap';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import useGetEventTypeOfWI from 'component/hook/useGetEventTypeOfWI';

import SelectedItemPanelFragment from 'component/fragment/test-result/shape-review/SelectedItemPanel';

import LocalStorageManager from 'manager/LocalStorageManager';
import {
  selectIsCoupletEctopicTypeClicked,
  selectIsMultiSelectedEvents,
  selectIsMultiSelectedForms,
  selectIsClickedFormEdited,
  selectIsSelectedEvents,
  selectIsSelectedForms,
  selectIsClickedEventEdited,
  selectNumberOfClickedEventsType,
  selectSurroundingBeatHrBpm,
  selectTenSecStripDetail,
  selectClickedInfo,
  selectNumberOfClickedForms,
  selectFirstSelectedInfoOfEventPanel,
  selectNumberOfChecked,
  selectFormPanelDetailOfFirstIndexOfLastSelectedSection,
  selectFilterSelectedItemListOfEventPanel,
  selectFilterSelectedItemListOfFormPanel,
  selectEventPoolingStateOfSelectedEvent,
  patchBeatsByFormIdRequested,
  selectEventPanelDetailOfFirstIndexOfLastSelectedSection,
  selectEventPanelDetailOfSecondIndexOfLastSelectedSection,
  selectSelectAllInfo,
} from 'redux/duck/shapeReviewDuck';
import { showDialog } from 'redux/duck/dialogDuck';
import { ChartListItem } from 'redux/duck/shapeReview/shapeReviewDuckType';

import { ActivePanelType } from '../ShapeReviewFragmentContainer';

export interface IShowDialogParams {
  dialogKey: string;
  params: any;
  callback?: any;
}

function SelectedItemPanelFragmentContainer(props: any) {
  const dispatch = useDispatch();

  // selectors
  const { pending: eventPoolingStateOfSelectedEventPending } =
    useShallowEqualSelector(selectEventPoolingStateOfSelectedEvent) as {
      pending: boolean;
    };
  const { eventType } = useShallowEqualSelector(selectClickedInfo) as any;

  const { index: firstSelectedInfoIndexOfEventPanel } =
    useShallowEqualSelector(selectFirstSelectedInfoOfEventPanel) || ({} as any);
  const numberOfClickedForms = useShallowEqualSelector(
    selectNumberOfClickedForms
  ) as number;
  const numberOfClickedEventsType = useShallowEqualSelector(
    selectNumberOfClickedEventsType
  ) as number;
  const numberOfChecked = useShallowEqualSelector(
    selectNumberOfChecked
  ) as number;
  const formPanelDetail = useShallowEqualSelector(
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection
  ) as any;
  const clusterFormDetail = useShallowEqualSelector(
    (state: any) => state.shapeReviewReducer.clusterFormDetail[0]
  ) as any;
  const isAllChecked = useShallowEqualSelector(selectSelectAllInfo) as any;

  const {
    onsetMs: tenSecStripOnsetMs,
    onsetWaveformIdx,
    terminationWaveformIdx,
    beatsOrigin,
    pending: tenSecStripDetailEditPending,
  } = useShallowEqualSelector(selectTenSecStripDetail) as any;

  const [prevHrBpm, beatHrBpm, nextHrBpm] = useShallowEqualSelector(
    selectSurroundingBeatHrBpm
  ) as [Number | 'N/A', Number | 'N/A', Number | 'N/A'];
  const isCoupletEctopicTypeClicked = useShallowEqualSelector(
    selectIsCoupletEctopicTypeClicked
  ) as boolean;
  const isClickedFormEdited = useShallowEqualSelector(
    selectIsClickedFormEdited
  ) as boolean;
  const isClickedEventEdited = useShallowEqualSelector(
    selectIsClickedEventEdited
  ) as boolean;
  const isSelectedForms = useShallowEqualSelector(
    selectIsSelectedForms
  ) as boolean;
  const isSelectedEvents = useShallowEqualSelector(
    selectIsSelectedEvents
  ) as any;
  const isMultiSelectedForms = useShallowEqualSelector(
    selectIsMultiSelectedForms
  ) as boolean;
  const isMultiSelectedEvents = useShallowEqualSelector(
    selectIsMultiSelectedEvents
  ) as boolean;
  const { onset, termination } = useShallowEqualSelector(
    isSelectedEvents
      ? selectFilterSelectedItemListOfEventPanel
      : selectFilterSelectedItemListOfFormPanel
  ) as any;
  const activePanel = useShallowEqualSelector(
    (state: any) =>
      state.shapeReviewReducer.shapeReviewState[ShapeReviewState.ACTIVE_PANEL]
  ) as ActivePanelType;
  const isTenSecStripEditMode = useShallowEqualSelector(
    (state: any) =>
      state.shapeReviewReducer.tenSecStripEditMode.tenSecStripEditing
  ) as boolean;
  const selectedEventDetail = useShallowEqualSelector(
    selectEventPanelDetailOfFirstIndexOfLastSelectedSection
  ) as ChartListItem;
  const selectedSecondEventDetail = useShallowEqualSelector(
    selectEventPanelDetailOfSecondIndexOfLastSelectedSection
  ) as ChartListItem;

  // dispatches
  const handlePatchBeatsByFormIdRequested = (reqBody: { beatType: number }) => {
    dispatch(patchBeatsByFormIdRequested(reqBody));
  };

  const handlePatchBeatsByWaveformIndexRequested = (reqBody: {
    tenSecStripDetailEditPending: boolean;
  }) => {
    dispatch(
      getUpdateBeatActionCreatorDuckMap('patchEvents', reqBody)[
        'SHAPE_REVIEW'
      ]()
    );
  };

  const handleDeleteBeatsByWaveformIndexRequested = (reqBody: {
    tenSecStripDetailEditPending: boolean;
  }) => {
    dispatch(
      getUpdateBeatActionCreatorDuckMap('deleteEvents', reqBody)[
        'SHAPE_REVIEW'
      ]()
    );
  };

  const handleDeleteBeatsByFormIdRequested = (reqBody: {
    tenSecStripDetailEditPending: boolean;
  }) => {
    dispatch(
      getUpdateBeatActionCreatorDuckMap('deleteForms', reqBody)[
        'SHAPE_REVIEW'
      ]()
    );
  };
  const handleShowDialog = ({
    dialogKey,
    params,
    callback,
  }: IShowDialogParams) => dispatch(showDialog(dialogKey, params, callback));

  // Custom hooks
  const typeOfCenterWI = useGetEventTypeOfWI({
    selectWaveformIndex:
      onsetWaveformIdx + ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX,
    onsetWaveformIndex: onsetWaveformIdx,
    terminationWaveformIndex: terminationWaveformIdx,
    beats: beatsOrigin,
  } as any);

  // local variables
  const beatCount = getFormInfoValue({
    key: 'beatCount',
    formPanelDetail,
    clusterFormDetail,
  });
  const formRankNumber = getFormInfoValue({
    key: 'formRankNumber',
    formPanelDetail,
    clusterFormDetail,
  });
  const distributionRatio = roundToSecondDecimalPlace(
    (beatCount / numberOfClickedEventsType) * 100
    // 폼에 포함된 이벤트 개수 / 전체 이벤트 개수 * 100 (두번째 자리에서 반올림)
  );
  const selectedBeatType = getSelectedBeatType(onset, termination);

  const selectedOriginWaveformIndex =
    selectedSecondEventDetail?.originWaveformIndex ??
    selectedEventDetail?.originWaveformIndex;
  const selectedTerminationWaveformIndex =
    selectedSecondEventDetail?.terminationWaveformIndex ??
    selectedEventDetail?.terminationWaveformIndex;

  const hasOriginWaveformIndex =
    (selectedSecondEventDetail &&
      selectedSecondEventDetail.beats.waveformIndex.includes(
        selectedOriginWaveformIndex
      )) ||
    selectedEventDetail?.beats.waveformIndex?.includes(
      selectedOriginWaveformIndex
    );

  const hasTerminationWaveformIndex =
    (selectedSecondEventDetail &&
      selectedSecondEventDetail.beats.waveformIndex.includes(
        selectedTerminationWaveformIndex
      )) ||
    selectedEventDetail?.beats.waveformIndex?.includes(
      selectedTerminationWaveformIndex
    );

  return (
    <SelectedItemPanelFragment
      selectedBeatType={selectedBeatType}
      activePanel={activePanel}
      handlePatchBeatsByFormIdRequested={handlePatchBeatsByFormIdRequested}
      handlePatchBeatsByWaveformIndexRequested={
        handlePatchBeatsByWaveformIndexRequested
      }
      handleDeleteBeatsByWaveformIndexRequested={
        handleDeleteBeatsByWaveformIndexRequested
      }
      handleDeleteBeatsByFormIdRequested={handleDeleteBeatsByFormIdRequested}
      formRankNumber={formRankNumber}
      distributionRatio={distributionRatio}
      numberOfChecked={numberOfChecked}
      prevHrBpm={prevHrBpm}
      beatHrBpm={beatHrBpm}
      nextHrBpm={nextHrBpm}
      isCoupletEctopicTypeClicked={isCoupletEctopicTypeClicked}
      tenSecStripDetailEditPending={tenSecStripDetailEditPending}
      isAllChecked={isAllChecked}
      isSelectedForms={isSelectedForms}
      isSelectedEvents={isSelectedEvents}
      isClickedFormEdited={isClickedFormEdited}
      isClickedEventEdited={isClickedEventEdited}
      isMultiSelectedForms={isMultiSelectedForms}
      isMultiSelectedEvents={isMultiSelectedEvents}
      tenSecStripOnsetMs={tenSecStripOnsetMs}
      beatCount={beatCount}
      isTenSecStripEditMode={isTenSecStripEditMode}
      numberOfClickedForms={numberOfClickedForms}
      hasOriginWaveformIndex={hasOriginWaveformIndex}
      hasTerminationWaveformIndex={hasTerminationWaveformIndex}
      openNewTabHandler={() =>
        openNewTabHandler({ typeOfCenterWI, onsetWaveformIdx })
      }
      onClickButtonGroupDataListHandler={(callback: Function) => {
        if (eventPoolingStateOfSelectedEventPending) return;

        onClickButtonGroupDataListHandler({
          isSelectedEvents,
          activePanel,
          numberOfClickedForms,
          numberOfChecked,
          handleShowDialog,
          callback,
        });
      }}
      itemPanelTitle={itemPanelTitleHandler({
        eventType,
        activePanel,
        formRankNumber,
        firstSelectedInfoIndexOfEventPanel,
        numberOfClickedForms,
        numberOfChecked,
      })}
      removeBeatSetting={{
        activePanel,
        tenSecStripDetailEditPending,
        handleDeleteBeatsByWaveformIndexRequested,
        handleDeleteBeatsByFormIdRequested,
      }}
    />
  );
}

export default SelectedItemPanelFragmentContainer;

interface openNewTabHandlerProps {
  typeOfCenterWI: string;
  onsetWaveformIdx: number;
}
export function openNewTabHandler({
  typeOfCenterWI,
  onsetWaveformIdx,
}: openNewTabHandlerProps) {
  const { origin, pathname } = window.location;

  const currentTidEventReviewUrl = origin + pathname + '?tab=event-review';
  const newBeatReviewSidePanelState = [
    {
      type: typeOfCenterWI,
      position: null,
      waveformIndex:
        onsetWaveformIdx + ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX,
      timeEventId: null,
    },
  ];
  LocalStorageManager.setItem(
    LocalStorageKey.SIDE_PANEL_STATE,
    newBeatReviewSidePanelState
  );

  window.open(currentTidEventReviewUrl, '_blank');
}

interface IOnClickButtonGroupDataListHandlerProps {
  isSelectedEvents: boolean;
  activePanel: ActivePanelType;
  numberOfClickedForms: number;
  numberOfChecked: number;
  handleShowDialog: Function;
  callback: Function;
}
function onClickButtonGroupDataListHandler({
  isSelectedEvents,
  activePanel,
  numberOfClickedForms,
  numberOfChecked,
  handleShowDialog,
  callback,
}: IOnClickButtonGroupDataListHandlerProps) {
  if (isSelectedEvents && activePanel === ShapeReviewSectionArea.EVENTS) {
    callback();
    return;
  }

  handleShowDialog({
    dialogKey: 'ConfirmDialog',
    params: {
      message:
        activePanel === ShapeReviewSectionArea.FORMS
          ? `선택한 ${numberOfClickedForms}개의 Form에 포함된 모든 Beat를 편집하시겠습니까?`
          : `선택한 ${numberOfClickedForms}개의 Form에 포함된 ${numberOfChecked.toLocaleString()}개의 Beat를 편집하시겠습니까?`,
      confirmButtonText: '편집',
      onSubmit: callback,
    },
  });
}

interface IItemPanelTitleHandlerProps {
  eventType: string;
  activePanel: ActivePanelType;
  formRankNumber: number;
  firstSelectedInfoIndexOfEventPanel: number;
  numberOfClickedForms: number;
  numberOfChecked: number;
}
function itemPanelTitleHandler({
  eventType,
  activePanel,
  formRankNumber = 0,
  firstSelectedInfoIndexOfEventPanel,
  numberOfClickedForms,
  numberOfChecked,
}: IItemPanelTitleHandlerProps) {
  if (numberOfClickedForms > 1) return `${numberOfClickedForms} selected`;
  if (numberOfChecked > 1 && activePanel === ShapeReviewSectionArea.EVENTS) {
    return `${numberOfChecked} selected`;
  }

  if (numberOfChecked === 0 || activePanel === ShapeReviewSectionArea.FORMS) {
    const shapeEctopicList = SIDE_PANEL_EVENT_GROUP[EVENT_GROUP_TYPE.SHAPE] as [
      any
    ];
    const clickedEventTypeLabel = shapeEctopicList.find(
      (v: any) => v.type === eventType
    )?.label;

    if (formRankNumber === 0) return `Edited ${clickedEventTypeLabel}`;
    if (formRankNumber === -1) return `Etc. ${clickedEventTypeLabel}`;

    return `Form ${formRankNumber}`;
  }

  return `Event ${firstSelectedInfoIndexOfEventPanel + 1}`;
}

function getSelectedBeatType(onset: any, termination: any) {
  if (onset.length === 0 && termination.length === 0) return;

  const extractBeatTypes = (
    formInfoList: any,
    keyType: 'onset' | 'termination'
  ) =>
    formInfoList
      .map((formInfo: any) => {
        const { beatType, waveformIndex } = formInfo.beats;
        const indexOfOriginWaveformIndexInWaveformIndexList =
          waveformIndex.indexOf(formInfo.originWaveformIndex);

        if (indexOfOriginWaveformIndexInWaveformIndexList < 0) return;

        const beatTypeIndex =
          keyType === 'onset'
            ? indexOfOriginWaveformIndexInWaveformIndexList
            : indexOfOriginWaveformIndexInWaveformIndexList + 1;

        return beatType[beatTypeIndex];
      })
      .filter((item: number | undefined) => item !== undefined);

  const selectedBeatTypes = [
    ...extractBeatTypes(onset, 'onset'),
    ...extractBeatTypes(termination, 'termination'),
  ];

  return selectedBeatTypes.every((val, i, arr) => val === arr[0])
    ? selectedBeatTypes[0]
    : undefined;
}

function getFormInfoValue({ key, formPanelDetail, clusterFormDetail }: any) {
  if (formPanelDetail && Object.keys(formPanelDetail.formInfo).length > 0) {
    return formPanelDetail.formInfo[key];
  } else if (clusterFormDetail) {
    return clusterFormDetail.formInfo[key];
  } else return { beatCount: 0, formRankNumber: 0 };
}
