import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  debounce,
  all,
} from 'redux-saga/effects';
import rfdc from 'rfdc';

import {
  CheckBoxAll,
  PoolingState,
  ShapeReviewSectionArea,
  ShapeReviewState,
  editType,
  shapeReviewFetchingOption,
} from 'constant/ShapeReviewConst';
import {
  EVENT_GROUP_TYPE,
  SIDE_PANEL_EVENT_GROUP,
  ECTOPIC_TYPE,
  EVENT_CONST_TYPES,
  SUFFIX_LIST,
} from 'constant/EventConst';
import {
  ECG_CHART_UNIT,
  POSITION_MOVE_TYPE,
  TEN_SEC_STRIP_DETAIL,
  TEN_SEC_STRIP_EDIT,
  rawAndEventCalledCase,
} from 'constant/ChartEditConst';

import {
  findIndexOfBeatEventDetailInFromList,
  getWillBeDisplayedFormDataList,
  getIndexOfNextPooling,
  getIndexOfNextPoolingInPrevCase,
  getNewCurrentPageDependOnPoolingData,
  getTargetMapFromPoolingEvents,
  getUpdatedEventsPoolingData,
  getUpdatedFormsPoolingData,
  isFormIdInList,
  updatePoolingBeatType,
  deleteBeatsByFormIdUpdatePoolingBeatType,
  getUpdatedDeletedEventsPoolingData,
} from 'util/reduxDuck/ShapeReviewDuckUtil';
import { unionArrays } from 'util/Utility';
import {
  getIsAllEventEdited,
  getIsIndeterminate,
  getSortOptionByOrdering,
} from 'util/shapeReview/shapeReviewUtil';
import { validateBeatEditResponse } from 'util/validation/ValidateBeatsEdit';
import { getTenSecAvgHrByCenter } from 'util/StripDataUtil';
import { compareECG } from 'util/OPFS/OPFSOperator';
import { shapeReviewPreSignedUrl } from 'util/PreSignedUrl/ShapeReviewPreSignedUrl';
import { ShapeReviewPatchBeatByWaveformIndexListCommand } from 'util/optimisticEventDataUpdate/shapeReview/shapeReview_PatchBeatByWaveformIndexList';

import ApiManager from 'network/ApiManager';
import { axiosSourceManager } from 'network/RestClient';

import { enqueueRequest } from './taskQueueDuck';
import {
  ArrangeRequiredStatus,
  BeatPostprocess,
  ClickedInfo,
  EventsPanelSelectedInfo,
  FormPanelSelectedInfo,
  PatchBeatByFormIds,
  PatchBeatByWaveFormIndexes,
  SelectedInfo,
  PanelSizeInfo,
  SelectAllInfo,
} from './shapeReview/shapeReviewDuckType';
import { selectIsWholeUnMark, selectRawBlob } from './testResultDuck';

const rfdcClone = rfdc();

// Constants
// Selector
// Actions
// etc function
// Reducer
// Action Creators
// Saga functions
// Saga

// Constants
const shapeReviewSidePanelEventsList =
  SIDE_PANEL_EVENT_GROUP[EVENT_GROUP_TYPE.SHAPE];
const ectopicType = ECTOPIC_TYPE;

/**
 * @typedef {Object} Beats
 * @property {number[]} waveformIndex - 비트의 웨이브폼 인덱스 배열
 * @property {number[]} beatType - 비트의 타입 배열
 * @property {number[]} hr - 비트의 심박수 배열
 */

/**
 * @typedef {Object} MainECG
 * @property {number[]} rawECG - 주요 ECG의 원시 ECG 배열
 * @property {number} onsetWaveformIndex - 시작 웨이브폼 인덱스
 * @property {number} terminationWaveformIndex - 종료 웨이브폼 인덱스
 * @property {number} onsetMs - 시작 시간(ms)
 * @property {number} terminationMs - 종료 시간(ms)
 */

/**
 * @typedef {Object} EctopicHR
 * @property {number|null} hrMin - 최소 이완심박수
 * @property {number|null} hrMax - 최대 이완심박수
 * @property {number|null} hrAvg - 평균 이완심박수
 */

/**
 * @typedef {Object} BeatEventDetail
 * @property {number} originWaveformIndex - 원본 웨이브폼 인덱스
 * @property {boolean} exists - 존재 여부
 * @property {number|null} rrHR - RR HR 값
 * @property {number} beatType - 비트 타입
 * @property {Beats} beats - 비트 정보
 * @property {number} sampleSize - 샘플 크기
 * @property {MainECG} mainECG - 주요 ECG 정보
 * @property {EctopicHR} ectopicHR - 이완심박수 정보
 */

/**
 * @typedef {Object} BeatEventDetailReturn
 * @property {BeatEventDetail} result - 결과 객체
 */

// Selector
const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;

//poolingData -> all
const selectPoolingDataOfAll = (state) => state.shapeReviewReducer.poolingData;

// shapeReviewReducer > poolingData
const selectPoolingDataOfEventPanelState = (state) =>
  state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS]
    .pauseState;
export const selectProgressPercentage = (state) =>
  state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS]
    .progressPercentage;

export const poolingDataEvent = (state) =>
  state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS];

// Form 선택 시 저장하는 선택된 Form의 PoolingData
export const selectPoolingDataOfSelectedForm = (state) =>
  state.shapeReviewReducer.selectedPoolingDataOfClusterForm.selectedPoolingData;

// shapeReviewReducer > shapeReviewState
export const selectActivePanel = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.ACTIVE_PANEL];
export const selectPaginationInfo: (state: any) => PanelSizeInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.PAGINATION_INFO];
const selectPanelSizeInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.PANEL_SIZE_INFO];
export const selectClickedInfo: (state: any) => ClickedInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.CLICKED_INFO];
export const selectSelectedInfo: (state: any) => SelectedInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECTED_INFO];
export const selectSelectAllInfo: (state: any) => SelectAllInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECT_ALL];
export const selectActiveOrderType = (state) =>
  state.shapeReviewReducer.shapeReviewState.orderingTypeSetting;

// shapeReviewReducer > selectedInfo
export const selectSelectedInfoOfFormsPanel: (
  state: any
) => FormPanelSelectedInfo = (state) =>
  state.shapeReviewReducer.selectedInfo[ShapeReviewSectionArea.FORMS];
export const selectSelectedInfoOfEventsPanel: (
  state: any
) => EventsPanelSelectedInfo = (state) =>
  state.shapeReviewReducer.selectedInfo[ShapeReviewSectionArea.EVENTS];

// shapeReviewReducer > form, event panel에 보여질 데이터
export const selectFormDataOfSelectedEvent = (state) =>
  state.shapeReviewReducer.clusterFormDetail;
export const selectEventDataOfSelectedForm = (state) =>
  state.shapeReviewReducer.clusterEventDetail;

// shapeReviewReducer > 수정된 정보
export const selectPatchBeatByFormIds: (state: any) => PatchBeatByFormIds = (
  state
) => state.shapeReviewReducer.patchBeatByFormIds;
export const selectPatchBeatByWaveFormIndexes: (
  state: any
) => PatchBeatByWaveFormIndexes = (state) =>
  state.shapeReviewReducer.patchBeatByWaveFormIndexes;

// shapeReviewReducer > etc
export const selectTenSecStripDetail = (state) =>
  state.shapeReviewReducer.tenSecStripDetail;
export const selectArrangeRequiredStatus: (
  state: any
) => ArrangeRequiredStatus = (state) =>
  state.shapeReviewReducer.arrangeRequiredStatus;
export const selectBeatPostprocessState: (state: any) => BeatPostprocess = (
  state
) => state.shapeReviewReducer.beatPostprocess;

// caliper 모드 state
export const selectIsCaliperMode = (state) =>
  state.shapeReviewReducer.caliper.isCaliperMode;

/**
 *
 * # shapeReviewState 정보
 * # focus, select chart 정보
 * # 선택된 정보 (이벤트 타입, 차트(focused, selected), 선택된 데이터 중 첫번째 데이터)
 * # pooling 조회 대상 list, pooling data
 * # edited data
 * # 기타 select
 */

/**
 * # shapeReviewState 정보
 *   - PAGINATION_INFO 정보
 *   - PANEL_SIZE_INFO 정보
 */
// - PAGINATION_INFO 정보
export const selectPanelPaginationInfo = (state, panelType) =>
  selectPaginationInfo(state)[panelType][selectClickedInfo(state).eventType];
export const selectFormPanelPaginationInfo = (state) =>
  selectPaginationInfo(state)[ShapeReviewSectionArea.FORMS][
    selectClickedInfo(state).eventType
  ];
export const selectEventPanelPaginationInfo = (state) =>
  selectPaginationInfo(state)[ShapeReviewSectionArea.EVENTS][
    selectClickedInfo(state).eventType
  ]?.[
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state)?.formInfo.id
  ];

// - PANEL_SIZE_INFO 정보
const selectFormPanelSizeInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.PANEL_SIZE_INFO][
    ShapeReviewSectionArea.FORMS
  ];
const selectEventPanelSizeInfo = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.PANEL_SIZE_INFO][
    ShapeReviewSectionArea.EVENTS
  ];

/*
 * # 선택된 정보 (이벤트 타입, 차트(focused, selected), 선택된 데이터 중 첫번째 데이터)
 *   1. 선택된 "이벤트타입" 정보
 *   2. 선택된 "차트(focused, selected)" 정보(lastSelectedSectionInfo, selectedItemList)
 *     - form panel의 선택된 차트 정보
 *     - event panel의 선택된 차트 정보
 *   3. 선택된 데이터 중 첫번째 데이터
 *     - Form panel에서 선택된 데이터 중 첫번째 데이터
 *     - Event panel에서 선택된 데이터 중 첫번째 데이터
 */
/**
 * 1. 선택된 "이벤트타입" 정보
 * */

export const selectSelectedEventType = (state) =>
  selectClickedInfo(state).eventType;

/**
 * 2. 선택된 "차트(focused, selected)" 정보(lastSelectedSectionInfo, selectedItemList)
 *   - form panel의 선택된 차트 정보[0] - 선택이 시작된 차트 정보
 *   - form panel의 선택된 차트 정보[1] - 계속해서 변경이 되는 마지막에 선택된 차트 정보 (ctrl + 이동, ctrl + click, shift + 이동, shift + click)
 *   - event panel의 선택된 차트 정보[0] - 선택이 시작된 차트 정보
 *   - event panel의 선택된 차트 정보[1] - 계속해서 변경이 되는 마지막에 선택된 차트 정보 (ctrl + 이동, ctrl + click, shift + 이동, shift + click)
 */
export const selectFirstSelectedInfoOfFormPanel = (state) =>
  selectSelectedInfoOfFormsPanel(state).lastSelectedSectionInfo[0];
export const selectSecondSelectedInfoOfFormPanel = (state) =>
  selectSelectedInfoOfFormsPanel(state).lastSelectedSectionInfo[1];
export const selectFirstSelectedInfoOfEventPanel = (state) =>
  selectSelectedInfoOfEventsPanel(state).lastSelectedSectionInfo[0];
export const selectSecondSelectedInfoOfEventPanel = (state) =>
  selectSelectedInfoOfEventsPanel(state)?.lastSelectedSectionInfo[1];

/**
 * 3. 선택된 데이터 중 첫번째 데이터
 *   - Form panel에서 선택된 데이터 중 첫번째 데이터
 *   - Form panel에서 선택된 데이터 중 두번째 데이터
 *   - Event panel에서 선택된 데이터 중 첫번째 데이터
 *   - Event panel에서 선택된 데이터 중 두번째 데이터
 * * selectFirstSelectedInfoOfFormPanel : lastSelectedSectionInfo[0], lastSelectedSectionInfo[1] 상태
 * * clusterEventDetail, clusterFormDetail의 selectFirstSelectedInfoOfFormPanel.index 의 formInfo 상태
 */
export const selectFormPanelDetailOfFirstIndexOfLastSelectedSection = (state) =>
  selectFormDataOfSelectedEvent(state)[
    selectFirstSelectedInfoOfFormPanel(state)?.index
  ] || { formInfo: {} };

export const selectFormPanelDetailOfSecondIndexOfLastSelectedSection = (
  state
) =>
  selectFormDataOfSelectedEvent(state)[
    selectSecondSelectedInfoOfFormPanel(state)?.index
  ] || { formInfo: {} };
export const selectEventPanelDetailOfFirstIndexOfLastSelectedSection = (
  state
) =>
  selectEventDataOfSelectedForm(state)[
    selectFirstSelectedInfoOfEventPanel(state)?.index
  ];
export const selectEventPanelDetailOfSecondIndexOfLastSelectedSection = (
  state
) =>
  selectEventDataOfSelectedForm(state)[
    selectSecondSelectedInfoOfEventPanel(state)?.index
  ];

/**
 * 4. 선택된 정보
 */
export const selectClickedSelectedInfoOfFormsPanel = (state) => {
  return state.shapeReviewReducer.shapeReviewState[
    ShapeReviewState.SELECTED_INFO
  ][ShapeReviewSectionArea.FORMS][selectClickedInfo(state).eventType];
};
export const selectClickedSelectedInfoOfEventPanel = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECTED_INFO][
    ShapeReviewSectionArea.EVENTS
  ][selectFirstSelectedInfoOfFormPanel(state)?.index];

/**
 *  # pooling 조회 대상 list, pooling data
 *  ## 1. 선택된 이벤트의 form panel 정보
 *    - 1.1 form info list (선택된 event type의 form panel에서 조회될 대상)
 *    - 1.2.1 form pooling data (선택된 이벤트의 form panel event 정보 - data)
 *    - 1.2.2 form pooling data (선택된 이벤트의 form panel event 정보 - pending, calledType)
 *
 *  ## 2. 선택된 forms의 event panel 정보
 *    - 2.1 event waveformIndexList list (선택된 form의 event panel에서 조회될 대상)
 *    - 2.2.1 event pooling data (선택된 form의 event panel 정보 - data)
 *    - 2.2.2 event pooling data (선택된 form의 event panel 정보 - pending, calledType, pauseState)
 */
// # 1. 선택된 이벤트의 form panel 정보
//   - 1.1 form info list (선택된 event type의 form panel에서 조회될 대상)
export const selectFormListInfoOfSelectedEvent = (state) =>
  state.shapeReviewReducer.clusterFormInfoList.data[
    selectClickedInfo(state).eventType
  ];
//   - 1.2.1 form pooling data (선택된 이벤트의 form panel event 정보 - data)
export const selectFormPoolingDataOfSelectedEvent = (state) =>
  state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.FORMS].data[
    selectClickedInfo(state).eventType
  ];
//   - 1.2.2 form pooling data (선택된 이벤트의 form panel event 정보 - pending, calledType)
export const selectFormPoolingStateOfSelectedEvent = (state) => ({
  pending:
    state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.FORMS].pending,
  calledType:
    state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.FORMS]
      .calledType,
});
// # 2. 선택된 forms의 event panel 정보
//   - 2.1 event waveformIndexList list (선택된 form의 event panel에서 조회될 대상)
const selectWaveformIndexListInfoOfSelectedForm = (state) =>
  state.shapeReviewReducer.waveformIndexListInfoOfForms.data[
    selectClickedInfo(state).eventType
  ][selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state)?.formInfo.id];
//   - 2.2.1 event pooling data (선택된 form의 event panel 정보 - data)
const selectEventPoolingDataOfSelectedForm = (state) => {
  return state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS]
    .data[selectClickedInfo(state).eventType]?.[
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state)?.formInfo.id
  ];
};
//   - 2.2.2 event pooling data (선택된 form의 event panel 정보 - pending, calledType, pauseState)
export const selectEventPoolingStateOfSelectedEvent = (state) => {
  const eventPanelPoolingDataState =
    state.shapeReviewReducer.poolingData[ShapeReviewSectionArea.EVENTS];
  return {
    pending: eventPanelPoolingDataState.pending,
    calledType: eventPanelPoolingDataState.calledType,
    pauseState: eventPanelPoolingDataState.pauseState,
  };
};

//  # 3 선택된 Form에 포함된 이벤트의 originWaveformIndex, terminationWaveformIndex 정보
//    - 3.1 선택된 form의 onset, termination waveformIndex 정보(ISO 인 경우에는 두 배열의 값이 같다.)
//    - ex. 3561 : {onsetWaveformIndexListOfForm:[],terminationWaveformIndexListOfForm:[]}
//    - selectClickedInfo : 클릭된 이벤트 타입(EVENT-TYPE-ISOLATE-2 ,..)
export const selectWaveformIndexListInfoOfForm = (state) =>
  state.shapeReviewReducer.waveformIndexListInfoOfForms.data[
    selectClickedInfo(state).eventType
  ];

// # 기타 select
/**
 * # edited data
 * - 선택한 event의 수정된 form list
 * - 선택한 form의 수정된 event list
 */
export const editedFormListByClickedEventType = (state) =>
  state.shapeReviewReducer.patchBeatByFormIds.patchedList[
    selectSelectedEventType(state)
  ];
export const editedEventListByClickedForm = (state) =>
  state.shapeReviewReducer.patchBeatByWaveFormIndexes.patchedList[
    selectSelectedEventType(state)
  ]?.[
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state)?.formInfo.id
  ] || [];
export const selectFormTotalCount = (state) => {
  const { totalFormsCount = 0 } =
    selectFormListInfoOfSelectedEvent(state) || {};
  const totalEventsCount = selectNumberOfClickedEventsType(state) ?? 0;

  return {
    totalEventsCount,
    totalFormsCount,
  };
};

export const selectNumberOfClickedForms = (state) =>
  [...selectSelectedInfoOfFormsPanel(state).selectedItemList].length;
export const selectNumberOfClickedEvents = (state) =>
  [...selectSelectedInfoOfEventsPanel(state).selectedItemList].length;
/**
 * @returns {boolean} form panel이 전체 선택 되어 있는 케이스
 */
export const selectIsFormPanelCheckedAll = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECT_ALL][
    ShapeReviewSectionArea.FORMS
  ] === CheckBoxAll.CheckedAll;
/**
 * @returns {boolean} event panel이 전체 선택 되어 있는 케이스
 */
export const selectIsEventsPanelCheckedAll = (state) =>
  state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECT_ALL][
    ShapeReviewSectionArea.EVENTS
  ] === CheckBoxAll.CheckedAll;
/**
 * @returns {boolean} 클릭한 이벤트타입이 Couplet인지에 대한 여부
 */
export const selectIsCoupletEctopicTypeClicked = (state) =>
  selectClickedInfo(state)?.ectopicType === ectopicType.COUPLET;
/**
 * @returns {boolean} 클릭한 Form이  한개이고, Edited 인 상태인지에 대한 여부
 */
export const selectIsClickedFormEdited = (state) => {
  const selectedFormsInfo = selectSelectedInfoOfFormsPanel(state);
  const formPanelDetailOfFirstIndexOfLastSelectedSection =
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state);

  if (!formPanelDetailOfFirstIndexOfLastSelectedSection) return;

  const firstIndexOfLastSelectedSectionInfoFormId =
    formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id;

  const selectEditedFormListByClickedEventType =
    editedFormListByClickedEventType(state);
  const selectedItemListLength = [...selectedFormsInfo.selectedItemList].length;

  return (
    selectEditedFormListByClickedEventType?.includes(
      firstIndexOfLastSelectedSectionInfoFormId
    ) && selectedItemListLength === 1
  );
};
export const selectIsClickedEventEdited = (state) => {
  const eventPanelDetailOfFirstIndexOfLastSelectedSection =
    selectEventPanelDetailOfFirstIndexOfLastSelectedSection(state);

  if (!eventPanelDetailOfFirstIndexOfLastSelectedSection) return;

  const originIndexOfEventPanelDetailOfFirstIndexOfLastSelectedSection =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.originWaveformIndex;

  const selectedEventsInfo = selectSelectedInfoOfEventsPanel(state);
  const selectEditedEventListByClickedForm =
    editedEventListByClickedForm(state);
  const selectedItemListLength = [...selectedEventsInfo.selectedItemList]
    .length;

  return (
    selectEditedEventListByClickedForm === editType.ALL ||
    (selectEditedEventListByClickedForm?.includes(
      originIndexOfEventPanelDetailOfFirstIndexOfLastSelectedSection
    ) &&
      selectedItemListLength === 1)
  );
};
/**
 * @returns {boolean} Form을 선택했는지에 대한 여부 (단일/복수 구분 X)
 */
export const selectIsSelectedForms = (state) => {
  const activePanel = selectActivePanel(state);
  const { FORMS: selectAllInfoOfForms } = selectSelectAllInfo(state);

  return (
    selectAllInfoOfForms !== CheckBoxAll.None &&
    activePanel === ShapeReviewSectionArea.FORMS
  );
};
/**
 * @returns {boolean} Events를 선택했는지에 대한 여부 (단일/복수 구분 X)
 */
export const selectIsSelectedEvents = (state) => {
  const activePanel = selectActivePanel(state);
  const { EVENTS: selectAllInfoOfEvents } = selectSelectAllInfo(state);

  return (
    selectAllInfoOfEvents !== CheckBoxAll.None &&
    activePanel === ShapeReviewSectionArea.EVENTS
  );
};
/**
 * @returns {boolean} Form을 단일 선택했는지에 대한 여부
 */
export const selectIsMultiSelectedForms = (state) => {
  const selectedInfoOfFormsPanel = selectSelectedInfoOfFormsPanel(state);
  const selectedItemListLength = [...selectedInfoOfFormsPanel.selectedItemList]
    .length;

  return selectedItemListLength > 1 && selectedItemListLength !== 0;
};
/**
 * @returns {boolean} Event를 복수 선택했는지에 대한 여부
 */
export const selectIsMultiSelectedEvents = (state) => {
  const selectedInfoOfEventsPanel = selectSelectedInfoOfEventsPanel(state);
  const selectedItemListLength = [...selectedInfoOfEventsPanel.selectedItemList]
    .length;

  return selectedItemListLength > 1 && selectedItemListLength !== 0;
};
/**
 * @returns {number} 선택한 Event Type의 총 비트 수
 */
export const selectNumberOfClickedEventsType = (state) => {
  const clusteringStatistics =
    state.shapeReviewReducer.clusteringStatistics.data;
  const clickedEventType = selectClickedInfo(state)?.eventType;

  const filteredEvent = shapeReviewSidePanelEventsList.find(
    (v) => v.type === clickedEventType
  );
  const eventSection = filteredEvent?.eventSection;
  const numberOfClickedEventsType = clusteringStatistics[eventSection];

  return numberOfClickedEventsType;
};
/**
 * @returns  {number[]} 선택한 Events의 [가운데 비트의 이전 Bpm, 가운데 비트 Bpm,가운데 비트의 다음 Bpm]
 */
export const selectSurroundingBeatHrBpm = (state) => {
  const firstSelectedInfoOfEventPanel =
    selectFirstSelectedInfoOfEventPanel(state);

  if (!firstSelectedInfoOfEventPanel) return ['N/A', 'N/A', 'N/A'];

  const eventPanelDetailOfFirstIndexOfLastSelectedSection =
    selectEventPanelDetailOfFirstIndexOfLastSelectedSection(state);

  if (!eventPanelDetailOfFirstIndexOfLastSelectedSection)
    return ['N/A', 'N/A', 'N/A'];

  const waveformIndex =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.beats.waveformIndex;
  const originWaveformIndex =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.originWaveformIndex;
  const indexOfCenterWaveformIndex = waveformIndex?.findIndex(
    (v) => v === originWaveformIndex
  );

  const prevHrBpm =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.beats.hr[
      indexOfCenterWaveformIndex - 1
    ];
  const beatHrBpm =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.beats.hr[
      indexOfCenterWaveformIndex
    ];
  const nextHrBpm =
    eventPanelDetailOfFirstIndexOfLastSelectedSection.beats.hr[
      indexOfCenterWaveformIndex + 1
    ];

  return [prevHrBpm, beatHrBpm, nextHrBpm].map((bpm) =>
    isNaN(bpm) || bpm === null || bpm === undefined ? '-' : Math.floor(bpm)
  );
};
/**
 * @returns {number} 선택한 체크박스의 개수
 */
export const selectNumberOfChecked = (state) => {
  const isSelectedForms = selectIsSelectedForms(state);
  const isSelectedEvents = selectIsSelectedEvents(state);
  const activePanel = selectActivePanel(state);

  if (!isSelectedForms && !isSelectedEvents) return;
  const isCoupletEctopicTypeClicked = selectIsCoupletEctopicTypeClicked(state);
  const isActiveSelectedInfo =
    (!isSelectedEvents && isSelectedForms) ||
    (isSelectedEvents && activePanel === ShapeReviewSectionArea.FORMS);
  let numberOfChecked = 0;

  if (isActiveSelectedInfo) {
    const selectedInfo = selectFilterSelectedItemListOfFormPanel(state) || {};
    numberOfChecked += sumBeatCount(selectedInfo.onset);

    if (isCoupletEctopicTypeClicked) {
      numberOfChecked += sumBeatCount(selectedInfo.termination);
    }
    return numberOfChecked;
  }

  const selectedInfo = selectSelectedInfoOfEventsPanel(state) || {};
  const selectedItemListArray = [...selectedInfo.selectedItemList];

  selectedItemListArray.forEach(([, v]) => {
    if (!Array.isArray(v.checkbox)) {
      return;
    }
    if (isCoupletEctopicTypeClicked) {
      numberOfChecked += v.checkbox.filter(Boolean).length;
    } else {
      numberOfChecked += Number(v.checkbox[0]);
    }
  });

  return numberOfChecked;

  function sumBeatCount(list = []) {
    return list.reduce((sum, item) => sum + item.formInfo.beatCount, 0);
  }
};

export const selectFilterSelectedItemListOfFormPanel = (state) => {
  const clickedInfo = selectClickedInfo(state);
  const formDataOfSelectedEvent = selectFormDataOfSelectedEvent(state);
  const { selectedItemList: selectedItemListOfFormPanel } =
    selectSelectedInfoOfFormsPanel(state);

  const filterSelectedItemList = formDataOfSelectedEvent.filter((_, i) =>
    selectedItemListOfFormPanel.has(i)
  );

  const onset = filterSelectedItemList.filter(
    (item) => selectedItemListOfFormPanel.get(item.index)?.checkbox?.[0]
  );
  const termination = filterSelectedItemList.filter(
    (item) =>
      selectedItemListOfFormPanel.get(item.index)?.checkbox?.[1] &&
      clickedInfo.ectopicType === ECTOPIC_TYPE.COUPLET
  );

  return {
    filterSelectedItemList,
    onset,
    termination:
      clickedInfo.ectopicType === ECTOPIC_TYPE.COUPLET ? termination : [],
  };
};
export const selectFilterSelectedItemListOfEventPanel = (state) => {
  const clickedInfo = selectClickedInfo(state);
  const eventDataOfSelectedForm = selectEventDataOfSelectedForm(state);
  const { selectedItemList: selectedItemListOfEventPanel } =
    selectSelectedInfoOfEventsPanel(state);

  const filterSelectedItemList = eventDataOfSelectedForm.filter((_, i) =>
    selectedItemListOfEventPanel.has(i)
  );

  const onset = filterSelectedItemList.filter(
    (item) => selectedItemListOfEventPanel.get(item.index)?.checkbox?.[0]
  );
  const termination = filterSelectedItemList.filter(
    (item) =>
      selectedItemListOfEventPanel.get(item.index)?.checkbox?.[1] &&
      clickedInfo.ectopicType === ECTOPIC_TYPE.COUPLET
  );

  return {
    filterSelectedItemList,
    onset,
    termination:
      clickedInfo.ectopicType === ECTOPIC_TYPE.COUPLET ? termination : [],
  };
};

export const selectCheckExistTargetPageData =
  ({ activePanelType, setPageType }) =>
  (state) => {
    let result;
    const selectedEventType = selectSelectedEventType(state);
    const formPanelDetailOfFirstIndexOfLastSelectedSection =
      selectFormPanelDetailOfFirstIndexOfLastSelectedSection(state);
    const clickedEventPaginationInfo =
      selectPaginationInfo(state)[activePanelType][selectedEventType];
    const clickedEventPanelSizeInfo =
      selectPanelSizeInfo(state)[activePanelType];

    if (!clickedEventPaginationInfo) {
      return;
    }

    let clickedEventCurrentPage, clickedTargetPoolingData;
    if (activePanelType === ShapeReviewSectionArea.FORMS) {
      clickedEventCurrentPage = clickedEventPaginationInfo.currentPage;
      clickedTargetPoolingData =
        state.shapeReviewReducer.poolingData[activePanelType].data[
          selectedEventType
        ];
    } else if (activePanelType === ShapeReviewSectionArea.EVENTS) {
      clickedEventCurrentPage =
        clickedEventPaginationInfo?.[
          formPanelDetailOfFirstIndexOfLastSelectedSection?.formInfo.id
        ]?.currentPage;
      clickedTargetPoolingData =
        state.shapeReviewReducer.poolingData[activePanelType].data[
          selectedEventType
        ][formPanelDetailOfFirstIndexOfLastSelectedSection?.formInfo.id];
    }
    let newCurrentPage;
    let waveFormIndexOfNextPageFirstItemIndex;
    if (setPageType === POSITION_MOVE_TYPE.PREV) {
      newCurrentPage = clickedEventCurrentPage - 1;
      if (newCurrentPage < 1) {
        newCurrentPage = 1;
      }
    } else if (setPageType === POSITION_MOVE_TYPE.NEXT) {
      newCurrentPage = clickedEventCurrentPage + 1;
      const nextPageFirstItemIndex =
        clickedEventPanelSizeInfo.pageSize * (newCurrentPage - 1);
      if (activePanelType === ShapeReviewSectionArea.FORMS) {
        waveFormIndexOfNextPageFirstItemIndex =
          state.shapeReviewReducer.clusterFormInfoList.data[selectedEventType]
            .formsThumbNailWaveFormIndex[nextPageFirstItemIndex];
      } else if (activePanelType === ShapeReviewSectionArea.EVENTS) {
        waveFormIndexOfNextPageFirstItemIndex =
          state.shapeReviewReducer.waveformIndexListInfoOfForms.data[
            selectedEventType
          ][formPanelDetailOfFirstIndexOfLastSelectedSection.id]
            .onsetWaveformIndexListOfForm[nextPageFirstItemIndex];
      }
    }
    result = clickedTargetPoolingData.get(
      waveFormIndexOfNextPageFirstItemIndex
    );
    return !!result;
  };

// Action
// # SET
const RESET_SHAPE_REVIEW_STATE = 'shape-review/RESET_SHAPE_REVIEW_STATE';
const SET_PANEL_SIZE = 'shape-review/SET_PANEL_SIZE';
const SET_SELECT_EVENT = 'shape-review/SET_SELECT_EVENT';

const SET_PAGE = 'shape-review/SET_PAGE';
const SET_PAGINATION = 'shape-review/SET_PAGINATION';
const SET_CLUSTERING_STATISTICS = 'shape-review/SET_CLUSTERING_STATISTICS';
const SET_TENSEC_STRIP_DETAIL = 'shape-review/SET_TENSEC_STRIP_DETAIL';
const RESET_TENSEC_STRIP_DETAIL = 'shape-review/RESET_TENSEC_STRIP_DETAIL';

// ## SET - 선택 관련
const SET_SELECT_ALL = 'shape-review/SET_SELECT_ALL';
const SET_ACTIVE_PANEL = 'shape-review/SET_ACTIVE_PANEL';
const SET_LAST_SELECTED_SECTION_INFO =
  'shape-review/SET_LAST_SELECTED_SECTION_INFO';
const SET_SELECTED_ITEM_LIST = 'shape-review/SET_SELECTED_ITEM_LIST';
const SET_INIT_SELECTED_ITEM_LIST = 'shape-review/SET_INIT_SELECTED_ITEM_LIST';

// ## SET - form panel에 보여질 데이터
const SET_FORM_DATA = 'shape-review/SET_FORM_DATA';
const SET_EVENT_DATA = 'shape-review/SET_EVENT_DATA';

/**
 * # api action list
 * ## api - fetching(clustering statistics)
 * ## api - fetching(form list)
 * ## api - fetching(form detail pooling Data)
 * ## api - fetching(event detail - raw and event)
 *
 * ## api - fetching(waveformIndexList of forms)
 * ## api - fetching(pooling data of event of form)
 *
 * ## api - patch beat update by formId
 * ## api - patch beat update by waveformIndex
 */
// ## api - fetching(clustering statistics)
const GET_CLUSTERING_STATISTICS_REQUESTED =
  'shape-review/GET_CLUSTERING_STATISTICS_REQUESTED';
const GET_CLUSTERING_STATISTICS_SUCCEED =
  'shape-review/GET_CLUSTERING_STATISTICS_SUCCEED';
const GET_CLUSTERING_STATISTICS_FAILED =
  'shape-review/GET_CLUSTERING_STATISTICS_FAILED';
// ## api - fetching(form list)
const GET_FORM_LIST_REQUESTED = 'shape-review/GET_FORM_LIST_REQUESTED';
const GET_FORM_LIST_SUCCEED = 'shape-review/GET_FORM_LIST_SUCCEED';
const GET_FORM_LIST_FAILED = 'shape-review/GET_FORM_LIST_FAILED';
// ## api - fetching(form detail pooling Data)
const GET_FORM_DETAIL_POOLING_DATA_REQUESTED =
  'shape-review/GET_FORM_DETAIL_POOLING_DATA_REQUESTED';
const GET_FORM_DETAIL_POOLING_DATA_SUCCEED =
  'shape-review/GET_FORM_DETAIL_POOLING_DATA_SUCCEED';
const GET_FORM_DETAIL_POOLING_DATA_FAILED =
  'shape-review/GET_FORM_DETAIL_POOLING_DATA_FAILED';
// ## api - fetching(form detail pooling Data - event detail(raw and event)
const GET_RAW_AND_EVENT_REQUESTED = 'shape-review/GET_RAW_AND_EVENT_REQUESTED';
const GET_RAW_AND_EVENT_SUCCEED = 'shape-review/GET_RAW_AND_EVENT_SUCCEED';
const GET_RAW_AND_EVENT_FAILED = 'shape-review/GET_RAW_AND_EVENT_FAILED';

// ## api - fetching(waveformIndexList of forms)
const DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED =
  'shape-review/DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED';
const GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED =
  'shape-review/GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED';
const GET_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED =
  'shape-review/GET_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED';
const GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED =
  'shape-review/GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED';
const GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED =
  'shape-review/GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED';
const GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED =
  'shape-review/GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED';
const GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_FAILED =
  'shape-review/GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_FAILED';
// ## api - fetching(pooling data of event of form)
const GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED =
  'shape-review/GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED';
const GET_POOLING_DATA_OF_EVENT_OF_FORM_SUCCEED =
  'shape-review/GET_POOLING_DATA_OF_EVENT_OF_FORM_SUCCEED';
const GET_POOLING_DATA_OF_EVENT_OF_FORM_FAILED =
  'shape-review/GET_POOLING_DATA_OF_EVENT_OF_FORM_FAILED';
const SET_PROGRESS_PERCENTAGE_POOLING_EVENT_LIST =
  'shape-review/SET_PROGRESS_PERCENTAGE_POOLING_EVENT_LIST';
const SET_PAUSE_POOLING_EVENT_LIST =
  'shape-review/SET_PAUSE_POOLING_EVENT_LIST';
const SET_FINISH_POOLING_EVENT_LIST =
  'shape-review/SET_FINISH_POOLING_EVENT_LIST';

// ## api - patch beat 방법1 (by formIds)
const PATCH_BEATS_BY_FORM_ID_REQUESTED =
  'shape-review/PATCH_BEATS_BY_FORM_ID_REQUESTED';
const PATCH_BEATS_BY_FORM_ID_SUCCEED =
  'shape-review/PATCH_BEATS_BY_FORM_ID_SUCCEED';
const PATCH_BEATS_BY_FORM_ID_FAILED = 'shape-review/PATCH_BEATS_FAILED';
// ## api - patch beat 방법2 (by waveformIndex)
const PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED =
  'shape-review/PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED';
const PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED =
  'shape-review/PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED';
const PATCH_BEATS_BY_WAVEFORM_INDEX_FAILED =
  'shape-review/PATCH_BEATS_BY_WAVEFORM_INDEX_FAILED';

// Beat Postprocess
const PATCH_BEAT_POSTPROCESS_REQUESTED =
  'shape-review/PATCH_BEAT_POSTPROCESS_REQUESTED';
const PATCH_BEAT_POSTPROCESS_SUCCEED =
  'shape-review/PATCH_BEAT_POSTPROCESS_SUCCEED';
const PATCH_BEAT_POSTPROCESS_FAILED =
  'shape-review/PATCH_BEAT_POSTPROCESS_FAILED';

// ## api - patch beat 방법1 (by formIds)
const DELETE_BEATS_BY_FORM_ID_REQUESTED =
  'shape-review/DELETE_BEATS_BY_FORM_ID_REQUESTED';
const DELETE_BEATS_BY_FORM_ID_SUCCEED =
  'shape-review/DELETE_BEATS_BY_FORM_ID_SUCCEED';
const DELETE_BEATS_BY_FORM_ID_FAILED = 'shape-review/DELETE_BEATS_FAILED';

// ## api - patch beat 방법2 (by waveformIndex)
const DELETE_BEATS_BY_WAVEFORM_INDEX_REQUESTED =
  'shape-review/DELETE_BEATS_BY_WAVEFORM_INDEX_REQUESTED';
const DELETE_BEATS_BY_WAVEFORM_INDEX_SUCCEED =
  'shape-review/DELETE_BEATS_BY_WAVEFORM_INDEX_SUCCEED';
const DELETE_BEATS_BY_WAVEFORM_INDEX_FAILED =
  'shape-review/DELETE_BEATS_BY_WAVEFORM_INDEX_FAILED';

// Arrange - (event-statistic) 재계산 + (clustering) 재정렬
const GET_ARRANGE_REQUIRED_STATUS_REQUESTED =
  'shape-review/GET_ARRANGE_REQUIRED_STATUS_REQUESTED';
const GET_ARRANGE_REQUIRED_STATUS_SUCCEED =
  'shape-review/GET_ARRANGE_REQUIRED_STATUS_SUCCEED';
const GET_ARRANGE_REQUIRED_STATUS_FAILED =
  'shape-review/GET_ARRANGE_REQUIRED_STATUS_FAILED';
const SET_IS_ARRANGE_REQUIRED = 'shape-review/SET_IS_ARRANGE_REQUIRED';

// Caliper
const SET_CALIPER_PLOT_LINES = 'shape-review/SET_CALIPER_PLOT_LINES';
const SET_IS_CALIPER_MODE = 'shape-review/SET_IS_CALIPER_MODE';
const SET_IS_TICK_MARKS_MODE = 'shape-review/SET_IS_TICK_MARKS_MODE';

// Add beats Mode
const SET_IS_TENSEC_STRIP_EDIT_MODE =
  'shape-review/SET_IS_TENSEC_STRIP_EDIT_MODE';

// 10s strip editing status
const SET_SHAPE_DETAIL_PEND_EDIT =
  'memo-web/shape-result/SET_SHAPE_DETAIL_PEND_EDIT';
const SET_SHAPE_DETAIL_EDITED = 'memo-web/shape-result/SET_SHAPE_DETAIL_EDITED';

// post beats
const POST_BEATS_REQUESTED = 'memo-web/shape-review/POST_BEATS_REQUESTED';
const POST_BEATS_SUCCEED = 'memo-web/shape-review/POST_BEATS_SUCCEED';
const POST_BEATS_FAILED = 'memo-web/shape-review/POST_BEATS_FAILED';

// patch beats
const PATCH_BEATS_REQUESTED = 'memo-web/shape-review/PATCH_BEATS_REQUESTED';
const PATCH_BEATS_SUCCEED = 'memo-web/shape-review/PATCH_BEATS_SUCCEED';
const PATCH_BEATS_FAILED = 'memo-web/shape-review/PATCH_BEATS_FAILED';

// delete beats
const DELETE_BEATS_REQUESTED = 'memo-web/shape-review/DELETE_BEATS_REQUESTED';
const DELETE_BEATS_SUCCEED = 'memo-web/shape-review/DELETE_BEATS_SUCCEED';
const DELETE_BEATS_FAILED = 'memo-web/shape-review/DELETE_BEATS_FAILED';

// Get entire events
const GET_BEATS_N_ECTOPIC_LIST_REQUESTED =
  'memo-web/shape-review/GET_BEATS_N_ECTOPIC_LIST_REQUESTED';
const GET_BEATS_N_ECTOPIC_LIST_SUCCEED =
  'memo-web/shape-review/GET_BEATS_N_ECTOPIC_LIST_SUCCEED';
const GET_BEATS_N_ECTOPIC_LIST_FAILED =
  'memo-web/shape-review/GET_BEATS_N_ECTOPIC_LIST_FAILED';

// events ordering
const SET_ORDER_SETTING_TYPE = 'memo-web/shape-review/SET_ORDER_SETTING_TYPE';

// Order Type Context Menu Show/Hide State
const SET_ORDERING_CONTEXTMENU =
  'memo-web/shape-review/SET_ORDERING_CONTEXTMENU';
//set checkbox status
// 처음 선택된 체크박스를 기준으로 하면 중간에 체크박스 옵션을 변경한 경우가 적용이 안되고
// lastSelectedInfo 를 기준으로 하면 중간에 삭제된 비트가 있는 경우 반복문을 돌면서 지워진 비트를 기준으로 변경시키기 때문에 변경 시점의 체크박스 옵션에 대한 상태가 필요함
const SET_SELECTED_CHECKBOX_STATUS =
  'memo-web/shape-review/SET_SELECTED_CHECKBOX_STATUS';

// Form 선택 시 선택한 Form의 poolingData를 redux 상태로 저장하는 액션
const SET_SELECTED_POOLING_DATA =
  'memo-web/shape-review/SET_SELECTED_POOLING_DATA';

/**
 * @typedef {Object} ShapeReviewEventType
 * @property {formsListObject} EVENT-TYPE-ISOLATE-2
 * @property {formsListObject} EVENT-TYPE-COUPLET-2
 * @property {formsListObject} EVENT-TYPE-ISOLATE-1
 * @property {formsListObject} EVENT-TYPE-COUPLET-1
 */

/**
 * @typedef {Object} formsListObject
 * @property {formsObject[]} forms
 * @property {WaveformIndex[]} formsThumbNailWaveFormIndex
 * @property {number} totalEditedBeatCount
 * @property {number} totalFormsCount
 */

/**
 * @typedef {Object} formsObject
 * @property {number} id
 * @property {number} beatCount
 * @property {number} editedBeatCount
 * @property {number} formBeatType
 * @property {string} formEctopicType
 * @property {number} formRankNumber
 * @property {number} representativeWaveformIndex
 */

/**
 * @typedef {Number} WaveformIndex Waveform Index
 */

/**
 * @typedef {Object} FormList
 * @property {BeatType} beatType
 * @property {EctopicType} ectopicType
 * @property {EventSection} eventSection
 * @property {string} label
 * @property {number} qty
 * @property {BeatReviewEvent} type // EventConstTypes에서 filter된 data
 */

/**
 * @type {FormList[]}
 */
let initFormPanelPagination = {};
let initEventPanelPagination = {};

let initFormPanelSelectedInfo = {};
let initEventPanelSelectedInfo = {};
/**
 * @type {ShapeReviewEventType}
 */
let initClusterFormInfoList = {};
let initWaveformIndexListInfoOfForm = {};

let initClusterFormPoolingData = {};
let initEventOfFormPoolingData = {};
for (const shapeReviewEvent of shapeReviewSidePanelEventsList) {
  const shapeReviewEventType = shapeReviewEvent.type;
  initFormPanelPagination[shapeReviewEventType] = {
    eventType: null,
    panelType: null,
    totalItemCount: null,
    pageSize: null,
    totalPageSize: null,
    currentPage: 1,
  };
  // type => [form.id]:{eventType:undefined,panelType:undefined,formId:undefined,totalItemCount:undefined,pageSize:undefined,totalPageSize:undefined,currentPage:1,};
  initEventPanelPagination[shapeReviewEventType] = {};
  initClusterFormInfoList[shapeReviewEventType] = {
    forms: [],
    formsThumbNailWaveFormIndex: [],
    totalEditedBeatCount: 0,
    totalFormsCount: 0,
  };
  // type: [shapeReviewEventType]: { [formsId]: {onsetWaveformIndexListOfForm: number[], totalBeatCount: number}}
  initWaveformIndexListInfoOfForm[shapeReviewEventType] = {};

  // type: [shapeReviewEventType]: { lastSelectedSectionInfo, selectedItemList }
  initFormPanelSelectedInfo[shapeReviewEventType] = {};
  // type: [shapeReviewEventType]: { [formsId]: { lastSelectedSectionInfo, selectedItemList }}
  initEventPanelSelectedInfo[shapeReviewEventType] = {};

  // pooling data
  initClusterFormPoolingData[shapeReviewEventType] = new Map(); // [shapeReviewEventType]: new Map() }
  initEventOfFormPoolingData[shapeReviewEventType] = {}; // [shapeReviewEventType]: { [formsId]: new Map() }
} // end of for

const initSelectedInfo = {
  [ShapeReviewSectionArea.FORMS]: {
    lastSelectedSectionInfo: [null, null],
    selectedItemList: new Map(),
  },
  [ShapeReviewSectionArea.EVENTS]: {
    lastSelectedSectionInfo: [null, null],
    selectedItemList: new Map(),
  },
};

// 데이터 기준(화면 기준 아님)
const initialState = {
  clusteringStatistics: {
    data: {},
    pending: false,
    error: null,
  },
  sidePanelState: {
    clickedInfo: {
      eventType: '', // EVENT-TYPE-ISOLATE-2 | EVENT-TYPE-COUPLET-2 | EVENT-TYPE-ISOLATE-1 | EVENT-TYPE-COUPLET-1,
      beatType: null,
      ectopicType: null,
    },
  },
  shapeReviewState: {
    [ShapeReviewState.ACTIVE_PANEL]: ShapeReviewSectionArea.FORMS, // SectionAreaEnum.EVENTS
    [ShapeReviewState.CLICKED_INFO]: {
      eventType: null, // BeatReviewEvent (EVENT-TYPE-ISOLATE-2 | EVENT-TYPE-COUPLET-2 | EVENT-TYPE-ISOLATE-1 | EVENT-TYPE-COUPLET-1)
      beatType: null, // BeatType
      ectopicType: null, // EctopicType
    },
    [ShapeReviewState.PANEL_SIZE_INFO]: {
      [ShapeReviewSectionArea.FORMS]: {
        columnNumber: null,
        rowNumber: null,
        pageSize: null,
      },
      [ShapeReviewSectionArea.EVENTS]: {
        columnNumber: null,
        rowNumber: null,
        pageSize: null,
      },
    },
    [ShapeReviewState.PAGINATION_INFO]: {
      [ShapeReviewSectionArea.FORMS]: initFormPanelPagination,
      [ShapeReviewSectionArea.EVENTS]: initEventPanelPagination,
    },
    [ShapeReviewState.SELECTED_INFO]: {
      [ShapeReviewSectionArea.FORMS]: initFormPanelSelectedInfo,
      [ShapeReviewSectionArea.EVENTS]: initEventPanelSelectedInfo,
    },
    [ShapeReviewState.SELECT_ALL]: {
      [ShapeReviewSectionArea.FORMS]: '', // enum CheckBoxAll
      [ShapeReviewSectionArea.EVENTS]: '', // enum CheckBoxAll
    },
    // Ordering Filter Type
    orderingTypeSetting: {
      valueText: '', //  Time
      optionText: '', // Time (Oldest)
      tooltipTitle: '', // 시간 흐름 순으로 정렬됨
      queryOrderBy: '', // api params ['','hr','-hr','rr-interval','-rr-interval']
      ascending: true, // asc, desc
      isDefault: true, // default value
    },
    // Order Context Menu Show & hide
    isOrderingBeatContextmenu: false,
    // Shape review 마지막 변경 체크박스 상태
    // 기본 클릭, 기본 이동, 체크박스 클릭, 단축키(1,2) 동작 시 상태 업데이트
    selectedCheckboxStatus: [],
  },
  selectedInfo: initSelectedInfo,
  // Info List (form, event of form)
  clusterFormInfoList: {
    data: initClusterFormInfoList,
    pending: false,
    error: null,
  },
  waveformIndexListInfoOfForms: {
    data: initWaveformIndexListInfoOfForm,
    pending: false,
    error: null,
  },
  // pooling data(form, event of form)
  poolingData: {
    [ShapeReviewSectionArea.FORMS]: {
      data: initClusterFormPoolingData,
      pending: false,
      calledType: '',
      error: null,
    },
    [ShapeReviewSectionArea.EVENTS]: {
      data: initEventOfFormPoolingData,
      pending: false,
      pauseState: false,
      progressPercentage: 0,
      calledType: '',
      error: null,
    },
  },
  // selected Form of pooling data
  selectedPoolingDataOfClusterForm: {
    selectedPoolingData: {},
  },
  // data to be displayed
  clusterFormDetail: [],
  clusterEventDetail: [],
  // patch beat by form ids(edited label에 사용)
  patchBeatByFormIds: {
    patchedList: {
      [EVENT_CONST_TYPES.ISO_VPC]: [], // 'EVENT-TYPE-ISOLATE-1'
      [EVENT_CONST_TYPES.COUPLET_VPC]: [], // 'EVENT-TYPE-COUPLET-1'
      [EVENT_CONST_TYPES.ISO_APC]: [], // 'EVENT-TYPE-ISOLATE-2'
      [EVENT_CONST_TYPES.COUPLET_APC]: [], // 'EVENT-TYPE-COUPLET-2'
    },
    pending: false,
    error: null,
  },
  // patch beat by waveformIndex list(edited label에 사용)
  patchBeatByWaveFormIndexes: {
    patchedList: {
      // * [number: chartId]: number[] | editType.ALL('ALL'),
      //   - 'ALL': form에서 전체 선택 이후 편집 했을 경우
      [EVENT_CONST_TYPES.ISO_VPC]: {}, // 'EVENT-TYPE-ISOLATE-1'
      [EVENT_CONST_TYPES.COUPLET_VPC]: {}, // 'EVENT-TYPE-COUPLET-1'
      [EVENT_CONST_TYPES.ISO_APC]: {}, // 'EVENT-TYPE-ISOLATE-2'
      [EVENT_CONST_TYPES.COUPLET_APC]: {}, // 'EVENT-TYPE-COUPLET-2'
    },
    pending: false,
    error: null,
    responseValidationResult: {
      succeedWaveformIndexes: [],
      failedWaveformIndexes: [],
    },
  },
  tenSecStripDetail: {
    onsetMs: null,
    terminationMs: null,
    onsetWaveformIdx: null,
    terminationWaveformIdx: null,
    hrAvg: null,
    ecgRaw: [],
    beatLabelButtonDataList: null,
    beatsOrigin: {},
    responseValidationResult: {
      requestAt: null,
      validResult: null,
      editTargetBeatType: null,
    },
    pending: false,
    error: null,
  },
  // patch beat update by waveformIndexes
  caliper: {
    caliperPlotLines: [],
    isCaliperMode: false,
    isTickMarksMode: false,
  },
  // editing 10sStrip - shape review 10s 편집 중 사이드 패널 비활성화를 위한 state
  tenSecStripEditMode: {
    tenSecStripEditing: false,
  },
  // Beat Postprocess
  beatPostprocess: {
    pending: false,
    error: null,
    data: {
      beatPostprocessedMs: null,
    },
  },
  //  Arrange Status
  arrangeRequiredStatus: {
    pending: false,
    error: null,
    isArrangeRequired: false,
  },
};

// Reducer
const getClickedEventType = (shapeReviewReducer) =>
  shapeReviewReducer.shapeReviewState[ShapeReviewState.CLICKED_INFO].eventType;
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_SHAPE_REVIEW_STATE: {
      const updatedState = action.updatedState;
      return {
        ...initialState,
        ...updatedState,
      };
    }
    case SET_PANEL_SIZE: {
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.PANEL_SIZE_INFO]: {
            ...state.shapeReviewState[ShapeReviewState.PANEL_SIZE_INFO],
            [action.panelType]: {
              columnNumber: action.columnNumber,
              rowNumber: action.rowNumber,
              pageSize: action.columnNumber * action.rowNumber,
            },
          },
        },
      };
    }
    case SET_SELECT_EVENT: {
      let initSelectedInfoState;
      if (!!action?.selectedFormPanel) {
        initSelectedInfoState = {
          ...state.selectedInfo,
          [ShapeReviewSectionArea.FORMS]: action.selectedFormPanel,
        };
      } else {
        initSelectedInfoState = initSelectedInfo;
      }

      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.CLICKED_INFO]: {
            ...state.shapeReviewState[ShapeReviewState.CLICKED_INFO],
            eventType: action.eventType,
            beatType: action.beatType,
            ectopicType: action.ectopicType,
          },
        },
        selectedInfo: { ...initSelectedInfoState },
        tenSecStripDetail: { ...initialState.tenSecStripDetail },
      };
    }

    case SET_PAGE: {
      const clickedEventType = selectSelectedEventType({
        shapeReviewReducer: state,
      });
      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
          shapeReviewReducer: state,
        });
      const clickedEventPaginationInfo = selectPaginationInfo({
        shapeReviewReducer: state,
      })[action.panelType][clickedEventType];
      const clickedEventPanelSizeInfo = selectPanelSizeInfo({
        shapeReviewReducer: state,
      })[action.panelType];

      let clickedEventCurrentPage, clickedTargetPoolingData;
      const poolingDataOfClickedPanel =
        state.poolingData[action.panelType].data[clickedEventType];

      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        clickedEventCurrentPage = clickedEventPaginationInfo.currentPage;
        clickedTargetPoolingData = poolingDataOfClickedPanel;
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        clickedEventCurrentPage =
          clickedEventPaginationInfo[
            formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
          ].currentPage;

        clickedTargetPoolingData =
          poolingDataOfClickedPanel[
            formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
          ];
      }

      let newCurrentPage;
      if (action.setPageType === POSITION_MOVE_TYPE.PREV) {
        newCurrentPage = clickedEventCurrentPage - 1;

        newCurrentPage = getNewCurrentPageDependOnPoolingData({
          clickedEventPanelSizeInfo,
          newCurrentPage,
          action,
          state,
          clickedEventType,
          formPanelDetailOfFirstIndexOfLastSelectedSection,
          clickedTargetPoolingData,
          clickedEventCurrentPage,
        });

        if (newCurrentPage < 1) newCurrentPage = 1;
      } else if (action.setPageType === POSITION_MOVE_TYPE.NEXT) {
        newCurrentPage = clickedEventCurrentPage + 1;

        newCurrentPage = getNewCurrentPageDependOnPoolingData({
          clickedEventPanelSizeInfo,
          newCurrentPage,
          action,
          state,
          clickedEventType,
          formPanelDetailOfFirstIndexOfLastSelectedSection,
          clickedTargetPoolingData,
          clickedEventCurrentPage,
        });

        if (newCurrentPage > clickedEventPaginationInfo.totalPageSize) {
          newCurrentPage = clickedEventPaginationInfo.totalPageSize;
        }
      } else if (action.setPageType === POSITION_MOVE_TYPE.JUMP) {
        newCurrentPage = action.value;
      }

      let setPageStateResult;
      let setSelectedInfoResult;
      const paginationInfoState =
        state.shapeReviewState[ShapeReviewState.PAGINATION_INFO];
      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        setSelectedInfoResult = {
          [ShapeReviewSectionArea.FORMS]: {
            lastSelectedSectionInfo: [undefined, undefined],
            selectedItemList: new Map(),
          },
        };
        setPageStateResult = {
          ...paginationInfoState,
          [action.panelType]: {
            ...paginationInfoState[action.panelType],
            [clickedEventType]: {
              ...paginationInfoState[action.panelType][clickedEventType],
              currentPage: Number(newCurrentPage),
            },
          },
        };
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        setSelectedInfoResult = {
          [ShapeReviewSectionArea.EVENTS]: {
            lastSelectedSectionInfo: [undefined, undefined],
            selectedItemList: new Map(),
          },
        };
        setPageStateResult = {
          ...paginationInfoState,
          [action.panelType]: {
            ...paginationInfoState[action.panelType],
            [clickedEventType]: {
              ...paginationInfoState[action.panelType][clickedEventType],
              [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]: {
                ...paginationInfoState[action.panelType][clickedEventType][
                  formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
                ],
                currentPage: Number(newCurrentPage),
              },
            },
          },
        };
      }
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.PAGINATION_INFO]: setPageStateResult,
        },
        selectedInfo: { ...state.selectedInfo, ...setSelectedInfoResult },
      }; // end of SET_PAGE
    }
    case SET_PAGINATION: {
      let setPaginationResult;
      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        setPaginationResult = {
          [action.panelType]: {
            ...state.shapeReviewState[ShapeReviewState.PAGINATION_INFO][
              action.panelType
            ],
            [action.eventType]: {
              eventType: action.eventType,
              panelType: action.panelType,
              totalItemCount: action.totalItemCount,
              totalPageSize: action.totalPageSize,
              currentPage: Number(action.currentPage),
            },
          },
        };
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        setPaginationResult = {
          [action.panelType]: {
            ...state.shapeReviewState[ShapeReviewState.PAGINATION_INFO][
              action.panelType
            ],
            [action.eventType]: {
              ...state.shapeReviewState[ShapeReviewState.PAGINATION_INFO][
                action.panelType
              ][action.eventType],
              [action.formId]: {
                eventType: action.eventType,
                panelType: action.panelType,
                totalItemCount: action.totalItemCount,
                totalPageSize: action.totalPageSize,
                currentPage: Number(action.currentPage),
              },
            },
          },
        };
      }
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.PAGINATION_INFO]: {
            ...state.shapeReviewState[ShapeReviewState.PAGINATION_INFO],
            ...setPaginationResult,
          },
        },
      };
    }
    case SET_TENSEC_STRIP_DETAIL: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          ...action.data,
        },
      };
    }
    case RESET_TENSEC_STRIP_DETAIL: {
      return {
        ...state,
        tenSecStripDetail: { ...initialState.tenSecStripDetail },
      };
    }
    case SET_SELECT_ALL: {
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.SELECT_ALL]: {
            ...state.shapeReviewState[ShapeReviewState.SELECT_ALL],
            [action.panelType]: action.selectAllState,
          },
        },
      };
    }
    case SET_ACTIVE_PANEL: {
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.ACTIVE_PANEL]: action.activePanel,
        },
      };
    }
    case SET_LAST_SELECTED_SECTION_INFO: {
      const clickedEventType = getClickedEventType(state);

      let updateSetLastSelectedSectionInfoState;
      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        updateSetLastSelectedSectionInfoState = {
          ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
          // FORMS, EVENTS
          [action.panelType]: {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
              action.panelType
            ],
            [clickedEventType]: {
              ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                action.panelType
              ][clickedEventType],
              lastSelectedSectionInfo: action.lastSelectedSectionInfo,
            },
          },
        };
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        const formPanelDetailOfFirstIndexOfLastSelectedSection =
          selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
            shapeReviewReducer: state,
          });
        const selectedFormId =
          formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id;

        if (!selectedFormId) {
          updateSetLastSelectedSectionInfoState = {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
          };
        } else {
          updateSetLastSelectedSectionInfoState = {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
            // FORMS, EVENTS
            [action.panelType]: {
              ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                action.panelType
              ],
              [clickedEventType]: {
                ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                  action.panelType
                ][clickedEventType],
                [selectedFormId]: {
                  ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                    action.panelType
                  ][clickedEventType][selectedFormId],
                  lastSelectedSectionInfo: action.lastSelectedSectionInfo,
                },
              },
            },
          };
        }
      }

      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.SELECTED_INFO]: {
            ...updateSetLastSelectedSectionInfoState,
          },
        },
        selectedInfo: {
          ...state.selectedInfo,
          [action.panelType]: {
            ...state.selectedInfo[action.panelType],
            lastSelectedSectionInfo: action.lastSelectedSectionInfo,
          },
        },
      };
    }
    case SET_SELECTED_ITEM_LIST: {
      const clickedEventType = getClickedEventType(state);

      let updateSelectAllState;
      let updateSetSelectedItemState;

      if (action.panelType === ShapeReviewSectionArea.FORMS) {
        const isIndeterminate = getIsIndeterminate({
          selectedItemList: [...action.selectedItemList],
          panelList: state.clusterFormDetail,
        });

        updateSelectAllState = {
          ...state.shapeReviewState[ShapeReviewState.SELECT_ALL],
          [action.panelType]: isIndeterminate
            ? CheckBoxAll.Indeterminate
            : state.shapeReviewState[ShapeReviewState.SELECT_ALL][
                action.panelType
              ],
        };

        updateSetSelectedItemState = {
          ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
          // FORMS, EVENTS
          [action.panelType]: {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
              action.panelType
            ],
            [clickedEventType]: {
              ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                action.panelType
              ][clickedEventType],
              selectedItemList: action.selectedItemList,
            },
          },
        };
      } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
        const isIndeterminate = getIsIndeterminate({
          selectedItemList: [...action.selectedItemList],
          panelList: state.clusterEventDetail,
        });

        updateSelectAllState = {
          ...state.shapeReviewState[ShapeReviewState.SELECT_ALL],
          [action.panelType]: isIndeterminate
            ? CheckBoxAll.Indeterminate
            : state.shapeReviewState[ShapeReviewState.SELECT_ALL][
                action.panelType
              ],
        };

        const formPanelDetailOfFirstIndexOfLastSelectedSection =
          selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
            shapeReviewReducer: state,
          });
        const selectedFormId =
          formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id;

        if (!selectedFormId) {
          updateSetSelectedItemState = {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
          };
        } else {
          updateSetSelectedItemState = {
            ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO],
            // FORMS, EVENTS
            [action.panelType]: {
              ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                action.panelType
              ],
              [clickedEventType]: {
                ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                  action.panelType
                ][clickedEventType],
                [selectedFormId]: {
                  ...state.shapeReviewState[ShapeReviewState.SELECTED_INFO][
                    action.panelType
                  ][clickedEventType][selectedFormId],
                  selectedItemList: action.selectedItemList,
                },
              },
            },
          };
        }
      }

      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.SELECT_ALL]: {
            ...updateSelectAllState,
          },
          [ShapeReviewState.SELECTED_INFO]: {
            ...updateSetSelectedItemState,
          },
        },
        selectedInfo: {
          ...state.selectedInfo,
          [action.panelType]: {
            ...state.selectedInfo[action.panelType],
            selectedItemList: action.selectedItemList,
          },
        },
      };
    }
    case SET_INIT_SELECTED_ITEM_LIST: {
      return {
        ...state,
        selectedInfo: {
          ...state.selectedInfo,
          [action.panelType]: {
            ...state.selectedInfo[action.panelType],
            selectedItemList: action.selectedItemList,
          },
        },
      };
    }
    case SET_FORM_DATA: {
      return {
        ...state,
        clusterFormDetail: action.formData,
      };
    }
    case SET_EVENT_DATA: {
      return {
        ...state,
        clusterEventDetail: action.eventData,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: false,
            pauseState: false,
          },
        },
      };
    }

    // # api
    // ## api - fetching(clustering statistics)
    case GET_CLUSTERING_STATISTICS_REQUESTED: {
      return {
        ...state,
        clusteringStatistics: {
          ...state.clusteringStatistics,
          pending: true,
          error: null,
        },
      };
    }
    case GET_CLUSTERING_STATISTICS_SUCCEED: {
      return {
        ...state,
        clusteringStatistics: {
          ...state.clusteringStatistics,
          data: action.data,
          pending: false,
          error: null,
        },
      };
    }
    case GET_CLUSTERING_STATISTICS_FAILED: {
      return {
        ...state,
        clusteringStatistics: {
          ...state.clusteringStatistics,
          pending: false,
          error: action.error,
        },
      };
    }
    // ## api - fetching(form list)
    case GET_FORM_LIST_REQUESTED: {
      return {
        ...state,
        clusterFormInfoList: {
          ...state.clusterFormInfoList,
          pending: true,
        },
        clusterFormDetail: [],
      };
    }
    case GET_FORM_LIST_SUCCEED: {
      const clickedEventType = getClickedEventType(state);

      return {
        ...state,
        clusterFormInfoList: {
          ...state.clusterFormInfoList,
          pending: false,
          data: {
            ...state.clusterFormInfoList.data,
            [state.shapeReviewState[ShapeReviewState.CLICKED_INFO].eventType]: {
              forms: action.forms.map((v, i) => {
                // v.formIndex = i;
                return v;
              }),
              formsThumbNailWaveFormIndex: action.formsThumbNailWaveFormIndex,
              totalEditedBeatCount: action.totalEditedBeatCount,
              totalFormsCount: action.totalFormsCount,
            },
          },
        },
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: new Map(
                ...[
                  action.formsThumbNailWaveFormIndex.map((v) => [v, undefined]),
                ]
              ),
            },
          },
        },
      };
    }
    case GET_FORM_LIST_FAILED: {
      return {
        ...state,
        clusterFormInfoList: {
          ...state.clusterFormInfoList,
          pending: false,
          error: action.error,
        },
      };
    }
    // ## api - fetching(form detail pooling Data)
    case GET_FORM_DETAIL_POOLING_DATA_REQUESTED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: true,
          },
        },
      };
    }
    case GET_FORM_DETAIL_POOLING_DATA_SUCCEED: {
      const clickedEventType = getClickedEventType(state);
      const serverStoredEditedFormIdList = [
        ...action.mergeFormAndEventDetailInfo.values(),
      ]
        .filter((v) => v?.formInfo.isEdited)
        .map((v) => v.formInfo.id);
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: false,
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: new Map([
                ...state.poolingData[ShapeReviewSectionArea.FORMS].data[
                  clickedEventType
                ],
                ...action.mergeFormAndEventDetailInfo,
              ]),
            },
          },
        },
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          patchedList: {
            ...state.patchBeatByFormIds.patchedList,
            [clickedEventType]: [
              ...state.patchBeatByFormIds.patchedList[clickedEventType],
              ...serverStoredEditedFormIdList,
            ],
          },
        },
      };
    }
    case GET_FORM_DETAIL_POOLING_DATA_FAILED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: false,
          },
        },
      };
    }
    // ## api - fetching(form detail pooling Data - event detail(raw and event)
    case GET_RAW_AND_EVENT_REQUESTED: {
      const clickedEventType = getClickedEventType(state);
      const fetchingTargetOfWaveformIndexList = state.clusterFormInfoList.data[
        clickedEventType
      ].formsThumbNailWaveFormIndex.slice(
        action.sliceInfoOfPooling.begin,
        action.sliceInfoOfPooling.end
      );
      const clickedEventPoolingMap =
        state.poolingData[ShapeReviewSectionArea.FORMS].data[clickedEventType];
      for (let poolingItem of clickedEventPoolingMap) {
        if (fetchingTargetOfWaveformIndexList.includes(poolingItem[0])) {
          clickedEventPoolingMap.set(poolingItem[0], PoolingState.PENDING);
        }
      }
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: true,
            calledType: action.calledType,
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: new Map([...clickedEventPoolingMap]),
            },
          },
        },
      };
    }
    case GET_RAW_AND_EVENT_SUCCEED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: false,
            calledType: '',
          },
        },
      };
    }
    case GET_RAW_AND_EVENT_FAILED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            pending: false,
            calledType: '',
            error: action.error,
          },
        },
      };
    }

    // ## api - fetching(waveformIndexList of forms)
    case DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED:
    case GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED: {
      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          pending: true,
        },
        clusterEventDetail: [], //
        selectedInfo: {
          ...state.selectedInfo,
          [ShapeReviewSectionArea.EVENTS]: {
            ...initSelectedInfo[ShapeReviewSectionArea.EVENTS],
          },
        },
        tenSecStripDetail: initialState.tenSecStripDetail,
      };
    }
    case GET_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED: {
      const clickedEventType = getClickedEventType(state);
      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
          shapeReviewReducer: state,
        });

      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          data: {
            ...state.waveformIndexListInfoOfForms.data,
            [clickedEventType]: {
              ...state.waveformIndexListInfoOfForms.data[clickedEventType],
              [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]: {
                onsetWaveformIndexListOfForm: action.onsetWaveformIndexesOfForm,
                terminationWaveformIndexListOfForm:
                  action.terminationWaveformIndexesOfForm,
                totalBeatCount: action.totalBeatCount,
              },
            },
          },
          pending: false,
        },
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]:
                  new Map(
                    ...[
                      action.onsetWaveformIndexesOfForm.map((v) => [
                        v,
                        undefined,
                      ]),
                    ]
                  ),
              },
            },
            pending: false,
          },
        },
      };
    }
    case GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED: {
      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          pending: false,
          error: action.error,
        },
      };
    }
    case GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED: {
      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          pending: true,
        },
        clusterEventDetail: [], //
        selectedInfo: {
          ...state.selectedInfo,
          [ShapeReviewSectionArea.EVENTS]: {
            ...initSelectedInfo[ShapeReviewSectionArea.EVENTS],
          },
        },
        tenSecStripDetail: initialState.tenSecStripDetail,
      };
    }
    case GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED: {
      const clickedEventType = getClickedEventType(state);
      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
          shapeReviewReducer: state,
        });
      const selectedFormIndex =
        formPanelDetailOfFirstIndexOfLastSelectedSection.index;
      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          data: {
            ...state.waveformIndexListInfoOfForms.data,
            [clickedEventType]: {
              ...state.waveformIndexListInfoOfForms.data[clickedEventType],
              [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]: {
                onsetWaveformIndexListOfForm: action.onsetWaveformIndexesOfForm,
                terminationWaveformIndexListOfForm:
                  action.terminationWaveformIndexesOfForm,
                totalBeatCount: action.totalBeatCount,
              },
            },
          },
          pending: false,
        },
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]:
                  new Map(
                    ...[
                      action.onsetWaveformIndexesOfForm.map((v) => [
                        v,
                        undefined,
                      ]),
                    ]
                  ),
              },
            },
            pending: false,
          },
        },
        clusterFormDetail: [
          ...state.clusterFormDetail.map((formDetail) => {
            if (formDetail.index === selectedFormIndex) {
              formDetail.currentOrderType = action.ordering;
            }
            return formDetail;
          }),
        ],
      };
    }
    case GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_FAILED: {
      return {
        ...state,
        waveformIndexListInfoOfForms: {
          ...state.waveformIndexListInfoOfForms,
          pending: false,
          error: action.error,
        },
      };
    }
    // ## api - fetching(pooling data of event of form)
    case GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED: {
      const clickedEventType = getClickedEventType(state);

      const waveformIndexListInfoOfFormId =
        state.waveformIndexListInfoOfForms.data[clickedEventType][
          action.formId
        ];

      if (waveformIndexListInfoOfFormId) {
        const onsetWaveformIndexListOfSelectedForm =
          state.waveformIndexListInfoOfForms.data[clickedEventType][
            action.formId
          ].onsetWaveformIndexListOfForm ?? [];

        const clickedEventOfFormPoolingMap =
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ][action.formId];
        const hasPoolingDataMap = [...clickedEventOfFormPoolingMap].filter(
          (v) => v[1] !== undefined
        );

        return {
          ...state,
          poolingData: {
            ...state.poolingData,
            [ShapeReviewSectionArea.EVENTS]: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS],
              pending: true,
              pauseState: false,
              progressPercentage: Math.round(
                ([...hasPoolingDataMap].length /
                  onsetWaveformIndexListOfSelectedForm.length) *
                  100
              ),
              calledType: action.calledType,
              data: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
                [clickedEventType]: {
                  ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                    clickedEventType
                  ],
                  [action.formId]: new Map([...clickedEventOfFormPoolingMap]),
                },
              },
            },
          },
        };
      } else {
        return {
          ...state,
          poolingData: {
            ...state.poolingData,
          },
        };
      }
    }
    case GET_POOLING_DATA_OF_EVENT_OF_FORM_SUCCEED: {
      const { poolingDataMap, formId } = action;
      const clickedEventType = getClickedEventType(state);
      const isCoupletEctopicTypeClicked = selectIsCoupletEctopicTypeClicked({
        shapeReviewReducer: {
          ...state,
        },
      });
      const poolingDataOfEvents =
        state.poolingData[ShapeReviewSectionArea.EVENTS].data[clickedEventType][
          formId
        ];
      if (poolingDataOfEvents) {
        const serverStoredPatchEventListOfClickedForm =
          _getEditedWaveformIndexes(
            poolingDataMap,
            isCoupletEctopicTypeClicked
          );
        const allEvents = [...poolingDataMap.values()];
        const isAllEventOfClickedFormEdited =
          allEvents.filter(Boolean).length ===
          serverStoredPatchEventListOfClickedForm.length;

        const updatedEventData = new Map([
          ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ][formId],
          ...poolingDataMap,
        ]);

        return {
          ...state,
          poolingData: {
            ...state.poolingData,
            [ShapeReviewSectionArea.EVENTS]: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS],
              data: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
                [clickedEventType]: {
                  ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                    clickedEventType
                  ],
                  [formId]: updatedEventData,
                },
              },
            },
          },
          patchBeatByWaveFormIndexes: {
            ...state.patchBeatByWaveFormIndexes,
            patchedList: {
              ...state.patchBeatByWaveFormIndexes.patchedList,
              [clickedEventType]: {
                ...state.patchBeatByWaveFormIndexes.patchedList[
                  clickedEventType
                ],
                [formId]: isAllEventOfClickedFormEdited
                  ? editType.ALL
                  : serverStoredPatchEventListOfClickedForm,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          poolingData: {
            ...state.poolingData,
          },
        };
      }

      function _getEditedWaveformIndexes(dataMap, isCoupletEctopicTypeClicked) {
        // 수정된 WI만 추출하는 함수
        return [...dataMap.values()]
          .filter((v) => {
            const centerWI = v.beats.waveformIndex.indexOf(
              v.originWaveformIndex
            );

            // isEdited 가 false인 경우는 수정이 안된것으로 판단합니다.
            const isCenterWIEdited = v.beats.isEdited[centerWI] === true;
            const isNextToCenterWIEdited =
              v.beats.isEdited[centerWI + 1] === true;

            return isCoupletEctopicTypeClicked
              ? isCenterWIEdited || isNextToCenterWIEdited || centerWI === -1
              : isCenterWIEdited || centerWI === -1;
          })
          .map((v) => v.originWaveformIndex);
      }
    }
    case GET_POOLING_DATA_OF_EVENT_OF_FORM_FAILED: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: false,
            calledType: '',
          },
        },
      };
    }
    case SET_PROGRESS_PERCENTAGE_POOLING_EVENT_LIST: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            progressPercentage: action.progressPercentage,
          },
        },
      };
    }
    case SET_PAUSE_POOLING_EVENT_LIST: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: !action.state,
            pauseState: action.state,
            calledType: '',
          },
        },
      };
    }
    case SET_FINISH_POOLING_EVENT_LIST: {
      return {
        ...state,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            pending: false,
            pauseState: false,
            progressPercentage: 0,
            calledType: '',
          },
        },
      };
    }
    case POST_BEATS_REQUESTED: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    }
    case POST_BEATS_SUCCEED: {
      const {
        data: { result: apiResResult },
        responseValidationResult,
        requestPostTensecStripDetail,
        requestSelectedInfoOfEventPanel,
        reqSelectedClusterEventDetail,
      } = action;

      const millisecondTime = new Date().getTime();
      const clickedEventType = getClickedEventType(state);

      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        state.selectedInfo[ShapeReviewSectionArea.FORMS]
          .lastSelectedSectionInfo[0]?.index;

      // selected form id(ex. 377700)
      const formId =
        state.clusterFormDetail[
          formPanelDetailOfFirstIndexOfLastSelectedSection
        ]?.formInfo.id;

      // beatOrigin을 poolingData 기준으로 가져온다.
      // 비트를 여러번 추가하다가 다른 이벤트를 클릭하는 경우, 한 10s에서 여러번 계속 추가하는 케이스를 위함
      const selectedEventOfPoolingData =
        state.poolingData[ShapeReviewSectionArea.EVENTS].data[clickedEventType][
          formId
        ];

      let { beatLabelButtonDataList, onsetWaveformIdx } =
        requestPostTensecStripDetail ?? {};

      const curPostRequestEventIndex =
        state.selectedInfo[ShapeReviewSectionArea.EVENTS]
          .lastSelectedSectionInfo[1]?.index ??
        state.selectedInfo[ShapeReviewSectionArea.EVENTS]
          .lastSelectedSectionInfo[0]?.index;

      // selectedInfo - Event - Index
      const selectedEventIndex = requestSelectedInfoOfEventPanel?.index;

      // originWaveformIndex (state)
      const selectedOriginWaveformIndex =
        reqSelectedClusterEventDetail[selectedEventIndex]?.originWaveformIndex;

      // terminationWaveformIndex (state)
      const selectedTerminationWaveformIndex =
        reqSelectedClusterEventDetail[selectedEventIndex]
          ?.terminationWaveformIndex;

      const beatsOrigin = selectedEventOfPoolingData.get(
        selectedOriginWaveformIndex
      )?.beats;

      // 편집 중인 10s에서 계속 요청할 경우 업데이트 된 상태로 재할당
      if (selectedEventIndex === curPostRequestEventIndex) {
        ({ beatLabelButtonDataList } = state.tenSecStripDetail);
      }

      // 추가할 비트 정보
      const targetWaveformIndexToAdd =
        apiResResult.waveformIndex[0] - onsetWaveformIdx;
      const targetBeatType = apiResResult.beatType[0];

      const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
        (v) => v.xAxisPoint > targetWaveformIndexToAdd
      );

      // 추가할 위치가 가장 마지막 case
      const finalAddBeatIndex =
        nextIndexOfAddBeat === -1
          ? beatsOrigin.beatType.length
          : nextIndexOfAddBeat;

      // originWaveformIndex index
      const originWaveformIndexPosition = beatsOrigin.waveformIndex.indexOf(
        selectedOriginWaveformIndex
      );
      // terminationWaveformIndex index
      const terminationWaveformIndexPosition =
        beatsOrigin.waveformIndex.indexOf(selectedTerminationWaveformIndex);

      //patchedList state
      const originPatchedList = rfdcClone(
        state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][
          formId
        ] ?? []
      );

      const isIsoEctopicType =
        originWaveformIndexPosition === terminationWaveformIndexPosition;

      // label 업데이트 필요 여부(origin)
      const isUpdatedOfOriginWaveformIndex =
        (originWaveformIndexPosition + 1 === nextIndexOfAddBeat &&
          (!isIsoEctopicType ||
            beatsOrigin.beatType[originWaveformIndexPosition] ===
              targetBeatType)) ||
        (originWaveformIndexPosition === nextIndexOfAddBeat &&
          beatsOrigin.beatType[originWaveformIndexPosition] === targetBeatType);

      // label 업데이트 필요 여부(termination)
      const isUpdatedOfTerminationWaveformIndex =
        (terminationWaveformIndexPosition === nextIndexOfAddBeat &&
          (!isIsoEctopicType ||
            beatsOrigin.beatType[terminationWaveformIndexPosition] ===
              targetBeatType)) ||
        (terminationWaveformIndexPosition + 1 === nextIndexOfAddBeat &&
          beatsOrigin.beatType[terminationWaveformIndexPosition] ===
            targetBeatType);

      // 새로운 beatLabelButtonDataList 생성
      const newBeatLabelButtonDataListAfterPostBeats = [
        ...beatLabelButtonDataList.slice(0, nextIndexOfAddBeat),
        {
          xAxisPoint: targetWaveformIndexToAdd,
          beatType: targetBeatType,
          title: TEN_SEC_STRIP_EDIT.BEAT_TYPE[targetBeatType],
          color: TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[targetBeatType],
          isSelected: false,
          isEventReview: '',
        },
        ...beatLabelButtonDataList.slice(nextIndexOfAddBeat),
      ];

      // 새로운 beatsOrigin 생성
      const newBeatsOrigin = {
        ...beatsOrigin,
        beatType: [
          ...beatsOrigin.beatType.slice(0, finalAddBeatIndex),
          responseValidationResult.editTargetBeatType,
          ...beatsOrigin.beatType.slice(finalAddBeatIndex),
        ],
        isEdited: [
          ...beatsOrigin.isEdited.slice(0, finalAddBeatIndex),
          true,
          ...beatsOrigin.isEdited.slice(finalAddBeatIndex),
        ],
        hr: [
          ...beatsOrigin.hr.slice(0, finalAddBeatIndex),
          null,
          ...beatsOrigin.hr.slice(finalAddBeatIndex),
        ],
        waveformIndex: [
          ...beatsOrigin.waveformIndex.slice(0, finalAddBeatIndex),
          responseValidationResult.validResult.accepted[0],
          ...beatsOrigin.waveformIndex.slice(finalAddBeatIndex),
        ],
      };

      // clusterFormDetail & poolingData[FORMS] 업데이트
      const { updatedMapForPoolingDataOfForm } = getTargetMapFromPoolingEvents({
        poolingDataEventsMap: rfdcClone(
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ]
        ),
        selectedOriginWaveformIndex,
        newBeatsOrigin,
      });

      // poolingData[FORMS] 업데이트
      const updatedPostedFormsPoolingData = getUpdatedFormsPoolingData({
        poolingDataFormsMap: rfdcClone(
          state.poolingData[ShapeReviewSectionArea.FORMS].data[clickedEventType]
        ),
        selectedOriginWaveformIndex,
        updatedMapForPoolingDataOfForm,
        millisecondTime,
      });
      //  poolingData[EVENTS] 업데이트
      const updatedPostedEventsPoolingData = getUpdatedEventsPoolingData({
        poolingDataEventsMap: rfdcClone(
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            state.shapeReviewState.CLICKED_INFO.eventType
          ]
        ),
        selectedOriginWaveformIndex,
        eventsKeys: Object.keys(
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            state.shapeReviewState.CLICKED_INFO.eventType
          ]
        ),
        newBeatsOrigin,
        millisecondTime,
      });
      // clusterFormDetail 업데이트
      const newClusterFormAfterPostBeats = state.clusterFormDetail.map(
        (eventDetail) =>
          eventDetail.originWaveformIndex === selectedOriginWaveformIndex
            ? {
                ...eventDetail,
                beats: updatedMapForPoolingDataOfForm,
                millisecondTime,
              }
            : eventDetail
      );

      // clusterEventDetail 업데이트
      const newClusterEventsAfterPostBeats = state.clusterEventDetail.map(
        (eventDetail) =>
          eventDetail.originWaveformIndex === selectedOriginWaveformIndex
            ? {
                ...eventDetail,
                beats: updatedPostedEventsPoolingData[formId].get(
                  selectedOriginWaveformIndex
                ).beats,
                millisecondTime,
              }
            : eventDetail
      );

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          ...(selectedEventIndex === curPostRequestEventIndex && {
            beatLabelButtonDataList: newBeatLabelButtonDataListAfterPostBeats,
            beatsOrigin: newBeatsOrigin,
            responseValidationResult: action.responseValidationResult,
            pending: false,
            error: null,
          }),
        },

        clusterEventDetail: newClusterEventsAfterPostBeats,
        clusterFormDetail: newClusterFormAfterPostBeats,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: updatedPostedEventsPoolingData,
            },
          },
          [ShapeReviewSectionArea.FORMS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: updatedPostedFormsPoolingData,
            },
          },
        },
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          patchedList: {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              [formId]:
                (isUpdatedOfOriginWaveformIndex ||
                  isUpdatedOfTerminationWaveformIndex) &&
                originPatchedList !== editType.ALL
                  ? [...originPatchedList, selectedOriginWaveformIndex]
                  : originPatchedList,
            },
          },
        },
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          isArrangeRequired: true,
        },
        tenSecStripEditMode: {
          ...state.tenSecStripEditMode,
          tenSecStripEditing: false,
        },
      };
    }

    case POST_BEATS_FAILED: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: action.error,
        },
      };
    }

    // [Beat CUD] patch beats
    case PATCH_BEATS_REQUESTED: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    }
    case PATCH_BEATS_SUCCEED: {
      const {
        data: { result: apiResResult },
        tabType,
        responseValidationResult,
        requestSelectedInfoOfEventPanel,
        requestPatchTensecStripDetail,
        reqSelectedClusterEventDetail,
      } = action;
      const millisecondTime = new Date().getTime();
      const clickedEventType = getClickedEventType(state);

      // A-fib이 편집 되었을 때 api 응답은 0 -> arrange alarm은 편집된 요소가 있을 때만 true로 업데이트
      const isApiResponse = apiResResult.beatType.length > 0;

      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        state.selectedInfo[ShapeReviewSectionArea.FORMS]
          .lastSelectedSectionInfo[0]?.index;

      // selected form id(ex. 377700)
      const formId =
        state.clusterFormDetail[
          formPanelDetailOfFirstIndexOfLastSelectedSection
        ]?.formInfo.id;

      // beatOrigin을 poolingData 기준으로 가져온다.x
      // 비트를 여러번 추가하다가 다른 이벤트를 클릭하는 경우, 한 10s에서 여러번 계속 추가하는 케이스를 위함
      const selectedEventOfPoolingData =
        state.poolingData[ShapeReviewSectionArea.EVENTS].data[clickedEventType][
          formId
        ];

      let { beatLabelButtonDataList, onsetWaveformIdx } =
        requestPatchTensecStripDetail ?? {};

      const curPatchRequestEventIndex =
        state.selectedInfo[ShapeReviewSectionArea.EVENTS]
          .lastSelectedSectionInfo[1]?.index ??
        state.selectedInfo[ShapeReviewSectionArea.EVENTS]
          .lastSelectedSectionInfo[0]?.index;

      // selectedInfo - Event - Index
      const selectedEventIndex = requestSelectedInfoOfEventPanel.index;

      // originWaveformIndex (state)
      const selectedOriginWaveformIndex =
        reqSelectedClusterEventDetail[selectedEventIndex]?.originWaveformIndex;

      // terminationWaveformIndex (state)
      const selectedTerminationWaveformIndex =
        reqSelectedClusterEventDetail[selectedEventIndex]
          ?.terminationWaveformIndex;

      const beatsOrigin = selectedEventOfPoolingData.get(
        selectedOriginWaveformIndex
      ).beats;

      // originWaveformIndex index
      const originWaveformIndexPosition = beatsOrigin.waveformIndex.indexOf(
        selectedOriginWaveformIndex
      );

      // 편집 중인 10s에서 계속 요청할 경우 업데이트 된 상태로 재할당
      if (selectedEventIndex === curPatchRequestEventIndex) {
        ({ beatLabelButtonDataList } = state.tenSecStripDetail);
      }

      if (tabType === TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU)
        return state;

      // terminationWaveformIndex index
      const terminationWaveformIndexPosition =
        beatsOrigin.waveformIndex.indexOf(selectedTerminationWaveformIndex);

      //patchedList state
      const originPatchedList = rfdcClone(
        state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][
          formId
        ] ?? []
      );

      // 편집할 비트 위치
      const beatPatchListWaveformIdx = apiResResult.waveformIndex.map((v) =>
        requestPatchTensecStripDetail.beatsOrigin.waveformIndex.indexOf(v)
      );

      // label 업데이트 필요 여부(origin)
      const isUpdatedOfOriginWaveformIndex =
        beatPatchListWaveformIdx.includes(originWaveformIndexPosition) ||
        ((beatPatchListWaveformIdx.includes(originWaveformIndexPosition - 1) ||
          beatPatchListWaveformIdx.includes(originWaveformIndexPosition + 1)) &&
          beatsOrigin.beatType[originWaveformIndexPosition] ===
            apiResResult.beatType[0]);

      // label 업데이트 필요 여부(termination)
      const isUpdatedOfTerminationWaveformIndex =
        beatPatchListWaveformIdx.includes(terminationWaveformIndexPosition) ||
        ((beatPatchListWaveformIdx.includes(
          terminationWaveformIndexPosition - 1
        ) ||
          beatPatchListWaveformIdx.includes(
            terminationWaveformIndexPosition + 1
          )) &&
          beatsOrigin.beatType[terminationWaveformIndexPosition] ===
            apiResResult.beatType[0]);

      // beatLabelButtonDataList 업데이트
      const newBeatLabelButtonDataListAfterPatchBeats =
        beatLabelButtonDataList.map((v) => {
          const patchIndex = apiResResult.waveformIndex.indexOf(
            v.xAxisPoint + onsetWaveformIdx
          );
          if (patchIndex !== -1) {
            return {
              ...v,
              isSelected: false,
              beatType: apiResResult.beatType[patchIndex],
              hr: apiResResult.hr[patchIndex],
              title:
                TEN_SEC_STRIP_EDIT.BEAT_TYPE[apiResResult.beatType[patchIndex]],
              color:
                TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[
                  apiResResult.beatType[patchIndex]
                ],
            };
          }
          return v;
        });

      // 새로운 beatsOriginBeats 생성
      const newBeatsOrigin = {
        ...beatsOrigin,
        beatType: [...beatsOrigin.beatType],
        hr: [...beatsOrigin.hr],
        isEdited: [...beatsOrigin.isEdited],
        waveformIndex: [...beatsOrigin.waveformIndex],
      };

      // tensec strip.responseValidationResult updated
      responseValidationResult.validResult.accepted.forEach((v) => {
        const index = beatsOrigin.waveformIndex.indexOf(v);
        newBeatsOrigin.beatType[index] =
          responseValidationResult.editTargetBeatType;
      });

      // clusterEventDetail 업데이트
      const newClusterEventsAfterPatchBeats = state.clusterEventDetail.map(
        (eventDetail) =>
          eventDetail.originWaveformIndex === selectedOriginWaveformIndex
            ? {
                ...eventDetail,
                beats: newBeatsOrigin,
                millisecondTime,
              }
            : eventDetail
      );

      // clusterFormDetail & poolingData[FORMS] 업데이트
      const { updatedMapForPoolingDataOfForm } = getTargetMapFromPoolingEvents({
        poolingDataEventsMap:
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ],
        selectedOriginWaveformIndex,
        newBeatsOrigin,
      });

      // clusterFormDetail 업데이트
      const newClusterFormAfterPatchBeats = state.clusterFormDetail.map(
        (eventDetail) => {
          if (eventDetail.originWaveformIndex === selectedOriginWaveformIndex) {
            return {
              ...eventDetail,
              beats: updatedMapForPoolingDataOfForm,
              millisecondTime,
            };
          }
          return eventDetail;
        }
      );
      // poolingData[FORMS] 업데이트
      const updatedPoolingDataForms = getUpdatedFormsPoolingData({
        poolingDataFormsMap:
          state.poolingData[ShapeReviewSectionArea.FORMS].data[
            clickedEventType
          ],
        selectedOriginWaveformIndex,
        updatedMapForPoolingDataOfForm,
        millisecondTime,
      });

      // poolingData[EVENTS] 업데이트
      const updatedPoolingDataEvents = getUpdatedEventsPoolingData({
        poolingDataEventsMap:
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ],
        selectedOriginWaveformIndex,
        eventsKeys: Object.keys(
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ]
        ),
        newBeatsOrigin,
        millisecondTime,
      });

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          ...(selectedEventIndex === curPatchRequestEventIndex && {
            beatsOrigin: newBeatsOrigin,
            beatLabelButtonDataList: newBeatLabelButtonDataListAfterPatchBeats,
            responseValidationResult,
            pending: false,
            error: null,
          }),
        },
        clusterEventDetail: newClusterEventsAfterPatchBeats,
        clusterFormDetail: newClusterFormAfterPatchBeats,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: updatedPoolingDataEvents,
            },
          },
          [ShapeReviewSectionArea.FORMS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: updatedPoolingDataForms,
            },
          },
        },
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          patchedList: {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              [formId]:
                (isUpdatedOfOriginWaveformIndex ||
                  isUpdatedOfTerminationWaveformIndex) &&
                originPatchedList !== editType.ALL
                  ? [...originPatchedList, selectedOriginWaveformIndex]
                  : originPatchedList,
            },
          },
        },
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          isArrangeRequired:
            isApiResponse || state.arrangeRequiredStatus.isArrangeRequired,
        },
        tenSecStripEditMode: {
          ...state.tenSecStripEditMode,
          tenSecStripEditing: false,
        },
      };
    }

    case PATCH_BEATS_FAILED: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: action.error,
        },
      };
    }
    case DELETE_BEATS_REQUESTED: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    }
    case DELETE_BEATS_SUCCEED: {
      const {
        reqSelectedClusterEventDetail,
        reqBody,
        requestDeleteTensecStripDetail,
        requestSelectedInfoOfEventPanel,
      } = action;

      const millisecondTime = new Date().getTime();
      const clickedEventType = getClickedEventType(state);

      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        state.selectedInfo[ShapeReviewSectionArea.FORMS]
          .lastSelectedSectionInfo[0]?.index;

      // selected form id(ex. 377700)
      const formId =
        state.clusterFormDetail[
          formPanelDetailOfFirstIndexOfLastSelectedSection
        ]?.formInfo.id;

      // beatOrigin을 poolingData 기준으로 가져온다.
      // 비트를 여러번 추가하다가 다른 이벤트를 클릭하는 경우, 한 10s에서 여러번 계속 추가하는 케이스를 위함
      const selectedEventOfPoolingData =
        state.poolingData[ShapeReviewSectionArea.EVENTS].data[clickedEventType][
          formId
        ];
      // selectedInfo - Event - Index
      const selectedEventIndex = requestSelectedInfoOfEventPanel.index;

      let { beatLabelButtonDataList, onsetWaveformIdx } =
        requestDeleteTensecStripDetail ?? {};

      const curDeleteRequestEventIndex =
        state.selectedInfo[ShapeReviewSectionArea.EVENTS]
          .lastSelectedSectionInfo[1]?.index ??
        state.selectedInfo[ShapeReviewSectionArea.EVENTS]
          .lastSelectedSectionInfo[0]?.index;

      // 편집 중인 10s에서 계속 요청할 경우 업데이트 된 상태로 재할당
      if (selectedEventIndex === curDeleteRequestEventIndex) {
        ({ beatLabelButtonDataList } = state.tenSecStripDetail);
      }

      // originWaveformIndex (state)
      const selectedOriginWaveformIndex =
        reqSelectedClusterEventDetail[selectedEventIndex]?.originWaveformIndex;
      // terminationWaveformIndex (state)
      const selectedTerminationWaveformIndex =
        reqSelectedClusterEventDetail[selectedEventIndex]
          ?.terminationWaveformIndex;

      const beatsOrigin = selectedEventOfPoolingData.get(
        selectedOriginWaveformIndex
      ).beats;
      //patchedList state
      const originPatchedList = rfdcClone(
        state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][formId]
      );
      //poolingData[EVENTS] state
      const poolingDataEventsMap = rfdcClone(
        state.poolingData[ShapeReviewSectionArea.EVENTS].data[clickedEventType]
      );
      //poolingData[FORMS] state
      const poolingDataFormsMap = rfdcClone(
        state.poolingData[ShapeReviewSectionArea.FORMS].data[clickedEventType]
      );
      const eventsKeys = Object.keys(poolingDataEventsMap);

      // beatLabelButtonDataList의 선택된 beat의 xAxis-onset을 계산한 리스트
      const editTargetWaveformIndexXAxisList = reqBody.waveformIndexes.map(
        (v) => v - onsetWaveformIdx
      );

      //삭제 할 waveformIndex list Index
      const editTargetWaveformIndexPosition = reqBody.waveformIndexes.map((v) =>
        beatsOrigin.waveformIndex.indexOf(v)
      );
      // editTargetWaveformIdxList에 포함되지 않는 newBeatsOrigin
      const newBeatsOrigin = Object.keys(beatsOrigin).reduce((acc, key) => {
        acc[key] = beatsOrigin[key].filter(
          (_, idx) => !editTargetWaveformIndexPosition.includes(idx)
        );
        return acc;
      }, {});

      // 비트 삭제 예외 케이스(현재 서버에서 반영이 안되어 있는데 추후에 반영 예정 위 소스를 아래 소스로 변경 필요)
      // S N S 으로 세번째 S가 이벤트 비트일 때 N을 삭제해도 edited label이 붙어야 하지만 현재는 붙지 않는다.(백엔드 작업 필요)

      // const isDeletedOriginWaveformIndex =
      //   newBeatsOrigin.waveformIndex.includes(selectedOriginWaveformIndex)
      //     ? true
      //     : false;
      // const isDeletedTerminationWaveformIndex =
      //   newBeatsOrigin.waveformIndex.includes(selectedTerminationWaveformIndex)
      //     ? true
      //     : false;

      // label 업데이트 필요 여부(origin)
      const isUpdatedOfOriginWaveformIndex = reqBody.waveformIndexes.includes(
        selectedOriginWaveformIndex
      );
      // 비트 삭제 예외 케이스(현재 서버에서 반영이 안되어 있는데 추후에 반영 예정 위 소스를 아래 소스로 변경 필요)
      // S N S 으로 세번째 S가 이벤트 비트일 때 N을 삭제해도 edited label이 붙어야 하지만 현재는 붙지 않는다.(백엔드 작업 필요)
      // const isUpdatedOfOriginWaveformIndex =
      //   reqBody.waveformIndexes.includes(selectedOriginWaveformIndex) ||
      //   (isDeletedOriginWaveformIndex &&
      //     newBeatsOrigin.beatType[
      //       newBeatsOrigin.waveformIndex.indexOf(selectedOriginWaveformIndex)
      //     ] ===
      //       newBeatsOrigin.beatType[
      //         newBeatsOrigin.waveformIndex.indexOf(
      //           selectedOriginWaveformIndex
      //         ) - 1
      //       ]) ||
      //   (isDeletedTerminationWaveformIndex &&
      //     newBeatsOrigin.beatType[
      //       newBeatsOrigin.waveformIndex.indexOf(
      //         selectedTerminationWaveformIndex
      //       )
      //     ] ===
      //       newBeatsOrigin.beatType[
      //         newBeatsOrigin.waveformIndex.indexOf(
      //           selectedTerminationWaveformIndex
      //         ) + 1
      //       ]);

      // label 업데이트 필요 여부(termination)
      const isUpdatedOfTerminationWaveformIndex =
        reqBody.waveformIndexes.includes(selectedTerminationWaveformIndex);

      // label 업데이트 필요 여부(termination)
      // 비트 삭제 예외 케이스(현재 서버에서 반영이 안되어 있는데 추후에 반영 예정 위 소스를 아래 소스로 변경 필요)
      // S N S 으로 세번째 S가 이벤트 비트일 때 N을 삭제해도 edited label이 붙어야 하지만 현재는 붙지 않는다.(백엔드 작업 필요)
      // const isUpdatedOfTerminationWaveformIndex =
      //   reqBody.waveformIndexes.includes(selectedTerminationWaveformIndex) ||
      //   (isDeletedOriginWaveformIndex &&
      //     newBeatsOrigin.beatType[
      //       newBeatsOrigin.waveformIndex.indexOf(
      //         selectedTerminationWaveformIndex
      //       )
      //     ] ===
      //       newBeatsOrigin.beatType[
      //         newBeatsOrigin.waveformIndex.indexOf(
      //           selectedTerminationWaveformIndex
      //         ) - 1
      //       ]) ||
      //   (isDeletedTerminationWaveformIndex &&
      //     newBeatsOrigin.beatType[
      //       newBeatsOrigin.waveformIndex.indexOf(
      //         selectedTerminationWaveformIndex
      //       )
      //     ] ===
      //       newBeatsOrigin.beatType[
      //         newBeatsOrigin.waveformIndex.indexOf(
      //           selectedTerminationWaveformIndex
      //         ) + 1
      //       ]);

      // newBeatLabelButtonDataList state
      const newBeatLabelButtonDataListAfterDeleteBeats =
        beatLabelButtonDataList.filter(
          (beatLabelButtonData) =>
            !editTargetWaveformIndexXAxisList.includes(
              beatLabelButtonData.xAxisPoint
            )
        );

      // Update clusterFormDetail and poolingData[FORMS]
      const { updatedMapForPoolingDataOfForm } = getTargetMapFromPoolingEvents({
        poolingDataEventsMap,
        selectedOriginWaveformIndex,
        newBeatsOrigin,
      });

      // newClusterFormAfterDeleteBeats state
      const newClusterFormAfterDeleteBeats = state.clusterFormDetail.map(
        (eventDetail) => {
          if (eventDetail.originWaveformIndex === selectedOriginWaveformIndex) {
            return {
              ...eventDetail,
              beats: updatedMapForPoolingDataOfForm,
              millisecondTime,
            };
          }
          return eventDetail;
        }
      );

      // Update clusterEventDetail after deletion
      const newClusterEventDetailAfterDeleteBeats =
        state.clusterEventDetail.map((eventDetail) => {
          if (eventDetail.originWaveformIndex === selectedOriginWaveformIndex) {
            return {
              ...eventDetail,
              beats: newBeatsOrigin,
              exists:
                isUpdatedOfOriginWaveformIndex &&
                isUpdatedOfTerminationWaveformIndex
                  ? false
                  : true,
              millisecondTime,
            };
          }
          return eventDetail;
        });

      // poolingData[FORMS] 업데이트
      const updatedPoolingDataForms = getUpdatedFormsPoolingData({
        poolingDataFormsMap,
        selectedOriginWaveformIndex,
        updatedMapForPoolingDataOfForm,
        millisecondTime,
      });
      // poolingData[EVENTS] 업데이트
      const updatedPoolingDataEvents = getUpdatedEventsPoolingData({
        poolingDataEventsMap,
        selectedOriginWaveformIndex,
        eventsKeys,
        millisecondTime,
        newBeatsOrigin,
      });

      // Update patchedList
      const updatedPatchedList =
        (isUpdatedOfOriginWaveformIndex ||
          isUpdatedOfTerminationWaveformIndex) &&
        originPatchedList !== editType.ALL
          ? [...originPatchedList, selectedOriginWaveformIndex]
          : originPatchedList;

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          ...(selectedEventIndex === curDeleteRequestEventIndex && {
            beatsOrigin: newBeatsOrigin,
            beatLabelButtonDataList: newBeatLabelButtonDataListAfterDeleteBeats,
            pending: false,
            error: null,
          }),
        },
        clusterEventDetail: newClusterEventDetailAfterDeleteBeats,
        clusterFormDetail: newClusterFormAfterDeleteBeats,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: updatedPoolingDataEvents,
            },
          },
          [ShapeReviewSectionArea.FORMS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: updatedPoolingDataForms,
            },
          },
        },
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          patchedList: {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              [formId]: updatedPatchedList,
            },
          },
        },
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          isArrangeRequired: true,
        },
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.SELECT_ALL]: {
            ...state.shapeReviewState[ShapeReviewState.SELECT_ALL],
            ...(isUpdatedOfOriginWaveformIndex ||
              (isUpdatedOfTerminationWaveformIndex && {
                EVENTS: 'None',
              })),
          },
        },
      };
    }

    case DELETE_BEATS_FAILED: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
      };
    }
    // ## api - patch beat 방법1 (by formIds)
    case PATCH_BEATS_BY_FORM_ID_REQUESTED: {
      return {
        ...state,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: true,
        },
      };
    }
    case PATCH_BEATS_BY_FORM_ID_SUCCEED: {
      // form 선택이후 event update 시 form, event관련 데이터 모두 업데이트 해줘야 합니다.
      const {
        reqBody: { beatType: reqBeatType, onsetFormIds, terminationFormIds },
        filterSelectedItemList,
        succeedWaveformIndexList,
      } = action;
      const clickedEventType = getClickedEventType(state);
      const millisecondTime = new Date().getTime();

      const updatedEventPoolingData = {};
      const updatedPatchBeatByFormIds = [];
      const updatedPatchBeatByWaveFormIndexes = {};
      const selectedFormId =
        state.clusterFormDetail[
          state.selectedInfo.FORMS.lastSelectedSectionInfo[0]?.index
        ]?.formInfo.id;

      // tenSecStripDetail이 활성화 되어 있는 경우 처리를 위함
      const {
        beatLabelButtonDataList,
        onsetWaveformIdx,
        terminationWaveformIdx,
      } = state.tenSecStripDetail ?? {};

      // redux state(clusterEventDetail) update
      const updateEditTypeOfClusterEventDetail = [];
      let newBeatLabelButtonDataListAfterPatchBeats;
      if (isFormIdInList(onsetFormIds, selectedFormId)) {
        updateEditTypeOfClusterEventDetail.push(editType.ONSET);
      }

      if (isFormIdInList(terminationFormIds, selectedFormId)) {
        updateEditTypeOfClusterEventDetail.push(editType.TERMINATION);
      }

      for (let filterSelectItem of filterSelectedItemList) {
        const formId = filterSelectItem.formInfo.id;
        updatedPatchBeatByWaveFormIndexes[formId] =
          state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][
            formId
          ] ?? [];

        updatedPatchBeatByFormIds.push(formId);

        const poolingEventOfClickedForm =
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ][formId];

        if (!poolingEventOfClickedForm) {
          continue;
        }

        const updatedPoolingDataOfClickedForm = [
          ...poolingEventOfClickedForm,
        ].map((poolingDataItem) => {
          if (!poolingDataItem[1]) {
            return poolingDataItem;
          }

          const poolingData = poolingDataItem[1];
          updatePoolingBeatType({
            poolingData,
            succeedWaveformIndexList,
            reqBeatType,
            formId,
            updatedPatchBeatByWaveFormIndexes,
          });

          return poolingDataItem;
        });

        const updatePoolingDataMap = new Map(updatedPoolingDataOfClickedForm);
        updatedEventPoolingData[formId] = updatePoolingDataMap;
      }

      if (onsetWaveformIdx && terminationWaveformIdx) {
        let tenSecStripDetailWaveformIndex =
          (onsetWaveformIdx + terminationWaveformIdx) / 2;

        for (let i in state.clusterEventDetail) {
          if (
            state.clusterEventDetail[i].originWaveformIndex ===
            tenSecStripDetailWaveformIndex
          ) {
            const chkBeatsType = state.poolingData[
              ShapeReviewSectionArea.EVENTS
            ].data[clickedEventType][selectedFormId].get(
              tenSecStripDetailWaveformIndex
            ).beats.beatType;

            newBeatLabelButtonDataListAfterPatchBeats =
              beatLabelButtonDataList.map((beat, idx) => {
                beat.isSelected = false;
                beat.beatType = chkBeatsType[idx];
                beat.title = TEN_SEC_STRIP_EDIT.BEAT_TYPE[chkBeatsType[idx]];
                beat.color =
                  TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[chkBeatsType[idx]];
                return beat;
              });
          }
        }
      }
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: newBeatLabelButtonDataListAfterPatchBeats
            ? newBeatLabelButtonDataListAfterPatchBeats
            : [],
          beatsOrigin: {
            ...state.tenSecStripDetail.beatsOrigin,
            beatType: state.tenSecStripDetail.beatsOrigin.beatType,
          },
          responseValidationResult:
            state.tenSecStripDetail.responseValidationResult,
          pending: false,
          error: null,
        },
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: false,
          patchedList: {
            ...state.patchBeatByFormIds.patchedList,
            [clickedEventType]: [
              ...new Set([
                ...state.patchBeatByFormIds.patchedList[clickedEventType],
                ...updatedPatchBeatByFormIds,
              ]),
            ],
          },
        },
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          patchedList: {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              ...updatedPatchBeatByWaveFormIndexes,
            },
          },
        },
        clusterFormDetail: [
          ...state.clusterFormDetail.map((formDetail) => {
            const isIncludeReqOnsetFormIdInSelectItem = onsetFormIds.includes(
              formDetail.formInfo.id
            );
            const isIncludeOriginWaveformIndexOfReq =
              succeedWaveformIndexList.includes(formDetail.originWaveformIndex);
            const isIncludeReqTerminationFormIdInSelectItem =
              terminationFormIds.includes(formDetail.formInfo.id);
            const isIncludeTerminationWaveformIndexOfReq =
              succeedWaveformIndexList.includes(
                formDetail.terminationWaveformIndex
              );

            if (
              isIncludeReqOnsetFormIdInSelectItem &&
              isIncludeOriginWaveformIndexOfReq
            ) {
              const indexOfOnsetOriginWaveformIndex =
                formDetail.beats.waveformIndex.indexOf(
                  formDetail.originWaveformIndex
                );
              formDetail.beatType = reqBeatType;
              formDetail.beats.beatType[indexOfOnsetOriginWaveformIndex] =
                reqBeatType;
            }

            if (
              isIncludeReqTerminationFormIdInSelectItem &&
              isIncludeTerminationWaveformIndexOfReq
            ) {
              const indexOfTerminationOriginWaveformIndex =
                formDetail.beats.waveformIndex.indexOf(
                  formDetail.terminationWaveformIndex
                );
              formDetail.beats.beatType[indexOfTerminationOriginWaveformIndex] =
                reqBeatType;
            }
            formDetail.millisecondTime = millisecondTime;
            return formDetail;
          }),
        ],
        clusterEventDetail: [
          ...state.clusterEventDetail.map((eventDetail) => {
            if (updateEditTypeOfClusterEventDetail.includes(editType.ONSET)) {
              if (
                succeedWaveformIndexList.includes(
                  eventDetail.originWaveformIndex
                )
              ) {
                const originWaveformIndex =
                  eventDetail.beats.waveformIndex.indexOf(
                    eventDetail.originWaveformIndex
                  );
                eventDetail.beats.beatType[originWaveformIndex] = reqBeatType;
              }
            }
            if (
              updateEditTypeOfClusterEventDetail.includes(editType.TERMINATION)
            ) {
              if (
                succeedWaveformIndexList.includes(
                  eventDetail.terminationWaveformIndex
                )
              ) {
                const terminationWaveformIndex =
                  eventDetail.beats.waveformIndex.indexOf(
                    eventDetail.terminationWaveformIndex
                  );
                eventDetail.beats.beatType[terminationWaveformIndex] =
                  reqBeatType;
              }
            }

            eventDetail.beatType = reqBeatType;
            eventDetail.millisecondTime = millisecondTime;

            return eventDetail;
          }),
        ],
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                ...updatedEventPoolingData,
              },
            },
          },
        },
      };
    }
    case PATCH_BEATS_BY_FORM_ID_FAILED: {
      return {
        ...state,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: false,
        },
      };
    }
    // ## api - patch beat 방법2 (by waveformIndex)
    case PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED: {
      return {
        ...state,
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          pending: true,
          responseValidationResult: {
            succeedWaveformIndexes: [],
            failedWaveformIndexes: [],
          },
        },
      };
    }
    case PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED: {
      const {
        poolingDataOfSelectedForm,
        isOptimisticEventDataUpdate,
        targetWaveformIndex,
        resBeatType,
        optimisticUpdatedTargetEvents,
        selectedTenSecStripOriginWaveformIndex,
        responseValidationResult: {
          succeedWaveformIndexes,
          failedWaveformIndexes,
        },
      } = action;
      const clickedEventType = getClickedEventType(state);
      const millisecondTime = new Date().getTime();
      const formPanelDetailOfFirstIndexOfLastSelectedSection =
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
          shapeReviewReducer: state,
        });
      const newBeatLabelButtonDataListAfterPatchBeats = rfdcClone(
        state.tenSecStripDetail.beatLabelButtonDataList
      );
      const updatedPoolingDataOfClickedEvent = [...poolingDataOfSelectedForm];
      const isEditedAll =
        state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][
          formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
        ] === editType.ALL;

      //편집하는 이벤트의 clusterEventDetail 업데이트 시 아래 업데이트 로직에서 바로 찾기 위해 사용하는 map
      const clusterEventDetailOfMap = new Map();
      state.clusterEventDetail.forEach((eventDetail) => {
        clusterEventDetailOfMap.set(
          eventDetail.originWaveformIndex,
          eventDetail
        );
      });

      // 선택한 이벤트만큼만 반복해서 optimisticUpdatedTargetEvents의 결과로
      // poolingData과 clusterEventDetail을 업데이트 하기 위한 clusterEventDetailOfMap를 업데이트 합니다.
      Object.entries(targetWaveformIndex).forEach(([key, value]) => {
        const poolingDataTarget = poolingDataOfSelectedForm.get(Number(value));
        const eventDetail = clusterEventDetailOfMap.get(value);

        // Update poolingDataTarget
        if (poolingDataTarget) {
          poolingDataTarget.beats.beatType =
            optimisticUpdatedTargetEvents[value].beatType;
          poolingDataTarget.beats.hr = optimisticUpdatedTargetEvents[value].hr;
          poolingDataTarget.beats.waveformIndex =
            optimisticUpdatedTargetEvents[value].waveformIndex;
          poolingDataTarget.beats.millisecondTime = new Date();
        }

        // Update eventDetail
        if (eventDetail) {
          const originIndex = eventDetail.originWaveformIndex;
          const terminationIndex = eventDetail.terminationWaveformIndex;

          if (targetWaveformIndex[originIndex] !== undefined) {
            eventDetail.beats.beatType =
              optimisticUpdatedTargetEvents[originIndex].beatType;
            eventDetail.beatType = resBeatType;
            eventDetail.beats.hr =
              optimisticUpdatedTargetEvents[originIndex].hr;
          }

          if (targetWaveformIndex[terminationIndex] !== undefined) {
            eventDetail.beats.beatType =
              optimisticUpdatedTargetEvents[originIndex].beatType;
            eventDetail.beatType = resBeatType;
            eventDetail.beats.hr =
              optimisticUpdatedTargetEvents[originIndex].hr;
          }

          eventDetail.millisecondTime = millisecondTime;
        }
      });

      // 업데이트 된 clusterEventDetail
      const newClusterEventDetail = Array.from(
        clusterEventDetailOfMap.values()
      );

      // beatLabelButtonData 업데이트 함수
      const updateBeatLabelButtonData = (eventDetail, resBeatType) => {
        const updateBeatData = (waveformIndex) => {
          const index = eventDetail.beats.waveformIndex.indexOf(waveformIndex);
          if (index !== -1) {
            const beatData = newBeatLabelButtonDataListAfterPatchBeats[index];
            beatData.isSelected = false;
            beatData.beatType = resBeatType;
            beatData.title = TEN_SEC_STRIP_EDIT.BEAT_TYPE[resBeatType];
            beatData.color = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[resBeatType];
          }
        };

        updateBeatData(eventDetail.originWaveformIndex);
        updateBeatData(eventDetail.terminationWaveformIndex);
      };

      // 10s strip이 open 되어있을때 업데이트
      if (newBeatLabelButtonDataListAfterPatchBeats) {
        const selectedTenSecStripWaveformIndexes =
          optimisticUpdatedTargetEvents[selectedTenSecStripOriginWaveformIndex]
            .waveformIndex;
        for (let i in optimisticUpdatedTargetEvents) {
          const eventDetail = poolingDataOfSelectedForm.get(Number(i));

          const originWaveformExistsInSucceedList =
            selectedTenSecStripWaveformIndexes.indexOf(
              eventDetail.originWaveformIndex
            );
          if (originWaveformExistsInSucceedList !== -1) {
            updateBeatLabelButtonData(eventDetail, resBeatType);
          }

          const terminationWaveformExistsInSucceedList =
            selectedTenSecStripWaveformIndexes.indexOf(
              eventDetail.terminationWaveformIndex
            );
          if (terminationWaveformExistsInSucceedList !== -1) {
            updateBeatLabelButtonData(eventDetail, resBeatType);
          }
        }
      }

      // 선택한 폼의 Event 중, 마지막 Event가 변경되었다면 Form의 대표이미지도 변경
      let updatedClickedFormPoolingData = [];
      const { representativeWaveformIndex } =
        formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo || {};
      const isLastEventEdited = !!(
        targetWaveformIndex[representativeWaveformIndex] ?? []
      ).length;

      if (isLastEventEdited) {
        const copiedPoolingData = getRepresentativeWaveformValue(
          state.poolingData.FORMS.data[clickedEventType],
          representativeWaveformIndex
        );

        const updatedPoolingData = updateBeatsAndTime(
          copiedPoolingData,
          updatedPoolingDataOfClickedEvent,
          representativeWaveformIndex
        );

        updatedClickedFormPoolingData = createUpdatedDataMap(
          representativeWaveformIndex,
          updatedPoolingData
        );

        function getRepresentativeWaveformValue(
          data,
          representativeWaveformIndex
        ) {
          return [...data].find(
            (v) => v[0] === representativeWaveformIndex
          )?.[1];
        }
        function updateBeatsAndTime(
          data,
          updatedData,
          representativeWaveformIndex
        ) {
          data.beats = updatedData.find(
            (v) => v[0] === representativeWaveformIndex
          )[1].beats;
          data.millisecondTime = new Date();

          return data;
        }
        function createUpdatedDataMap(representativeWaveformIndex, data) {
          return new Map([[representativeWaveformIndex, data]]);
        }
      }

      // updateState
      let updateStateOfPatchBeatsByWaveformIndexSucceed = {
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          pending: !isOptimisticEventDataUpdate
            ? false
            : state.patchBeatByWaveFormIndexes.pending,
          responseValidationResult: {
            succeedWaveformIndexes: succeedWaveformIndexes ?? [],
            failedWaveformIndexes: failedWaveformIndexes ?? [],
          },
        },
        clusterFormDetail: rfdcClone(
          state.clusterFormDetail.map((formDetail) => {
            const updatedWaveformIndexList =
              targetWaveformIndex[formDetail.originWaveformIndex] ?? [];
            const updatedTerminationWaveformIndexList =
              targetWaveformIndex[formDetail.terminationWaveformIndex] ?? [];

            if (updatedWaveformIndexList.length > 0) {
              for (let updatedWaveformIndex of updatedWaveformIndexList) {
                const onsetWaveformIndexIndex =
                  formDetail.beats.waveformIndex.indexOf(updatedWaveformIndex);
                formDetail.beats.beatType[onsetWaveformIndexIndex] =
                  resBeatType;
                formDetail.beatType = resBeatType;
              }
            }

            if (updatedTerminationWaveformIndexList.length > 0) {
              for (let updatedWaveformIndex of updatedTerminationWaveformIndexList) {
                const terminationWaveformIndexIndex =
                  formDetail.beats.waveformIndex.indexOf(updatedWaveformIndex);
                formDetail.beats.beatType[terminationWaveformIndexIndex] =
                  resBeatType;
                formDetail.beatType = resBeatType;
              }
            }
            formDetail.millisecondTime = millisecondTime;
            return formDetail;
          })
        ),
        clusterEventDetail: newClusterEventDetail,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.FORMS]: {
            ...state.poolingData[ShapeReviewSectionArea.FORMS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: new Map(
                [...state.poolingData.FORMS.data[clickedEventType]],
                [...updatedClickedFormPoolingData]
              ),
            },
          },
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]:
                  new Map(updatedPoolingDataOfClickedEvent),
              },
            },
          },
        },
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: newBeatLabelButtonDataListAfterPatchBeats
            ? newBeatLabelButtonDataListAfterPatchBeats
            : [],
          beatsOrigin: {
            ...state.tenSecStripDetail.beatsOrigin,
          },
          responseValidationResult:
            state.tenSecStripDetail.responseValidationResult,
          pending: false,
          error: null,
        },
      };
      // 폼 패널안의 모든 이벤트가 edited면, Form도 edited로 변경
      const patchedEventList =
        state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][
          formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
        ] || [];
      const validPatchedEventList =
        patchedEventList === editType.ALL
          ? [...updatedPoolingDataOfClickedEvent.map((v) => v[0])]
          : patchedEventList;
      const newPatchedEventListOfClickedForm = unionArrays(
        validPatchedEventList,
        succeedWaveformIndexes
      );
      const isAllEventEdited = getIsAllEventEdited({
        poolingDataOfClickedEvent: updatedPoolingDataOfClickedEvent,
        newPatchedEventListOfClickedForm,
      });
      const patchBeatByFormId =
        newPatchedEventListOfClickedForm.length ===
        updatedPoolingDataOfClickedEvent.length
          ? [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]
          : [];
      const waveformIndexListInfoOfForms =
        state.waveformIndexListInfoOfForms.data[clickedEventType];
      const targetUpdatePatchedList = rfdcClone(
        waveformIndexListInfoOfForms[
          selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
            shapeReviewReducer: state,
          }).formInfo.id
        ]
      );
      const newPatchedList = Object.keys(action.targetWaveformIndex).map(
        (item) => {
          const terminationTargetUpdatePatchedListIndex =
            targetUpdatePatchedList.terminationWaveformIndexListOfForm.indexOf(
              Number(item)
            );
          const onsetTargetUpdatePatchedListIndex =
            targetUpdatePatchedList.onsetWaveformIndexListOfForm.indexOf(
              Number(item)
            );
          const targetUpdatePatchedListIndex =
            terminationTargetUpdatePatchedListIndex !== -1
              ? terminationTargetUpdatePatchedListIndex
              : onsetTargetUpdatePatchedListIndex;

          return targetUpdatePatchedList.onsetWaveformIndexListOfForm[
            targetUpdatePatchedListIndex
          ];
        }
      );
      if (!isEditedAll) {
        updateStateOfPatchBeatsByWaveformIndexSucceed.patchBeatByWaveFormIndexes.patchedList =
          {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              [formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id]:
                isAllEventEdited
                  ? editType.ALL
                  : [
                      ...(state.patchBeatByWaveFormIndexes.patchedList[
                        clickedEventType
                      ][
                        selectFormPanelDetailOfFirstIndexOfLastSelectedSection({
                          shapeReviewReducer: state,
                        }).formInfo.id
                      ] ?? []),
                      ...new Set(newPatchedList),
                    ],
            },
          };
      }
      return {
        ...state,
        ...updateStateOfPatchBeatsByWaveformIndexSucceed,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          patchedList: {
            ...state.patchBeatByFormIds.patchedList,
            [clickedEventType]: [
              ...state.patchBeatByFormIds.patchedList[clickedEventType],
              ...patchBeatByFormId,
            ],
          },
        },
      };
    }
    case PATCH_BEATS_BY_WAVEFORM_INDEX_FAILED: {
      return {
        ...state,
      };
    }
    // Beat Postprocess
    case PATCH_BEAT_POSTPROCESS_REQUESTED: {
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: true,
          error: null,
        },
      };
    }
    case PATCH_BEAT_POSTPROCESS_SUCCEED: {
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: false,
          data: {
            ...action.payload,
          },
        },
      };
    }
    case PATCH_BEAT_POSTPROCESS_FAILED: {
      return {
        ...state,
        beatPostprocess: {
          ...state.beatPostprocess,
          pending: false,
          error: action.payload.error,
        },
      };
    }

    case DELETE_BEATS_BY_WAVEFORM_INDEX_REQUESTED: {
      return {
        ...state,
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          pending: true,
          responseValidationResult: {
            succeedWaveformIndexes: [],
            failedWaveformIndexes: [],
          },
        },
      };
    }
    case DELETE_BEATS_BY_WAVEFORM_INDEX_SUCCEED: {
      const {
        waveformIndexes,
        formId,
        focusedSectionInfo,
        deleteTargetWaveformIndexOfOriginWaveformIndex,
        newPatchedList,
        poolingDataOfAll,
      } = action.reqBody;

      const targetWaveformIndexPosition = {};
      const millisecondTime = new Date().getTime();
      const newClusterFormDetail = rfdcClone(state.clusterFormDetail);
      const newTensecStrip = rfdcClone(state.tenSecStripDetail);
      const newClusterEventDetail = rfdcClone(state.clusterEventDetail);
      const clickedEventType = getClickedEventType(state);
      const poolingDataEventsMap =
        poolingDataOfAll[ShapeReviewSectionArea.EVENTS].data[
          state.shapeReviewState.CLICKED_INFO.eventType
        ];
      const poolingDataFormsMap = rfdcClone(
        poolingDataOfAll[ShapeReviewSectionArea.FORMS].data[
          state.shapeReviewState.CLICKED_INFO.eventType
        ]
      );

      const eventsKeys = Object.keys(poolingDataEventsMap);

      // 활성화 된 10s의 originWaveformIndex value
      const selectedOriginWaveformIndex =
        !newTensecStrip.onsetWaveformIdx &&
        !newTensecStrip.terminationWaveformIdx
          ? null
          : (newTensecStrip.onsetWaveformIdx +
              newTensecStrip.terminationWaveformIdx) /
            2;

      // 이벤트 타입(EVENT-TYPE-ISOLATE-2, EVENT-TYPE-ISOLATE-1, ) - Form Id {8371, 8377, ..}
      const waveformIndexListInfoOfForms =
        state.waveformIndexListInfoOfForms.data[clickedEventType];

      // waveformIndexListInfoOfForm - {[originWaveformIndexListOfForm], [terminationWaveformIndexListOfForm]}
      const selectedWaveformIndexListInfoOfForms =
        waveformIndexListInfoOfForms[formId];

      // Edited Label이 붙은 events list
      const originPatchedList =
        state.patchBeatByWaveFormIndexes.patchedList[clickedEventType][formId];

      // 삭제 이후 업데이트 한 patchedList
      const updatedPatchedList =
        originPatchedList === editType.ALL
          ? editType.ALL
          : [...new Set([...originPatchedList, ...newPatchedList])].filter(
              (n) => Number(n)
            );

      // Form에 포함된 모든 Events가 수정되었는지에 대한 여뷰
      const allEventsPatched =
        updatedPatchedList.length ===
        state.shapeReviewState.PAGINATION_INFO[ShapeReviewSectionArea.EVENTS][
          clickedEventType
        ][formId].totalItemCount;

      deleteTargetWaveformIndexOfOriginWaveformIndex.forEach(
        (targetEvent, idx) => {
          const [key] = Object.entries(targetEvent)[0];
          // clusterEventDetail 중 삭제 요청 된 eventDetail
          const eventDetail = poolingDataEventsMap[formId].get(Number(key));
          // originWaveformIndex Index
          const targetOriginWaveformIndex =
            eventDetail.beats.waveformIndex.indexOf(
              eventDetail.originWaveformIndex
            );

          if (
            waveformIndexes.includes(eventDetail.originWaveformIndex) &&
            targetOriginWaveformIndex !== -1
          ) {
            targetWaveformIndexPosition[eventDetail.originWaveformIndex] = {
              targetIndex: targetOriginWaveformIndex,
              targetWaveformIndex: eventDetail.originWaveformIndex,
            };
          }
          // terminationWaveformIndex Index
          const targetTerminationWaveformIndex =
            eventDetail.beats.waveformIndex.indexOf(
              eventDetail.terminationWaveformIndex
            );

          if (
            waveformIndexes.includes(eventDetail.terminationWaveformIndex) &&
            targetTerminationWaveformIndex !== -1
          ) {
            targetWaveformIndexPosition[eventDetail.terminationWaveformIndex] =
              {
                targetIndex: targetTerminationWaveformIndex,
                targetWaveformIndex: eventDetail.originWaveformIndex,
              };
          }
        }
      );

      // 10s Strip 비트 삭제
      if (newTensecStrip.onsetWaveformIdx) {
        waveformIndexes.forEach((v) => {
          const targetIndex =
            newTensecStrip.beatsOrigin.waveformIndex.indexOf(v);

          if (targetIndex !== -1) {
            newTensecStrip.beatsOrigin.waveformIndex.splice(targetIndex, 1);
            newTensecStrip.beatsOrigin.beatType.splice(targetIndex, 1);
            newTensecStrip.beatsOrigin.hr.splice(targetIndex, 1);
            newTensecStrip.beatsOrigin.isEdited.splice(targetIndex, 1);
            newTensecStrip.beatLabelButtonDataList.splice(targetIndex, 1);
          }
        });
      }

      for (const [key, value] of poolingDataFormsMap) {
        if (selectedOriginWaveformIndex === key) {
          const originWaveformIndex = value.beats.waveformIndex.indexOf(
            value.originWaveformIndex
          );

          if (originWaveformIndex !== -1) {
            value.beats.hr.splice(originWaveformIndex, 1);
            value.beats.beatType.splice(originWaveformIndex, 1);
            value.beats.isEdited.splice(originWaveformIndex, 1);
            value.beats.waveformIndex.splice(originWaveformIndex, 1);
          }

          const terminationWaveformIndex =
            selectedWaveformIndexListInfoOfForms.onsetWaveformIndexListOfForm.indexOf(
              value.terminationWaveformIndex
            );

          if (terminationWaveformIndex !== -1) {
            value.beats.hr.splice(terminationWaveformIndex, 1);
            value.beats.beatType.splice(terminationWaveformIndex, 1);
            value.beats.isEdited.splice(terminationWaveformIndex, 1);
            value.beats.waveformIndex.splice(terminationWaveformIndex, 1);
          }

          value.millisecondTime = millisecondTime;
          poolingDataFormsMap.set(key, value);
        }
      }
      const newClusterEventAfterDeleteBeats = newClusterEventDetail.map(
        (eventDetail, idx) => {
          if (waveformIndexes.includes(eventDetail.originWaveformIndex)) {
            const originWaveformIndex = eventDetail.beats.waveformIndex.indexOf(
              eventDetail.originWaveformIndex
            );

            if (originWaveformIndex !== -1) {
              eventDetail.beats.hr.splice(originWaveformIndex, 1);
              eventDetail.beats.beatType.splice(originWaveformIndex, 1);
              eventDetail.beats.isEdited.splice(originWaveformIndex, 1);
              eventDetail.beats.waveformIndex.splice(originWaveformIndex, 1);
            }
          }
          if (waveformIndexes.includes(eventDetail.terminationWaveformIndex)) {
            const terminationWaveformIndex =
              eventDetail.beats.waveformIndex.indexOf(
                eventDetail.terminationWaveformIndex
              );

            if (terminationWaveformIndex !== -1) {
              eventDetail.beats.hr.splice(terminationWaveformIndex, 1);
              eventDetail.beats.beatType.splice(terminationWaveformIndex, 1);
              eventDetail.beats.isEdited.splice(terminationWaveformIndex, 1);
              eventDetail.beats.waveformIndex.splice(
                terminationWaveformIndex,
                1
              );
            }
          }
          eventDetail.millisecondTime = millisecondTime;
          return eventDetail;
        }
      );

      const newClusterFormAfterDeleteBeats = newClusterFormDetail.map(
        (eventDetail, idx) => {
          if (waveformIndexes.includes(eventDetail.originWaveformIndex)) {
            const originWaveformIndex = eventDetail.beats.waveformIndex.indexOf(
              eventDetail.originWaveformIndex
            );

            if (originWaveformIndex !== -1) {
              eventDetail.beats.hr.splice(originWaveformIndex, 1);
              eventDetail.beats.beatType.splice(originWaveformIndex, 1);
              eventDetail.beats.isEdited.splice(originWaveformIndex, 1);
              eventDetail.beats.waveformIndex.splice(originWaveformIndex, 1);
            }

            const terminationWaveformIndex =
              eventDetail.beats.waveformIndex.indexOf(
                eventDetail.terminationWaveformIndex
              );

            if (terminationWaveformIndex !== -1) {
              eventDetail.beats.hr.splice(terminationWaveformIndex, 1);
              eventDetail.beats.beatType.splice(terminationWaveformIndex, 1);
              eventDetail.beats.isEdited.splice(terminationWaveformIndex, 1);
              eventDetail.beats.waveformIndex.splice(
                terminationWaveformIndex,
                1
              );
            }
            eventDetail.millisecondTime = millisecondTime;
          }
          return eventDetail;
        }
      );

      const updatedDeletedEventsPoolingData =
        getUpdatedDeletedEventsPoolingData({
          poolingDataEventsMap,
          eventsKeys,
          millisecondTime,
          targetWaveformIndexPosition,
        });

      return {
        ...state,
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          patchedList: {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              [formId]:
                updatedPatchedList.length ===
                state.shapeReviewState.PAGINATION_INFO[
                  ShapeReviewSectionArea.EVENTS
                ][clickedEventType][formId].totalItemCount
                  ? 'ALL'
                  : updatedPatchedList,
            },
          },
        },
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          patchedList: {
            ...state.patchBeatByFormIds.patchedList,
            [clickedEventType]: [
              ...state.patchBeatByFormIds.patchedList[clickedEventType],
              ...(allEventsPatched ? [formId] : []),
            ],
          },
        },

        tenSecStripDetail: newTensecStrip,
        clusterEventDetail: newClusterEventAfterDeleteBeats,
        clusterFormDetail: newClusterFormAfterDeleteBeats,
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: updatedDeletedEventsPoolingData,
            },
          },
          [ShapeReviewSectionArea.FORMS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: poolingDataFormsMap,
            },
          },
        },
        shapeReviewState: {
          ...state.shapeReviewState,
          [ShapeReviewState.SELECT_ALL]: {
            ...state.shapeReviewState[ShapeReviewState.SELECT_ALL],
            EVENTS: 'None',
          },
        },
      };
    }

    case DELETE_BEATS_BY_WAVEFORM_INDEX_FAILED: {
      return {
        ...state,
      };
    }

    case DELETE_BEATS_BY_FORM_ID_REQUESTED: {
      return {
        ...state,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: true,
        },
      };
    }
    case DELETE_BEATS_BY_FORM_ID_SUCCEED: {
      const {
        poolingDataOfAll,
        onsetFormIds,
        terminationFormIds,
        filterSelectedItemList,
        deleteTargetWaveformIndexList,
      } = action;
      const updatedEventPoolingData = {};
      const updatedPatchBeatByFormIds = [];
      const updatedPatchBeatByWaveFormIndexes = {};

      const clickedEventType = getClickedEventType(state);
      const millisecondTime = new Date().getTime();
      const updateEditTypeOfClusterEventDetail = [];
      const newBeatLabelButtonDataListAfterPatchBeats = rfdcClone(
        state.tenSecStripDetail.beatLabelButtonDataList
      );

      const selectedFormId =
        state.clusterFormDetail[
          state.selectedInfo.FORMS.lastSelectedSectionInfo[0]?.index
        ]?.formInfo.id;

      const selectedEventDetailIndex =
        state.clusterEventDetail[
          state.selectedInfo[ShapeReviewSectionArea.EVENTS]
            ?.lastSelectedSectionInfo[0]?.index
        ] ?? {};

      const getWaveformIndexPoint = (index, offset) => index - offset ?? 0;

      const tensecStripOriginWaveformIndexAxiosPoint = getWaveformIndexPoint(
        selectedEventDetailIndex?.originWaveformIndex,
        state.tenSecStripDetail.onsetWaveformIdx
      );

      const tensecStripTerminationWaveformIndexAxiosPoint =
        getWaveformIndexPoint(
          selectedEventDetailIndex?.terminationWaveformIndex,
          state.tenSecStripDetail.onsetWaveformIdx
        );

      const getIndexPosition = (point) =>
        Array.isArray(newBeatLabelButtonDataListAfterPatchBeats)
          ? newBeatLabelButtonDataListAfterPatchBeats.findIndex(
              (item) => item.xAxisPoint === point
            )
          : -1;

      const tensecStripOriginWaveformIndexPosition = getIndexPosition(
        tensecStripOriginWaveformIndexAxiosPoint
      );
      const tensecStripTerminationWaveformIndexPosition = getIndexPosition(
        tensecStripTerminationWaveformIndexAxiosPoint
      );

      for (let filterSelectItem of filterSelectedItemList) {
        const formId = filterSelectItem.formInfo.id;

        updatedPatchBeatByFormIds.push(formId);
        updatedPatchBeatByWaveFormIndexes[formId] = editType.ALL;

        const poolingForm =
          poolingDataOfAll[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ];

        if (poolingForm[formId]) {
          const poolingEventOfClickedForm =
            poolingDataOfAll[ShapeReviewSectionArea.EVENTS].data[
              clickedEventType
            ][formId];

          if (!poolingEventOfClickedForm) {
            continue;
          }

          const updatedPoolingDataOfClickedForm = [
            ...poolingEventOfClickedForm,
          ].map((poolingDataItem) => {
            if (!poolingDataItem[1]) {
              return poolingDataItem;
            }

            const poolingData = poolingDataItem[1];

            deleteBeatsByFormIdUpdatePoolingBeatType({
              poolingData,
              deleteTargetWaveformIndexList,
            });

            return poolingDataItem;
          });

          const updatePoolingDataMap = new Map(updatedPoolingDataOfClickedForm);
          updatedEventPoolingData[formId] = updatePoolingDataMap;
        }
      }

      if (isFormIdInList(onsetFormIds, selectedFormId)) {
        updateEditTypeOfClusterEventDetail.push(editType.ONSET);
      }
      if (isFormIdInList(terminationFormIds, selectedFormId)) {
        updateEditTypeOfClusterEventDetail.push(editType.TERMINATION);
      }

      if (state.tenSecStripDetail && selectedEventDetailIndex) {
        const shouldDeleteOrigin =
          deleteTargetWaveformIndexList.includes(
            selectedEventDetailIndex.originWaveformIndex
          ) && tensecStripOriginWaveformIndexPosition >= 0;

        const shouldDeleteTermination =
          deleteTargetWaveformIndexList.includes(
            selectedEventDetailIndex.terminationWaveformIndex
          ) && tensecStripTerminationWaveformIndexPosition >= 0;

        if (shouldDeleteOrigin) {
          newBeatLabelButtonDataListAfterPatchBeats.splice(
            tensecStripOriginWaveformIndexPosition,
            1
          );
        }
        // ISO 인 경우 originWaveformIndex 와 terminationWaveformIndex가 같기 때문에 originWaveformIndex 와 terminationWaveformIndex가
        // 다른 경우에만 terminationWaveformIndex 위치를 삭제한다.
        if (
          shouldDeleteTermination &&
          selectedEventDetailIndex?.originWaveformIndex !==
            selectedEventDetailIndex?.terminationWaveformIndex
        ) {
          newBeatLabelButtonDataListAfterPatchBeats.splice(
            tensecStripTerminationWaveformIndexPosition,
            1
          );
        }
      }

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: newBeatLabelButtonDataListAfterPatchBeats,
          beatsOrigin: {
            ...state.tenSecStripDetail.beatsOrigin,
            beatType: state.tenSecStripDetail.beatsOrigin.beatType,
          },
          responseValidationResult:
            state.tenSecStripDetail.responseValidationResult,
          pending: false,
          error: null,
        },
        clusterFormDetail: [
          ...state.clusterFormDetail.map((formDetail) => {
            const isIncludeReqOnsetFormIdInSelectItem = onsetFormIds.includes(
              formDetail.formInfo.id
            );
            const isIncludeReqTerminationFormIdInSelectItem =
              terminationFormIds.includes(formDetail.formInfo.id);
            if (isIncludeReqOnsetFormIdInSelectItem) {
              const indexOfOnsetOriginWaveformIndex =
                formDetail.beats.waveformIndex.indexOf(
                  formDetail.originWaveformIndex
                );
              formDetail.beats.beatType.splice(
                indexOfOnsetOriginWaveformIndex,
                1
              );
              formDetail.beats.hr.splice(indexOfOnsetOriginWaveformIndex, 1);
              formDetail.beats.isEdited.splice(
                indexOfOnsetOriginWaveformIndex,
                1
              );
              formDetail.beats.waveformIndex.splice(
                indexOfOnsetOriginWaveformIndex,
                1
              );
            }

            if (isIncludeReqTerminationFormIdInSelectItem) {
              const indexOfTerminationOriginWaveformIndex =
                formDetail.beats.waveformIndex.indexOf(
                  formDetail.terminationWaveformIndex
                );

              formDetail.beats.beatType.splice(
                indexOfTerminationOriginWaveformIndex,
                1
              );
              formDetail.beats.hr.splice(
                indexOfTerminationOriginWaveformIndex,
                1
              );
              formDetail.beats.isEdited.splice(
                indexOfTerminationOriginWaveformIndex,
                1
              );
              formDetail.beats.waveformIndex.splice(
                indexOfTerminationOriginWaveformIndex,
                1
              );
            }
            formDetail.millisecondTime = millisecondTime;

            return formDetail;
          }),
        ],
        clusterEventDetail: [
          ...state.clusterEventDetail.map((eventDetail) => {
            const originWaveformIndex = eventDetail.beats.waveformIndex.indexOf(
              eventDetail.originWaveformIndex
            );
            if (updateEditTypeOfClusterEventDetail.includes(editType.ONSET)) {
              if (
                deleteTargetWaveformIndexList.includes(
                  eventDetail.originWaveformIndex
                ) &&
                originWaveformIndex !== -1
              ) {
                eventDetail.beats.beatType.splice(originWaveformIndex, 1);
                eventDetail.beats.hr.splice(originWaveformIndex, 1);
                eventDetail.beats.waveformIndex.splice(originWaveformIndex, 1);
                eventDetail.beats.isEdited.splice(originWaveformIndex, 1);
              }
            }
            const terminationWaveformIndex =
              eventDetail.beats.waveformIndex.indexOf(
                eventDetail.terminationWaveformIndex
              );
            if (
              updateEditTypeOfClusterEventDetail.includes(editType.TERMINATION)
            ) {
              if (
                deleteTargetWaveformIndexList.includes(
                  eventDetail.terminationWaveformIndex
                ) &&
                terminationWaveformIndex !== -1
              ) {
                eventDetail.beats.beatType.splice(terminationWaveformIndex, 1);
                eventDetail.beats.hr.splice(terminationWaveformIndex, 1);
                eventDetail.beats.waveformIndex.splice(
                  terminationWaveformIndex,
                  1
                );
                eventDetail.beats.isEdited.splice(terminationWaveformIndex, 1);
              }
            }
            if (!originWaveformIndex && !terminationWaveformIndex)
              eventDetail.exists = false;
            eventDetail.millisecondTime = millisecondTime;

            return eventDetail;
          }),
        ],
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: false,
          patchedList: {
            ...state.patchBeatByFormIds.patchedList,
            [clickedEventType]: [
              ...new Set([
                ...state.patchBeatByFormIds.patchedList[clickedEventType],
                ...updatedPatchBeatByFormIds,
              ]),
            ],
          },
        },
        patchBeatByWaveFormIndexes: {
          ...state.patchBeatByWaveFormIndexes,
          patchedList: {
            ...state.patchBeatByWaveFormIndexes.patchedList,
            [clickedEventType]: {
              ...state.patchBeatByWaveFormIndexes.patchedList[clickedEventType],
              ...updatedPatchBeatByWaveFormIndexes,
            },
          },
        },
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            ...state.poolingData[ShapeReviewSectionArea.EVENTS],
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: {
                ...state.poolingData[ShapeReviewSectionArea.EVENTS].data[
                  clickedEventType
                ],
                ...updatedEventPoolingData,
              },
            },
          },
        },
      };
    }
    case DELETE_BEATS_BY_FORM_ID_FAILED: {
      return {
        ...state,
        patchBeatByFormIds: {
          ...state.patchBeatByFormIds,
          pending: false,
        },
      };
    }
    // Arrange
    case GET_ARRANGE_REQUIRED_STATUS_REQUESTED: {
      return {
        ...state,
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          pending: true,
          error: null,
        },
      };
    }
    case GET_ARRANGE_REQUIRED_STATUS_SUCCEED: {
      return {
        ...state,
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          pending: false,
          error: null,
          isArrangeRequired: action.payload.isArrangeRequired,
        },
      };
    }
    case GET_ARRANGE_REQUIRED_STATUS_FAILED: {
      return {
        ...state,
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          pending: false,
          error: action.error,
        },
      };
    }
    // Caliper
    case SET_CALIPER_PLOT_LINES: {
      return {
        ...state,
        caliper: {
          ...state.caliper,
          caliperPlotLines: action.caliperPlotLines,
        },
      };
    }
    case SET_IS_CALIPER_MODE: {
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isCaliperMode: action.isCaliperMode,
        },
      };
    }
    case SET_IS_TICK_MARKS_MODE: {
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isTickMarksMode: action.isTickMarksMode,
        },
      };
    }
    case SET_IS_TENSEC_STRIP_EDIT_MODE: {
      return {
        ...state,
        tenSecStripEditMode: {
          ...state.tenSecStripEditMode,
          tenSecStripEditing: action.tenSecStripEditing,
        },
      };
    }

    case SET_IS_ARRANGE_REQUIRED: {
      return {
        ...state,
        arrangeRequiredStatus: {
          ...state.arrangeRequiredStatus,
          isArrangeRequired: action.payload.isArrangeRequired,
        },
      };
    }
    case GET_BEATS_N_ECTOPIC_LIST_REQUESTED: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          ...action.data,
        },
      };
    }

    case GET_BEATS_N_ECTOPIC_LIST_SUCCEED: {
      const {
        isEqualReqResEvent, // isEqualOrigin && isEqualTermination - selectedClusterEventDetail === reqSelectedClusterEventDetail : boolean
        newBeatsOrigin, // getBeatsFilterWaveformIndexRange api response updated with beatsOrigin
        selectedEventOriginWaveformIndex, // reqSelectedClusterEventDetail의 선택된 event originWaveformIndex
        beatsData, // api response
        newSelectedClusterEventDetail, // reqSelectedClusterEventDetail updated with api response(hr, waveformIndex, beatType)
        requestSelectedInfoOfEventPanel, // selectedInfo[ShapeReviewSectionArea.EVENTS] -  lastSelectedSectionInfo[1] ?? lastSelectedSectionInfo[0];
      } = action;

      const millisecondTime = new Date().getTime();
      const clickedEventType = getClickedEventType(state);

      const succeedSelectedInfoEventPanelIndex =
        state.selectedInfo[ShapeReviewSectionArea.EVENTS]
          .lastSelectedSectionInfo[1]?.index ??
        state.selectedInfo[ShapeReviewSectionArea.EVENTS]
          .lastSelectedSectionInfo[0]?.index;

      // selectedInfo - Event - Index
      const selectedEventIndex = requestSelectedInfoOfEventPanel.index;

      // 같을 때만 hrAvg 업데이트 해줘야됨 조건 재확인 필요.
      const tenSecStripAvgHr = getTenSecAvgHrByCenter(
        beatsData,
        (newSelectedClusterEventDetail[selectedEventIndex].mainECG
          .onsetWaveformIndex +
          newSelectedClusterEventDetail[selectedEventIndex].mainECG
            .terminationWaveformIndex) /
          2
      );

      // originWaveformIndex (state)
      const updatedPostedEventsPoolingData = getUpdatedEventsPoolingData({
        poolingDataEventsMap: rfdcClone(
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            state.shapeReviewState.CLICKED_INFO.eventType
          ]
        ),
        selectedOriginWaveformIndex: selectedEventOriginWaveformIndex,
        eventsKeys: Object.keys(
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            state.shapeReviewState.CLICKED_INFO.eventType
          ]
        ),
        newBeatsOrigin,
        millisecondTime,
      });

      const { updatedMapForPoolingDataOfForm } = getTargetMapFromPoolingEvents({
        poolingDataEventsMap: rfdcClone(
          state.poolingData[ShapeReviewSectionArea.EVENTS].data[
            clickedEventType
          ]
        ),
        selectedOriginWaveformIndex: selectedEventOriginWaveformIndex,
        newBeatsOrigin,
      });

      const updatedPostedFormsPoolingData = getUpdatedFormsPoolingData({
        poolingDataFormsMap: rfdcClone(
          state.poolingData[ShapeReviewSectionArea.FORMS].data[clickedEventType]
        ),
        selectedOriginWaveformIndex: selectedEventOriginWaveformIndex,
        updatedMapForPoolingDataOfForm,
        millisecondTime,
      });

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          ...(selectedEventIndex === succeedSelectedInfoEventPanelIndex &&
            isEqualReqResEvent && {
              beatsOrigin: {
                ...state.tenSecStripDetail.beatsOrigin,
                ...beatsData,
              },
              hrAvg: tenSecStripAvgHr,
              pending: false,
            }),
        },
        ...(isEqualReqResEvent && {
          clusterEventDetail: newSelectedClusterEventDetail,
        }),
        poolingData: {
          ...state.poolingData,
          [ShapeReviewSectionArea.EVENTS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.EVENTS].data,
              [clickedEventType]: updatedPostedEventsPoolingData,
            },
          },
          [ShapeReviewSectionArea.FORMS]: {
            data: {
              ...state.poolingData[ShapeReviewSectionArea.FORMS].data,
              [clickedEventType]: updatedPostedFormsPoolingData,
            },
          },
        },
      };
    }
    case GET_BEATS_N_ECTOPIC_LIST_FAILED: {
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: action.error,
        },
      };
    }
    case SET_ORDER_SETTING_TYPE: {
      const defaultSortOption = {
        valueText: '',
        optionText: '',
        tooltipTitle: '',
        queryOrderBy: '',
        ascending: true,
        isDefault: true,
      };
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          orderingTypeSetting: action.orderingTypeSetting ?? defaultSortOption,
        },
      };
    }

    case SET_ORDERING_CONTEXTMENU: {
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          isOrderingBeatContextmenu: action.isOpenOrderingContextmenu,
        },
      };
    }

    case SET_SELECTED_CHECKBOX_STATUS: {
      return {
        ...state,
        shapeReviewState: {
          ...state.shapeReviewState,
          selectedCheckboxStatus: action.checkboxStatus,
        },
      };
    }
    case SET_SELECTED_POOLING_DATA: {
      return {
        ...state,
        selectedPoolingDataOfClusterForm: {
          ...state.selectedPoolingDataOfClusterForm,
          selectedPoolingData: action.selectedPoolingData,
        },
      };
    }

    default:
      return state;
  }
}

// Action Creators
export function resetShapeReviewState(updatedState = {}) {
  return { type: RESET_SHAPE_REVIEW_STATE, updatedState };
}
export function setPanelSize({ panelType, columnNumber, rowNumber }) {
  return {
    type: SET_PANEL_SIZE,
    panelType,
    columnNumber,
    rowNumber,
  };
}
// Set 10s Strip Detail
function setTenSecStripDetail(data) {
  return { type: SET_TENSEC_STRIP_DETAIL, data };
}
function resetTenSecStripDetail() {
  return { type: RESET_TENSEC_STRIP_DETAIL };
}

export function setSelectEvent({
  beatType,
  ectopicType,
  eventType,
  selectedFormPanel,
}) {
  return {
    type: SET_SELECT_EVENT,
    beatType,
    ectopicType,
    eventType,
    selectedFormPanel,
  };
}

export function setPagination({
  eventType,
  formId,
  panelType,
  totalItemCount,
  totalPageSize,
  currentPage,
}) {
  return {
    type: SET_PAGINATION,
    eventType,
    formId,
    panelType,
    totalItemCount,
    totalPageSize,
    currentPage,
  };
}

/**
 * 페이지를 설정하는 액션 생성자 함수입니다.
 * @param {Object} options - 페이지 설정 옵션
 * @param {ShapeReviewSectionArea.FORMS || ShapeReviewSectionArea.EVENTS} options.panelType - 패널 타입
 * @param {POSITION_MOVE_TYPE} options.setPageType - 페이지 설정 타입
 * @param {number} options.value - panel current page
 * @returns {Object} - 페이지 설정 액션 객체
 */
export function setPage({ panelType, setPageType, value }) {
  return {
    type: SET_PAGE,
    panelType,
    setPageType,
    value,
  };
}
export function setLastSelectedSectionInfo({
  triggerType,
  panelType,
  lastSelectedSectionInfo,
}) {
  return {
    type: SET_LAST_SELECTED_SECTION_INFO,
    triggerType,
    panelType,
    lastSelectedSectionInfo,
  };
}
export function setSelectedItemList({ panelType, selectedItemList }) {
  return { type: SET_SELECTED_ITEM_LIST, panelType, selectedItemList };
}

//Shape review에서 드래그를 사용해서 편집 ,삭제를 하는데 요청과 완료 사이에 다시 드래그를 하게 되면 완료 시점에 드래그를 풀어버려 사이드 패널의 편집, 삭제 단축키가 동작하는 이슈가 발생
//- 드래그 시에 선택한 이벤트를 해제 시켜 사이드 패널 편집을 불가능 하게 만든다.
export function setInitSelectedItemList({ panelType, selectedItemList }) {
  return { type: SET_SELECTED_ITEM_LIST, panelType, selectedItemList };
}
export function setSelectAll({ panelType, selectAllState }) {
  return { type: SET_SELECT_ALL, panelType, selectAllState };
}
export function setActivePanel({ activePanel }) {
  return { type: SET_ACTIVE_PANEL, activePanel };
}
export function setFormData(formData) {
  return { type: SET_FORM_DATA, formData };
}
export function setEventData(eventData) {
  return { type: SET_EVENT_DATA, eventData };
}
export function getClusteringStatisticsRequested() {
  return { type: GET_CLUSTERING_STATISTICS_REQUESTED };
}
function getClusteringStatisticsSucceed(data) {
  return { type: GET_CLUSTERING_STATISTICS_SUCCEED, data };
}
function getClusteringStatisticsFailed(error) {
  return { type: GET_CLUSTERING_STATISTICS_FAILED, error };
}
export function getFormListRequested({ formBeatType, formEctopicType }) {
  return {
    type: GET_FORM_LIST_REQUESTED,
    formBeatType,
    formEctopicType,
  };
}
export function getFormListSucceed({
  forms,
  formsThumbNailWaveFormIndex,
  totalEditedBeatCount,
  totalFormsCount,
}) {
  return {
    type: GET_FORM_LIST_SUCCEED,
    forms,
    formsThumbNailWaveFormIndex,
    totalEditedBeatCount,
    totalFormsCount,
  };
}
export function getFormListFailed(error) {
  return { type: GET_FORM_LIST_FAILED, error };
}

export function getFormDetailPoolingDataRequested(param) {
  return { type: GET_FORM_DETAIL_POOLING_DATA_REQUESTED, param };
}
export function getFormDetailPoolingDataSucceed({
  mergeFormAndEventDetailInfo,
}) {
  return {
    type: GET_FORM_DETAIL_POOLING_DATA_SUCCEED,
    mergeFormAndEventDetailInfo,
  };
}
export function getFormDetailPoolingDataFailed(error) {
  return { type: GET_FORM_DETAIL_POOLING_DATA_FAILED, error };
}

export function getRawAndEventRequested({
  calledType,
  sliceInfoOfPooling,
} = {}) {
  return {
    type: GET_RAW_AND_EVENT_REQUESTED,
    calledType,
    sliceInfoOfPooling,
  };
}
function getRawAndEventSucceed() {
  return {
    type: GET_RAW_AND_EVENT_SUCCEED,
  };
}
function getRawAndEventFailed(error) {
  return { type: GET_RAW_AND_EVENT_FAILED, error };
}
export function getPoolingDataOfEventOfFormRequested({
  calledType,
  sliceInfoOfPooling,
  formId,
} = {}) {
  return {
    type: GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED,
    calledType,
    sliceInfoOfPooling,
    formId,
  };
}
function getPoolingDataOfEventOfFormSucceed({ formId, poolingDataMap }) {
  return {
    type: GET_POOLING_DATA_OF_EVENT_OF_FORM_SUCCEED,
    formId,
    poolingDataMap,
  };
}
function getPoolingDataOfEventOfFormFailed(error) {
  return { type: GET_POOLING_DATA_OF_EVENT_OF_FORM_FAILED, error };
}
function setProgressPercentagePoolingEventList(progressPercentage) {
  return {
    type: SET_PROGRESS_PERCENTAGE_POOLING_EVENT_LIST,
    progressPercentage,
  };
}
export function setPausePoolingEventList(state) {
  return {
    type: SET_PAUSE_POOLING_EVENT_LIST,
    state,
  };
}
function setFinishPoolingEventList() {
  return {
    type: SET_FINISH_POOLING_EVENT_LIST,
  };
}
export function debounceGetWaveformIndexListOfFormRequested({
  formId = {},
} = {}) {
  return {
    type: DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    formId,
  };
}
export function getWaveformIndexListOfFormRequested({ formId } = {}) {
  return {
    type: GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    formId,
  };
}
function getWaveformIndexListOfFormSucceed({
  onsetWaveformIndexesOfForm,
  terminationWaveformIndexesOfForm,
  totalBeatCount,
}) {
  return {
    type: GET_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED,
    onsetWaveformIndexesOfForm,
    terminationWaveformIndexesOfForm,
    totalBeatCount,
  };
}
function getWaveformIndexListOfFormFailed(error) {
  return { type: GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED, error };
}

export function getOrderedWaveformIndexListOfFormRequested({
  formId = {},
  ordering,
} = {}) {
  return {
    type: GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    formId,
    ordering,
  };
}
function getOrderedWaveformIndexListOfFormSucceed({
  onsetWaveformIndexesOfForm,
  terminationWaveformIndexesOfForm,
  totalBeatCount,
  ordering,
}) {
  return {
    type: GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_SUCCEED,
    onsetWaveformIndexesOfForm,
    terminationWaveformIndexesOfForm,
    totalBeatCount,
    ordering,
  };
}
function getOrderedWaveformIndexListOfFormFailed(error) {
  return { type: GET_WAVEFORM_INDEX_LIST_OF_FORM_FAILED, error };
}

// patch beat update by formId
export function patchBeatsByFormIdRequested(reqBody) {
  return {
    type: PATCH_BEATS_BY_FORM_ID_REQUESTED,
    reqBody,
  };
}
function patchBeatsByFormIdSucceed({
  reqBody,
  filterSelectedItemList,
  succeedWaveformIndexList,
}) {
  return {
    type: PATCH_BEATS_BY_FORM_ID_SUCCEED,
    reqBody,
    filterSelectedItemList,
    succeedWaveformIndexList,
  };
}
function patchBeatsByFormIdFailed(error) {
  return {
    type: PATCH_BEATS_BY_FORM_ID_FAILED,
    error,
  };
}
// add 10s strip beat
export function postBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: POST_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function postBeatsSucceed(
  data,
  responseValidationResult,
  requestPostTensecStripDetail,
  requestSelectedInfoOfEventPanel,
  reqSelectedClusterEventDetail
) {
  return {
    type: POST_BEATS_SUCCEED,
    data,
    responseValidationResult,
    requestPostTensecStripDetail,
    requestSelectedInfoOfEventPanel,
    reqSelectedClusterEventDetail,
  };
}
function postBeatsFailed(error) {
  return {
    type: POST_BEATS_FAILED,
    error,
  };
}
// delete 10s strip beat
export function deleteBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType,
  selectedBeatBtnWaveformIndexList
) {
  return {
    type: DELETE_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
    selectedBeatBtnWaveformIndexList,
  };
}
function deleteBeatsSucceed({
  reqSelectedClusterEventDetail,
  reqBody,
  requestDeleteTensecStripDetail,
  requestSelectedInfoOfEventPanel,
}) {
  return {
    type: DELETE_BEATS_SUCCEED,
    reqSelectedClusterEventDetail,
    reqBody,
    requestDeleteTensecStripDetail,
    requestSelectedInfoOfEventPanel,
  };
}
function deleteBeatsFailed(error) {
  return {
    type: DELETE_BEATS_FAILED,
    error,
  };
}
// patch 10s strip beat
export function patchBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: PATCH_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function patchBeatsSucceed(
  data,
  tabType,
  responseValidationResult,
  requestSelectedInfoOfEventPanel,
  requestPatchTensecStripDetail,
  reqSelectedClusterEventDetail
) {
  return {
    type: PATCH_BEATS_SUCCEED,
    data,
    tabType,
    responseValidationResult,
    requestSelectedInfoOfEventPanel,
    requestPatchTensecStripDetail,
    reqSelectedClusterEventDetail,
  };
}
function patchBeatsFailed(error) {
  return {
    type: PATCH_BEATS_FAILED,
    error,
  };
}
// patch beat update by waveformIndex
export function patchBeatsByWaveformIndexRequested(reqBody) {
  return {
    type: PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED,
    reqBody,
  };
}
export function patchBeatsByWaveformIndexSucceed({
  poolingDataOfSelectedForm,
  isOptimisticEventDataUpdate,
  targetWaveformIndex,
  optimisticUpdatedTargetEvents,
  selectedTenSecStripOriginWaveformIndex,
  resBeatType,
  responseValidationResult,
}) {
  return {
    type: PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED,
    poolingDataOfSelectedForm,
    isOptimisticEventDataUpdate: isOptimisticEventDataUpdate ?? false,
    targetWaveformIndex,
    optimisticUpdatedTargetEvents,
    selectedTenSecStripOriginWaveformIndex,
    resBeatType,
    responseValidationResult,
  };
}
function patchBeatsByWaveformIndexFailed(error) {
  return {
    type: PATCH_BEATS_BY_WAVEFORM_INDEX_FAILED,
    error,
  };
}

// delete beat update by waveformIndex(events)
export function deleteBeatsByWaveformIndexRequested(reqBody) {
  return {
    type: DELETE_BEATS_BY_WAVEFORM_INDEX_REQUESTED,
    reqBody,
  };
}
function deleteBeatsByWaveformIndexSucceed(reqBody) {
  return {
    type: DELETE_BEATS_BY_WAVEFORM_INDEX_SUCCEED,
    reqBody,
  };
}
function deleteBeatsByWaveformIndexFailed(error) {
  return {
    type: DELETE_BEATS_BY_WAVEFORM_INDEX_FAILED,
    error,
  };
}
// delete beat update by waveformIndex(form)
export function deleteBeatsByFormIdRequested(reqBody) {
  return { type: DELETE_BEATS_BY_FORM_ID_REQUESTED, reqBody };
}
function deleteBeatsByFormIdSucceed({
  poolingDataOfAll,
  onsetFormIds,
  terminationFormIds,
  filterSelectedItemList,
  deleteTargetWaveformIndexList,
}) {
  return {
    type: DELETE_BEATS_BY_FORM_ID_SUCCEED,
    poolingDataOfAll,
    onsetFormIds,
    terminationFormIds,
    filterSelectedItemList,
    deleteTargetWaveformIndexList,
  };
}
function deleteBeatsByFormIdFailed(reqBody) {
  return { type: DELETE_BEATS_BY_FORM_ID_FAILED, reqBody };
}

// Caliper
export function setCaliperPlotLines(caliperPlotLines) {
  return { type: SET_CALIPER_PLOT_LINES, caliperPlotLines };
}
export function setIsCaliperMode(isCaliperMode) {
  return { type: SET_IS_CALIPER_MODE, isCaliperMode };
}
export function setIsTickMarksMode(isTickMarksMode) {
  return { type: SET_IS_TICK_MARKS_MODE, isTickMarksMode };
}

// 10s Editing mode
// 10s의 비트를 선택했을때, add beat 중일때 사이드 패널의 단축키 비활성화를 위함
export function setIsTenSecStripEditMode(tenSecStripEditing) {
  return { type: SET_IS_TENSEC_STRIP_EDIT_MODE, tenSecStripEditing };
}

// Arrange 필요 여부 확인
export function getArrangeRequiredStatusRequested(payload) {
  return { type: GET_ARRANGE_REQUIRED_STATUS_REQUESTED, payload };
}
function getArrangeRequiredStatusSucceed(payload) {
  return { type: GET_ARRANGE_REQUIRED_STATUS_SUCCEED, payload };
}
function getArrangeRequiredStatusFailed(payload) {
  return { type: GET_ARRANGE_REQUIRED_STATUS_FAILED, payload };
}
export function setIsArrangeRequired(payload) {
  return { type: SET_IS_ARRANGE_REQUIRED, payload };
}

// Arrange: beat(Ectopic), Clustering  재정렬
export function patchBeatPostprocessRequested(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_REQUESTED, payload };
}
function patchBeatPostprocessSucceed(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_SUCCEED, payload };
}
function patchBeatPostprocessFailed(payload) {
  return { type: PATCH_BEAT_POSTPROCESS_FAILED, payload };
}
function setShapeDetailEditPending(newPendingEditState) {
  return { type: SET_SHAPE_DETAIL_PEND_EDIT, newPendingEditState };
}
function setEventDetailEdited() {
  return { type: SET_SHAPE_DETAIL_EDITED };
}

export function getBeatsNEctopicListRequested({
  reqSelectedClusterEventDetail,
  reqSelectedEvent,
  onsetWaveformIdx,
  terminationWaveformIdx,
  requestSelectedInfoOfEventPanel,
}) {
  return {
    type: GET_BEATS_N_ECTOPIC_LIST_REQUESTED,
    reqSelectedClusterEventDetail,
    reqSelectedEvent,
    onsetWaveformIdx,
    terminationWaveformIdx,
    requestSelectedInfoOfEventPanel,
  };
}
function getBeatsNEctopicListSucceed({
  isEqualReqResEvent,
  newBeatsOrigin,
  selectedEventOriginWaveformIndex,
  beatsData,
  newSelectedClusterEventDetail,
  requestSelectedInfoOfEventPanel,
}) {
  return {
    type: GET_BEATS_N_ECTOPIC_LIST_SUCCEED,
    isEqualReqResEvent,
    newBeatsOrigin,
    selectedEventOriginWaveformIndex,
    beatsData,
    newSelectedClusterEventDetail,
    requestSelectedInfoOfEventPanel,
  };
}
function getBeatsNEctopicListFailed(error) {
  return { type: GET_BEATS_N_ECTOPIC_LIST_FAILED, error };
}
// set selected checkbox status
export function setSelectedCheckboxStatus(checkboxStatus) {
  return { type: SET_SELECTED_CHECKBOX_STATUS, checkboxStatus };
}

export function setSelectedPoolingData(selectedPoolingData) {
  return { type: SET_SELECTED_POOLING_DATA, selectedPoolingData };
}

// set events ordering type
export function setOrderSettingType(orderingTypeSetting) {
  return { type: SET_ORDER_SETTING_TYPE, orderingTypeSetting };
}
// order context menu show & hide state
export function setOrderingContextmenuRequest(isOpenOrderingContextmenu) {
  return { type: SET_ORDERING_CONTEXTMENU, isOpenOrderingContextmenu };
}

// Saga Functions
function* _setPage(action) {
  try {
    /**
     *
     * step1. setting form data(be displayed form panel)
     *
     * step2. pooling
     * step2.1 validate pooling
     * step2.2 request pooling
     */
    const { setPageType } = action;
    const formListInfoOfSelectedEvent = yield select(
      selectFormListInfoOfSelectedEvent
    );
    const formsThumbNailWaveFormIndexOfSelectedEvent =
      formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex;
    const formPoolingDataOfSelectedEvent = yield select(
      selectFormPoolingDataOfSelectedEvent
    );
    const waveformIndexListInfoOfSelectedForm = yield select(
      selectWaveformIndexListInfoOfSelectedForm
    );
    const onsetWaveformIndexListOfForm =
      waveformIndexListInfoOfSelectedForm?.onsetWaveformIndexListOfForm;
    const eventPoolingDataOfSelectedForm = yield select(
      selectEventPoolingDataOfSelectedForm
    );
    //
    const { pageSize: formPanelSize } = yield select(selectFormPanelSizeInfo);
    const { pageSize: eventPanelSize } = yield select(selectEventPanelSizeInfo);
    const { currentPage: formPanelCurrentPage } = yield select(
      selectFormPanelPaginationInfo
    );
    const eventPanelPaginationInfo = yield select(
      selectEventPanelPaginationInfo
    );
    const eventPanelCurrentPage = eventPanelPaginationInfo?.currentPage;

    // step1. setting form data(be displayed form panel)
    let willBeDisplayedFormDataList;
    let panelCurrentPage, panelSize;
    let waveformIndexOfSelectedTarget,
      sliceInfoOfBeDisplayedFormData,
      poolingDataOfSelectedTarget;
    if (action.panelType === ShapeReviewSectionArea.FORMS) {
      panelCurrentPage = formPanelCurrentPage;
      panelSize = formPanelSize;
      waveformIndexOfSelectedTarget =
        formsThumbNailWaveFormIndexOfSelectedEvent;
      poolingDataOfSelectedTarget = formPoolingDataOfSelectedEvent;
    } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
      panelCurrentPage = eventPanelCurrentPage;
      panelSize = eventPanelSize;
      waveformIndexOfSelectedTarget = onsetWaveformIndexListOfForm;
      poolingDataOfSelectedTarget = eventPoolingDataOfSelectedForm;
    }

    sliceInfoOfBeDisplayedFormData = {
      begin: (panelCurrentPage - 1) * panelSize,
      end: panelCurrentPage * panelSize,
    };

    willBeDisplayedFormDataList = getWillBeDisplayedFormDataList({
      waveformIndexOfSelectedTarget,
      sliceInfoOfBeDisplayedFormData,
      poolingDataOfSelectedTarget,
    });

    if (action.panelType === ShapeReviewSectionArea.FORMS) {
      yield put(setFormData(willBeDisplayedFormDataList));
    } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
      yield put(setEventData(willBeDisplayedFormDataList));
    }

    // step2. pooling
    const basisMultipleFetching =
      shapeReviewFetchingOption[ShapeReviewSectionArea.FORMS]
        .BASIS_MULTIPLE_FETCHING;
    let calledType;
    if (setPageType === POSITION_MOVE_TYPE.JUMP) {
      calledType = rawAndEventCalledCase.POSITION_JUMP;
    } else {
      calledType = rawAndEventCalledCase.DATA_POLLING;
    }

    // step2.1 validate pooling
    // step2.2 request pooling
    const beginIndexOfCurrentPage = sliceInfoOfBeDisplayedFormData.begin;
    const endIndexOfCurrentPage = sliceInfoOfBeDisplayedFormData.end;
    let cursorLimit, indexOfNextPooling, sliceInfoOfPooling;
    if (setPageType === POSITION_MOVE_TYPE.PREV) {
      cursorLimit = (formPanelCurrentPage - basisMultipleFetching) * panelSize;
      indexOfNextPooling = getIndexOfNextPoolingInPrevCase(
        beginIndexOfCurrentPage,
        waveformIndexOfSelectedTarget,
        poolingDataOfSelectedTarget,
        cursorLimit
      );

      if (!indexOfNextPooling) {
        return;
      }

      sliceInfoOfPooling = {
        begin: indexOfNextPooling - panelSize * basisMultipleFetching + 1,
        end: indexOfNextPooling + 1,
      };
    } else if (
      setPageType === POSITION_MOVE_TYPE.NEXT ||
      setPageType === POSITION_MOVE_TYPE.JUMP
    ) {
      cursorLimit = (formPanelCurrentPage + basisMultipleFetching) * panelSize;
      indexOfNextPooling = getIndexOfNextPooling(
        beginIndexOfCurrentPage,
        waveformIndexOfSelectedTarget,
        poolingDataOfSelectedTarget,
        cursorLimit
      );

      if (!indexOfNextPooling) {
        return;
      }

      sliceInfoOfPooling = {
        begin: indexOfNextPooling,
        end: indexOfNextPooling + panelSize * basisMultipleFetching,
      };
    }

    if (action.panelType === ShapeReviewSectionArea.FORMS) {
      yield put(getRawAndEventRequested({ calledType, sliceInfoOfPooling }));
    } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
      const firstSelectedInfoOfFormPanel = yield select(
        selectFirstSelectedInfoOfFormPanel
      );
      const formIdOfSelectedItem = firstSelectedInfoOfFormPanel?.formInfo.id;

      yield put(
        getPoolingDataOfEventOfFormRequested({
          calledType,
          sliceInfoOfPooling,
          formId: formIdOfSelectedItem,
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* _getClusteringStatistics(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);

    const {
      data: { result },
    } = yield call(ApiManager.getClusteringStatistics, {
      ecgTestId,
    });

    yield put(getClusteringStatisticsSucceed(result));
  } catch (error) {
    console.error(error);
    yield put(getClusteringStatisticsFailed(error));
  }
}

function* _getFormListRequested(action) {
  try {
    const { formBeatType, formEctopicType } = action;
    const ecgTestId = yield select(selectEcgTestId);
    const selectedEventType = yield select(selectSelectedEventType);
    const formListInfoOfSelectedEvent = yield select(
      selectFormListInfoOfSelectedEvent
    );
    const { pageSize: formPanelSize } = yield select(selectFormPanelSizeInfo);

    // 이미 forms 데이터가 있는 경우
    if (formListInfoOfSelectedEvent.forms.length !== 0) {
      const formPoolingDataOfSelectedEvent = yield select(
        selectFormPoolingDataOfSelectedEvent
      );
      const willBeDisplayedFormDataList = getWillBeDisplayedFormDataList({
        waveformIndexOfSelectedTarget:
          formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex,
        sliceInfoOfBeDisplayedFormData: { begin: 0, end: formPanelSize },
        poolingDataOfSelectedTarget: formPoolingDataOfSelectedEvent,
      });

      yield put(setFormData(willBeDisplayedFormDataList));
      return;
    }
    // step1. fetching forms list
    //   ㄴ [call - api] getClusteringForms
    //   ㄴ [put] getFormListSucceed
    //   ㄴ [put] setPagination

    // step2. fetching event list (forms의 thumbnail image 때문에 호출)
    //   ㄴ [put] getRawAndEventRequested

    // step1. fetching forms list
    //   ㄴ [call - api] getClusteringForms
    const {
      data: {
        result: { forms, totalEditedBeatCount, totalFormsCount },
      },
    } = yield call(ApiManager.getClusteringForms, {
      ecgTestId,
      formBeatType,
      formEctopicType,
    });

    const formsThumbNailWaveFormIndex = forms.map(
      (v) => v.representativeWaveformIndex
    );

    //   ㄴ [put] getFormListSucceed
    yield put(
      getFormListSucceed({
        forms,
        formsThumbNailWaveFormIndex,
        totalEditedBeatCount,
        totalFormsCount,
      })
    );

    if (formListInfoOfSelectedEvent.forms.length === 0) {
      const paginationInfo = {
        eventType: selectedEventType,
        panelType: ShapeReviewSectionArea.FORMS,
        totalItemCount: totalFormsCount,
        totalPageSize: Math.ceil(totalFormsCount / formPanelSize),
        currentPage: 1,
      };
      //   ㄴ [put] setPagination
      yield put(setPagination({ ...paginationInfo }));
    }

    // step2. fetching event list (forms의 thumbnail image 때문에 호출)
    //   ㄴ [put] getRawAndEventRequested
    const calledType = rawAndEventCalledCase.CLICK_EVENT;
    const basisMultipleFetching =
      shapeReviewFetchingOption[ShapeReviewSectionArea.FORMS]
        .BASIS_MULTIPLE_FETCHING;
    const sliceInfoOfPooling = {
      begin: 0,
      end: formPanelSize * basisMultipleFetching,
    };
    yield put(getRawAndEventRequested({ calledType, sliceInfoOfPooling }));
  } catch (error) {
    console.error(error);
    yield put(getFormListFailed(error));
  }
}

function* _getRawAndEvent({ calledType, sliceInfoOfPooling }) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const formListInfoOfSelectedEvent = yield select(
      selectFormListInfoOfSelectedEvent
    );
    const panelSizeInfo = yield select(selectPanelSizeInfo);
    const { pageSize: formPanelSize } =
      panelSizeInfo[ShapeReviewSectionArea.FORMS];

    /* 
      # STEP LIST
        step1. fetching
        step2. setting pooling
        step3. post process(호출 방법에 따른 후처리)
    */
    const slicedFormsWaveformIndexes =
      formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex.slice(
        sliceInfoOfPooling.begin,
        sliceInfoOfPooling.end
      );
    const chartSampleSize =
      shapeReviewFetchingOption.fetchingOption.chartSampleSizeOf4s;

    if (slicedFormsWaveformIndexes.length === 0) {
      return;
    }

    // step1. fetching
    /** @type {BeatEventDetailReturn} */
    const {
      data: { results: beatEventDetailList },
    } = yield call(ApiManager.getBeatsFilterWaveformIndexWithSampleSize, {
      ecgTestId,
      waveformIndexes: slicedFormsWaveformIndexes,
      sampleSize: chartSampleSize,
      withRegisteredStrip: false,
      withRaw: true,
    });
    yield put(getRawAndEventSucceed());

    // step2. setting pooling
    const mergeFormAndEventDetailInfo = new Map();
    for (let i = 0; i < beatEventDetailList.length; i++) {
      const beatEventDetail = beatEventDetailList[i];
      const indexOfBeatEventDetailInFromList =
        findIndexOfBeatEventDetailInFromList(
          formListInfoOfSelectedEvent,
          beatEventDetail
        );

      Object.assign(beatEventDetail, {
        formInfo:
          formListInfoOfSelectedEvent.forms[indexOfBeatEventDetailInFromList],
      });

      mergeFormAndEventDetailInfo.set(
        beatEventDetail.originWaveformIndex,
        beatEventDetail
      );
      //terminationWaveformIndex 정보 추가
      mergeFormAndEventDetailInfo.get(
        beatEventDetail.originWaveformIndex
      ).terminationWaveformIndex =
        formListInfoOfSelectedEvent.forms[
          i
        ].representativeTerminationWaveformIndex;
    }
    yield put(getFormDetailPoolingDataSucceed({ mergeFormAndEventDetailInfo }));

    // step3. post process(호출 방법에 따른 후처리)
    if (calledType === rawAndEventCalledCase.CLICK_EVENT) {
      const willBeDisplayedFormDataList = getWillBeDisplayedFormDataList({
        waveformIndexOfSelectedTarget:
          formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex,
        sliceInfoOfBeDisplayedFormData: { begin: 0, end: formPanelSize },
        poolingDataOfSelectedTarget: mergeFormAndEventDetailInfo,
      });

      yield put(setFormData(willBeDisplayedFormDataList));
    } else if (
      calledType === rawAndEventCalledCase.DATA_POLLING ||
      calledType === rawAndEventCalledCase.POSITION_JUMP
    ) {
      const selectedEventFormData = yield select(selectFormDataOfSelectedEvent);
      if (selectedEventFormData.length !== 0) return;

      const selectedEventType = yield select(selectSelectedEventType);
      const paginationInfo = yield select(selectPaginationInfo);
      const formPoolingDataOfSelectedEvent = yield select(
        selectFormPoolingDataOfSelectedEvent
      );
      const formPanelPaginationInfo =
        paginationInfo[ShapeReviewSectionArea.FORMS];
      const { currentPage: formPanelCurrentPage } =
        formPanelPaginationInfo[selectedEventType];

      const willBeDisplayedFormDataList = getWillBeDisplayedFormDataList({
        waveformIndexOfSelectedTarget:
          formListInfoOfSelectedEvent.formsThumbNailWaveFormIndex,
        sliceInfoOfBeDisplayedFormData: {
          begin: (formPanelCurrentPage - 1) * formPanelSize,
          end: formPanelCurrentPage * formPanelSize,
        },
        poolingDataOfSelectedTarget: formPoolingDataOfSelectedEvent,
      });

      yield put(setFormData(willBeDisplayedFormDataList));
    }
  } catch (error) {
    console.error(error);
    yield put(getRawAndEventFailed(error));
  }
}

function* _getWaveformIndexListOfForm({ formId }) {
  try {
    const selectedEventType = yield select(selectSelectedEventType);
    const waveformIndexListInfoOfSelectedForm = yield select(
      selectWaveformIndexListInfoOfSelectedForm
    );

    const { pageSize: formPanelSize } = yield select(selectEventPanelSizeInfo);

    // // 선택한 Form에 이벤트 존재와 관계 없이 정렬 타입은 초기화 or 기존에 설정했던 정렬 타입을 보여줘야한다.
    const clusterFormDetailList = yield select(selectFormDataOfSelectedEvent);
    const firstSelectedInfoOfFormPanel = yield select(
      selectFirstSelectedInfoOfFormPanel
    );
    const selectedClusterFormDetail =
      clusterFormDetailList[firstSelectedInfoOfFormPanel?.index];

    if (selectedClusterFormDetail) {
      if (selectedClusterFormDetail.currentOrderType === undefined) {
        const defaultSortOption = {
          valueText: '',
          optionText: '',
          tooltipTitle: '',
          queryOrderBy: '',
          ascending: true,
          isDefault: true,
        };
        selectedClusterFormDetail.currentOrderType = defaultSortOption;
      }
    }
    const orderingTypeSetting = getSortOptionByOrdering(
      selectedClusterFormDetail?.currentOrderType
    );

    yield put(setOrderSettingType(orderingTypeSetting));

    if (!waveformIndexListInfoOfSelectedForm) {
      const {
        data: {
          result: {
            onsetWaveformIndexes,
            totalBeatCount,
            terminationWaveformIndexes,
          },
        },
      } = yield call(ApiManager.getWaveformIndexesOfForm, {
        formId,
      });

      if (onsetWaveformIndexes.length === 0) {
        return;
      }

      yield put(
        getWaveformIndexListOfFormSucceed({
          onsetWaveformIndexesOfForm: onsetWaveformIndexes,
          terminationWaveformIndexesOfForm: terminationWaveformIndexes,
          totalBeatCount,
        })
      );

      const paginationInfo = {
        eventType: selectedEventType,
        formId,
        panelType: ShapeReviewSectionArea.EVENTS,
        totalItemCount: totalBeatCount,
        totalPageSize: Math.ceil(totalBeatCount / formPanelSize),
        currentPage: 1,
      };
      yield put(setPagination({ ...paginationInfo }));
    }

    const eventPoolingDataOfSelectedForm = yield select(
      selectEventPoolingDataOfSelectedForm
    );
    const hasAllPoolingData = [...eventPoolingDataOfSelectedForm].every(
      (v) => v[1] !== undefined
    );

    const panelSizeInfo = yield select(selectPanelSizeInfo);
    const { pageSize: eventPanelSize } =
      panelSizeInfo[ShapeReviewSectionArea.EVENTS];
    if (hasAllPoolingData) {
      const originWaveformIndexListOfSelectedForm =
        waveformIndexListInfoOfSelectedForm.onsetWaveformIndexListOfForm;

      const eventPanelPaginationInfo = yield select(
        selectEventPanelPaginationInfo
      );
      const eventPanelCurrentPage = eventPanelPaginationInfo?.currentPage;
      const panelCurrentPage = eventPanelCurrentPage;
      const panelSize = eventPanelSize;
      const sliceInfoOfBeDisplayedFormData = {
        begin: (panelCurrentPage - 1) * panelSize,
        end: panelCurrentPage * panelSize,
      };

      const willBeDisplayedFormDataList = getWillBeDisplayedFormDataList({
        waveformIndexOfSelectedTarget: originWaveformIndexListOfSelectedForm,
        // sliceInfoOfBeDisplayedFormData: { begin: 0, end: eventPanelSize },
        sliceInfoOfBeDisplayedFormData,
        poolingDataOfSelectedTarget: eventPoolingDataOfSelectedForm,
      });
      yield put(setSelectedPoolingData(eventPoolingDataOfSelectedForm));
      yield put(setEventData(willBeDisplayedFormDataList));
    } else {
      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );
      const undefinedBeginIndexOfPoolingData = [
        ...eventPoolingDataOfSelectedForm,
      ].findIndex((v) => v[1] === undefined);

      const calledType = rawAndEventCalledCase.CLICK_EVENT;
      const basisMultipleFetching =
        shapeReviewFetchingOption[ShapeReviewSectionArea.EVENTS]
          .BASIS_MULTIPLE_FETCHING;
      const sliceInfoOfPooling = {
        begin: 0 + undefinedBeginIndexOfPoolingData,
        end:
          eventPanelSize * basisMultipleFetching +
          undefinedBeginIndexOfPoolingData,
      };

      yield put(
        getPoolingDataOfEventOfFormRequested({
          calledType,
          sliceInfoOfPooling,
          formId,
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(getWaveformIndexListOfFormFailed(error));
  }
}
function* _getOrderedWaveformIndexListOfForm({ formId, ordering = '' }) {
  try {
    const selectedEventType = yield select(selectSelectedEventType);
    const { pageSize: formPanelSize } = yield select(selectEventPanelSizeInfo);
    const selectedEventTypeFormList = yield select(
      selectFormListInfoOfSelectedEvent
    );

    const countOfEventInSelectedForm =
      selectedEventTypeFormList.forms.find((item) => item.id === formId)
        .beatCount ?? 0;

    if (countOfEventInSelectedForm === 0) {
      return;
    }
    const {
      data: {
        result: {
          onsetWaveformIndexes,
          totalBeatCount,
          terminationWaveformIndexes,
        },
      },
    } = yield call(ApiManager.getWaveformIndexesOfForm, {
      formId,
      ordering,
    });

    yield put(
      getOrderedWaveformIndexListOfFormSucceed({
        onsetWaveformIndexesOfForm: onsetWaveformIndexes,
        terminationWaveformIndexesOfForm: terminationWaveformIndexes,
        totalBeatCount,
        ordering,
      })
    );

    const paginationInfo = {
      eventType: selectedEventType,
      formId,
      panelType: ShapeReviewSectionArea.EVENTS,
      totalItemCount: totalBeatCount,
      totalPageSize: Math.ceil(totalBeatCount / formPanelSize),
      currentPage: 1,
    };
    yield put(setPagination({ ...paginationInfo }));

    const panelSizeInfo = yield select(selectPanelSizeInfo);
    const { pageSize: eventPanelSize } =
      panelSizeInfo[ShapeReviewSectionArea.EVENTS];

    const eventPoolingDataOfSelectedForm = yield select(
      selectEventPoolingDataOfSelectedForm
    );
    const undefinedBeginIndexOfPoolingData = [
      ...eventPoolingDataOfSelectedForm,
    ].findIndex((v) => v[1] === undefined);

    const calledType = rawAndEventCalledCase.CLICK_EVENT;
    const basisMultipleFetching =
      shapeReviewFetchingOption[ShapeReviewSectionArea.EVENTS]
        .BASIS_MULTIPLE_FETCHING;
    const sliceInfoOfPooling = {
      begin: 0 + undefinedBeginIndexOfPoolingData,
      end:
        eventPanelSize * basisMultipleFetching +
        undefinedBeginIndexOfPoolingData,
    };

    yield put(
      getPoolingDataOfEventOfFormRequested({
        calledType,
        sliceInfoOfPooling,
        formId,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(getOrderedWaveformIndexListOfFormFailed(error));
  }
}

function* _getPoolingDataOfEventOfForm({
  calledType,
  sliceInfoOfPooling,
  formId,
}) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const waveformIndexListInfoOfSelectedForm = yield select(
      selectWaveformIndexListInfoOfSelectedForm
    );
    const originWaveformIndexListOfSelectedForm =
      waveformIndexListInfoOfSelectedForm.onsetWaveformIndexListOfForm;
    const waveformIndexListOfSelectedForm = [
      ...waveformIndexListInfoOfSelectedForm.onsetWaveformIndexListOfForm,
    ];

    const terminationWaveformIndexListOfSelectedForm =
      waveformIndexListInfoOfSelectedForm.terminationWaveformIndexListOfForm;

    const panelSizeInfo = yield select(selectPanelSizeInfo);
    const { pageSize: eventPanelSize } =
      panelSizeInfo[ShapeReviewSectionArea.EVENTS];

    /* 
      # STEP LIST
        step1. fetching
        step2. setting pooling
        step3. post process(호출 방법에 따른 후처리)
    */
    let poolingDataMap = new Map();
    const slicedFormsWaveformIndexes = waveformIndexListOfSelectedForm.slice(
      sliceInfoOfPooling.begin
    );
    const chartSampleSize = 2500; // 10초차트 에서 사용하기 위해 2500개 호출

    if (slicedFormsWaveformIndexes.length === 0) {
      return;
    }

    // raw가 redux에 세팅 된 여부에 따라서 raw를 api에서 받을지 decoding해서 세팅할지 결정하는 flag 변수 입니다.
    const rawBlob = yield select(selectRawBlob);
    const isRawFileDataAvailable = rawBlob !== undefined;
    const fetchingSize = shapeReviewFetchingOption.fetchingOption.fetchingSize;
    const withRaw = !isRawFileDataAvailable || window.devMode.checkRawData;

    // step1. fetching
    let beatEventDetailList = [];
    let apiRequestCount = 0;
    while (slicedFormsWaveformIndexes.length > 0) {
      const poolingDataOfEventPanelState = yield select(
        selectPoolingDataOfEventPanelState
      );
      if (poolingDataOfEventPanelState) {
        break;
      }

      // setting fetching target waveform index list
      let j = 0;
      let fetchingTargetWfiList = [];
      while (j < fetchingSize) {
        j++;
        if (slicedFormsWaveformIndexes.length !== 0) {
          fetchingTargetWfiList.push(slicedFormsWaveformIndexes.shift());
        } else {
          break;
        }
      }

      /** @type {BeatEventDetailReturn} */
      const axiosSource = axiosSourceManager.createCancelToken(
        axiosSourceManager.target.shapeReviewFetchingEvent
      );

      const result = yield call(
        ApiManager.getBeatsFilterWaveformIndexWithSampleSize,
        {
          ecgTestId,
          waveformIndexes: fetchingTargetWfiList,
          sampleSize: chartSampleSize,
          withRegisteredStrip: false,
          withRaw,
          axiosSource,
        }
      );

      let decodingRawDataList = [];
      if (isRawFileDataAvailable || window.devMode.checkRawData) {
        decodingRawDataList = yield call(
          shapeReviewPreSignedUrl.fetchMultipleAPIs.bind(
            shapeReviewPreSignedUrl
          ),
          {
            rawBlob,
            chartSampleSize,
            fetchingTargetWfiList,
          }
        );
      }

      // for test: raw data 비교 (raw from decoding vs raw from api)
      if (window.devMode.checkRawData) {
        compareECG(result.data.results, decodingRawDataList);
      }

      const currentBatchStartIndex = apiRequestCount * fetchingSize;
      const partOfBeatEventDetailList = result?.data?.results.map(
        (event, idx) => {
          event.terminationWaveformIndex =
            terminationWaveformIndexListOfSelectedForm[
              idx + currentBatchStartIndex
            ];

          // initialPaddingLength이 0보다 큰 경우
          //  : originWaveformIndex이 ecg 시작 이후 HALF_TEN_SEC_WAVEFORM_IDX(1250)보다 작은 경우
          //  : 이 경우 originWaveformIndex가 10s strip에 가운데로 오기 위해서 앞쪽에 initialPaddingLength 만큼의 배열요소가 필요하다.
          const initialPaddingLength =
            ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX -
            event.originWaveformIndex;

          const isPaddingLengthGreaterThanZero = initialPaddingLength > 0;

          if (!isPaddingLengthGreaterThanZero) {
            if (isRawFileDataAvailable) {
              event.mainECG = decodingRawDataList[idx];
            }
            return event;
          }

          const rawECG = isRawFileDataAvailable
            ? decodingRawDataList[idx].rawECG
            : event.mainECG.rawECG;
          const startWfiOfRawEcg = isPaddingLengthGreaterThanZero
            ? 0
            : ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;
          const ecgRaw = [
            ...Array.from({ length: initialPaddingLength }, () => 0),
          ].concat(rawECG.slice(startWfiOfRawEcg));

          return {
            ...event,
            mainECG: {
              ...event.mainECG,
              // 음수 값으로 세팅되는 케이스
              onsetWaveformIndex:
                event.originWaveformIndex -
                ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX,
              terminationWaveformIndex:
                event.originWaveformIndex -
                ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX +
                2500,
              rawECG: isRawFileDataAvailable
                ? decodingRawDataList[idx].rawECG
                : ecgRaw,
            },
          };
        }
      );
      apiRequestCount++;

      const isPausedPoolingCase = !partOfBeatEventDetailList;
      if (isPausedPoolingCase) return;

      beatEventDetailList.push(...partOfBeatEventDetailList);

      // step2. setting pooling
      for (let i = 0; i < beatEventDetailList.length; i++) {
        const beatEventDetail = beatEventDetailList[i];
        beatEventDetail.totalIndex =
          originWaveformIndexListOfSelectedForm.indexOf(
            beatEventDetail.originWaveformIndex
          );
        poolingDataMap.set(
          beatEventDetail.originWaveformIndex,
          beatEventDetail
        );
      }
      yield put(getPoolingDataOfEventOfFormSucceed({ formId, poolingDataMap }));

      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );
      const hasPoolingDataMap = [...eventPoolingDataOfSelectedForm].filter(
        (v) => v[1] !== undefined
      );

      yield put(
        setProgressPercentagePoolingEventList(
          Math.round(
            ([...hasPoolingDataMap].length /
              originWaveformIndexListOfSelectedForm.length) *
              100
          )
        )
      );
    }

    yield put(setFinishPoolingEventList());
    // step3. post process(호출 방법에 따른 후처리)
    if (
      calledType === rawAndEventCalledCase.CLICK_EVENT ||
      calledType === rawAndEventCalledCase.KEY_EVENT
    ) {
      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );

      const willBeDisplayedFormDataList = getWillBeDisplayedFormDataList({
        waveformIndexOfSelectedTarget: originWaveformIndexListOfSelectedForm,
        sliceInfoOfBeDisplayedFormData: { begin: 0, end: eventPanelSize },
        poolingDataOfSelectedTarget: eventPoolingDataOfSelectedForm,
      });
      yield put(setSelectedPoolingData(eventPoolingDataOfSelectedForm));
      yield put(setEventData(willBeDisplayedFormDataList));
    } else if (
      calledType === rawAndEventCalledCase.DATA_POLLING ||
      calledType === rawAndEventCalledCase.POSITION_JUMP
    ) {
      const { currentPage: eventPanelCurrentPage } = yield select(
        selectEventPanelPaginationInfo
      );
      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );

      const willBeDisplayedFormDataList = getWillBeDisplayedFormDataList({
        waveformIndexOfSelectedTarget: originWaveformIndexListOfSelectedForm,
        sliceInfoOfBeDisplayedFormData: {
          begin: (eventPanelCurrentPage - 1) * eventPanelSize,
          end: eventPanelCurrentPage * eventPanelSize,
        },
        poolingDataOfSelectedTarget: eventPoolingDataOfSelectedForm,
      });
      yield put(setEventData(willBeDisplayedFormDataList));
    }
  } catch (error) {
    console.error(error);
    yield put(getPoolingDataOfEventOfFormFailed(error));
  }
}

function* _setPausePoolingEventList(action) {
  try {
    if (action.state === true) {
      axiosSourceManager.cancelApiRequest(
        axiosSourceManager.target.shapeReviewFetchingEvent
      );
    } else if (action.state === false) {
      const eventPoolingDataOfSelectedForm = yield select(
        selectEventPoolingDataOfSelectedForm
      );
      const undefinedBeginIndexOfPoolingData = [
        ...eventPoolingDataOfSelectedForm,
      ].findIndex((v) => v[1] === undefined);

      const calledType = rawAndEventCalledCase.CLICK_EVENT;
      const panelSizeInfo = yield select(selectPanelSizeInfo);
      const { pageSize: eventPanelSize } =
        panelSizeInfo[ShapeReviewSectionArea.EVENTS];
      const basisMultipleFetching =
        shapeReviewFetchingOption[ShapeReviewSectionArea.EVENTS]
          .BASIS_MULTIPLE_FETCHING;
      const sliceInfoOfPooling = {
        begin: 0 + undefinedBeginIndexOfPoolingData,
        end:
          eventPanelSize * basisMultipleFetching +
          undefinedBeginIndexOfPoolingData,
      };
      const formPanelDetailOfFirstIndexOfLastSelectedSection = yield select(
        selectFormPanelDetailOfFirstIndexOfLastSelectedSection
      );

      const firstIndexOfLastSelectedSectionInfoFormId =
        formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id;
      yield put(
        getPoolingDataOfEventOfFormRequested({
          calledType,
          sliceInfoOfPooling,
          formId: firstIndexOfLastSelectedSectionInfoFormId,
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* _patchBeatsByFormIds(action) {
  try {
    const reqBeatType = action.reqBody.beatType;
    const ecgTestId = yield select(selectEcgTestId);
    const filterSelectedItemListOfFormPanel = yield select(
      selectFilterSelectedItemListOfFormPanel
    );

    const firstSelectedInfoOfEventPanel = yield select(
      selectFirstSelectedInfoOfEventPanel
    );
    const secondSelectedInfoOfEventPanel = yield select(
      selectSecondSelectedInfoOfEventPanel
    );
    const requestPatchBeatsByFormTensecStripDetail = yield select(
      selectTenSecStripDetail
    );
    const reqSelectedClusterEventDetail = yield select(
      selectEventDataOfSelectedForm
    );

    const requestSelectedInfoOfEventPanel =
      secondSelectedInfoOfEventPanel ?? firstSelectedInfoOfEventPanel;

    const reqSelectedEvent =
      reqSelectedClusterEventDetail[requestSelectedInfoOfEventPanel?.index];

    const reqBody = {
      beatType: reqBeatType,
      onsetFormIds: filterSelectedItemListOfFormPanel.onset.map(
        (v) => v.formInfo.id
      ),
      terminationFormIds: filterSelectedItemListOfFormPanel.termination.map(
        (v) => v.formInfo.id
      ),
    };

    const {
      data: {
        result: { beatType: resBeatType, waveformIndex: resWaveformIndex },
      },
      status,
    } = yield call(ApiManager.updateBeatsByFormIds, ecgTestId, reqBody);
    if (status !== 200) throw new Error('http response status is not 200');

    const filterSelectedItemList =
      filterSelectedItemListOfFormPanel.filterSelectedItemList;

    const failedBeatTypeIndexList = resBeatType.reduce((acc, beatType, idx) => {
      if (beatType === reqBeatType) {
        return acc;
      }

      acc.push(idx);
      return acc;
    }, []);

    const succeedWaveformIndexList = resWaveformIndex.filter(
      (_, idx) => !failedBeatTypeIndexList.includes(idx)
    );
    yield put(
      patchBeatsByFormIdSucceed({
        reqBody,
        filterSelectedItemList,
        succeedWaveformIndexList,
      })
    );
    if (
      requestPatchBeatsByFormTensecStripDetail.onsetWaveformIdx &&
      requestPatchBeatsByFormTensecStripDetail.terminationWaveformIdx
    ) {
      yield put(
        getBeatsNEctopicListRequested({
          reqSelectedClusterEventDetail,
          reqSelectedEvent,
          onsetWaveformIdx:
            requestPatchBeatsByFormTensecStripDetail.onsetWaveformIdx,
          terminationWaveformIdx:
            requestPatchBeatsByFormTensecStripDetail.terminationWaveformIdx,
          requestSelectedInfoOfEventPanel,
        })
      );
    }
    yield put(setIsArrangeRequired({ isArrangeRequired: true }));
  } catch (error) {
    console.error(error);
    yield put(patchBeatsByFormIdFailed(error));
  }
}
function* _patchBeatsByWaveformIndexes(action) {
  try {
    const { reqBody } = action;
    if (reqBody.tenSecStripDetailEditPending) {
      return;
    }
    const ecgTestId = yield select(selectEcgTestId);
    const filterSelectedItemListOfEventPanel = yield select(
      selectFilterSelectedItemListOfEventPanel
    );

    const requestPatchBeatsByEventTensecStripDetail = yield select(
      selectTenSecStripDetail
    );
    const poolingDataOfSelectedForm = yield select(
      selectPoolingDataOfSelectedForm
    );

    // originWaveformIndex || terminationWaveformIndex를 key로 originWaveformIndex를 value로 하는 업데이트를 시킬 이벤트의 정보
    const targetWaveformIndex = {};
    let reqWaveformIndexList = [];
    for (let onset of filterSelectedItemListOfEventPanel.onset) {
      const waveformIndexes = onset.beats.waveformIndex;
      const originWaveformIndex = onset.originWaveformIndex;
      const originWaveformIndexIndex =
        waveformIndexes[waveformIndexes.indexOf(originWaveformIndex)];
      if (!originWaveformIndexIndex) {
        continue;
      }

      // targetWaveformIndex : 실제 업데이트를 하려고 하는 target
      // originWaveformIndex를 기준으로 편집한다.(poolingData가 Map 형태로 originWaveformIndex가 key입니다.)
      // 첫번째 비트 편집 시 : originWaveformIndex를 key로 value는 originWaveformIndex를 담는다.
      // 두번째 비트 편집시 : terminationWaveformIndex를 key로 value는 originWaveformIndex를 담는다.
      targetWaveformIndex[originWaveformIndex] = originWaveformIndex;

      reqWaveformIndexList.push(originWaveformIndexIndex);
    }

    for (let termination of filterSelectedItemListOfEventPanel.termination) {
      const waveformIndexes = termination.beats.waveformIndex;
      const terminationIndex = termination.terminationWaveformIndex;
      const originWaveformIndex = termination.originWaveformIndex;
      const terminationWaveformIndex =
        waveformIndexes[waveformIndexes.indexOf(terminationIndex)];
      if (!terminationWaveformIndex) {
        continue;
      }
      targetWaveformIndex[terminationIndex] = originWaveformIndex;

      reqWaveformIndexList.push(terminationWaveformIndex);
    }
    action.reqBody.waveformIndexes = reqWaveformIndexList.sort((a, b) => a - b);
    if (action.reqBody.waveformIndexes.length === 0) {
      return;
    }

    // Optimistic update Class
    const patchBeatByRangeListCommand =
      new ShapeReviewPatchBeatByWaveformIndexListCommand({
        updateReqOption: { reqBody },
        targetWaveformIndex,
        filterBeatsNEctopicList:
          filterSelectedItemListOfEventPanel.filterSelectedItemList,
        filterSelectedItemListOfEventPanel,
      });

    // 편집 요청 한 이벤트의 optimistic update 된 정보
    // updatedTargetEvents = { 이벤트의 originWaveformIndex : { beatType:{}, hr:{}, waveformIndex:{} } }
    // optimistic 이후 실패한 originWaveformIndex 리스트
    const {
      updatedTargetEvents: optimisticUpdatedTargetEvents,
      succeedWaveformIndexes,
      failedWaveformIndexes,
    } = yield patchBeatByRangeListCommand.execute();

    // tenSecStrip이 Open 상태 케이스에 사용되는 originWaveformIndex
    const selectedTenSecStripOriginWaveformIndex =
      (requestPatchBeatsByEventTensecStripDetail.onsetWaveformIdx +
        requestPatchBeatsByEventTensecStripDetail.terminationWaveformIdx) /
      2;

    yield put(
      patchBeatsByWaveformIndexSucceed({
        poolingDataOfSelectedForm,
        targetWaveformIndex,
        resBeatType: reqBody.beatType,
        optimisticUpdatedTargetEvents,
        selectedTenSecStripOriginWaveformIndex,
        responseValidationResult: {
          succeedWaveformIndexes,
          failedWaveformIndexes,
        },
      })
    );

    // API 호출
    const requestStatement = {
      requestType: SUFFIX_LIST.EDIT_BEAT_SUFFIX.UPDATE_BEATS_BY_INDEXES, // api
      ecgTestId: ecgTestId,
      reqBody,
    };
    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      yield put(setIsArrangeRequired({ isArrangeRequired: true }));
    }
    function* failedCallback(error) {
      yield put(patchBeatsByWaveformIndexFailed(error));
    }
  } catch (error) {
    console.error(error);
    yield put(patchBeatsByWaveformIndexFailed(error));
  }
}

function* _deleteBeatsByWaveformIndexRequested(action) {
  try {
    const { reqBody } = action;
    if (reqBody.tenSecStripDetailEditPending) {
      return;
    }
    const ecgTestId = yield select(selectEcgTestId);
    const filterSelectedItemListOfEventPanel = yield select(
      selectFilterSelectedItemListOfEventPanel
    );
    const focusedSectionInfo = yield select(selectSelectedInfoOfEventsPanel);

    let reqWaveformIndexList = [];
    // newPatchedList 는 state.patchBeatByWaveformIndexes로 Events에 editedLabel을 붙이기 위함
    // originWaveformIndex의 값이 들어가야지 edited Label이 붙는다.
    // terminationWaveformIndex가 추가되어도 edited Label이 붙지 않는다.
    // ISO는 두개 waveformIndex가 같아서 관계 없지만, COUPLET는 두 정보가 다르기 때문에 termination 비트가 수정이 되더라도 originWaveformIndex 의 정보를 추가해줘야한다.

    const newPatchedList = [];
    const deleteTargetWaveformIndexOfOriginWaveformIndex = [];
    for (let onset of filterSelectedItemListOfEventPanel.onset) {
      const hasOriginWaveformIndex = onset.beats.waveformIndex.includes(
        onset.originWaveformIndex
      );
      if (!hasOriginWaveformIndex) {
        continue;
      }
      reqWaveformIndexList.push(onset.originWaveformIndex);
      newPatchedList.push(onset.originWaveformIndex);
      deleteTargetWaveformIndexOfOriginWaveformIndex.push({
        [onset.originWaveformIndex]: onset.originWaveformIndex,
      });
    }

    for (let termination of filterSelectedItemListOfEventPanel.termination) {
      const hasTerminationWaveformIndex =
        termination.beats.waveformIndex.includes(
          termination.terminationWaveformIndex
        );
      if (!hasTerminationWaveformIndex) {
        continue;
      }
      reqWaveformIndexList.push(termination.terminationWaveformIndex);
      newPatchedList.push(termination.originWaveformIndex);
      deleteTargetWaveformIndexOfOriginWaveformIndex.push({
        [termination.originWaveformIndex]: termination.terminationWaveformIndex,
      });
    }

    action.reqBody.waveformIndexes = reqWaveformIndexList.sort((a, b) => a - b);

    if (action.reqBody.waveformIndexes.length === 0) {
      return;
    }

    const waveformIndexes = action.reqBody.waveformIndexes;

    const formPanelDetailOfFirstIndexOfLastSelectedSection = yield select(
      selectFormPanelDetailOfFirstIndexOfLastSelectedSection
    );
    const formId =
      formPanelDetailOfFirstIndexOfLastSelectedSection?.formInfo.id;

    const requestStatement = {
      requestType: SUFFIX_LIST.EDIT_BEAT_SUFFIX.REMOVE_BEATS, // api
      ecgTestId: ecgTestId,
      reqBody: action.reqBody,
    };

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );
    const poolingDataOfAll = yield select(selectPoolingDataOfAll);
    function* succeedCallback({ status }) {
      yield put(
        deleteBeatsByWaveformIndexSucceed({
          poolingDataOfAll,
          focusedSectionInfo,
          waveformIndexes,
          newPatchedList,
          deleteTargetWaveformIndexOfOriginWaveformIndex,
          filterSelectedItemListOfEventPanel,
          formId,
        })
      );
      yield put(setIsArrangeRequired({ isArrangeRequired: true }));
    }
    function* failedCallback(error) {
      yield put(deleteBeatsByWaveformIndexFailed(error));
    }
  } catch (error) {
    console.error(error);
    yield put(deleteBeatsByWaveformIndexFailed(error));
  }
}

function* _deleteBeatsByFormIdRequested(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { selectedItemList } = yield select(selectSelectedInfoOfFormsPanel);

    // 선택된 form의 onset, termination waveformIndex 정보 ex. 3561 : {onsetWaveformIndexListOfForm:[],terminationWaveformIndexListOfForm:[]}
    const selectWaveformIndexListInfoOfFormList = yield select(
      selectWaveformIndexListInfoOfForm
    );
    // 선택된 eventDetails
    const selectFormDataOfSelectedEventList = yield select(
      selectFormDataOfSelectedEvent
    );
    // 선택된 formDetails
    const filterSelectedItemListOfFormPanel = yield select(
      selectFilterSelectedItemListOfFormPanel
    );
    // 선택된 selectedItem List (value : index, matrix, checkbox)
    const selectedInfoOfFormsPanel = yield select(
      selectSelectedInfoOfFormsPanel
    );

    const onsetFormIds = [];
    const terminationFormIds = [];

    for (const [key, value] of selectedItemList) {
      const formInfoId =
        selectFormDataOfSelectedEventList[value.index].formInfo.id;
      if (value.checkbox[0]) onsetFormIds.push(formInfoId);
      if (value.checkbox[1]) terminationFormIds.push(formInfoId);
    }

    yield call(ApiManager.deleteRemoveBeatsByForms, {
      ecgTestId,
      body: { onsetFormIds, terminationFormIds },
    });

    const filterSelectedItemList =
      filterSelectedItemListOfFormPanel.filterSelectedItemList;

    const updatedPatchBeatByFormIds = [];
    const deleteTargetWaveformIndexList = [];

    for (let filterSelectItem of filterSelectedItemList) {
      const formId = filterSelectItem.formInfo.id;
      const filterSelectItemCheckbox =
        selectedInfoOfFormsPanel.selectedItemList.get(
          filterSelectItem.index
        ).checkbox;
      updatedPatchBeatByFormIds.push(formId);

      // 삭제된 이벤트 비트가 삭제된 이후 Events read
      if (!selectWaveformIndexListInfoOfFormList[formId]) {
        const {
          data: {
            result: { onsetWaveformIndexes, terminationWaveformIndexes },
          },
        } = yield call(ApiManager.getWaveformIndexesOfForm, { formId });

        if (filterSelectItemCheckbox[0]) {
          deleteTargetWaveformIndexList.push(...onsetWaveformIndexes);
        }
        if (filterSelectItemCheckbox[1]) {
          deleteTargetWaveformIndexList.push(...terminationWaveformIndexes);
        }
      } else {
        if (filterSelectItemCheckbox[0]) {
          deleteTargetWaveformIndexList.push(
            ...selectWaveformIndexListInfoOfFormList[formId]
              .onsetWaveformIndexListOfForm
          );
        }
        if (filterSelectItemCheckbox[1]) {
          deleteTargetWaveformIndexList.push(
            ...selectWaveformIndexListInfoOfFormList[formId]
              .terminationWaveformIndexListOfForm
          );
        }
      }
    }
    const poolingDataOfAll = yield select(selectPoolingDataOfAll);

    yield put(
      deleteBeatsByFormIdSucceed({
        poolingDataOfAll,
        onsetFormIds,
        terminationFormIds,
        filterSelectedItemList,
        deleteTargetWaveformIndexList,
      })
    );
    yield put(setIsArrangeRequired({ isArrangeRequired: true }));
  } catch (error) {
    console.error(error);
    yield put(deleteBeatsByFormIdFailed(error));
  }
}

function* _setLastSelectedSectionInfo({
  triggerType,
  panelType,
  lastSelectedSectionInfo,
}) {
  try {
    const numberOfClickedForms = yield select(selectNumberOfClickedForms);
    const { FORMS: selectAllInfoOfForms } = yield select(selectSelectAllInfo);
    const isOverClickedFormsMoreThanOne = numberOfClickedForms > 1;
    const isClickEventPanel = panelType === ShapeReviewSectionArea.EVENTS;
    const isOnlyClickCase = !lastSelectedSectionInfo?.[0];
    const doesSelectSection =
      lastSelectedSectionInfo.filter((v) => !v).length !== 1; // shift + click case

    if (
      isClickEventPanel ||
      isOnlyClickCase ||
      isOverClickedFormsMoreThanOne ||
      doesSelectSection ||
      selectAllInfoOfForms === CheckBoxAll.None
    ) {
      return;
    }

    const formDataOfSelectedEvent = yield select(selectFormDataOfSelectedEvent);
    const formId =
      formDataOfSelectedEvent[lastSelectedSectionInfo[0].index]?.formInfo?.id;

    if (!formId) {
      return;
    }

    if (triggerType === rawAndEventCalledCase.CLICK_EVENT) {
      yield put(getWaveformIndexListOfFormRequested({ formId }));
    } else if (triggerType === rawAndEventCalledCase.KEY_EVENT) {
      yield put(
        debounceGetWaveformIndexListOfFormRequested({
          formId,
        })
      );
    }
  } catch (error) {
    console.error('error: ', error);
  }
}
function* _tenSecStripHandler(action) {
  try {
    if (action.panelType === ShapeReviewSectionArea.FORMS) {
      return;
    }

    const poolingDataEvents = yield select(poolingDataEvent);
    const clickedEventType = yield select(selectSelectedEventType);

    const formPanelDetailOfFirstIndexOfLastSelectedSection = yield select(
      selectFormPanelDetailOfFirstIndexOfLastSelectedSection
    );
    const firstSelectedInfoOfEventPanel = yield select(
      selectFirstSelectedInfoOfEventPanel
    );
    const secondSelectedInfoOfEventPanel = yield select(
      selectSecondSelectedInfoOfEventPanel
    );
    const reqSelectedClusterEventDetail = yield select(
      selectEventDataOfSelectedForm
    );
    const formId =
      formPanelDetailOfFirstIndexOfLastSelectedSection?.formInfo.id;

    const requestSelectedInfoOfEventPanel =
      secondSelectedInfoOfEventPanel ?? firstSelectedInfoOfEventPanel;

    const selectedEventOfPoolingData =
      poolingDataEvents.data[clickedEventType][formId];

    const selectedEventOriginWaveformIndex =
      reqSelectedClusterEventDetail[requestSelectedInfoOfEventPanel?.index]
        ?.originWaveformIndex;

    const selectedEventFromPoolingData = selectedEventOfPoolingData?.get(
      selectedEventOriginWaveformIndex
    );

    const selectedCaliperMode = yield select(selectIsCaliperMode);
    if (selectedEventFromPoolingData === undefined) {
      yield put(resetTenSecStripDetail());
      return;
    }
    const { beats, mainECG } = selectedEventFromPoolingData;
    const tenSecStripAvgHr = getTenSecAvgHrByCenter(
      beats,
      selectedEventFromPoolingData.originWaveformIndex
    );
    let tenSecStripDetail = {
      onsetMs: mainECG.onsetMs,
      terminationMs: mainECG.terminationMs,
      onsetWaveformIdx: mainECG.onsetWaveformIndex,
      terminationWaveformIdx: mainECG.terminationWaveformIndex,
      hrAvg: tenSecStripAvgHr,
      ecgRaw: mainECG.rawECG,
      beatLabelButtonDataList: [],
      beatsOrigin: beats,
    };

    const beatType = TEN_SEC_STRIP_EDIT.BEAT_TYPE;
    const beatColorType = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE;
    const waveformIndexList = beats.waveformIndex
      .slice(
        beats.waveformIndex.findIndex((v) => v >= mainECG.onsetWaveformIndex),
        beats.waveformIndex.findLastIndex(
          (v) => v <= mainECG.terminationWaveformIndex
        ) + 1
      )
      .map((waveformIdx) => waveformIdx - mainECG.onsetWaveformIndex);
    const beatTypeList = beats.beatType.slice(
      beats.waveformIndex.findIndex((v) => v >= mainECG.onsetWaveformIndex),
      beats.waveformIndex.findLastIndex(
        (v) => v <= mainECG.terminationWaveformIndex
      ) + 1
    );
    for (let index in waveformIndexList) {
      tenSecStripDetail.beatLabelButtonDataList.push({
        xAxisPoint: waveformIndexList[index],
        isSelected:
          waveformIndexList[index] ===
          beats.waveformIndex[0] - mainECG.onsetWaveformIndex,
        beatType: beatTypeList[index],
        title: beatType[beatTypeList[index]],
        color: beatColorType[beatTypeList[index]],
        isEventReview: '',
      });
    }

    if (selectedCaliperMode) setIsCaliperMode(false);

    yield put(setTenSecStripDetail(tenSecStripDetail));
  } catch (error) {
    console.error(error);
  }
}

function* _postBeats(action) {
  try {
    const { reqBody, suffix, tabType } = action;

    const ecgTestId = yield select(selectEcgTestId);
    const firstSelectedInfoOfEventPanel = yield select(
      selectFirstSelectedInfoOfEventPanel
    );
    const secondSelectedInfoOfEventPanel = yield select(
      selectSecondSelectedInfoOfEventPanel
    );
    const requestPostTensecStripDetail = yield select(selectTenSecStripDetail);

    const reqSelectedClusterEventDetail = yield select(
      selectEventDataOfSelectedForm
    );
    const requestSelectedInfoOfEventPanel =
      secondSelectedInfoOfEventPanel ?? firstSelectedInfoOfEventPanel;

    const requestAt = new Date().getTime();

    const reqSelectedEvent =
      reqSelectedClusterEventDetail[requestSelectedInfoOfEventPanel?.index];

    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
      tabType: tabType,
    };

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      yield put(
        postBeatsSucceed(
          data,
          {
            requestAt,
            validResult: validateBeatEditResponse(reqBody, data.result),
            editTargetBeatType: reqBody.beatType,
            tabType,
          },
          requestPostTensecStripDetail,
          requestSelectedInfoOfEventPanel,
          reqSelectedClusterEventDetail
        )
      );
      yield put(
        getBeatsNEctopicListRequested({
          reqSelectedClusterEventDetail,
          reqSelectedEvent,
          onsetWaveformIdx: requestPostTensecStripDetail.onsetWaveformIdx,
          terminationWaveformIdx:
            requestPostTensecStripDetail.terminationWaveformIdx,
          requestSelectedInfoOfEventPanel,
        })
      );
    }
    function* failedCallback(error) {
      console.error(error);
      yield put(postBeatsFailed(error, action));
    }
  } catch (error) {
    console.error(error);
    yield put(postBeatsFailed(error, action));
  }
}

function* _patchBeats(action) {
  try {
    const isWholeUnMark = yield select(selectIsWholeUnMark);
    const firstSelectedInfoOfEventPanel = yield select(
      selectFirstSelectedInfoOfEventPanel
    );
    const secondSelectedInfoOfEventPanel = yield select(
      selectSecondSelectedInfoOfEventPanel
    );

    const reqSelectedClusterEventDetail = yield select(
      selectEventDataOfSelectedForm
    );

    const requestSelectedInfoOfEventPanel =
      secondSelectedInfoOfEventPanel ?? firstSelectedInfoOfEventPanel;

    const reqSelectedEvent =
      reqSelectedClusterEventDetail[requestSelectedInfoOfEventPanel.index];

    const requestPatchTensecStripDetail = yield select(selectTenSecStripDetail);

    if (isWholeUnMark) yield put(setShapeDetailEditPending(true));
    const ecgTestId = yield select(selectEcgTestId);
    const { reqBody, suffix, tabType } = action;

    const requestAt = new Date().getTime();
    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ data }) {
      if (isWholeUnMark) {
        yield put(setEventDetailEdited());
      }

      yield put(
        patchBeatsSucceed(
          data,
          tabType,
          {
            requestAt,
            validResult: validateBeatEditResponse(reqBody, data.result),
            editTargetBeatType: reqBody.beatType,
          },
          requestSelectedInfoOfEventPanel,
          requestPatchTensecStripDetail,
          reqSelectedClusterEventDetail
        )
      );

      yield put(
        getBeatsNEctopicListRequested({
          reqSelectedClusterEventDetail,
          reqSelectedEvent,
          onsetWaveformIdx: requestPatchTensecStripDetail.onsetWaveformIdx,
          terminationWaveformIdx:
            requestPatchTensecStripDetail.terminationWaveformIdx,
          requestSelectedInfoOfEventPanel,
        })
      );
    }
    function* failedCallback(error) {
      console.error(error, action);
      yield put(patchBeatsFailed(error, action));
    }
  } catch (error) {
    console.error(error);
    yield put(patchBeatsFailed(error, action));
  }
}

function* _deleteBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { reqBody, suffix } = action;
    const requestStatement = {
      requestType: suffix,
      ecgTestId: ecgTestId,
      reqBody: reqBody,
    };

    const firstSelectedInfoOfEventPanel = yield select(
      selectFirstSelectedInfoOfEventPanel
    );
    const secondSelectedInfoOfEventPanel = yield select(
      selectSecondSelectedInfoOfEventPanel
    );

    const reqSelectedClusterEventDetail = yield select(
      selectEventDataOfSelectedForm
    );

    const requestSelectedInfoOfEventPanel =
      secondSelectedInfoOfEventPanel ?? firstSelectedInfoOfEventPanel;

    const reqSelectedEvent =
      reqSelectedClusterEventDetail[requestSelectedInfoOfEventPanel.index];

    let requestDeleteTensecStripDetail = yield select(selectTenSecStripDetail);

    yield put(
      enqueueRequest({
        requestStatement,
        succeedCallback,
        failedCallback,
      })
    );
    function* succeedCallback({ status }) {
      yield put(
        deleteBeatsSucceed({
          reqSelectedClusterEventDetail,
          reqBody,
          requestDeleteTensecStripDetail,
          requestSelectedInfoOfEventPanel,
        })
      );
      yield put(
        getBeatsNEctopicListRequested({
          reqSelectedClusterEventDetail,
          reqSelectedEvent,
          onsetWaveformIdx: requestDeleteTensecStripDetail.onsetWaveformIdx,
          terminationWaveformIdx:
            requestDeleteTensecStripDetail.terminationWaveformIdx,
          requestSelectedInfoOfEventPanel,
        })
      );
    }
    function* failedCallback(error) {
      yield put(deleteBeatsFailed(error));
    }
  } catch (error) {
    yield put(deleteBeatsFailed(error));
  }
}
// Beat Postprocess
function* _patchBeatPostprocess(action) {
  let payload = {};
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      data: { result },
    } = yield call(ApiManager.patchBeatPostprocess, {
      ecgTestId,
      doRearrange: true,
    });

    payload.beatPostprocessedMs = result.beatPostprocessedMs;
    const clickedInfo = yield select(selectClickedInfo);
    const updatedState = {
      shapeReviewState: {
        ...initialState.shapeReviewState,
        [ShapeReviewState.CLICKED_INFO]: {
          ...clickedInfo,
        },
      },
    };
    yield put(resetShapeReviewState(updatedState));
    yield put(getClusteringStatisticsRequested());
    yield put(patchBeatPostprocessSucceed(payload));
    yield put(setIsArrangeRequired({ isArrangeRequired: false }));
  } catch (error) {
    payload.error = error;
    yield put(patchBeatPostprocessFailed(payload));
  }
}

// Arrange
function* _getIsArrangeRequiredRequested(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);

    const {
      data: {
        result: { isArrangeRequired },
      },
    } = yield call(ApiManager.getIsArrangeRequired, {
      ecgTestId,
    });

    yield put(getArrangeRequiredStatusSucceed({ isArrangeRequired }));
  } catch (error) {
    console.error(error);
    yield put(getArrangeRequiredStatusFailed(error));
  }
}

function* _getBeatsNEctopicList(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      reqSelectedClusterEventDetail, // state.shapeReviewReducer.clusterEventDetail - getBeatsNEctopicListRequest 시점
      reqSelectedEvent, // reqSelectedClusterEventDetail[selectedInfoOfEventPanel?.index] - clusterEventDetail 중 선택된 이벤트
      onsetWaveformIdx, // tenSecStripDetail의 onsetWaveformIdx
      terminationWaveformIdx, // tenSecStripDetail의 terminationWaveformIdx
      requestSelectedInfoOfEventPanel, // selectedInfo[ShapeReviewSectionArea.EVENTS] -  lastSelectedSectionInfo[1] ?? lastSelectedSectionInfo[0];
    } = action;

    const selectedEventIndex = requestSelectedInfoOfEventPanel.index;

    const selectedEventOriginWaveformIndex =
      reqSelectedClusterEventDetail[selectedEventIndex].originWaveformIndex;

    const selectedClusterEventDetail = yield select(
      selectEventDataOfSelectedForm
    );
    const {
      data: { result: beatsData },
    } = yield call(ApiManager.getBeatsFilterWaveformIndexRange, {
      ecgTestId,
      onsetWaveformIndex: onsetWaveformIdx < 0 ? 0 : onsetWaveformIdx,
      terminationWaveformIndex: terminationWaveformIdx,
    });

    const newBeatsOrigin = {
      ...reqSelectedEvent.beats,
      hr: beatsData.hr,
      beatType: beatsData.beatType,
      waveformIndex: beatsData.waveformIndex,
    };

    const isEqualOrigin =
      selectedClusterEventDetail[0].originWaveformIndex ===
      reqSelectedClusterEventDetail[0].originWaveformIndex;

    const isEqualTermination =
      selectedClusterEventDetail[0].terminationWaveformIndex ===
      reqSelectedClusterEventDetail[0].terminationWaveformIndex;

    const isEqualReqResEvent = isEqualOrigin && isEqualTermination;

    const newSelectedClusterEventDetail = reqSelectedClusterEventDetail.map(
      (event) => {
        if (event.index === selectedEventIndex) {
          return {
            ...event,
            beats: {
              ...event.beats,
              hr: beatsData.hr,
              beatType: beatsData.beatType,
              waveformIndex: beatsData.waveformIndex,
            },
          };
        }
        return event;
      }
    );

    yield put(
      getBeatsNEctopicListSucceed({
        isEqualReqResEvent,
        newBeatsOrigin,
        selectedEventOriginWaveformIndex,
        beatsData,
        newSelectedClusterEventDetail,
        requestSelectedInfoOfEventPanel,
      })
    );
  } catch (error) {
    getBeatsNEctopicListFailed(error);
    console.error(error);
  }
}

// Saga
export function* saga() {
  /**
   * setting
   * setting: event panel data
   * setting: tensec Strip
   *
   * fetching: clustering statistics
   * fetching: Form panel data
   * fetching: Event panel data
   * fetching: stop pooling event panel data
   * fetching: get 10s strip of [beatType, hr, waveformIndex]
   *
   * edit: event
   * edit: event beat by event(sidePanel)
   * edit: Beat Postprocess
   * edit: Arrange
   *
   * delete: delete all of events by form(side panel)
   * delete: delete event Beats by Event(side panel)
   */

  // setting
  yield takeLatest(SET_PAGE, _setPage);
  // setting: event panel data
  yield takeLatest(SET_LAST_SELECTED_SECTION_INFO, _setLastSelectedSectionInfo);
  // setting: tensec Strip
  yield takeLatest(SET_LAST_SELECTED_SECTION_INFO, _tenSecStripHandler);
  yield takeLatest(PATCH_BEATS_BY_WAVEFORM_INDEX_SUCCEED, _tenSecStripHandler);

  // fetching: clustering statistics
  yield takeLatest(
    GET_CLUSTERING_STATISTICS_REQUESTED,
    _getClusteringStatistics
  );
  // fetching: Form panel data
  yield takeLatest(GET_FORM_LIST_REQUESTED, _getFormListRequested);
  yield takeEvery(GET_RAW_AND_EVENT_REQUESTED, _getRawAndEvent);
  // fetching: Event panel data
  yield debounce(
    1000,
    DEBOUNCE_GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    _getWaveformIndexListOfForm
  );
  yield takeEvery(
    GET_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    _getWaveformIndexListOfForm
  );
  yield takeEvery(
    GET_POOLING_DATA_OF_EVENT_OF_FORM_REQUESTED,
    _getPoolingDataOfEventOfForm
  );
  // fetching: stop pooling event panel data
  yield takeLatest(SET_PAUSE_POOLING_EVENT_LIST, _setPausePoolingEventList);
  // fetching: get 10s strip of [beatType, hr, waveformIndex]
  yield takeEvery(GET_BEATS_N_ECTOPIC_LIST_REQUESTED, _getBeatsNEctopicList);

  // edit: event
  yield takeLatest(PATCH_BEATS_BY_FORM_ID_REQUESTED, _patchBeatsByFormIds);
  // edit: event beat by event(sidePanel)
  yield takeLatest(
    PATCH_BEATS_BY_WAVEFORM_INDEX_REQUESTED,
    _patchBeatsByWaveformIndexes
  );
  // edit: Beat Postprocess
  yield takeLatest(PATCH_BEAT_POSTPROCESS_REQUESTED, _patchBeatPostprocess);
  // edit: Arrange
  yield takeLatest(
    GET_ARRANGE_REQUIRED_STATUS_REQUESTED,
    _getIsArrangeRequiredRequested
  );
  // edit: add beats
  yield takeEvery(POST_BEATS_REQUESTED, _postBeats);
  // edit: patch beats
  yield takeLatest(PATCH_BEATS_REQUESTED, _patchBeats);

  // delete : delete all of events by form(side panel)
  yield takeLatest(
    DELETE_BEATS_BY_FORM_ID_REQUESTED,
    _deleteBeatsByFormIdRequested
  );
  // delete: delete event Beats by Event(side panel)
  yield takeLatest(
    DELETE_BEATS_BY_WAVEFORM_INDEX_REQUESTED,
    _deleteBeatsByWaveformIndexRequested
  );
  // delete: delete beats
  yield takeEvery(DELETE_BEATS_REQUESTED, _deleteBeats);

  // order: order events
  yield takeEvery(
    GET_ORDERED_WAVEFORM_INDEX_LIST_OF_FORM_REQUESTED,
    _getOrderedWaveformIndexListOfForm
  );
}
