window.devMode = {
  isLimitSaveInOPFS: true,
  checkRawData: false, // raw data 비교(from decoding vs api)
};
window.setDevMove = {
  isLimitSaveInOPFS: (option) => {
    return (window.devMode.isLimitSaveInOPFS = option);
  },
  checkRawData: (option) => {
    return (window.devMode.checkRawData = option);
  },
};
// window.setDevMove.checkRawData(true);

export const devMode = () => {
  return {};
};
