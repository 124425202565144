// Milliseconds 값을 Waveform Index 기준으로 변환
export function transformMsToWaveformIndex(recordingStartMs, targetMs) {
  return Math.floor((targetMs - recordingStartMs) / 4);
}

// WaveformIndex 값에 대한 시간을 반환
// todo: jyoon [#refactoring] [2021-08-26] "* 4" 키워드로 검색해서 필요한 부분 전체 refactoring 필요
export function transformWaveformIndexToMs(recordingStartMs, waveformIndex) {
  const timePerWfi = 4; // 1wfi === 4ms
  const elapsedTime = waveformIndex * timePerWfi; // 경과 시간

  // 측정 시작 시간 + 경과 시간 = wfi가 가리키는 시간
  return recordingStartMs + elapsedTime;
}
