import { select } from 'redux-saga/effects';

import { EVENT_CONST_TYPES } from 'constant/EventConst';

import { calculateHeartRate } from 'util/reduxDuck/TestResultDuckUtil';

import { BeatType } from '@type/ecgEventType/baseEventType';
import { OptimisticEventDataUpdateForBeats } from '@type/optimisticUpdate/type';
import {
  selectRecordingTime,
  selectTimeEventList,
} from 'redux/duck/testResultDuck';
import { TimeEvent } from '@type/ecgEventType/eventType';
import { BeatsNBeatEvents } from '@type/beatNEctopic/process';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';

import {
  PatchBeat,
  Validation,
} from '../eventReview/beatEvent/commandPattern/eventReview_PatchBeat';
import {
  ReqBody,
  ShapeReviewUpdateReqOptionProps,
} from '../eventReview/beatEvent/optimisticEventDataUpdateForBeatEvent';
import { Command } from '../eventReview/eventUpdateCmdPattern';

export class PatchBeatByWaveformIndexList extends PatchBeat {
  result: any;
  reqParam: any;

  constructor(reqParam: any) {
    super();
    this.reqParam = reqParam;
  }

  *optimisticPatchBeatsByWaveformIndex({
    updateReqOption,
    targetWaveformIndex,
    filterBeatsNEctopicList,
  }: ShapeReviewUpdateReqOptionProps) {
    const updatedTargetEvents: Record<number, any> = {};
    let result: OptimisticEventDataUpdateForBeats = {
      hr: [],
      beatType: [],
      waveformIndex: [],
    };
    const { reqBody } = updateReqOption;
    let failedWaveformIndexes: number[] = [];
    let succeedWaveformIndexes: number[] = [];

    // 요청한 이벤트 갯수만 큼 반복(여러개를 선택해서 편집 case)
    for (let filterBeats of filterBeatsNEctopicList) {
      const beatsArray: BeatsNBeatEvents[] = [];
      beatsArray.push(filterBeats);
      const mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
        this.mergeBeatsNEctopicList(beatsArray);
      const copyMergedBeatsNEctopicList = this.rfdcClone(
        mergedBeatsNEctopicList
      );
      // STEP1: beatUpdate
      result = this.updateLogic({
        targetWaveformIndex,
        originWaveformIndex: filterBeats.originWaveformIndex,
        terminationWaveformIndex: filterBeats.terminationWaveformIndex,
        reqBody,
        mergedBeatsNEctopicList,
      });
      // STEP2: validation
      const {
        mergedBeatsNEctopicList: updatedMergedBeatsNEctopicList,
        succeedWaveformIndex,
        failedWaveformIndex,
      } = yield this._validation({
        reqBody,
        updateReqOption,
        mergedBeatsNEctopicList: result,
        copyMergedBeatsNEctopicList,
      });
      // Afib 영역 편집 여부에 따라 편집 결과, 성공한 waveformIndex list, 실패 waveformIndex list
      result = updatedMergedBeatsNEctopicList;
      failedWaveformIndexes = [...failedWaveformIndex];
      succeedWaveformIndexes = [...succeedWaveformIndex];

      this.result = this.setOptimisticEventDataUpdateResult({
        isValidationSuccessful: true,
        result,
      });
      updatedTargetEvents[filterBeats.originWaveformIndex] = result;
    }
    return {
      updatedTargetEvents,
      succeedWaveformIndexes,
      failedWaveformIndexes,
    };
  }

  updateLogic({
    targetWaveformIndex,
    originWaveformIndex,
    terminationWaveformIndex,
    reqBody,
    mergedBeatsNEctopicList,
  }: {
    targetWaveformIndex?: any;
    originWaveformIndex?: WaveformIndex;
    terminationWaveformIndex?: WaveformIndex;
    reqBody: ReqBody;
    mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  }): OptimisticEventDataUpdateForBeats {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;
    // ISO,COUPLET의 onset 편집 case
    if (
      originWaveformIndex &&
      targetWaveformIndex[originWaveformIndex] === originWaveformIndex
    ) {
      const updateTargetWaveformIndexIndex: number =
        waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex === originWaveformIndex
        );

      const isTargetBeatNoise =
        beatTypeList[updateTargetWaveformIndexIndex] === BeatType.NOISE;
      const isPrevBeatNoise =
        beatTypeList[updateTargetWaveformIndexIndex - 1] === BeatType.NOISE;
      const isNextBeatNoise =
        beatTypeList[updateTargetWaveformIndexIndex + 1] === BeatType.NOISE;

      if (isTargetBeatNoise) {
        hrList[updateTargetWaveformIndexIndex] = isPrevBeatNoise
          ? null
          : calculateHeartRate(
              waveformIndexList[updateTargetWaveformIndexIndex],
              waveformIndexList[updateTargetWaveformIndexIndex - 1]
            );

        hrList[updateTargetWaveformIndexIndex + 1] = isNextBeatNoise
          ? null
          : calculateHeartRate(
              waveformIndexList[updateTargetWaveformIndexIndex + 1],
              waveformIndexList[updateTargetWaveformIndexIndex]
            );
      }

      // 요청한 비트 타입으로 변경
      beatTypeList[updateTargetWaveformIndexIndex] = reqBody.beatType;

      // 비트를 Noise(Q비트)로 변경 시
      if (reqBody.beatType === BeatType.NOISE) {
        hrList[updateTargetWaveformIndexIndex] = null;
        hrList[updateTargetWaveformIndexIndex + 1] = null;
      }
    }
    // COUPLET의 termination 편집 case
    if (
      terminationWaveformIndex &&
      targetWaveformIndex[terminationWaveformIndex] === originWaveformIndex
    ) {
      const updateTargetTerminationWaveformIndexIndex: number =
        waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex === terminationWaveformIndex
        );

      const isTargetBeatNoise =
        beatTypeList[updateTargetTerminationWaveformIndexIndex] ===
        BeatType.NOISE;
      const isPrevBeatNoise =
        beatTypeList[updateTargetTerminationWaveformIndexIndex - 1] ===
        BeatType.NOISE;
      const isNextBeatNoise =
        beatTypeList[updateTargetTerminationWaveformIndexIndex + 1] ===
        BeatType.NOISE;

      if (isTargetBeatNoise) {
        hrList[updateTargetTerminationWaveformIndexIndex] = isPrevBeatNoise
          ? null
          : calculateHeartRate(
              waveformIndexList[updateTargetTerminationWaveformIndexIndex],
              waveformIndexList[updateTargetTerminationWaveformIndexIndex - 1]
            );

        hrList[updateTargetTerminationWaveformIndexIndex + 1] = isNextBeatNoise
          ? null
          : calculateHeartRate(
              waveformIndexList[updateTargetTerminationWaveformIndexIndex + 1],
              waveformIndexList[updateTargetTerminationWaveformIndexIndex]
            );
      }

      // 요청한 비트 타입으로 변경
      beatTypeList[updateTargetTerminationWaveformIndexIndex] =
        reqBody.beatType;

      // 비트를 Noise(Q비트)로 변경 시
      if (reqBody.beatType === BeatType.NOISE) {
        hrList[updateTargetTerminationWaveformIndexIndex] = null;
        hrList[updateTargetTerminationWaveformIndexIndex + 1] = null;
      }
    }

    return mergedBeatsNEctopicList;
  }

  *_validation({
    reqBody,
    updateReqOption,
    mergedBeatsNEctopicList,
    copyMergedBeatsNEctopicList,
  }: Validation): any {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    let failedWaveformIndex: number[] = [];
    let succeedWaveformIndex: number[] = [];
    if (reqBody.beatType === BeatType.APC) {
      const EVENT_CONST_TYPE_AF = EVENT_CONST_TYPES.AF;
      const { recordingStartMs } = yield select(selectRecordingTime);
      const afList: TimeEvent[] = yield select((state) =>
        selectTimeEventList(state, EVENT_CONST_TYPE_AF)
      );

      reqBody.waveformIndexes.forEach((updateTargetWaveform) => {
        const updateTargetWaveformIndexIndex = waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex === updateTargetWaveform
        );

        const selectedEventTimeStamp =
          recordingStartMs + updateTargetWaveform * 4;
        const isWithinAfEvent = afList.some(
          (afInfo) =>
            selectedEventTimeStamp >= afInfo.onsetMs &&
            selectedEventTimeStamp <= afInfo.terminationMs
        );

        // 성공한 이벤트의 waveformIndex를 추가
        succeedWaveformIndex.push(updateTargetWaveform);

        if (isWithinAfEvent && updateTargetWaveformIndexIndex > 0) {
          hrList[updateTargetWaveformIndexIndex] = null;
          beatTypeList[updateTargetWaveformIndexIndex] =
            copyMergedBeatsNEctopicList.beatType[
              updateTargetWaveformIndexIndex
            ];
          waveformIndexList[updateTargetWaveformIndexIndex] =
            updateTargetWaveform;
          // 실패한 케이스의 이벤트의 waveformIndex를 succeedWaveformIndex에서 삭제 및 failedWaveformIndex에 추가
          succeedWaveformIndex.pop();
          failedWaveformIndex.push(updateTargetWaveform);
        }
      });
    }
    return {
      mergedBeatsNEctopicList,
      succeedWaveformIndex,
      failedWaveformIndex,
    };
  }
}

// ### COMMAND 역할
export class ShapeReviewPatchBeatByWaveformIndexListCommand {
  command: any;
  executeInst: any;

  constructor(value: any) {
    this.command = new Command(PatchBeatByWaveformIndexList, null, value);
  }

  *execute() {
    this.executeInst = new this.command.executeClass(this.command.value);
    if (!this.executeInst) {
      throw new Error(
        'execute must be called before optimisticPatchBeatsByWaveformIndex'
      );
    }
    return yield this.executeInst.optimisticPatchBeatsByWaveformIndex(
      this.command.value
    );
  }
}
