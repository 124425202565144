import styled from 'styled-components';

import { ReactComponent as HelpOriginIcon } from 'static/icon/ic-help.svg';

const HelpIcon = styled(HelpOriginIcon)`
  width: 11.67px;
  height: 11.67px;
  & path {
    fill: ${(props) => props.theme.color.COOL_GRAY_70};
  }
`;

const Wrapper = styled.div`
  padding: 11px 24px 10px;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NavigationChildLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > * {
    :not(:last-child) {
      margin-right: ${(props) => props.childrenGap ?? 8}px;
    }
  }
`;

const NavigationChildRightWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
  gap: 16px;
  right: 24px;
`;

const BeatDetailInformationText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${(props) => props.theme.color.BLACK};
`;

const BeatDetailHrInformationText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.DARK};
`;

const SelectStripHelpTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 5.17px;
`;

const SelectStripHelpText = styled.label`
  font-size: 11px;
  font-weight: 500;
  line-height: 130%;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

export const VerticalBar = styled.div`
  height: 20px;
  border-right: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
`;

const BeatDetailSubInformationWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;

  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
`;
const BeatDetailSubInformationLabel = styled.div`
  color: ${(props) => props.theme.color.COOL_GRAY_80};
`;
const BeatDetailSubInformationValue = styled.div`
  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;

function TenSecStripDetailAddReportHeader(props) {
  const { selectStripHelpText, avgHrValue } = props;

  return (
    <Wrapper>
      <NavigationChildLeftWrapper>
        <BeatDetailInformationText>Strip Preview</BeatDetailInformationText>
        <VerticalBar />
        <BeatDetailSubInformationWrapper>
          <BeatDetailSubInformationLabel>
            {'10s Avg HR'}
          </BeatDetailSubInformationLabel>
          <BeatDetailSubInformationValue>
            {avgHrValue}
          </BeatDetailSubInformationValue>
        </BeatDetailSubInformationWrapper>
      </NavigationChildLeftWrapper>
      <NavigationChildRightWrapper>
        <SelectStripHelpTextWrapper className="SelectStripHelpTextWrapper">
          <HelpIcon />
          <SelectStripHelpText>{selectStripHelpText}</SelectStripHelpText>
        </SelectStripHelpTextWrapper>
      </NavigationChildRightWrapper>
    </Wrapper>
  );
}

export default TenSecStripDetailAddReportHeader;
