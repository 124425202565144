import rfdc from 'rfdc';

import { optimisticEventDataUpdate } from 'util/optimisticEventDataUpdate/optimisticEventDataUpdate';

import { BeatType } from '@type/ecgEventType/baseEventType';
import {
  optimisticEventDataUpdateCase,
  OptimisticEventDataUpdateForBeats,
} from '@type/optimisticUpdate/type';
import { BeatsNBeatEvents } from '@type/beatNEctopic/process';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';

/**
 * *** 특징 ***
 *
 * * postBeats
 *    - 10s strip에서만 가능
 *    - 즉, 비트 단위로(waveformIndex[]) 요청
 * * delete Beats
 *    - 10s strip에서만 가능
 *    - 즉, 비트 단위로(waveformIndex[]) 요청
 * * patchBeats
 *    - 10s, 30s strip에서 비트 변경하는 방식이 다름
 *      - 10s strip: 비트 단위(waveformIndex[])
 *      - 30s strip: 구간 단위(leftWaveformIndex: waveformIndex; rightWaveformIndex: waveformIndex;)
 *
 * * validation
 *    - af & s beat
 *      : af 구간에는 s비트를 추가하지 못함
 *      - s -> af
 *        : af구간을 제외 비트 추가
 *      - af -> s
 *        : af구간을 추가한 구간은 s비트 제거
 *        : af구간 제외 했을때 기존에 s비트가 있다면 다시 생겨야 함
 */
export class eventReviewOptimisticEventDataUpdateForBeatEvent extends optimisticEventDataUpdate {
  rfdcClone;

  constructor() {
    super();
    this.rfdcClone = rfdc();
  }

  mergeBeatsNEctopicList(
    filterBeatsNEctopicList: BeatsNBeatEvents[]
  ): OptimisticEventDataUpdateForBeats {
    const initResult: OptimisticEventDataUpdateForBeats = {
      beatType: [],
      hr: [],
      waveformIndex: [],
    };

    const mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
      filterBeatsNEctopicList.reduce((acc, cur) => {
        acc.beatType = [...acc.beatType, ...cur.beats.beatType];
        acc.hr = [...acc.hr, ...cur.beats.hr];
        acc.waveformIndex = [...acc.waveformIndex, ...cur.beats.waveformIndex];

        return acc;
      }, initResult);

    return mergedBeatsNEctopicList;
  }

  setOptimisticEventDataUpdateResult({
    isValidationSuccessful,
    result,
  }: {
    isValidationSuccessful: boolean;
    result: OptimisticEventDataUpdateForBeats;
  }) {
    return {
      isValidationSuccessful,
      result,
    };
  }
}

// 비트 선택(=TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW, =PatchBeatByWaveformIndexListCommand)
interface IReqBody {
  beatType: BeatType;
  waveformIndexes: WaveformIndex[];
}
// 구간 선택(=TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU, =PatchBeatByRangeListCommand)
interface IReqBody {
  beatType: BeatType;
  leftWaveformIndex: WaveformIndex;
  rightWaveformIndex: WaveformIndex;
}

export type ReqBody = IReqBody;

export interface UpdateReqOption {
  optimisticEventDataUpdateCase: optimisticEventDataUpdateCase;
  reqBody: ReqBody;
  tabType: 'ARRHYTHMIA_CONTEXTMENU' | 'EVENT_REVIEW';
}

export interface UpdateReqOptionProps {
  updateReqOption: UpdateReqOption;
  filterBeatsNEctopicList: BeatsNBeatEvents[];
}
export interface ShapeReviewUpdateReqOptionProps extends UpdateReqOptionProps {
  targetWaveformIndex: { [key: number]: number };
}
export interface OptimisticEventDataUpdateForBeatsResult
  extends OptimisticEventDataUpdateForBeats {
  isValidationSuccessful: true;
}

export interface filterSelectedItemListOfEventPanelType {
  filterBeatsNEctopicList: BeatsNBeatEvents[];
  onset: WaveformIndex[];
  termination: WaveformIndex[];
}
