// 필요한 타입 정의
interface WriteFileOptions {
  fileName: string;
  blobData: Blob;
}

interface ReadFileOptions {
  fileName: string;
}

export class OPFSOperator {
  private rootDir: Promise<FileSystemDirectoryHandle>;

  constructor() {
    this.rootDir = navigator.storage.getDirectory(); // 디렉토리 핸들을 저장
  }

  /**
   *
   * * 파일 생성 예시
   *    opfsOperator.writeFile({
   *      fileName: 'example.txt',
   *      blobData: new Blob(['Hello World']),
   *    });
   * @param param0
   */
  async writeFile({ fileName, blobData }: WriteFileOptions): Promise<void> {
    try {
      const rootDir = await this.rootDir;
      // 파일이 없으면 생성하고, 파일이 있으면 덮어씁니다.
      const fileHandle = await rootDir.getFileHandle(fileName, {
        create: true,
      });
      // 파일에 blob 데이터를 씁니다.
      const writableStream = await fileHandle.createWritable();
      // blob 데이터를 파일에 씁니다.
      await writableStream.write(blobData);
      // 파일을 닫습니다.
      await writableStream.close();
    } catch (error) {
      console.error(`📂⬅️ Error writing file: ${error}`);
    }
  }

  async readFile({ fileName }: ReadFileOptions): Promise<File | undefined> {
    try {
      const rootDir = await this.rootDir;
      const fileHandle = await rootDir.getFileHandle(fileName);
      const file = await fileHandle.getFile();
      return file;
    } catch (error) {
      console.error(`📂➡️ Error reading file: ${error}`);
      return undefined;
    }
  }

  async checkFilesInAnyDirectory() {
    if ('storage' in navigator && 'getDirectory' in navigator.storage) {
      try {
        const directoryHandle = await this.rootDir;
        const entries = directoryHandle.values();
        const firstEntry = await entries.next();
        if (!firstEntry.done) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error accessing directory:', error);
      }
    } else {
      console.log('File System Access API is not supported in this browser.');
    }
  }

  async clearDirectory(): Promise<void> {
    try {
      const rootDir = await this.rootDir;
      for await (const entry of rootDir.values()) {
        if (entry.kind === 'file') {
          // console.log(`Deleted file: ${entry.name}`);
          await rootDir.removeEntry(entry.name);
        } else if (entry.kind === 'directory') {
          // console.log(`Deleted directory: ${entry.name}`);
          await this.removeDirectory(entry);
        }
      }
    } catch (error) {
      console.error('Error clearing directory:', error);
    }
  }

  private async removeDirectory(directoryHandle: any): Promise<void> {
    for await (const entry of directoryHandle.values()) {
      if (entry.kind === 'file') {
        await directoryHandle.removeEntry(entry.name);
      } else if (entry.kind === 'directory') {
        await this.removeDirectory(entry);
      }
    }
    await directoryHandle.removeSelf();
  }

  getArrayByteSize(arr: (number | string | boolean)[]): string {
    let elementSize: number;
    if (typeof arr[0] === 'number') {
      elementSize = 8;
    } else if (typeof arr[0] === 'string') {
      elementSize = 2 * arr[0].length;
    } else if (typeof arr[0] === 'boolean') {
      elementSize = 1;
    } else {
      throw new Error('Unsupported type.');
    }
    return `${(arr.length * elementSize) / 1024 / 1024} MB`;
  }
}

export const opfsOperator = new OPFSOperator();

interface ECGData {
  rawECG: number[];
}

interface MainECG extends ECGData {
  // Additional properties can be added here as needed.
}

interface Array1Type {
  mainECG: MainECG;
}

interface Array2Type extends ECGData {
  // Additional properties can be added here as needed.
}

/**
 *
 * @param arr1
 * @param arr2
 */
export function compareECG(arr1: Array1Type[], arr2: Array2Type[]) {
  if (arr1.length === 0 || arr2.length === 0) {
    console.log('arr1.length === 0 || arr2.length === 0');
  }
  const ecg1 = arr1[0].mainECG.rawECG;
  const ecg2 = arr2[0].rawECG;

  if (ecg1.length !== ecg2.length) {
    console.log('ecg1.length !== ecg2.length');
  }

  for (let i = 0; i < ecg1.length; i++) {
    if (ecg1[i] !== ecg2[i]) {
      if (Math.abs(ecg1[i] - ecg2[i]) > 0.0001) {
        console.log(
          `%c 오차가 0.0001 이상입니다. -> Index: ${i}, ECG1: ${
            ecg1[i]
          }, ECG2: ${ecg2[i]}, 오차: ${ecg1[i] - ecg2[i]}`,
          'color: red'
        );
      }
    }
  }
  console.log('condition 4');
}

// Sample data structure initialization
// Example usage
// const arr1: Array1Type[] = [
//   {
//     mainECG: {
//       rawECG: [-0.0128, -0.0128, -0.0125, -0.0121 /* more data */],
//     },
//   },
// ];

// const arr2: Array2Type[] = [
//   {
//     rawECG: [-0.0128, -0.0128, -0.0125, -0.0121 /* more data */],
//   },
// ];
// compareECG(arr1, arr2);
