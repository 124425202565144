export type navigationPanelCase =
  typeof navigationPanelCase[keyof typeof navigationPanelCase];

//
export type shortCut = typeof shortCut[keyof typeof shortCut];
export type ChartBoxNumber = typeof ChartBoxNumber[keyof typeof ChartBoxNumber];
export type keyboardDirection =
  typeof keyboardDirection[keyof typeof keyboardDirection];
export type ComposeKey = typeof ComposeKey[keyof typeof ComposeKey];
export type ShapeReviewState =
  typeof ShapeReviewState[keyof typeof ShapeReviewState];
export type CheckBoxAll = typeof CheckBoxAll[keyof typeof CheckBoxAll];
export type ShapeReviewSectionArea =
  typeof ShapeReviewSectionArea[keyof typeof ShapeReviewSectionArea];
export type editType = typeof editType[keyof typeof editType];
export type UI_INTERACTION = typeof UI_INTERACTION[keyof typeof UI_INTERACTION];
export type POSITION_MOVE_TYPE =
  typeof POSITION_MOVE_TYPE[keyof typeof POSITION_MOVE_TYPE];
export type validationKeyDownType =
  typeof validationKeyDownType[keyof typeof validationKeyDownType];
export type shapeReviewLabel =
  typeof shapeReviewLabel[keyof typeof shapeReviewLabel];
export type ShapeReviewRawAndEventCalledCase =
  typeof ShapeReviewRawAndEventCalledCase[keyof typeof ShapeReviewRawAndEventCalledCase];
export type ShapeReviewPositionMoveType =
  typeof ShapeReviewPositionMoveType[keyof typeof ShapeReviewPositionMoveType];
export type PoolingState = typeof PoolingState[keyof typeof PoolingState];

//
export const navigationPanelCase = {
  isPauseCase: 'isPauseCase',
  isPendingCase: 'isPendingCase',
  isTotalPageInit: 'isTotalPageInit',
  isAbleCase: 'isAbleCase',
} as const;

export const shortCut = {
  n: 'n',
  a: 'a',
  N: 'N',
  A: 'A',
  '[': '[',
  ']': ']',
} as const;

export const ChartBoxNumber = {
  KeyOne: '1',
  KeyTwo: '2',
} as const;

export const keyboardDirection = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
} as const;

export const ComposeKey = {
  none: '',
  ctrlKey: 'ctrlKey',
  altKey: 'altKey',
  metaKey: 'metaKey',
  shiftKey: 'shiftKey',
} as const;

export const ShapeReviewState = {
  ACTIVE_PANEL: 'ACTIVE_PANEL',
  CLICKED_INFO: 'CLICKED_INFO',
  PANEL_SIZE_INFO: 'PANEL_SIZE_INFO',
  PAGINATION_INFO: 'PAGINATION_INFO',
  SELECTED_INFO: 'SELECTED_INFO',
  CHECK_EXIST_TARGET_PAGE: 'CHECK_EXIST_TARGET_PAGE',
  SELECT_ALL: 'SELECT_ALL',
} as const;

export const CheckBoxAll = {
  None: 'None',
  CheckedAll: 'CheckedAll',
  Indeterminate: 'Indeterminate',
} as const;

export const ShapeReviewSectionArea = {
  FORMS: 'FORMS',
  FORMS_NAVIGATION: 'FORMS_NAVIGATION',
  FORMS_LIST: 'FORMS_LIST',
  EVENTS: 'EVENTS',
  EVENTS_NAVIGATION: 'EVENTS_NAVIGATION',
  EVENTS_LIST: 'EVENTS_LIST',
  SIDE_PANEL: 'SIDE_PANEL',
} as const;

export const editType = {
  ALL: 'ALL',
  ONSET: 'ONSET',
  TERMINATION: 'TERMINATION',
} as const;

export const UI_INTERACTION = {
  CLICK: 'CLICK',
  SHIFT_CLICK: 'SHIFT_CLICK',
  META_CLICK: 'META_CLICK',
  ARROW_KEYS: 'ARROW_KEYS',
  SHIFT_ARROW_KEYS: 'SHIFT_ARROW_KEYS',
  META_ARROW_KEYS: 'META_ARROW_KEYS',
} as const;

export const POSITION_MOVE_TYPE = {
  INIT: 'INIT', // side panel event click
  JUMP: 'JUMP', // position move by typing number in input box
  PREV: 'PREV', // position move by left arrow key
  NEXT: 'NEXT', // position move by right arrow key
} as const;

export const validationKeyDownType = {
  MOVE_NEXT_PAGE: 'MOVE_NEXT_PAGE',
  MOVE_PREV_PAGE: 'MOVE_PREV_PAGE',
  OVER_PANEL_SIZE: 'OVER_PANEL_SIZE',
  OVER_CHART_LIST_LENGTH: 'OVER_CHART_LIST_LENGTH',
  UNDER_PANEL_SIZE_WITH_SHIFT_KEY: 'UNDER_PANEL_SIZE_WITH_SHIFT_KEY',
  OVER_PANEL_SIZE_WITH_SHIFT_KEY: 'OVER_PANEL_SIZE_WITH_SHIFT_KEY',
  OVER_PANEL_SIZE_WITH_CTRL_KEY: 'OVER_PANEL_SIZE_WITH_CTRL_KEY',
} as const;

export const shapeReviewLabel = {
  Edited: 'Edited',
  Etc: 'Etc',
  Forms: 'Forms',
} as const;

export const ShapeReviewRawAndEventCalledCase = {
  KEY_EVENT: 'KEY_EVENT',
  CLICK_EVENT: 'CLICK_EVENT',
  DATA_POLLING: 'DATA_POLLING',
  POSITION_JUMP: 'POSITION_JUMP',
} as const;

export const ShapeReviewPositionMoveType = {
  INIT: 'INIT',
  JUMP: 'JUMP',
  PREV: 'PREV',
  NEXT: 'NEXT',
} as const;

export const PoolingState = {
  PENDING: 'PENDING',
} as const;

//
export const NAVIGATION_HEIGHT = 38;
export const SHAPE_REVIEW_CHART_INFO = {
  MIN_WIDTH: 184,
  MIN_HEIGHT: 80,
  MAX_WIDTH: 194,
  MAX_HEIGHT: 90,
  CONTAINER_WIDTH: 184,
  CONTAINER_HEIGHT: 80,
  PADDING_LEFT_RIGHT: 24,
  PADDING_UP_DOWN: 12,
};
export const panelTabIndex = {
  [ShapeReviewSectionArea.FORMS]: 1,
  [ShapeReviewSectionArea.EVENTS]: 2,
};

export const shapeReviewFetchingOption = {
  [ShapeReviewSectionArea.FORMS]: {
    BASIS_MULTIPLE_FETCHING: 3,
  },
  [ShapeReviewSectionArea.EVENTS]: {
    BASIS_MULTIPLE_FETCHING: 3,
  },
  fetchingOption: {
    fetchingSize: 100,
    chartSampleSizeOf4s: 1000,
    chartSampleSizeOf12s: 3000,
  },
  initShapeReviewFetchingSize: 100,
  setShapeReviewFetchingSize(size: number) {
    this.fetchingOption.fetchingSize = size;
  },
};

export type operandType = [number, number];
export interface IBasisOperand {
  keyboardDirection: operandType;
}

//Shape review order filter clicked elements
// 정렬 컨텍스트 메뉴가 나오도록 클릭되는 elements
export const ShapeReviewOrderFilterClickedElements = {
  SORT_LABEL_WRAPPER: 'SortLabelWrapper',
  SORT_LABEL: 'SortLabel',
};
