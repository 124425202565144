import {
  CSSProperties,
  ForwardedRef,
  MouseEvent,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { SortOption, SortOptionList } from 'constant/SortConst';

import DropdownBase, {
  CLASS_NAME_DROPDOWN_ANCHOR,
  CLASS_NAME_DROPDOWN_ANCHOR_HOVER,
  CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE,
} from 'component/ui/dropdown/DropdownBase';
import { MenuBase } from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';
import Tooltip from 'component/ui/tooltip/Tooltip';
import { CheckIcon, SortAscIcon, SortDescIcon } from 'component/ui/icons';

const CLASS_NAME_SORT_ORDER_DROPDOWN = 'sort-order-dropdown';

const TextWrapper = styled.div`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
`;
const LabelTextWrapper = styled(TextWrapper)`
  padding: 3px 12px;

  font-size: 10px;
  line-height: 130%;

  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

interface ValueWrapperProps {
  abbreviated: boolean;
}
const ValueWrapper = styled.div<ValueWrapperProps>`
  gap: 2px;
  display: flex;
  align-content: stretch;
  align-items: center;
  & * {
    color: ${(props) => props.theme.color.COOL_GRAY_80};
    fill: ${(props) => props.theme.color.COOL_GRAY_80};
  }
  & svg {
    width: 16px;
    height: 16px;
  }
  & ${TextWrapper} {
    ${(props) => (props.abbreviated ? 'display: none;' : '')}
  }
`;

interface OptionWrapperProps {
  readonly selected: boolean;
}
const OptionWrapper = styled.div<OptionWrapperProps>`
  width: 100%;
  gap: 4px;

  display: flex;

  & * {
    // Text Font Color
    color: ${(props) => props.theme.color.COOL_GRAY_90};
    // Icon Color
    fill: ${(props) => props.theme.color.COOL_GRAY_80};
  }
  & svg {
    width: 16px;
    height: 16px;
    ${(props) => (!props.selected ? 'visibility: hidden;' : '')};
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.color.COOL_GRAY_30};
`;

interface SelectableOptionProps {
  selected?: boolean;
  optionText: string;
  textWrapperStyle?: CSSProperties;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  [x: string]: any;
}
export const SelectableOption = forwardRef<
  HTMLDivElement,
  SelectableOptionProps
>(
  (
    { selected = false, optionText, onClick, textWrapperStyle, ...restProps },
    ref
  ) => {
    return (
      <MenuItem {...restProps} ref={ref} onClick={onClick}>
        <OptionWrapper selected={selected}>
          <CheckIcon />
          <TextWrapper style={textWrapperStyle}>{optionText}</TextWrapper>
        </OptionWrapper>
      </MenuItem>
    );
  }
);

interface SortOptionDropdownProps {
  selectedOption: SortOption;
  optionList: SortOptionList;
  abbreviated?: boolean;
  onChange?: (newSelectedOption: SortOption) => any;
  [x: string]: any;
}
function SortOptionDropdown(
  {
    selectedOption,
    optionList,
    abbreviated = false,
    onChange,
    ...restProps
  }: SortOptionDropdownProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const intl = useIntl();

  const [_selectedOption, setSelectedOption] = useState(selectedOption);
  const [tooltipProps, setTooltipProps] = useState<{
    open?: boolean;
    offset?: [number, number];
  }>({
    offset: [0, 1],
    open: false,
  });

  useEffect(() => {
    if (!selectedOption) return;
    if (selectedOption.optionText === _selectedOption.optionText) return;

    setSelectedOption(selectedOption);
  }, [selectedOption]);

  /*
  Dropdown 의 Anchor 요소의 상태(hover, active) 추적
   */
  useEffect(() => {
    const targetElement = document.querySelector(
      `.${CLASS_NAME_SORT_ORDER_DROPDOWN} .${CLASS_NAME_DROPDOWN_ANCHOR}`
    );
    const mutationObserver = new MutationObserver((entries) => {
      for (let entry of entries) {
        const target = entry.target as Element;
        const classList = target.classList;
        const isHover = classList.contains(CLASS_NAME_DROPDOWN_ANCHOR_HOVER);
        const isActive = classList.contains(CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE);

        setTooltipProps((prevProps) => ({
          ...prevProps,
          open: !isActive && isHover,
        }));
      }
    });
    if (!targetElement) return;

    mutationObserver.observe(targetElement, { attributes: true });

    // cleanup function
    return () => {
      mutationObserver.disconnect();
    };
  }, []);

  const onChangeHandler = (newSelectedOption: SortOption) => {
    if (newSelectedOption.optionText === _selectedOption.optionText) return;

    setSelectedOption(newSelectedOption);
    if (typeof onChange !== 'function') {
      return;
    }
    onChange(newSelectedOption);
  };

  if (
    !selectedOption ||
    optionList.findIndex(
      (value) => value.optionText === selectedOption.optionText
    ) === -1
  ) {
    // console.log('SortOptionDropdown error: selectedOption 값 이상?');
    // debugger;
    return <></>;
  }

  return (
    <Tooltip
      title={intl.formatMessage(_selectedOption.tooltipTitle)}
      {...tooltipProps}>
      <DropdownBase
        {...restProps}
        ref={ref}
        anchorProps={{
          style: {
            padding: '6px 4px',
            border: '0px',
            minWidth: 0,
          },
          /** 정렬 옵션이 한 개 뿐인 경우 Dropdown UI 기능 비활성화; 마우스 이벤트에 반응하지 않음 */
          disabledMouseEvent: optionList.length <= 1,
        }}
        value={
          <ValueWrapper abbreviated={abbreviated}>
            <TextWrapper>{_selectedOption.valueText}</TextWrapper>
            {getOptionIcon(_selectedOption.ascending)}
          </ValueWrapper>
        }
        contentCloseReason="default"
        dropdownDirection="right"
        className={CLASS_NAME_SORT_ORDER_DROPDOWN}>
        <MenuBase>
          <LabelTextWrapper>Sort by</LabelTextWrapper>
          <Divider />
          {optionList.map((value) => {
            return (
              <SelectableOption
                key={`${value.optionText}_${value.queryOrderBy}_${value.ascending}_${value.isDefault}`}
                selected={value.optionText === _selectedOption.optionText}
                optionText={value.optionText}
                onClick={() => {
                  onChangeHandler(value);
                }}
              />
            );
          })}
        </MenuBase>
      </DropdownBase>
    </Tooltip>
  );
}

export default forwardRef(SortOptionDropdown);

function getOptionIcon(isAscendingOption: boolean | Array<boolean>) {
  let isAscending;
  if (Array.isArray(isAscendingOption)) {
    isAscending = isAscendingOption[0];
  } else {
    isAscending = isAscendingOption;
  }

  let result;
  if (isAscending) {
    result = <SortAscIcon />;
  } else {
    result = <SortDescIcon />;
  }
  return result;
}
