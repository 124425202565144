import {
  all,
  put,
  call,
  take,
  delay,
  select,
  debounce,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';
import rfdc from 'rfdc';
import { END, eventChannel } from 'redux-saga';

import Const from 'constant/Const';
import {
  BASIS_AMPLITUDE_RATE,
  REPORT_EVENT_EDITOR_STEP_MAP,
  STRIP_TYPE_MAP,
} from 'constant/ReportConst';
import {
  AMPLITUDE_OPTION,
  ECG_CHART_UNIT,
  SELECTION_MARKER_TYPE,
  TEN_SEC_SCRIPT_DETAIL,
  TEN_SEC_STRIP,
  TEN_SEC_STRIP_DETAIL,
  TEN_SEC_STRIP_EDIT,
} from 'constant/ChartEditConst';
import {
  EVENT_CONST_TYPES,
  EVENT_GROUP_TYPE,
  BEAT_TYPE,
  TIME_EVENT_TYPE,
  REPORT_SECTION,
  AV_BLOCK_LIST,
  PATIENT_EVENT_TYPE_LIST,
  EVENT_CONST_TYPES_VALUE_LIST,
} from 'constant/EventConst';
import { SORT_ORDER, PAUSE_SORT_DEFAULT } from 'constant/SortConst';
import LocalStorageKey from 'constant/LocalStorageKey';

import DateUtil from 'util/DateUtil';
import {
  transformTimeEvents,
  getInitRepresentativeStripInfo,
  getSearchBeatsNEctopicListRangeAfterUpdateEvent,
  _getTenSecStripInfo,
  _getFilterBeatsNEctopicList,
  mergeLeadOffInfo,
  _getBeatLabelButtonDataList,
  getLocalCenterWaveformIndex,
  getRepresentativeCenterWaveformIndex,
  getUpdatedSelectedEventList,
  getOnsetTerminationByCenter,
  getIsRawDataOnly,
} from 'util/reduxDuck/TestResultDuckUtil';
import {
  getSidePanelEventData,
  checkIfIsAvBlockEventType,
  getEventInfo,
  getEventInfoByType,
  isBeatType,
} from 'util/EventConstUtil';
import { validateBeatEditResponse } from 'util/validation/ValidateBeatsEdit';
import { postProcessEditedTimeEvent } from 'util/optimisticEventDataUpdate/eventReview/timeEvent/PostProcessTimeEventEdit';
import {
  selectFilteredEpisodeOrLeadOffList,
  getOverlapRangeFilter,
  getBeatsNBeatEventsList,
  getTotalFreshBeatsNBeatEventsList,
} from 'util/BeatUtil';
import { preProcessTimeEventEdit } from 'util/optimisticEventDataUpdate/eventReview/timeEvent/PreProcessTimeEventEdit';
import { getTenSecAvgHrByFromTo } from 'util/StripDataUtil';
import { PostBeatCommand } from 'util/optimisticEventDataUpdate/eventReview/beatEvent/commandPattern/eventReview_PostBeat';
import { PatchBeatByWaveformIndexListCommand } from 'util/optimisticEventDataUpdate/eventReview/beatEvent/commandPattern/eventReview_PatchBeatByWaveformIndexList';
import { PatchBeatByRangeListCommand } from 'util/optimisticEventDataUpdate/eventReview/beatEvent/commandPattern/eventReview_PatchBeatByRangeList';
import ChartUtil, { getTenSecStripParam } from 'util/ChartUtil';
import { isNotNullOrUndefined, optionalParameter } from 'util/Utility';
import { DeleteBeatCommand } from 'util/optimisticEventDataUpdate/eventReview/beatEvent/commandPattern/eventReview_DeleteBeat';
import { eventUpdateInst } from 'util/optimisticEventDataUpdate/eventReview/eventUpdateCmdPattern';
import { optimisticEventDataUpdateOptionMap } from 'util/optimisticEventDataUpdate/eventReview/beatEvent/commandPattern/eventReview_PatchBeat';
import { EventReviewPreSignedUrl } from 'util/PreSignedUrl/EventReviewPreSignedUrl';

import StatusCode from 'network/StatusCode';
import ApiManager from 'network/ApiManager';

import { optimisticEventDataUpdateCase } from '@type/optimisticUpdate/type';
import { EventEditErrorClass } from '@type/optimisticUpdate/validation';
import { EventSection } from '@type/ecgEventType/baseEventType';
import { taskQueueType } from '@type/optimisticUpdate/taskQueue';
import LocalStorageManager from 'manager/LocalStorageManager';

import { _devMode } from './ops/opsDuck';
import { resetHrReviewState } from './hrReviewDuck';
import {
  resetBeatReviewState,
  patchBeatPostprocessRequested,
} from './beatReviewDuck';
import {
  enqueueRequest,
  schedulingGetEventDetail,
  selectFilterTaskOfGetTimeEvent,
  selectFilterTaskOfPostTimeEvent,
  selectValidation,
} from './taskQueueDuck';

// Selector
// Actions
// etc function
// Reducer
// Action Creators
// Saga functions
// Saga

const rfdcClone = rfdc();
const DEBUGGING_LOG_ECG_RAW = 0;
const {
  POST_TIME_EVENT: { action: POST_TIME_EVENT },
  GET_TIME_EVENTS_LIST: { action: GET_TIME_EVENTS_LIST },
  GET_EVENT_DETAIL: { action: GET_EVENT_DETAIL },
  POST_PROCESS_EDITED_TIME_EVENT: { action: POST_PROCESS_EDITED_TIME_EVENT },
} = taskQueueType;
const storedThirtySecAmplitudeRate = LocalStorageManager.getItem(
  LocalStorageKey.LAST_VIEWED_TID_STATE
)?.thirtySecAmplitudeRate;

// Selector
export const selectRawBlob = (state) => state.testResultReducer.rawBlob;
export const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;
export const selectReportId = (state) =>
  state.testResultReducer.ecgTest.data?.latestReport?.rid;
export const selectRecordingTime = (state) =>
  state.testResultReducer.recordingTime;
export const selectSideTabValue = (state) =>
  state.testResultReducer.eventReview.sidePanelState.tabValue;
export const selectSelectedEventList = (state) =>
  state.testResultReducer.eventReview.sidePanelState.selectedEventList;

export const selectSelectedEventDetail = (state) =>
  state.testResultReducer.eventDetail.data;
export const selectEventDetailFetchingRetryTimes = (state) =>
  state.testResultReducer.eventDetail.retryTimes;
export const selectEventDetailFetchingCondition = (state) =>
  state.testResultReducer.eventDetail.fetchingCondition;
export const selectEventReviewSortOrder = (state) =>
  state.testResultReducer.eventReview.sortOrder;
export const selectSelectionStrip = (state) =>
  state.testResultReducer.eventReview.selectionStrip;
const selectEcgTestStatus = (state) =>
  state.testResultReducer.ecgTest.data?.ecgTestStatus;
const selectCloudStatus = (state) =>
  state.testResultReducer.ecgTest.data?.cloudStatus;
export const selectIsRawDataOnly = (state) =>
  getIsRawDataOnly({
    isRawDataOnly: state.testResultReducer.ecgTest.data?.isRawDataOnly,
    isRawDataReady: state.testResultReducer.ecgTest.data?.isRawDataReady,
    ecgTestStatus: selectEcgTestStatus(state),
    cloudStatus: selectCloudStatus(state),
  });
export const selectEcgRawList = (state) =>
  state.testResultReducer.ecgRaw.ecgRawList;
const getTenSecStrip = (state) =>
  state.testResultReducer.eventReview.tenSecStrip;
export const selectBeatsNEctopicList = (state) =>
  state.testResultReducer.beatsNEctopicList.data;
/** @returns {import('constant/ReportConst').ReportEventEditorState} ReportEventEditor 모듈의 Global State */
export const selectReportEventEditorState = (state) =>
  state.testResultReducer.eventReview.reportEventEditor;
export const selectReportEventEditorStep = (state) =>
  state.testResultReducer.eventReview.reportEventEditor.editorStep;
/** 리포트 담기 중 대표 Strip 선택 가능 상황 */
export const selectIsSelectableRepresentativeStrip = (state) =>
  selectReportEventEditorState(state).editorStep ===
  REPORT_EVENT_EDITOR_STEP_MAP.TITLE;
/** Events 텝에서 전체 이벤트 조회 가능 상황 */
export const selectIsSelectableChart = (state) =>
  state.testResultReducer.eventReview.sidePanelState.tabValue ===
    EVENT_GROUP_TYPE.EVENTS &&
  selectReportEventEditorState(state).editorStep ===
    REPORT_EVENT_EDITOR_STEP_MAP.CANCEL;
/** 선택된 Event Marker 의 Highlight 에서 Context Menu 가 열린 상황 */
export const selectIsWholeUnMark = (state) =>
  Boolean(
    state.testResultReducer.eventReview.isOpenArrhythmiaContextmenu &&
      selectSelectedEventList(state).length === 1 &&
      !state.testResultReducer.eventDetail.pending &&
      state.testResultReducer.eventDetail.data
  );
export const selectTimeEventList = (state, eventType) =>
  state.testResultReducer.timeEventsList.data.filter((value) => {
    if (EVENT_CONST_TYPES_VALUE_LIST.includes(eventType)) {
      return value.type === eventType;
    } else if (eventType === undefined) {
      return true;
    }
    return false;
  });
export const selectThirtySecAmplitudeRate = (state) =>
  state.testResultReducer.eventReview.thirtySecAmplitudeRate;
export const selectEventDetail = (state) => state.testResultReducer.eventDetail;

// Actions
// Global State Actions
const INITIALIZE = 'memo-web/test-result/INITIALIZE';
// EntireEcgFragment Data
const SET_NAVIGATOR_TIMESTAMP =
  'memo-web/test-result/event/SET_NAVIGATOR_TIMESTAMP';
const SET_HR_HIGHLIGHT_TIMESTAMP =
  'memo-web/test-result/event/SET_HR_HIGHLIGHT';
// Manage Side Panel State
const SET_SIDE_PANEL_TAB_VALUE =
  'memo-web/test-result/event/SET_SIDE_PANEL_TAB_VALUE';
const SET_SIDE_PANEL_SELECTED_VALUE_LIST =
  'memo-web/test-result/event/SET_SIDE_PANEL_SELECTED_VALUE_LIST';
// Representative Report Strip State Management
const SET_REPRESENTATIVE_STRIP_INFO =
  'memo-web/test-result/event/SET_REPRESENTATIVE_STRIP_INFO';
const RESET_REPRESENTATIVE_STRIP_INFO =
  'memo-web/test-result/event/RESET_REPRESENTATIVE_STRIP_INFO';
const SET_CHART_SELECTED_STRIP =
  'memo-web/test-result/SET_CHART_SELECTED_STRIP';
// Report Event Editor Management
const SET_REPORT_EVENT_EDITOR_START =
  'memo-web/test-result/SET_REPORT_EVENT_EDITOR_START';
const SET_REPORT_EVENT_EDITOR_CLOSE =
  'memo-web/test-result/SET_REPORT_EVENT_EDITOR_CLOSE';
const SET_REPORT_EVENT_EDITOR_NEW_STATE =
  'memo-web/test-result/SET_REPORT_EVENT_EDITOR_NEW_STATE';
const SET_BASIC_LEAD_OFF = 'memo-web/test-result/SET_BASIC_LEAD_OFF';
const SET_SORT_ORDER = 'memo-web/test-result/SET_SORT_ORDER';
const SET_THIRTY_SEC_AMPLITUDE_RATE =
  'memo-web/test-result/SET_THIRTY_SEC_AMPLITUDE_RATE';
const SET_RAW_BLOB = 'memo-web/test-result/SET_RAW_BLOB';

// Server State Actions
// Get ECG Test
const GET_ECG_TEST_REQUESTED = 'memo-web/test-result/GET_ECG_TEST_REQUESTED';
const GET_ECG_TEST_SUCCEED = 'memo-web/test-result/GET_ECG_TEST_SUCCEED';
const GET_ECG_TEST_FAILED = 'memo-web/test-result/GET_ECG_TEST_FAILED';
// Patch ECG Test
const PATCH_ECG_TEST_REQUESTED =
  'memo-web/test-result/PATCH_ECG_TEST_REQUESTED';
const PATCH_ECG_TEST_SUCCEED = 'memo-web/test-result/PATCH_ECG_TEST_SUCCEED';
const PATCH_ECG_TEST_FAILED = 'memo-web/test-result/PATCH_ECG_TEST_FAILED';
// Get single events
const SET_EVENT_DETAIL_FETCHING_RETRY_TIMES =
  'memo-web/test-result/SET_EVENT_DETAIL_FETCHING_RETRY_COUNT';
const GET_EVENT_DETAIL_RETRY_REQUESTED =
  'memo-web/test-result/GET_EVENT_DETAIL_RETRY_REQUESTED';
const GET_EVENT_DETAIL_REQUESTED =
  'memo-web/test-result/GET_EVENT_DETAIL_REQUESTED';
const GET_EVENT_DETAIL_SUCCEED =
  'memo-web/test-result/GET_EVENT_DETAIL_SUCCEED';
const GET_EVENT_DETAIL_FAILED = 'memo-web/test-result/GET_EVENT_DETAIL_FAILED';
const SET_EVENT_DETAIL_DATA_INIT =
  'memo-web/test-result/SET_EVENT_DETAIL_DATA_INIT';
const SET_EVENT_DETAIL_EDITED = 'memo-web/test-result/SET_EVENT_DETAIL_EDITED';
const SET_EVENT_DETAIL_PEND_EDIT =
  'memo-web/test-result/SET_EVENT_DETAIL_PEND_EDIT';
// Get & Post & Update & Delete report events
const GET_REPORT_EVENTS_REQUESTED =
  'memo-web/test-result/GET_REPORT_EVENTS_REQUESTED';
const GET_REPORT_EVENTS_SUCCEED =
  'memo-web/test-result/GET_REPORT_EVENTS_SUCCEED';
const GET_REPORT_EVENTS_FAILED =
  'memo-web/test-result/GET_REPORT_EVENTS_FAILED';
const POST_REPORT_EVENT_REQUESTED =
  'memo-web/test-result/POST_REPORT_EVENT_REQUESTED';
const POST_REPORT_EVENT_SUCCEED =
  'memo-web/test-result/POST_REPORT_EVENT_SUCCEED';
const POST_REPORT_EVENT_FAILED =
  'memo-web/test-result/POST_REPORT_EVENT_FAILED';
const UPDATE_REPORT_EVENT_REQUESTED =
  'memo-web/test-result/UPDATE_REPORT_EVENT_REQUESTED';
const UPDATE_REPORT_EVENT_SUCCEED =
  'memo-web/test-result/UPDATE_REPORT_EVENT_SUCCEED';
const UPDATE_REPORT_EVENT_FAILED =
  'memo-web/test-result/UPDATE_REPORT_EVENT_FAILED';
const DELETE_REPORT_EVENT_REQUESTED =
  'memo-web/test-result/DELETE_REPORT_EVENT_REQUESTED';
const DELETE_REPORT_EVENT_SUCCEED =
  'memo-web/test-result/DELETE_REPORT_EVENT_SUCCEED';
const DELETE_REPORT_EVENT_FAILED =
  'memo-web/test-result/DELETE_REPORT_EVENT_FAILED';
const GET_NEXT_REPORT_EVENT = 'memo-web/test-result/GET_NEXT_REPORT_EVENT';

// PTE Report Info Update
const UPDATE_PTE_REPORT_INFO_REQUESTED =
  'memo-web/test-result/UPDATE_PTE_REPORT_INFO_REQUESTED';
const UPDATE_PTE_REPORT_INFO_SUCCEED =
  'memo-web/test-result/UPDATE_PTE_REPORT_INFO_SUCCEED';
const UPDATE_PTE_REPORT_INFO_FAILED =
  'memo-web/test-result/UPDATE_PTE_REPORT_INFO_FAILED';

// Get Findings Template
const GET_FINDINGS_TEMPLATE_REQUESTED =
  'memo-web/test-result/GET_FINDINGS_TEMPLATE_REQUESTED';
const GET_FINDINGS_TEMPLATE_SUCCEED =
  'memo-web/test-result/GET_FINDINGS_TEMPLATE_SUCCEED';
const GET_FINDINGS_TEMPLATE_FAILED =
  'memo-web/test-result/GET_FINDINGS_TEMPLATE_FAILED';

// GET ECG Statistics data
const GET_ECGS_STATISTICS_REQUESTED =
  'memo-web/test-result/GET_ECGS_STATISTICS_REQUESTED';
const GET_ECGS_STATISTICS_SUCCEED =
  'memo-web/test-result/GET_ECGS_STATISTICS_SUCCEED';
const GET_ECGS_STATISTICS_FAILED =
  'memo-web/test-result/GET_ECGS_STATISTICS_FAILED';

// GET Report Statistics data
const GET_REPORTS_STATISTICS_REQUESTED =
  'memo-web/test-result/GET_REPORTS_STATISTICS_REQUESTED';
const GET_REPORTS_STATISTICS_SUCCEED =
  'memo-web/test-result/GET_REPORTS_STATISTICS_SUCCEED';
const GET_REPORTS_STATISTICS_FAILED =
  'memo-web/test-result/GET_REPORTS_STATISTICS_FAILED';

// Get All Statistics data
const GET_BOTH_STATISTICS_DELEGATED =
  'memo-web/test-result/GET_BOTH_STATISTICS_DELEGATED';
const GET_ALL_STATISTICS_REQUESTED =
  'memo-web/test-result/GET_ALL_STATISTICS_REQUESTED';
const GET_ALL_STATISTICS_SUCCEED =
  'memo-web/test-result/GET_ALL_STATISTICS_SUCCEED';
const GET_ALL_STATISTICS_FAILED =
  'memo-web/test-result/GET_ALL_STATISTICS_FAILED';

// Adjust Statistics Count
const ADJUST_ECGS_STATISTICS = 'memo-web/test-result/ADJUST_ECGS_STATISTICS';
const ADJUST_REPORTS_STATISTICS =
  'memo-web/test-result/ADJUST_REPORTS_STATISTICS';

// Get entire events
const GET_TIME_EVENTS_LIST_REQUESTED =
  'memo-web/test-result/GET_TIME_EVENTS_LIST_REQUESTED';
const GET_TIME_EVENTS_LIST_SUCCEED =
  'memo-web/test-result/GET_TIME_EVENTS_LIST_SUCCEED';
const GET_TIME_EVENTS_LIST_FAILED =
  'memo-web/test-result/GET_TIME_EVENTS_LIST_FAILED';
const GET_BEATS_N_ECTOPIC_LIST_REQUESTED =
  'memo-web/test-result/GET_BEATS_N_ECTOPIC_LIST_REQUESTED';
const GET_BEATS_N_ECTOPIC_LIST_SUCCEED =
  'memo-web/test-result/GET_BEATS_N_ECTOPIC_LIST_SUCCEED';
const GET_BEATS_N_ECTOPIC_LIST_FAILED =
  'memo-web/test-result/GET_BEATS_N_ECTOPIC_LIST_FAILED';

// Get daily heart rate
const GET_DAILY_HEART_RATE_REQUESTED =
  'memo-web/test-result/GET_DAILY_HEART_RATE_REQUESTED';
const GET_DAILY_HEART_RATE_SUCCEED =
  'memo-web/test-result/GET_DAILY_HEART_RATE_SUCCEED';
const GET_DAILY_HEART_RATE_FAILED =
  'memo-web/test-result/GET_DAILY_HEART_RATE_FAILED';
const SET_PATIENT_TRIGGERED_EVENT_LIST =
  'memo-web/test-result/SET_PATIENT_TRIGGERED_EVENT_LIST';

// Get ecgRaw data
const GET_ECGRAW_INIT_REQUESTED =
  'memo-web/test-result/GET_ECGRAW_INIT_REQUESTED';
const GET_ECGRAW_BACKWARD_REQUESTED =
  'memo-web/test-result/GET_ECGRAW_BACKWARD_REQUESTED';
const GET_ECGRAW_FORWARD_REQUESTED =
  'memo-web/test-result/GET_ECGRAW_FORWARD_REQUESTED';
const GET_ECGRAW_REQUESTED = 'memo-web/test-result/GET_ECGRAW_REQUESTED';
const GET_ECGRAW_INIT_SUCCEED = 'memo-web/test-result/GET_ECGRAW_INIT_SUCCEED';
const GET_ECGRAW_SUCCEED = 'memo-web/test-result/GET_ECGRAW_SUCCEED';
const GET_ECGRAW_FAILED = 'memo-web/test-result/GET_ECGRAW_FAILED';

// Arrhythmia Edit
const SET_SELECTION_STRIP = 'memo-web/test-result/SET_SELECTION_STRIP';
const SET_TENSEC_STRIP = 'memo-web/test-result/SET_TENSEC_STRIP';
const SET_TENSEC_STRIP_DETAIL = 'memo-web/test-result/SET_TENSEC_STRIP_DETAIL';
const SET_ARRHYTHMIA_CONTEXTMENU =
  'memo-web/test-result/SET_ARRHYTHMIA_CONTEXTMENU';
const SET_BEAT_CONTEXTMENU = 'memo-web/test-result/SET_BEAT_CONTEXTMENU';

// ECG chart list - setting
const SET_ECGCHARTLIST_SCROLL_TOP =
  'memo-web/test-result/SET_ECGCHARTLIST_SCROLL_TOP';
const SET_IS_ECGCHARTLIST_SCROLL_TO_INDEX =
  'memo-web/test-result/SET_IS_ECGCHARTLIST_SCROLL_TO_INDEX';

// post beats
const POST_BEATS_REQUESTED = 'memo-web/event-review/POST_BEATS_REQUESTED';
const POST_BEATS_SUCCEED = 'memo-web/event-review/POST_BEATS_SUCCEED';
const POST_BEATS_FAILED = 'memo-web/event-review/POST_BEATS_FAILED';

// patch beats
const PATCH_BEATS_REQUESTED = 'memo-web/event-review/PATCH_BEATS_REQUESTED';
const PATCH_BEATS_SUCCEED = 'memo-web/event-review/PATCH_BEATS_SUCCEED';
const PATCH_BEATS_FAILED = 'memo-web/event-review/PATCH_BEATS_FAILED';

// delete beats
const DELETE_BEATS_REQUESTED = 'memo-web/event-review/DELETE_BEATS_REQUESTED';
const DELETE_BEATS_SUCCEED = 'memo-web/event-review/DELETE_BEATS_SUCCEED';
const DELETE_BEATS_FAILED = 'memo-web/event-review/DELETE_BEATS_FAILED';

// post time events(post TimeEvent when edit Beat on ContextMenu)
const POST_TIME_EVENT_REQUESTED =
  'memo-web/time-event/POST_TIME_EVENT_REQUESTED';
const POST_TIME_EVENT_SUCCEED = 'memo-web/time-event/POST_TIME_EVENT_SUCCEED';
const POST_TIME_EVENT_FAILED = 'memo-web/time-event/POST_TIME_EVENT_FAILED';

// Request Print Report
const REQUEST_PRINT_REPORT_REQUESTED =
  'memo-web/test-result/REQUEST_PRINT_REPORT_REQUESTED';
const REQUEST_PRINT_REPORT_SUCCEED =
  'memo-web/test-result/REQUEST_PRINT_REPORT_SUCCEED';
const REQUEST_PRINT_REPORT_FAILED =
  'memo-web/test-result/REQUEST_PRINT_REPORT_FAILED';

// Caliper
const SET_CALIPER_PLOT_LINES = 'memo-web/event-review/SET_CALIPER_PLOT_LINES';
const SET_IS_CALIPER_MODE = 'memo-web/event-review/SET_IS_CALIPER_MODE';
const SET_IS_TICK_MARKS_MODE = 'memo-web/event-review/SET_IS_TICK_MARKS_MODE';

// Move position (by current value)
const REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED =
  'memo-web/test-result/REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED';

// Reducer
const initialState = {
  // View Data
  ecgTestId: null,
  recordingTime: { recordingStartMs: null, recordingEndMs: null },
  findingsTemplate: {
    pending: false,
    data: null,
    error: null,
  },
  eventReview: {
    isSetEcgChartListScroll: false,
    thirtySecAmplitudeRate:
      storedThirtySecAmplitudeRate || AMPLITUDE_OPTION.TWENTY_MV.RATE,
    navigatorTimestamp: null, // initValue = recordingTime, 시간이동시 여기에 저장
    hrHighlightTimestamp: null, // hr차트에서 선택한 시간
    ecgCaretTimestamp: null, // 캐럿타임
    /**
     * SidePanel 관련 State
     * tabValue: 선택된 탭 밸류
     * selectedEventList: {type: EVENT_CONST_TYPES, position: Number, timeEventId: Number | null, waveformIndex: Number: null}
     */
    sidePanelState: {
      tabValue: EVENT_GROUP_TYPE.EVENTS,
      selectedEventList: [],
      isUpdateFromChart: false,
    },
    sortOrder: SORT_ORDER,
    /** @type {import('constant/ReportConst').ReportEventEditorState} ReportEventEditor 모듈의 Global State */
    reportEventEditor: {
      // 수정용
      reportEventId: null,
      prevSelectedReportSection: null,
      prevAnnotation: null,
      prevMainRepresentativeInfo: {
        selectedMs: null,
        representativeOnsetIndex: null,
        representativeTerminationIndex: null,
        amplitudeRate: BASIS_AMPLITUDE_RATE, // import { BASIS_AMPLITUDE_RATE } from constant/ReportConst
        isRemoved: true,
      },
      prevSubRepresentativeInfo: {
        selectedMs: null,
        representativeOnsetIndex: null,
        representativeTerminationIndex: null,
        amplitudeRate: BASIS_AMPLITUDE_RATE,
        isRemoved: true,
        reportEventId: null,
      },
      // 수정, 신규
      eventType: null, // import { EVENT_CONST_TYPES } from constant/EventConst
      editorStep: 0, // import { REPORT_EVENT_EDITOR_STEP_MAP } from constant/ReportConst
      selectedReportSection: null,
      selectedStripType: STRIP_TYPE_MAP.MAIN,
      mainRepresentativeInfo: {
        selectedMs: null,
        representativeOnsetIndex: null,
        representativeTerminationIndex: null,
        amplitudeRate: BASIS_AMPLITUDE_RATE, // import { BASIS_AMPLITUDE_RATE } from constant/ReportConst
      },
      subRepresentativeInfo: {
        selectedMs: null,
        representativeOnsetIndex: null,
        representativeTerminationIndex: null,
        amplitudeRate: BASIS_AMPLITUDE_RATE, // import { BASIS_AMPLITUDE_RATE } from constant/ReportConst
        isRemoved: true,
        isMainChanged: false,
      },
      selectedAmplitudeRate: BASIS_AMPLITUDE_RATE, // import { BASIS_AMPLITUDE_RATE } from constant/ReportConst
    },
    // 리포트 담기 및 수정 시 초기 10초 스트립 정보
    representativeStripInfo: {
      selectedMs: null,
      representativeOnsetIndex: null,
      representativeTerminationIndex: null,
    },
    tenSecStripDetail: {
      onsetMs: undefined,
      terminationMs: undefined,
      onsetWaveformIdx: undefined,
      terminationWaveformIdx: undefined,
      hrAvg: undefined,
      ecgRaw: undefined,
      beatLabelButtonDataList: null,
      responseValidationResult: {
        requestAt: null,
        validResult: null,
        editTargetBeatType: null,
      },
      pending: false,
      error: null,
    },
    chartSelectedTimestamp: 0,
    // event edit
    selectionStrip: {
      onset: {
        representativeTimestamp: undefined,
        representativeWaveformIndex: undefined,
        clickedWaveformIndex: undefined,
        clickedTimestamp: undefined,
      },
      termination: {
        representativeTimestamp: undefined,
        representativeWaveformIndex: undefined,
        clickedWaveformIndex: undefined,
        clickedTimestamp: undefined,
      },
    },
    tenSecStrip: {
      representativeCenterTimeStamp: undefined,
      representativeCenterWaveformIndex: undefined,
      centerWaveformIndex: undefined,
      main: {
        type: TEN_SEC_STRIP.TYPE.MAIN,
        position: '',
        representativeTimestamp: undefined,
        onsetWaveformIndex: undefined,
        terminationWaveformIndex: undefined,
      },
      extra: {
        type: TEN_SEC_STRIP.TYPE.EXTRA,
        position: '',
        representativeTimestamp: undefined,
        onsetWaveformIndex: undefined,
        terminationWaveformIndex: undefined,
      },
    },
    isOpenArrhythmiaContextmenu: false,
    isOpenBeatContextmenu: false,
    ecgChartListScrollTop: 0,
  },

  // API Data
  ecgTest: {
    pending: false,
    data: null,
    error: null,
  },
  // todo: jyoon(240522) - [refactor] timeEvents중 leadOff인것과 아닌 데이터가 따로 관리되고 있음.
  timeEventsList: {
    pending: false,
    error: null,
    leadOff: [],
    data: [
      // {
      //   createAt: 0, // 정보 생성 시각의 timestamp === 부정맥 정보 업데이트 시점
      //   type: '',
      //   onsetMs: 0,
      //   onsetWaveformIndex: 0,
      //   terminationMs: 0,
      //   terminationWaveformIndex: 0,
      //   // 이하 API 조회 시 필요한 정보
      //   timeEventId: '',
      //   onsetRPeakIndex: 0,
      //   position: 0,
      // },
    ],
  },
  beatsNEctopicList: {
    pending: false,
    error: null,
    data: {},
  },
  // 10s strip detail - beat data
  beats: {
    pending: false,
    data: {
      waveformIndex: [],
      beatType: [],
      hr: [],
      bpm: null,
      position: [],
    },
    error: null,
  },
  //  10s strip detail: 선택된 비트 차트 (hr 리뷰 오른쪽 데이터들 idx, 10sec Strip...) Req.3번 방식
  beatsTenSecStripDetail: {
    pending: false,
    data: {
      baselineWaveformIndex: 0,
      ecgData: [],
      beatTypeZones: [],
      beats: {},
      duration: null,
      hrAvg: null,
      hrMax: null,
      hrMin: null,
      onsetMs: null,
      terminationMs: null,
      onsetWaveformIndex: null,
      terminationWaveformIndex: null,
      beatRenderData: null,
      beatBtnRenderData: null,
    },
    error: null,
  },
  hrv: {
    pending: false,
    data: {
      heartRatePoints: [],
      hourlyBands: [],
    },
    error: null,
  },
  /** @type {Array<import('component/hook/useGetPatientEvents').PatientTriggeredEventInfo>} Patient Triggered Events 목록, HRV 데이터 생성과정 중 함께 생성 */
  patientEvents: [
    // {
    //   position: 0,
    //   eventTimestamp: 1652353692000,
    //   eventBy: 1,
    //   eventType: 10,
    //   eventEntryType: 1,
    //   eventEtcNote: null,
    //   patientNote: null,
    //   triggerStartTimestamp: 1652353650000,
    //   triggerEndTimestamp: 1652353740000,
    // },
  ],
  // timeEvent, ectopicEvent 를 아우르는 구성으로 변경?
  updateTimeEvent: {
    // time Events api (AF, Pause, Others, AV_Block)
    pending: false,
    data: {},
    error: null,
  },
  ectopicEvent: {
    // ectopic Events api (iso, couplet , VT, VPC, ...)
    // 계산을 클라이언트에서 할지, 서버에서 계산해서 받을지?
    pending: false,
    data: {},
    error: null,
  },
  eventDetail: {
    pending: false,
    data: null,
    error: null,
    isEventEdited: false,
    pendingEdit: false,
    retryTimes: null, // event detail fetching retry Times
    fetchingCondition: null,
  },
  reportDetail: {
    pending: false,
    /** @type {import('redux/container/fragment/test-result/side-panel/ReportEventEditorFragmentContainer').ReportEvent} */
    data: null,
    error: null,
  },
  reportEvents: {
    pending: false,
    data: null,
    error: null,
  },
  reportState: {
    pending: false,
    data: null,
    error: null,
  },
  ecgStatistics: {
    updatedAt: null,
    pending: false,
    data: null,
    error: null,
  },
  reportStatistics: {
    updatedAt: null,
    pending: false,
    data: null,
    error: null,
  },
  allStatistics: {
    updatedAt: null,
    pending: false,
    data: null,
    error: null,
  },
  ecgRaw: {
    // pending
    pending: [],
    backwardPending: false,
    forwardPending: false,

    // direction
    isBackward: false,
    isForward: false,

    // data list
    ecgRawList: [],
    ecgRawSection: {
      beginOnset: undefined,
      endTermination: undefined,
    },
    initExtraSelection: {
      backward: { onset: undefined, termination: undefined },
      forward: { onset: undefined, termination: undefined },
    },

    // option
    isJumpToTime: undefined,
    navigatorTime: undefined,
    caretTime: undefined,
    scrollType: undefined,
    prependDataLength: undefined,
    withBeat: undefined,
    isBeatStrip: undefined,
    error: null,
  },
  caliper: {
    caliperPlotLines: [],
    isCaliperMode: false,
    isTickMarksMode: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE:
      if (action.targetSection) {
        return {
          ...state,
          [action.targetSection]: initialState[action.targetSection],
        };
      } else {
        return {
          ...initialState,
          ecgTestId: action.ecgTestId,
        };
      }
    // EntireEcgFragment Data
    case SET_NAVIGATOR_TIMESTAMP:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          navigatorTimestamp: action.timestamp,
          ecgCaretTimestamp: action.timestamp,
          isSetEcgChartListScroll: false,
        },
      };
    case SET_HR_HIGHLIGHT_TIMESTAMP:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          hrHighlightTimestamp: action.timestamp,
          ecgCaretTimestamp: action.timestamp,
          isSetEcgChartListScroll: false,
        },
      };
    // Handle SidePanel State
    case SET_SIDE_PANEL_TAB_VALUE:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          sidePanelState: {
            ...initialState.eventReview.sidePanelState,
            tabValue: action.tabValue,
          },
          tenSecStrip: {
            ...initialState.eventReview.tenSecStrip,
          },
        },
        eventDetail: {
          ...initialState.eventDetail,
        },
        reportDetail: {
          ...initialState.reportDetail,
        },
      };
    case SET_SIDE_PANEL_SELECTED_VALUE_LIST:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          sidePanelState: {
            ...state.eventReview.sidePanelState,
            selectedEventList: action.newSelectedEventList,
            isUpdateFromChart: action.isFromChart,
          },
        },
      };
    // Representative Strip State Management
    case SET_REPRESENTATIVE_STRIP_INFO:
      let { representativeOnsetIndex, representativeTerminationIndex } =
        action.newInfo;
      const representativeStripLength =
        representativeTerminationIndex - representativeOnsetIndex;
      const lastWaveformIndex = Math.floor(
        (state.recordingTime.recordingEndMs -
          state.recordingTime.recordingStartMs) /
          4
      );
      if (representativeOnsetIndex < 0) {
        representativeOnsetIndex = 1;
        representativeTerminationIndex = representativeStripLength + 1;
      } else if (lastWaveformIndex < representativeTerminationIndex) {
        representativeOnsetIndex =
          lastWaveformIndex - representativeStripLength;
        representativeTerminationIndex = lastWaveformIndex;
      }
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          representativeStripInfo: {
            ...action.newInfo,
            representativeOnsetIndex,
            representativeTerminationIndex,
          },
        },
      };
    case RESET_REPRESENTATIVE_STRIP_INFO:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          representativeStripInfo: {
            ...initialState.eventReview.representativeStripInfo,
          },
        },
      };
    // Report Event Editor Management
    case SET_REPORT_EVENT_EDITOR_START:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          reportEventEditor: {
            ...initialState.eventReview.reportEventEditor,
            editorStep: 1,
            eventType: action.eventType,
            ...action.prevSubState,
          },
        },
      };
    case SET_REPORT_EVENT_EDITOR_CLOSE:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          reportEventEditor: {
            ...initialState.eventReview.reportEventEditor,
          },
        },
      };
    case SET_REPORT_EVENT_EDITOR_NEW_STATE:
      // editorStep 이 2로 변경될 때 selectedRepresentativeInfo.
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          reportEventEditor: {
            ...state.eventReview.reportEventEditor,
            ...action.newState,
          },
        },
      };
    case SET_CHART_SELECTED_STRIP:
      const chartSelectedTimestamp =
        action.selectedTimeMs - (action.selectedTimeMs % 30000);

      if (chartSelectedTimestamp === state.eventReview.chartSelectedTimestamp) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          chartSelectedTimestamp,
        },
      };
    case SET_BASIC_LEAD_OFF:
      return {
        ...state,
        timeEventsList: {
          ...state.timeEventsList,
          leadOff: mergeLeadOffInfo(
            state.timeEventsList.leadOff,
            action.basicLeadOffList
          ),
        },
      };
    case SET_SORT_ORDER:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          sortOrder: {
            ...state.eventReview.sortOrder,
            [action.newSortOrderKey]: action.newSortOrderValue,
          },
        },
      };
    case SET_THIRTY_SEC_AMPLITUDE_RATE: {
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          thirtySecAmplitudeRate: action.amplitudeRate,
        },
      };
    }
    case SET_RAW_BLOB: {
      return {
        ...state,
        rawBlob: action.rawBlob,
      };
    }

    // Get ECG Test
    case GET_ECG_TEST_REQUESTED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: true,
          error: null,
        },
      };
    case GET_ECG_TEST_SUCCEED:
      return {
        ...state,
        recordingTime: {
          recordingStartMs: DateUtil.formatMs(
            action.data.patchecg.startTimestamp
          ),
          recordingEndMs: DateUtil.formatMs(action.data.patchecg.endTimestamp),
        },
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          data: {
            ...action.data,
            latestReport: {
              ...action.data.latestReport,
              analyzedTime: action.data.latestReport?.analyzedTime || 0,
              recordingTime:
                action.data.latestReport?.recordingTime ||
                (action.data.patchecg.endTimestamp -
                  action.data.patchecg.startTimestamp) *
                  1000,
            },
          },
        },
      };
    case GET_ECG_TEST_FAILED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          error: action.error,
        },
      };
    // Patch ECG Test
    case PATCH_ECG_TEST_REQUESTED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: true,
          error: null,
        },
      };
    case PATCH_ECG_TEST_SUCCEED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          data: action.data,
        },
      };
    case PATCH_ECG_TEST_FAILED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          error: action.error,
        },
      };
    // Get event detail
    case SET_EVENT_DETAIL_FETCHING_RETRY_TIMES:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          retryTimes: action.retryTimes,
        },
      };
    case GET_EVENT_DETAIL_RETRY_REQUESTED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          data: null,
          error: null,
          isEventEdited: false,
          pendingEdit: false,
        },
      };
    case GET_EVENT_DETAIL_REQUESTED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
          isEventEdited: false,
          pendingEdit: false,
          fetchingCondition: action,
        },
      };
    case GET_EVENT_DETAIL_SUCCEED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: false,
          data: action.data,
          error: null,
        },
        eventReview: {
          ...state.eventReview,
          sidePanelState: {
            ...state.eventReview.sidePanelState,
            selectedEventList: action.newSelectedEventList,
          },
        },
      };
    case GET_EVENT_DETAIL_FAILED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: false,
          data: null,
          error: action.error,
        },
      };
    case SET_EVENT_DETAIL_DATA_INIT:
      return {
        ...state,
        eventDetail: initialState.eventDetail,
      };
    case SET_EVENT_DETAIL_EDITED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          isEventEdited: true,
        },
      };
    case SET_EVENT_DETAIL_PEND_EDIT:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pendingEdit: action.newPendingEditState,
        },
      };
    // Get Findings Template
    case GET_FINDINGS_TEMPLATE_REQUESTED:
      return {
        ...state,
        findingsTemplate: {
          ...state.findingsTemplate,
          pending: true,
          error: null,
        },
      };
    case GET_FINDINGS_TEMPLATE_SUCCEED:
      return {
        ...state,
        findingsTemplate: {
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case GET_FINDINGS_TEMPLATE_FAILED:
      return {
        ...state,
        findingsTemplate: {
          ...state.findingsTemplate,
          pending: false,
          error: action.error,
        },
      };
    // Get ecgs statistics
    case GET_ECGS_STATISTICS_REQUESTED:
      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          pending: true,
          error: null,
        },
      };
    case GET_ECGS_STATISTICS_SUCCEED:
      return {
        ...state,
        ecgStatistics: {
          updatedAt: Date.now(),
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case GET_ECGS_STATISTICS_FAILED:
      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          pending: false,
          error: action.error,
        },
      };
    // Get reports statistics
    case GET_REPORTS_STATISTICS_REQUESTED:
      return {
        ...state,
        reportStatistics: {
          ...state.reportStatistics,
          pending: true,
          error: null,
        },
      };
    case GET_REPORTS_STATISTICS_SUCCEED:
      return {
        ...state,
        reportStatistics: {
          updatedAt: Date.now(),
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case GET_REPORTS_STATISTICS_FAILED:
      return {
        ...state,
        reportStatistics: {
          ...state.reportStatistics,
          pending: false,
          error: action.error,
        },
      };
    // get all statistics
    case GET_ALL_STATISTICS_REQUESTED:
      return {
        ...state,
        allStatistics: {
          pending: true,
          error: null,
        },
      };
    case GET_ALL_STATISTICS_SUCCEED:
      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          updatedAt: action.updatedAt,
          data: action.data.ecgStatistics,
        },
        reportStatistics: {
          ...state.reportStatistics,
          updatedAt: action.updatedAt,
          data: action.data.reportStatistics,
        },
        allStatistics: {
          updatedAt: action.updatedAt,
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case GET_ALL_STATISTICS_FAILED:
      return {
        ...state,
        allStatistics: {
          pending: false,
          error: action.error,
        },
      };
    // Adjust ecgs statistics
    case ADJUST_ECGS_STATISTICS:
      return {
        ...state,
        ecgStatistics: {
          ...state.ecgStatistics,
          data: {
            ...state.ecgStatistics.data,
            [action.eventSection]:
              state.ecgStatistics.data[action.eventSection] +
              action.adjustValue,
          },
        },
      };
    // Adjust reports statistics
    case ADJUST_REPORTS_STATISTICS:
      return {
        ...state,
        reportStatistics: {
          ...state.reportStatistics,
          data: {
            ...state.reportStatistics.data,
            [action.reportSection]:
              state.reportStatistics.data[action.reportSection] +
              action.adjustValue,
          },
        },
      };
    // GET report events
    case GET_REPORT_EVENTS_REQUESTED:
    case GET_NEXT_REPORT_EVENT:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          pending: true,
          error: null,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
          isEventEdited: false,
        },
      };
    case GET_REPORT_EVENTS_SUCCEED:
      return {
        ...state,
        reportDetail: {
          pending: false,
          data: action.data,
          error: null,
        },
        // ...(() =>
        //   state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
        //     ? {
        //         eventDetail: {
        //           ...state.eventDetail,
        //           pending: false,
        //         },
        //       }
        //     : {})(),
      };
    case GET_REPORT_EVENTS_FAILED:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          pending: false,
          error: action.error,
        },
        ...(() =>
          state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
            ? {
                eventDetail: {
                  ...state.eventDetail,
                  pending: false,
                },
              }
            : {})(),
      };
    // POST report events
    case POST_REPORT_EVENT_REQUESTED:
      return {
        ...state,
        reportEvents: { ...state.reportEvents, pending: true, error: null },
        // API 응답전 선반영
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          data: {
            ...state.eventDetail.data,
            registeredReport: [
              ...state.eventDetail.data.registeredReport,
              {
                ...action.newReportEventInfo,
                reportEventId: null,
              },
            ],
          },
        },
      };
    case POST_REPORT_EVENT_SUCCEED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
          data: action.data,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: false,
          data: {
            ...state.eventDetail.data,
            registeredReport: [
              // reportEventId가 null이 아닌 항목만 필터링
              ...state.eventDetail.data.registeredReport.filter(
                (value) => value.reportEventId !== null
              ),
              {
                ...action.data,
              },
            ],
          },
        },
      };
    case POST_REPORT_EVENT_FAILED:
      return {
        ...state,
        reportEvents: {
          ...state.eventDetail,
          pending: false,
          error: action.error,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: false,
          data: {
            ...state.eventDetail.data,
            registeredReport: [
              ...(state.eventDetail.data?.registeredReport || []).filter(
                (value) => value.reportEventId !== null
              ),
            ],
          },
        },
      };
    // update report event
    case UPDATE_REPORT_EVENT_REQUESTED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: true,
        },
        // API 응답전 선반영
        ...(() => {
          if (!action.isPreUpdate) return {};

          if (
            state.eventReview.sidePanelState.tabValue ===
            EVENT_GROUP_TYPE.EVENTS
          ) {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: true,
                data: {
                  ...state.eventDetail.data,
                  registeredReport: state.eventDetail.data.registeredReport.map(
                    (value) =>
                      value.reportEventId !== action.reportEventId
                        ? { ...value }
                        : {
                            ...value,
                            ...action.newReportEventInfo,
                          }
                  ),
                },
              },
            };
          } else {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: true,
              },
              reportDetail: {
                ...state.reportDetail,
                pending: true,
                data: {
                  ...state.reportDetail.data,
                  ...action.newReportEventInfo,
                },
              },
            };
          }
        })(),
      };
    case UPDATE_REPORT_EVENT_SUCCEED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
          data: action.data,
        },
        // API 응답후 선반영 후조치
        ...(() => {
          if (!action.isPreUpdate) return {};

          if (
            state.eventReview.sidePanelState.tabValue ===
            EVENT_GROUP_TYPE.EVENTS
          ) {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: false,
              },
            };
          } else {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: false,
              },
              reportDetail: {
                ...state.reportDetail,
                pending: false,
              },
            };
          }
        })(),
      };
    case UPDATE_REPORT_EVENT_FAILED:
      return {
        ...state,
        reportEvents: {
          pending: false,
          error: action.error,
        },
        // API 응답후 선반영 후조치
        ...(() => {
          if (!action.isPreUpdate) return {};

          if (
            state.eventReview.sidePanelState.tabValue ===
            EVENT_GROUP_TYPE.EVENTS
          ) {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: false,
              },
            };
          } else {
            return {
              eventDetail: {
                ...state.eventDetail,
                pending: false,
              },
              reportDetail: {
                ...state.reportDetail,
                pending: false,
              },
            };
          }
        })(),
      };
    // delete report event
    case DELETE_REPORT_EVENT_REQUESTED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: true,
        },
        // Side Panel Tab 이 Events 인 경우 API 응답전 선반영
        ...(() =>
          state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
            ? {
                eventDetail: {
                  ...state.eventDetail,
                  pending: true,
                  data: {
                    ...state.eventDetail.data,
                    registeredReport:
                      state.eventDetail.data.registeredReport.filter(
                        (value) => value.reportEventId !== action.reportEventId
                      ),
                  },
                },
              }
            : {})(),
      };
    case DELETE_REPORT_EVENT_SUCCEED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
        },
        // Side Panel Tab 이 Events 인 경우 API 응답후 선반영 후조치
        ...(() =>
          state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
            ? {
                eventDetail: {
                  ...state.eventDetail,
                  pending: false,
                },
              }
            : {})(),
      };
    case DELETE_REPORT_EVENT_FAILED:
      return {
        ...state,
        reportEvents: {
          pending: false,
          error: action.error,
        },
        // Side Panel Tab 이 Events 인 경우 API 응답후 선반영 후조치
        ...(() =>
          state.eventReview.sidePanelState.tabValue === EVENT_GROUP_TYPE.EVENTS
            ? {
                eventDetail: {
                  ...state.eventDetail,
                  pending: false,
                },
              }
            : {})(),
      };
    // PTE Report Info Update
    case UPDATE_PTE_REPORT_INFO_REQUESTED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: true,
          error: null,
        },
        reportDetail: {
          ...state.reportDetail,
          pending: true,
          error: null,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
        },
      };
    case UPDATE_PTE_REPORT_INFO_SUCCEED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
          data: action.payload.data,
        },
      };
    case UPDATE_PTE_REPORT_INFO_FAILED:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
          error: action.payload.error,
        },
        reportDetail: {
          ...state.reportDetail,
          pending: false,
        },
        eventDetail: {
          ...state.eventDetail,
          pending: false,
        },
      };
    // Get entire events
    case GET_TIME_EVENTS_LIST_REQUESTED:
      return {
        ...state,
        timeEventsList: {
          ...state.timeEventsList,
          pending: true,
          error: null,
        },
      };
    case GET_TIME_EVENTS_LIST_SUCCEED:
      return {
        ...state,
        timeEventsList: {
          ...state.timeEventsList,
          pending: false,
          leadOff: action.freshLeadOffList,
          data: action.freshTimeEventList,
        },
      };
    case GET_TIME_EVENTS_LIST_FAILED:
      return {
        ...state,
        timeEventsList: {
          ...state.timeEventsList,
          pending: false,
          error: action.error,
        },
      };
    // get beats, ectopics list
    case GET_BEATS_N_ECTOPIC_LIST_REQUESTED:
      return {
        ...state,
        beatsNEctopicList: {
          ...state.beatsNEctopicList,
          pending: true,
          error: null,
        },
      };
    case GET_BEATS_N_ECTOPIC_LIST_SUCCEED:
      return {
        ...state,
        beatsNEctopicList: {
          ...state.beatsNEctopicList,
          pending: false,
          data: action.freshBeatsNBeatEventsList,
        },
      };
    case GET_BEATS_N_ECTOPIC_LIST_FAILED:
      return {
        ...state,
        beatsNEctopicList: {
          ...state.beatsNEctopicList,
          pending: false,
          error: action.error,
        },
      };
    // Get daily heart rate
    case GET_DAILY_HEART_RATE_REQUESTED:
      return {
        ...state,
        hrv: {
          ...state.hrv,
          pending: true,
          error: null,
        },
      };
    case GET_DAILY_HEART_RATE_SUCCEED:
      return {
        ...state,
        hrv: {
          ...state.hrv,
          pending: false,
          data: action.data,
        },
        patientEvents: action.patientEvents,
      };
    case GET_DAILY_HEART_RATE_FAILED:
      return {
        ...state,
        hrv: {
          ...state.hrv,
          pending: false,
          error: action.error,
        },
      };
    case SET_PATIENT_TRIGGERED_EVENT_LIST:
      return {
        ...state,
        patientEvents: action.payload.patientEvents,
      };
    // Get ecgRaw data
    case GET_ECGRAW_INIT_REQUESTED:
      let initAtTime = action.atTime;
      return {
        ...state,
        ecgRaw: {
          ...state.ecgRaw,
          ecgRawList: action.isInit ? [] : state.ecgRaw.ecgRawList,
          pending: true,
          backwardPending: true,
          forwardPending: true,
          withBeat: action.withBeat,
          isBeatStrip: action.isBeatStrip,
          initAtTime,
          error: null,
        },
        // Beat 정보와 Beat 이벤트 정보 목록 초기화
        beatsNEctopicList: action.isInit
          ? initialState.beatsNEctopicList
          : state.beatsNEctopicList,
      };
    case GET_ECGRAW_BACKWARD_REQUESTED:
    case GET_ECGRAW_FORWARD_REQUESTED:
    case GET_ECGRAW_REQUESTED:
      return {
        ...state,
        ecgRaw: {
          ...state.ecgRaw,
          ecgRawList: action.isInit ? [] : state.ecgRaw.ecgRawList,
          pending: action.isInit ? true : state.ecgRaw.pending,
          backwardPending:
            action.isScroll && action.isBackward
              ? true
              : state.ecgRaw.backwardPending,
          forwardPending:
            action.isScroll && action.isForward
              ? true
              : state.ecgRaw.forwardPending,
          isBackward:
            action.isScroll && action.isBackward
              ? true
              : state.ecgRaw.isBackward,

          isForward: action.isForward ? true : state.ecgRaw.isForward,
          withBeat: action.withBeat,
          isBeatStrip: action.isBeatStrip,
          error: null,
        },
      };
    case GET_ECGRAW_SUCCEED:
      const {
        results,
        action: {
          atTime,
          isBackward,
          isForward,
          isInit,
          isJumpToTime,
          isScroll,
          initAtTimeLocalState,
        },
        newEcgRawList,
      } = action;

      DEBUGGING_LOG_ECG_RAW &&
        console.log('🚨🚨🚨 log 🚨🚨🚨 - GET_ECGRAW_SUCCEED', {
          initAtTimeLocalState: DateUtil.formatDateTime(initAtTimeLocalState),
          'state.ecgRaw.initAtTime': DateUtil.formatDateTime(
            state.ecgRaw.initAtTime
          ),
          isInit,
          resultIndex_0: DateUtil.formatDateTime(results.at(0).onsetMs),
          'resultIndex_-1': DateUtil.formatDateTime(results.at(-1).onsetMs),
        });

      if (initAtTimeLocalState !== state.ecgRaw.initAtTime) {
        console.error('infinity scroll initTime error');
        return { ...state };
      }

      let rawApiResult, scrollType, navigatorTime, caretTime;
      let updatedPending = state.ecgRaw.pending;
      let updatedBackwardPending = state.ecgRaw.backwardPending;
      let updatedForwardPending = state.ecgRaw.forwardPending;

      rawApiResult = results;
      navigatorTime = isInit && atTime;
      caretTime = state.eventReview?.ecgCaretTimestamp || null;

      if (isInit) {
        updatedPending = false;
      }
      if (isScroll && isBackward) {
        scrollType = 'up';
        updatedBackwardPending = false;
      }
      if (isScroll && isForward) {
        scrollType = 'down';
        updatedForwardPending = false;
      }

      DEBUGGING_LOG_ECG_RAW &&
        newEcgRawList.forEach((v) => {
          if (v.onsetMs === results.at(0).onsetMs) {
            console.log('onsetMs: ', DateUtil.formatDateTime(v.onsetMs), '* ');
          } else if (v.onsetMs === state.ecgRaw.initAtTime) {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs),
              '* '
            );
          } else {
            console.log('onsetMs: ', DateUtil.formatDateTime(v.onsetMs));
          }
        });
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          chartSelectedTimestamp: 0,
        },
        ecgRaw: {
          ...state.ecgRaw,
          // pending
          pending: updatedPending,
          backwardPending: isInit
            ? state.ecgRaw.backwardPending
            : updatedBackwardPending,
          forwardPending: isInit
            ? state.ecgRaw.forwardPending
            : updatedForwardPending,
          // data list
          ecgRawList: [...newEcgRawList],
          ecgRawSection: {
            beginOnset: newEcgRawList.at(0).onsetMs,
            endTermination: newEcgRawList.at(-1).onsetMs,
          },
          // fetching ecgRaw data option
          isJumpToTime: isJumpToTime,
          navigatorTime: navigatorTime,
          caretTime: caretTime,
          scrollType,
          prependDataLength: isScroll && isBackward ? rawApiResult.length : 0,
        },
      };
    case GET_ECGRAW_INIT_SUCCEED:
      const {
        action: { backwardListLength, initExtraSelection },
        newEcgRawList: extraInitNewEcgRawList,
      } = action;

      DEBUGGING_LOG_ECG_RAW &&
        extraInitNewEcgRawList.forEach((v) => {
          if (v.onsetMs === initExtraSelection.backward?.onset) {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs),
              '* '
            );
          } else if (v.onsetMs === initExtraSelection.forward?.onset) {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs),
              '* '
            );
          } else if (v.onsetMs === state.ecgRaw.initAtTime) {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs),
              '* '
            );
          } else {
            console.log(
              'extra Init > onsetMs: ',
              DateUtil.formatDateTime(v.onsetMs)
            );
          }
        });

      return {
        ...state,
        ecgRaw: {
          ...state.ecgRaw,
          // pending
          backwardPending: false,
          forwardPending: false,

          // direction
          isBackward: true,
          isForward: true,

          // data list
          ecgRawList: [...extraInitNewEcgRawList],
          ecgRawSection: {
            beginOnset: extraInitNewEcgRawList.at(0)?.onsetMs,
            endTermination: extraInitNewEcgRawList.at(-1)?.onsetMs,
          },
          initExtraSelection: {
            backward: {
              onset: initExtraSelection.backward?.onset,
              termination: initExtraSelection.backward?.termination,
            },
            forward: {
              onset: initExtraSelection.forward?.onset,
              termination: initExtraSelection.forward?.onset,
            },
          },

          // option
          prependDataLength: backwardListLength,
        },
      };

    case GET_ECGRAW_FAILED:
      return {
        ...state,
        ecgRaw: {
          ...state.ecgRaw,
          pending: false,
          backwardPending: false,
          forwardPending: false,
          error: action.error,
        },
      };
    // POST Beats - 비트추가
    case POST_BEATS_REQUESTED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: true,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case POST_BEATS_SUCCEED:
      let newBeatLabelButtonDataListAfterPostBeats;
      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.eventReview.tenSecStripDetail;
        if (
          Array.isArray(apiResResult.waveformIndex) &&
          apiResResult.waveformIndex.length > 0
        ) {
          const editTargetWaveformIndex =
            apiResResult.waveformIndex[0] - onsetWaveformIdx;
          const editTargetBeatType = apiResResult.beatType[0];
          const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
            (v) => v.xAxisPoint > editTargetWaveformIndex
          );
          beatLabelButtonDataList.splice(nextIndexOfAddBeat, 0, {
            xAxisPoint: editTargetWaveformIndex,
            beatType: editTargetBeatType,
            title: TEN_SEC_STRIP_EDIT.BEAT_TYPE[editTargetBeatType],
            color: TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[editTargetBeatType],
            isSelected: false,
            isEventReview: '',
          });
        }
        newBeatLabelButtonDataListAfterPostBeats = beatLabelButtonDataList;
      })();

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            beatLabelButtonDataList: [
              ...newBeatLabelButtonDataListAfterPostBeats,
            ],
            responseValidationResult: action.responseValidationResult,
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case POST_BEATS_FAILED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: false,
            error: action.error,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    // PATCH Beats
    case PATCH_BEATS_REQUESTED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: true,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case PATCH_BEATS_SUCCEED:
      // 업데이트 성공시 get 재호출
      // 10s strip detail에서 업데이트 하는 경우만
      let newBeatLabelButtonDataListAfterPatchBeats;
      let updateBeatLabelButtonDataList = false;

      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;

        if (tabType === TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU) return;

        // 10s strip button 업데이트
        updateBeatLabelButtonDataList = true;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.eventReview.tenSecStripDetail;

        for (let i in apiResResult.waveformIndex) {
          newBeatLabelButtonDataListAfterPatchBeats =
            beatLabelButtonDataList.map((v) => {
              if (
                v.xAxisPoint ===
                apiResResult.waveformIndex[i] - onsetWaveformIdx
              ) {
                v.isSelected = false;
                v.beatType = apiResResult.beatType[i];
                v.title = TEN_SEC_STRIP_EDIT.BEAT_TYPE[v.beatType];
                v.color = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[v.beatType];
                return v;
              }
              return v;
            });
        }
      })();

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            beatLabelButtonDataList: updateBeatLabelButtonDataList
              ? [...newBeatLabelButtonDataListAfterPatchBeats]
              : state.eventReview.tenSecStripDetail.beatLabelButtonDataList,
            responseValidationResult: action.responseValidationResult,
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case PATCH_BEATS_FAILED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    // DELETE Beats
    case DELETE_BEATS_REQUESTED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: true,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case DELETE_BEATS_SUCCEED:
      let newBeatLabelButtonDataListAfterDeleteBeats;

      (function () {
        const { reqBody } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.eventReview.tenSecStripDetail;

        const editTargetWaveformIndexList = reqBody.waveformIndexes.map(
          (v) => v - onsetWaveformIdx
        );
        const filteredBeatLabelButtonDataList = beatLabelButtonDataList.filter(
          (beatLabelButtonData) =>
            !editTargetWaveformIndexList.includes(
              beatLabelButtonData.xAxisPoint
            )
        );
        newBeatLabelButtonDataListAfterDeleteBeats =
          filteredBeatLabelButtonDataList;
      })();

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            beatLabelButtonDataList: [
              ...newBeatLabelButtonDataListAfterDeleteBeats,
            ],
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case DELETE_BEATS_FAILED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            pending: false,
            error: null,
          },
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    // Edit feature
    case SET_SELECTION_STRIP:
      const selectionStrip = action.selectionStrip;

      let newSelectionStrip = {};
      let newSelectionStripState;

      // eslint-disable-next-line default-case
      switch (selectionStrip.selectionMarkerType) {
        case SELECTION_MARKER_TYPE.ONSET:
          newSelectionStrip = {
            onset: {
              representativeTimestamp: selectionStrip.representativeTimestamp,
              representativeWaveformIndex:
                selectionStrip.representativeWaveformIndex,
              clickedTimestamp:
                selectionStrip.representativeTimestamp +
                selectionStrip.clickedWaveformIndex * 4,
              clickedWaveformIndex: selectionStrip.clickedWaveformIndex,
            },
            termination: {
              representativeTimestamp: undefined,
              representativeWaveformIndex: undefined,
              clickedTimestamp: undefined,
              clickedWaveformIndex: undefined,
            },
          };

          newSelectionStripState = {
            ...newSelectionStrip,
          };

          return {
            ...state,
            eventReview: {
              ...state.eventReview,
              selectionStrip: { ...newSelectionStripState },
              tenSecStrip: {
                ...state.eventReview.tenSecStrip,
                representativeCenterTimeStamp:
                  selectionStrip.representativeTimestamp,
                representativeCenterWaveformIndex:
                  selectionStrip.representativeWaveformIndex,
                centerWaveformIndex: selectionStrip.clickedWaveformIndex,
              },
            },
            /**
             * # SET_SELECTION_STRIP action에서 onset selection 변경시 "isEventEdited: false 목적"
             *   - 상황
             *      : event review > 10 strip에서 비트 편집 이후
             *        사이드 패널 이벤트 정보가 "EditedEvent" component로 변경이 됨.
             *   - 목적
             *      : 다시 30s strip에서 onset selection 변경시 선택한
             *        이벤트 정보 초기화를 위한 조치
             */
            eventDetail: {
              ...state.eventDetail,
              isEventEdited: false,
            },
          };
        case SELECTION_MARKER_TYPE.TERMINATION:
          newSelectionStrip.termination = {
            representativeTimestamp: selectionStrip.representativeTimestamp,
            representativeWaveformIndex:
              selectionStrip.representativeWaveformIndex,
            clickedTimestamp:
              selectionStrip.representativeTimestamp +
              selectionStrip.clickedWaveformIndex * 4,
            clickedWaveformIndex: selectionStrip.clickedWaveformIndex,
          };

          newSelectionStripState = {
            ...state.eventReview.selectionStrip,
            ...newSelectionStrip,
          };

          let isSwapCondition, isSwapCurrTerminationWithPrevTermination;

          const {
            onset: {
              representativeTimestamp: onsetRepresentativeTimestamp,
              clickedWaveformIndex: onsetWaveformIndex,
            },
            termination: {
              representativeTimestamp: terminationRepresentativeTimestamp,
              clickedWaveformIndex: terminationWaveformIndex,
            },
          } = newSelectionStripState;

          if (
            (onsetRepresentativeTimestamp ===
              terminationRepresentativeTimestamp &&
              onsetWaveformIndex > terminationWaveformIndex) || // single line
            onsetRepresentativeTimestamp > terminationRepresentativeTimestamp // multi line
          ) {
            const swap = newSelectionStripState.termination;
            newSelectionStripState.termination = newSelectionStripState.onset;
            newSelectionStripState.onset = swap;
          }

          if (
            state.eventReview.selectionStrip.termination
              .representativeWaveformIndex !== undefined &&
            newSelectionStrip.termination.representativeWaveformIndex +
              newSelectionStrip.termination.clickedWaveformIndex <
              state.eventReview.selectionStrip.onset
                .representativeWaveformIndex +
                state.eventReview.selectionStrip.onset.clickedWaveformIndex
          ) {
            newSelectionStripState.termination =
              state.eventReview.selectionStrip.termination;
          }

          // onset이 termination보다 클때
          isSwapCondition = () => {
            return (
              (onsetRepresentativeTimestamp ===
                terminationRepresentativeTimestamp &&
                onsetWaveformIndex > terminationWaveformIndex) || // single line
              onsetRepresentativeTimestamp > terminationRepresentativeTimestamp // multi line
            );
          };
          // onset, termination이 이미 모두 있을때, shift+click으로 termination을 update시켜줄때 onset 보다 작은 시점을 클릭 했을때
          //  => 이전의 termination을 현재 termination으로 update
          isSwapCurrTerminationWithPrevTermination = () => {
            return (
              state.eventReview.selectionStrip.termination
                .representativeWaveformIndex !== undefined &&
              newSelectionStrip.termination.representativeWaveformIndex +
                newSelectionStrip.termination.clickedWaveformIndex <
                state.eventReview.selectionStrip.onset
                  .representativeWaveformIndex +
                  state.eventReview.selectionStrip.onset.clickedWaveformIndex
            );
          };

          break;
        case SELECTION_MARKER_TYPE.RESET:
          newSelectionStripState = initialState.eventReview.selectionStrip;
          break;
      }

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          selectionStrip: { ...newSelectionStripState },
        },
      };
    case SET_TENSEC_STRIP:
      // init - reset
      let newState = {
        representativeCenterTimeStamp:
          action.tenSecStrip.representativeCenterTimeStamp,
        representativeCenterWaveformIndex:
          action.tenSecStrip.representativeCenterWaveformIndex,
        centerWaveformIndex: action.tenSecStrip.centerWaveformIndex,
      };

      const MAIN = action.tenSecStrip[TEN_SEC_STRIP.TYPE.MAIN];
      const EXTRA = action.tenSecStrip[TEN_SEC_STRIP.TYPE.EXTRA];
      const RESET = action.tenSecStrip[TEN_SEC_STRIP.TYPE.RESET];

      if (RESET) {
        return {
          ...state,
          eventReview: {
            ...state.eventReview,
            tenSecStrip: {
              ...initialState.eventReview.tenSecStrip,
            },
          },
        };
      }

      if (MAIN && MAIN.representativeTimestamp !== undefined) {
        Object.assign(newState, {
          main: MAIN,
        });
      }

      if (EXTRA) {
        if (EXTRA.representativeTimestamp !== undefined) {
          Object.assign(newState, {
            extra: EXTRA,
          });
        } else {
          Object.assign(newState, {
            extra: initialState.eventReview.tenSecStrip.extra,
          });
        }
      }

      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStrip: {
            ...state.eventReview.tenSecStrip,
            ...newState,
          },
        },
      };
    case SET_TENSEC_STRIP_DETAIL:
      const { tenSecStripDetail } = action;
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          tenSecStripDetail: {
            ...state.eventReview.tenSecStripDetail,
            ...tenSecStripDetail,
          },
        },
      };
    case SET_ARRHYTHMIA_CONTEXTMENU:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          isOpenArrhythmiaContextmenu:
            !getIsRawDataOnly({
              isRawDataOnly: state.ecgTest.data?.isRawDataOnly,
              isRawDataReady: state.ecgTest.data?.isRawDataReady,
              ecgTestStatus: state.ecgTest.data?.ecgTestStatus,
              cloudStatus: state.ecgTest.data?.cloudStatus,
            }) &&
            state.eventReview.reportEventEditor.editorStep ===
              REPORT_EVENT_EDITOR_STEP_MAP.CANCEL &&
            state.eventReview.sidePanelState.tabValue ===
              EVENT_GROUP_TYPE.EVENTS &&
            action.isOpenArrhythmiaContextmenu,
        },
      };
    // 10s strip detail edit feature
    case SET_BEAT_CONTEXTMENU:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          isOpenBeatContextmenu: action.isOpenBeatContextmenu,
        },
      };
    // post TimeEvent when edit Beat on ContextMenu
    case POST_TIME_EVENT_REQUESTED:
      return {
        ...state,
        updateTimeEvent: {
          pending: true,
          data: {},
          error: null,
        },
      };
    case POST_TIME_EVENT_SUCCEED:
      return {
        ...state,
        updateTimeEvent: {
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case POST_TIME_EVENT_FAILED:
      return {
        ...state,
        updateTimeEvent: {
          pending: false,
          data: {},
          error: action.error,
        },
      };
    // event review tab feature
    case SET_ECGCHARTLIST_SCROLL_TOP:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          ecgChartListScrollTop: action.ecgChartListScrollTop,
          chartSelectedTimestamp: 0,
        },
      };
    // Request Print Report
    // refactor(진현) : 리포트 상태 따로 관리 필요
    case REQUEST_PRINT_REPORT_REQUESTED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: true,
          error: null,
        },
        reportState: {
          ...state.reportState,
          pending: true,
          error: null,
        },
      };
    case REQUEST_PRINT_REPORT_SUCCEED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          data: {
            ...state.ecgTest.data,
            latestReport: {
              ...state.ecgTest.data.latestReport,
              ...action.data,
            },
          },
        },
        reportState: {
          ...state.reportState,
          pending: false,
          data: action.data,
        },
      };
    case REQUEST_PRINT_REPORT_FAILED:
      return {
        ...state,
        ecgTest: {
          ...state.ecgTest,
          pending: false,
          error: action.error,
        },
        reportState: {
          ...state.reportState,
          pending: false,
          error: action.error,
        },
      };
    case SET_CALIPER_PLOT_LINES:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          caliperPlotLines: action.caliperPlotLines,
        },
      };
    case SET_IS_CALIPER_MODE:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isCaliperMode: action.isCaliperMode,
        },
      };
    case SET_IS_TICK_MARKS_MODE:
      return {
        ...state,
        caliper: {
          ...state.caliper,
          isTickMarksMode: action.isTickMarksMode,
        },
      };
    case REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED:
      return {
        ...state,
        eventReview: {
          ...state.eventReview,
          sidePanelState: {
            ...state.eventReview.sidePanelState,
            isUpdateFromChart: false,
          },
        },
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
          isEventEdited: false,
          pendingEdit: false,
        },
      };
    default:
      return state;
  }
}

// Action Creators
export function initializeState(ecgTestId, targetSection) {
  if (ecgTestId) {
    return { type: INITIALIZE, ecgTestId, targetSection };
  }
}

// EntireEcgFragment Data
export function setNavigatorTimestamp(timestamp, isInit) {
  return { type: SET_NAVIGATOR_TIMESTAMP, timestamp, isInit };
}
export function setHrHighlight(timestamp) {
  return { type: SET_HR_HIGHLIGHT_TIMESTAMP, timestamp };
}

// Handle sidePanelState
export function setSidePanelTabValue(tabValue) {
  return { type: SET_SIDE_PANEL_TAB_VALUE, tabValue };
}
/**
 * Side Panel 의 선택된 이벤트 정보 배열(`sidePanelState.selectedEventList`)을 업데이트 하는 Action
 *
 * Action 처리 후 Side Panel 의 탭에 따라 조회 API 요청함(`_setSelectedEventListHandler`)
 * @param {{type, position, timeEventId, waveformIndex, nearestBeatWaveformIndexWithSelection}[]} newSelectedEventList
 * @param {Boolean} isFromChart 정보 업데이트가 어디서 발생됐는지 확인용, true 면 차트 스크롤 이동 안함
 * @returns
 */
export function setSidePanelSelectedEventList(
  newSelectedEventList,
  isFromChart = false,
  isOnlySet = false
) {
  return {
    type: SET_SIDE_PANEL_SELECTED_VALUE_LIST,
    newSelectedEventList,
    isFromChart,
    isOnlySet,
  };
}

// Representative Strip State Management
/**
 *
 * @param {{selectedMs: Timestamp, representativeOnsetIndex: WaveformIndex, representativeTerminationIndex: WaveformIndex}} newInfo
 * @returns
 */
export function setRepresentativeStripInfo(newInfo) {
  // stale representative marker remove
  const chartEditInst = ChartUtil.chartEdit();
  chartEditInst.removeSelectionMarkerAll();
  chartEditInst.removeRepresentativeReportStrip();

  return { type: SET_REPRESENTATIVE_STRIP_INFO, newInfo };
}
export function resetRepresentativeStripInfo() {
  // stale representative marker remove
  const chartEditInst = ChartUtil.chartEdit();
  chartEditInst.removeSelectionMarkerAll();
  chartEditInst.removeRepresentativeReportStrip();

  return { type: RESET_REPRESENTATIVE_STRIP_INFO };
}

// Report Event Editor Management
/**
 *
 * @param {EVENT_CONST_TYPES} eventType
 * @param {{reportEventId: String, prevAnnotation: String, selectedReportSection: REPORT_SECTION}?} prevSubState
 * @returns
 */
export function setReportEventEditorStart(eventType, prevSubState) {
  return {
    type: SET_REPORT_EVENT_EDITOR_START,
    eventType,
    prevSubState,
  };
}
export function setReportEventEditorClose() {
  return { type: SET_REPORT_EVENT_EDITOR_CLOSE };
}
export function setReportEventEditorNewState(newState) {
  return { type: SET_REPORT_EVENT_EDITOR_NEW_STATE, newState };
}

// Handle chart selected Strips
export function setChartSelectedStrip(selectedTimeMs) {
  return { type: SET_CHART_SELECTED_STRIP, selectedTimeMs };
}

function setBasicLeadOff(basicLeadOffList) {
  return {
    type: SET_BASIC_LEAD_OFF,
    basicLeadOffList,
  };
}
/**
 *
 * @param {{newSortOrderKey: string, newSortOrderValue: object}} param0
 * @returns
 */
export function setSortOrder({ newSortOrderKey, newSortOrderValue }) {
  return {
    type: SET_SORT_ORDER,
    newSortOrderKey,
    newSortOrderValue,
  };
}

export function setThirtySecAmplitudeRate(amplitudeRate) {
  return {
    type: SET_THIRTY_SEC_AMPLITUDE_RATE,
    amplitudeRate,
  };
}
export function setRawFile(rawBlob) {
  return {
    type: SET_RAW_BLOB,
    rawBlob,
  };
}

// Get ECG Test
export function getEcgTestRequested(isInitialized) {
  return { type: GET_ECG_TEST_REQUESTED, isInitialized };
}
export function getEcgTestSucceed(data) {
  return {
    type: GET_ECG_TEST_SUCCEED,
    data,
  };
}
function getEcgTestFailed(error) {
  return { type: GET_ECG_TEST_FAILED, error };
}

// Patch ECG Test
export function patchEcgTestRequested({ ecgTestId, form, callback }) {
  return { type: PATCH_ECG_TEST_REQUESTED, ecgTestId, form, callback };
}
function patchEcgTestSucceed(data) {
  return {
    type: PATCH_ECG_TEST_SUCCEED,
    data,
  };
}
function patchEcgTestFailed(error) {
  return { type: PATCH_ECG_TEST_FAILED, error };
}

// Get single events
function setEventDetailFetchingRetryTimes(retryTimes) {
  return {
    type: SET_EVENT_DETAIL_FETCHING_RETRY_TIMES,
    retryTimes,
  };
}
export function getEventDetailRetryRequested() {
  return {
    type: GET_EVENT_DETAIL_RETRY_REQUESTED,
  };
}
function getEventDetailRequested({
  eventType,
  searchCondition, // beat event type: 선택한 waveformIndex, time event type: 선택한 이벤트 eventId
  position,
  selectedGlobalMs,
  //
  isSelectedEventUpdate,
  isIgnoreTimeJump,
  //
  releaseRequestedInfoAction,
  isGeminyType,
  inCludedGeminyEvent,
}) {
  return {
    type: GET_EVENT_DETAIL_REQUESTED,
    eventType,
    searchCondition,
    position,
    selectedGlobalMs,
    //
    isSelectedEventUpdate,
    isIgnoreTimeJump,
    //
    releaseRequestedInfoAction,
    isGeminyType,
    inCludedGeminyEvent,
  };
}
function getEventDetailSucceed(data, newSelectedEventList) {
  return {
    type: GET_EVENT_DETAIL_SUCCEED,
    data,
    newSelectedEventList,
  };
}
function getEventDetailFailed(error) {
  return { type: GET_EVENT_DETAIL_FAILED, error };
}
function initEventDetailData() {
  return { type: SET_EVENT_DETAIL_DATA_INIT };
}
function setEventDetailEdited() {
  return { type: SET_EVENT_DETAIL_EDITED };
}
function setEventDetailEditPending(newPendingEditState) {
  return { type: SET_EVENT_DETAIL_PEND_EDIT, newPendingEditState };
}

// Get entire events

/**
 *
 * @param {string | Array[string]} targetTimeEventType
 * @param {{traceId?: string, isWholeUnMark?: boolean, callback?: GeneratorFunction}?} options
 * @returns
 */
export function getTimeEventsListRequested(targetTimeEventType, options) {
  return {
    type: GET_TIME_EVENTS_LIST_REQUESTED,
    targetTimeEventType,
    options,
  };
}

/**
 *
 * @param {*} freshLeadOffList
 * @param {*} freshTimeEventList
 * @returns
 */
export function getTimeEventsListSucceed(freshLeadOffList, freshTimeEventList) {
  return {
    type: GET_TIME_EVENTS_LIST_SUCCEED,
    freshLeadOffList,
    freshTimeEventList,
  };
}
function getTimeEventsListFailed(error) {
  return { type: GET_TIME_EVENTS_LIST_FAILED, error };
}

// /**
//  * Action creator for requesting beats and ectopic list.
//  *
//  * @param {number} onsetWaveformIndex - The onset waveform index.
//  * @param {number} terminationWaveformIndex - The termination waveform index.
//  * @param {Object} [options={}] - Optional parameters.
//  * @param {boolean} [options.isWholeUnMark=false] - Flag indicating whether the whole unmark is needed.
//  * @param {boolean} [options.isOptimisticEventDataUpdate=false] - Flag indicating whether the pre UI update is needed.
//  * @param {Object} [options.optimisticEventDataUpdateOption={}] - The pre UI update options.
//  * @param {string} [options.optimisticEventDataUpdateOption.optimisticEventDataUpdateCase=null] - The pre UI update case.
//  * @param {number} [options.optimisticEventDataUpdateOption.onsetWaveformIndex=null] - The onset waveform index for pre UI update.
//  * @param {number} [options.optimisticEventDataUpdateOption.terminationWaveformIndex=null] - The termination waveform index for pre UI update.
//  * @param {string} [options.optimisticEventDataUpdateOption.beatType=null] - The beat type for pre UI update.
//  * @param {Object} [options.optimisticEventDataUpdateOption.reqBody=null] - The request body for pre UI update.
//  * @param {string} [options.optimisticEventDataUpdateOption.tabType=null] - The tab type for pre UI update.
//  * @returns {Object} The action object.
//  */
export function getBeatsNEctopicListRequested(
  onsetWaveformIndex,
  terminationWaveformIndex,
  options
) {
  return {
    type: GET_BEATS_N_ECTOPIC_LIST_REQUESTED,
    onsetWaveformIndex,
    terminationWaveformIndex,
    options,
  };
}
export function getBeatsNEctopicListSucceed(freshBeatsNBeatEventsList) {
  return {
    type: GET_BEATS_N_ECTOPIC_LIST_SUCCEED,
    freshBeatsNBeatEventsList,
  };
}
function getBeatsNEctopicListFailed(error) {
  return { type: GET_BEATS_N_ECTOPIC_LIST_FAILED, error };
}

// Get daily heart rate
export function getDailyHeartRateRequested({ calcIsNoise }) {
  return { type: GET_DAILY_HEART_RATE_REQUESTED, calcIsNoise };
}
function getDailyHeartRateSucceed(data, patientEvents) {
  return {
    type: GET_DAILY_HEART_RATE_SUCCEED,
    data: data,
    patientEvents,
  };
}
function getDailyHeartRateFailed(error) {
  return { type: GET_DAILY_HEART_RATE_FAILED, error };
}
export function setPatientTriggeredEventList(patientEvents) {
  return { type: SET_PATIENT_TRIGGERED_EVENT_LIST, payload: { patientEvents } };
}

// Get ecgRaw data
export function getEcgRawInitRequested(
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit = true,
  isJumpToTime = false,
  isScroll = false,
  initAtTimeLocalState
) {
  return {
    type: GET_ECGRAW_INIT_REQUESTED,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
    initAtTimeLocalState,
  };
}
export function getEcgRawRequestedBackward(
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit = true,
  isJumpToTime = false,
  isScroll = false,
  initAtTimeLocalState
) {
  return {
    type: GET_ECGRAW_BACKWARD_REQUESTED,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
    initAtTimeLocalState,
  };
}
export function getEcgRawRequestedForward(
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit = true,
  isJumpToTime = false,
  isScroll = false,
  initAtTimeLocalState
) {
  return {
    type: GET_ECGRAW_FORWARD_REQUESTED,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
    initAtTimeLocalState,
  };
}
export function getEcgRawRequested(
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit = true,
  isJumpToTime = false,
  isScroll = false,
  initAtTimeLocalState
) {
  return {
    type: GET_ECGRAW_REQUESTED,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
    initAtTimeLocalState,
  };
}
function getEcgRawSucceed(results, action, newEcgRawList) {
  return {
    type: GET_ECGRAW_SUCCEED,
    results,
    action,
    newEcgRawList,
  };
}
function getEcgRawInitSucceed(results, action, newEcgRawList) {
  return {
    type: GET_ECGRAW_INIT_SUCCEED,
    results,
    action,
    newEcgRawList,
  };
}
function getEcgRawFailed(error) {
  return { type: GET_ECGRAW_FAILED, error };
}

// post beats
export function postBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: POST_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function postBeatsSucceed(data, responseValidationResult) {
  return {
    type: POST_BEATS_SUCCEED,
    data,
    responseValidationResult,
  };
}
function postBeatsFailed(error) {
  return {
    type: POST_BEATS_FAILED,
    error,
  };
}

// patch beats Event
export function patchBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: PATCH_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function patchBeatsSucceed(data, tabType, responseValidationResult) {
  return {
    type: PATCH_BEATS_SUCCEED,
    data,
    tabType,
    responseValidationResult,
  };
}
function patchBeatsFailed(error) {
  return {
    type: PATCH_BEATS_FAILED,
    error,
  };
}
// delete beats Event
export function deleteBeatsRequested(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: DELETE_BEATS_REQUESTED,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
function deleteBeatsSucceed(reqBody) {
  return {
    type: DELETE_BEATS_SUCCEED,
    reqBody,
  };
}
function deleteBeatsFailed(error) {
  return {
    type: DELETE_BEATS_FAILED,
    error,
  };
}
// post Time Event
export function postTimeEventRequested(
  tid,
  onsetWaveformIndex,
  terminationWaveformIndex,
  eventType,
  isRemove
) {
  return {
    type: POST_TIME_EVENT_REQUESTED,
    tid,
    onsetWaveformIndex,
    terminationWaveformIndex,
    eventType,
    isRemove,
  };
}
export function postTimeEventSucceed(data) {
  return { type: POST_TIME_EVENT_SUCCEED, data };
}
function postTimeEventFailed(error) {
  return { type: POST_TIME_EVENT_FAILED, error };
}

// Get Findings Template
export function getFindingsTemplateRequest() {
  return { type: GET_FINDINGS_TEMPLATE_REQUESTED };
}
function getFindingsTemplateSucceed(data) {
  return {
    type: GET_FINDINGS_TEMPLATE_SUCCEED,
    data,
  };
}
function getFindingsTemplateFailed(error) {
  return { type: GET_FINDINGS_TEMPLATE_FAILED, error };
}
// 유효성 검사 로직에도 사용될 예정
export function getEcgsStatisticsRequest() {
  return {
    type: GET_ECGS_STATISTICS_REQUESTED,
  };
}
export function getEcgsStatisticsSucceed(data) {
  return {
    type: GET_ECGS_STATISTICS_SUCCEED,
    data,
  };
}
function getEcgsStatisticsFailed(error) {
  return { type: GET_ECGS_STATISTICS_FAILED, error };
}

export function getReportsStatisticsRequest() {
  return {
    type: GET_REPORTS_STATISTICS_REQUESTED,
  };
}
function getReportsStatisticSucceed(data) {
  return {
    type: GET_REPORTS_STATISTICS_SUCCEED,
    data,
  };
}
function getReportStatisticFailed(error) {
  return {
    type: GET_REPORTS_STATISTICS_FAILED,
    error,
  };
}

export function getBothStatisticsDelegated() {
  return {
    type: GET_BOTH_STATISTICS_DELEGATED,
  };
}

export function getAllStatisticsRequest(ecgTestId) {
  return {
    type: GET_ALL_STATISTICS_REQUESTED,
    ecgTestId,
  };
}
function getAllStatisticsSucceed(data, updatedAt) {
  return {
    type: GET_ALL_STATISTICS_SUCCEED,
    data,
    updatedAt,
  };
}
function getAllStatisticsFailed(error) {
  return {
    type: GET_ALL_STATISTICS_FAILED,
    error,
  };
}

export function adjustEcgsStatistic(eventSection, adjustValue) {
  return {
    type: ADJUST_ECGS_STATISTICS,
    eventSection,
    adjustValue,
  };
}

export function adjustReportStatistic(reportSection, adjustValue) {
  return {
    type: ADJUST_REPORTS_STATISTICS,
    reportSection,
    adjustValue,
  };
}

export function getReportEventsRequested(reportSection, position = 1) {
  return { type: GET_REPORT_EVENTS_REQUESTED, reportSection, position };
}
function getReportEventsSucceed(data) {
  return { type: GET_REPORT_EVENTS_SUCCEED, data };
}
function getReportEventsFailed(error) {
  return { type: GET_REPORT_EVENTS_FAILED, error };
}

/**
 * @typedef ReportEventInfoType
 * @property {string} [rid]
 * @property {Number} [timeEventId]
 * @property {number} [onsetWaveformIndex]
 * @property {number} [patientEventId]
 * @property {REPORT_SECTION} reportSection
 * @property {number} representativeOnsetIndex
 * @property {number} representativeTerminationIndex
 * @property {string} annotation
 * @property {number} amplitudeRate
 */
/**
 *
 * @param {ReportEventInfoType} newReportEventInfo
 * @returns
 */
export function postReportEventRequested(newReportEventInfo) {
  return { type: POST_REPORT_EVENT_REQUESTED, newReportEventInfo };
}
function postReportEventSucceed(data) {
  return { type: POST_REPORT_EVENT_SUCCEED, data };
}
function postReportEventFailed(error) {
  return { type: POST_REPORT_EVENT_FAILED, error };
}
/**
 *
 * @param {Number} reportEventId
 * @param {ReportEventInfoType} newReportEventInfo
 * @param {Boolean} isPreUpdate
 * @param {import('redux').Action?} afterAction
 * @returns {import('redux').Action}
 */
export function updateReportEventRequested(
  reportEventId,
  newReportEventInfo,
  isPreUpdate,
  afterAction
) {
  return {
    type: UPDATE_REPORT_EVENT_REQUESTED,
    reportEventId,
    newReportEventInfo,
    isPreUpdate,
    afterAction,
  };
}
function updateReportEventSucceed(data, isPreUpdate) {
  return { type: UPDATE_REPORT_EVENT_SUCCEED, data, isPreUpdate };
}
function updateReportEventFailed(error, isPreUpdate) {
  return { type: UPDATE_REPORT_EVENT_FAILED, error, isPreUpdate };
}
/**
 *
 * @param {*} reportEventId
 * @param {import('redux').Action?} afterAction
 * @returns {import('redux').Action}
 */
export function deleteReportEventRequested(reportEventId, afterAction) {
  return { type: DELETE_REPORT_EVENT_REQUESTED, reportEventId, afterAction };
}
function deleteReportEventSucceed() {
  return { type: DELETE_REPORT_EVENT_SUCCEED };
}
function deleteReportEventFailed(error) {
  return { type: DELETE_REPORT_EVENT_FAILED, error };
}
export function getNextReportEvent(editedReportSection) {
  return {
    type: GET_NEXT_REPORT_EVENT,
    editedReportSection: editedReportSection,
  };
}

export function updatePTEReportInfoRequested(isRemove) {
  return {
    type: UPDATE_PTE_REPORT_INFO_REQUESTED,
    payload: { isRemove: isRemove },
  };
}
function updatePTEReportInfoSucceed(data) {
  return { type: UPDATE_PTE_REPORT_INFO_SUCCEED, payload: { data: data } };
}
function updatePTEReportInfoFailed(error) {
  return { type: UPDATE_PTE_REPORT_INFO_FAILED, payload: { error: error } };
}

// ecg chart list edit ui interaction
export function setSelectionStripRequest(selectionStrip) {
  return { type: SET_SELECTION_STRIP, selectionStrip };
}
export function setTenSecStripRequest(tenSecStrip) {
  return { type: SET_TENSEC_STRIP, tenSecStrip };
}
export function setArrhythmiaContextmenuRequest(isOpenArrhythmiaContextmenu) {
  return { type: SET_ARRHYTHMIA_CONTEXTMENU, isOpenArrhythmiaContextmenu };
}
export function setBeatContextmenuRequest(isOpenBeatContextmenu) {
  return { type: SET_BEAT_CONTEXTMENU, isOpenBeatContextmenu };
}
export function setEcgchartlistScrollTopRequest(ecgChartListScrollTop) {
  return { type: SET_ECGCHARTLIST_SCROLL_TOP, ecgChartListScrollTop };
}

export function setTenSecStripDetailRequest(tenSecStripDetail) {
  return {
    type: SET_TENSEC_STRIP_DETAIL,
    tenSecStripDetail,
  };
}

export function setIsEcgChartListScrollRequest(isSetEcgChartListScroll) {
  return {
    type: SET_IS_ECGCHARTLIST_SCROLL_TO_INDEX,
    isSetEcgChartListScroll,
  };
}

export function requestPrintReportRequested(tid, request) {
  return {
    type: REQUEST_PRINT_REPORT_REQUESTED,
    tid,
    request,
  };
}
function requestPrintReportSucceed(data) {
  return {
    type: REQUEST_PRINT_REPORT_SUCCEED,
    data,
  };
}
function requestPrintReportFailed(error) {
  return {
    type: REQUEST_PRINT_REPORT_FAILED,
    error,
  };
}

export function requestMoveEctopicPositionRequested(params) {
  return {
    type: REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED,
    params,
  };
}

// Caliper
export function setCaliperPlotLines(caliperPlotLines) {
  return { type: SET_CALIPER_PLOT_LINES, caliperPlotLines };
}
export function setIsCaliperMode(isCaliperMode) {
  return { type: SET_IS_CALIPER_MODE, isCaliperMode };
}
export function setIsTickMarksMode(isTickMarksMode) {
  return { type: SET_IS_TICK_MARKS_MODE, isTickMarksMode };
}

// Saga functions
function* _initializeState(action) {
  const isInitialized = true;
  // hrReview, beatReview redux state 초기화
  yield put(resetBeatReviewState());
  yield put(resetHrReviewState());

  yield put(getEcgTestRequested(isInitialized));
  // isRawData 때문에 아래 2가지 Action의 dispatch 타이밍 변경
  // TODO: 준호 - 전체 이벤트 정보 요청 타이밍 ecg test 데이터 확보 이후로 변경 필요
  // yield put(getTimeEventsListRequested()); //
  // yield put(getDailyHeartRateRequested()); //
  yield put(getTimeEventsListRequested(TIME_EVENT_TYPE.LEAD_OFF));
}

function* _selectionStripHandler(action) {
  try {
    const { selectionStrip } = action;
    const {
      selectionMarkerType,
      representativeTimestamp,
      clickedWaveformIndex,
      extraParam,
    } = selectionStrip;

    if (selectionMarkerType === SELECTION_MARKER_TYPE.RESET) {
      return;
    }
    if (selectionMarkerType === SELECTION_MARKER_TYPE.TERMINATION) {
      yield put(setSidePanelSelectedEventList([], true)); // 구간 선택 시 selectedEventList 초기화
      return;
    }

    // ECG Chart List 에서 그냥 클릭이 발생된 경우
    // selectionMarkerType === SELECTION_MARKER_TYPE.ONSET
    const isSelectableChart = yield select(selectIsSelectableChart);
    const isSelectableRepresentativeStrip = yield select(
      selectIsSelectableRepresentativeStrip
    );

    if (isSelectableChart && extraParam.isNoise) {
      // Events 탭에서 전체 이벤트 조회 가능 상황인데, 클릭된 지점 Noise 일 경우
      yield call(setEventsInfo, [
        { type: EVENT_CONST_TYPES.NOISE, position: 0 },
      ]);
      return;
    }

    const { recordingStartMs, recordingEndMs } = yield select(
      selectRecordingTime
    );
    const representativeWaveformIndex = parseInt(
      (representativeTimestamp - recordingStartMs) / 4
    );
    const selectedGlobalWaveformIndex =
      representativeWaveformIndex + clickedWaveformIndex;
    const selectedGlobalMs = representativeTimestamp + clickedWaveformIndex * 4;

    /**************************************************/
    /* if statement condition                        */
    /*   ㄴ Events 탭에서 전체 이벤트 조회 가능 상황 */
    /*   ㄴ 리포트 담기 중 대표 Strip 선택 가능 상황 */
    /**************************************************/
    if (isSelectableChart) {
      // Events 탭에서 전체 이벤트 조회 가능 상황
      const beatInfoMap = yield select(({ testResultReducer }) => ({
        [representativeWaveformIndex - ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX]:
          testResultReducer.beatsNEctopicList.data[
            representativeWaveformIndex - ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
          ],
        [representativeWaveformIndex]:
          testResultReducer.beatsNEctopicList.data[representativeWaveformIndex],
        [representativeWaveformIndex + ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX]:
          testResultReducer.beatsNEctopicList.data[
            representativeWaveformIndex + ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
          ],
      }));

      const beatWaveformIndexList = Object.values(beatInfoMap)
        .filter((v) => !!v)
        .map((v) => v.beats.waveformIndex)
        .flat()
        .sort((a, b) => a - b);

      // 선택 지점과 제일 인접한 Beat 의 위치
      const nearestBeatWaveformIndexWithSelection = getNearestOne(
        beatWaveformIndexList,
        selectedGlobalWaveformIndex
      );
      // 선택 지점의 Ectopic 정보 획득, 사전 List-Up 된 것에서 확보
      const ectopicList = (
        beatInfoMap[representativeWaveformIndex]?.ectopics ?? []
      )
        .filter((value) =>
          value.waveformIndex.includes(nearestBeatWaveformIndexWithSelection)
        )
        .map((value) => ({
          type: value.type,
          position: null,
          timeEventId: null,
          waveformIndex: value.waveformIndex,
          nearestBeatWaveformIndexWithSelection,
          selectedGlobalMs,
        }));

      // 선택 지점의 Time Event 정보 획득, 사전 List-Up 된 것에서 확보
      const isSelectedInTimeEventRange = (timeEvent, selectedMs) => {
        return (
          timeEvent.onsetMs <= selectedMs &&
          selectedMs <= timeEvent.terminationMs
        );
      };

      let filterTimeEventListBySelectMs = yield select((state) =>
        state.testResultReducer.timeEventsList.data.filter((timeEvent) =>
          isSelectedInTimeEventRange(timeEvent, selectedGlobalMs)
        )
      );
      const eventUpdateClass = yield eventUpdateInst;
      const hasPauseEvent = filterTimeEventListBySelectMs.some(
        (event) => event.type === EVENT_CONST_TYPES.PAUSE
      );
      const isBeatUpdated = eventUpdateClass.getIsBeatUpdated();

      if (hasPauseEvent && isBeatUpdated) {
        // EventReview Chart에서 선택한 지점에 Pause Event가 존재하고,
        // 이전에 편집이력이 있을 경우, 서버로부터 Pause Event List를 불러옵니다.(정확한 position 정보를 가져오기 위함)
        const isWholeUnMark = yield select(selectIsWholeUnMark);
        yield put(
          enqueueRequest({
            requestStatement: {
              type: GET_TIME_EVENTS_LIST,
            },
            getAction: (options = {}) => {
              return getTimeEventsListRequested(TIME_EVENT_TYPE.PAUSE, {
                ...options,
                isWholeUnMark,
              });
            },
            succeedCallback: function () {},
            failedCallback: () => {},
          })
        );
        // yield take(GET_TIME_EVENTS_LIST_SUCCEED);
        eventUpdateClass.setIsBeatUpdatedFlag(false);
        filterTimeEventListBySelectMs = yield select((state) =>
          state.testResultReducer.timeEventsList.data.filter((timeEvent) =>
            isSelectedInTimeEventRange(timeEvent, selectedGlobalMs)
          )
        );
      }

      const eventList = [
        ...ectopicList,
        ...filterTimeEventListBySelectMs.map((v) => ({
          type: v.type,
          position: v.position,
          timeEventId: v.timeEventId,
          waveformIndex: null,
          selectedGlobalMs,
        })),
      ];
      const isSelectedSomeEvent = eventList.length !== 0;

      // 선택된 이벤트가 없는 경우(eventList === []) 일 경우, side panel의 eventDetail을 초기화 시킨다.
      if (!isSelectedSomeEvent) {
        yield put(initEventDetailData());
      }

      yield call(
        setEventsInfo,
        eventList.length === 0
          ? [{ type: EVENT_CONST_TYPES.NORMAL, position: 0 }]
          : eventList
      );
    } else if (isSelectableRepresentativeStrip) {
      // fresh representative state update

      const recordingEndWaveformIndex = (recordingEndMs - recordingStartMs) / 4;
      const selectedWaveformIndex = (selectedGlobalMs - recordingStartMs) / 4;
      const prevRepresentativeStripInfo = yield select(
        ({ testResultReducer: state }) =>
          state.eventReview.representativeStripInfo
      );
      const stripHalfWaveformLength =
        (prevRepresentativeStripInfo.representativeTerminationIndex -
          prevRepresentativeStripInfo.representativeOnsetIndex) /
        2;
      /** @type {import('constant/ReportConst').ReportEventEditorState} ReportEventEditor 모듈의 Global State */
      const prevReportEventEditorState = yield select(
        selectReportEventEditorState
      );
      const selectedStripType = prevReportEventEditorState.selectedStripType;
      const {
        modifiedCenterWaveformIndex,
        onsetWaveformIndex,
        terminationWaveformIndex,
      } = getOnsetTerminationByCenter(
        selectedWaveformIndex,
        stripHalfWaveformLength,
        recordingEndWaveformIndex
      );

      const newStripInfo = {
        selectedMs: recordingStartMs + modifiedCenterWaveformIndex * 4,
        representativeOnsetIndex: onsetWaveformIndex,
        representativeTerminationIndex: terminationWaveformIndex,
      };
      const prevStripInfo =
        selectedStripType === STRIP_TYPE_MAP.MAIN
          ? prevReportEventEditorState.mainRepresentativeInfo
          : prevReportEventEditorState.subRepresentativeInfo;
      if (
        prevStripInfo.selectedMs !== newStripInfo.selectedMs ||
        prevStripInfo.representativeOnsetIndex !==
          newStripInfo.representativeOnsetIndex ||
        prevStripInfo.representativeTerminationIndex !==
          newStripInfo.representativeTerminationIndex
      ) {
        let newSubState =
          selectedStripType === STRIP_TYPE_MAP.MAIN
            ? {
                mainRepresentativeInfo: {
                  ...prevReportEventEditorState.mainRepresentativeInfo,
                  ...newStripInfo,
                },
              }
            : {
                subRepresentativeInfo: {
                  ...prevReportEventEditorState.subRepresentativeInfo,
                  ...newStripInfo,
                },
              };
        if (
          selectedStripType === STRIP_TYPE_MAP.MAIN &&
          !prevReportEventEditorState.subRepresentativeInfo.isRemoved
        ) {
          newSubState.subRepresentativeInfo = {
            ...prevReportEventEditorState.subRepresentativeInfo,
            isRemoved: true,
            isMainChanged: true,
            amplitudeRate: BASIS_AMPLITUDE_RATE,
          };
        }

        yield put(setReportEventEditorNewState(newSubState));
      }

      const prevRepresentativeStripState = yield select(
        (state) => state.testResultReducer.eventReview.representativeStripInfo
      );
      if (
        !(
          newStripInfo.selectedMs === prevRepresentativeStripState.selectedMs &&
          newStripInfo.representativeOnsetIndex ===
            prevRepresentativeStripState.representativeOnsetIndex &&
          newStripInfo.representativeTerminationIndex ===
            prevRepresentativeStripState.representativeTerminationIndex
        )
      ) {
        // Representative Strip State 에 변경 사항이 있을경우에만 업데이트
        yield put(setRepresentativeStripInfo(newStripInfo));
      }
    }
  } catch (error) {
    console.error(error);
  }

  /**
   *
   * @param {Array<number>} ascList
   * @param {number} target
   * @returns {number}
   */
  function getNearestOne(ascList, target) {
    if (!(Array.isArray(ascList) && ascList.length > 0)) {
      throw new Error('getNearestOne error: PARAMETER ARRAY(ascList)');
    }
    if (!(Number.isInteger(target) && target >= 0)) {
      throw new Error('getNearestOne error: PARAMETER NUMBER(target)');
    }

    const firstBiggerIndex = ascList.findIndex((value) => target <= value);
    const smallestBigger = ascList.at(firstBiggerIndex);
    const largestSmaller = ascList.at(firstBiggerIndex - 1);
    if (
      Math.abs(smallestBigger - target) <= Math.abs(target - largestSmaller)
    ) {
      return smallestBigger;
    }

    return largestSmaller;
  }

  function* setEventsInfo(nextEventList) {
    const prevEventList = yield select(selectSelectedEventList);
    const eventDetailFetchingRetryTimes = yield select(
      selectEventDetailFetchingRetryTimes
    );

    const isEventDetailFetchingRetryDone = eventDetailFetchingRetryTimes !== 1; // event marker detail 조회 retry가 끝났을때 같은 event marker를 선택했을때 다시 조회 할 수 있게(optimistic update로 인해서 조회시 retry 하는 매커니즘이 생긴로직)
    const isSameEventCount = prevEventList.length === nextEventList.length;
    const hasSameEvent = nextEventList.some((nextEvent) =>
      prevEventList.some((prevEvent) => {
        const compareKeyList = [
          'type',
          'position',
          'timeEventId',
          // 'waveformIndex',
        ];
        const compareTargetKeyList = compareKeyList.filter((key) => {
          if (key === 'type') {
            const nextEventInfo = getEventInfoByType(nextEvent.type);
            const prevEventInfo = getEventInfoByType(prevEvent.type);
            const isBeatTypeNextEvent = isBeatType(nextEventInfo.beatType);
            const isBeatTypePrevEvent = isBeatType(prevEventInfo.beatType);
            if (
              isBeatTypeNextEvent &&
              isBeatTypePrevEvent &&
              nextEvent.waveformIndex &&
              prevEvent.waveformIndex
            ) {
              const nextEventWfiString = nextEvent.waveformIndex.toString();
              const prevEventWfiString = prevEvent.waveformIndex.toString();
              const rst = nextEventWfiString === prevEventWfiString ?? false;
              return rst;
            }
          }
          return !!nextEvent[key] && !!prevEvent[key];
        });

        const isAllSameFromTargetKeyList =
          compareTargetKeyList.length === 0
            ? false
            : compareTargetKeyList.every(
                (key) => nextEvent[key] === prevEvent[key]
              );

        return isAllSameFromTargetKeyList;
      })
    );
    // 이전에 선택된 이벤트 정보(prevEventList)와 현재 클릭한 정보(prevEventList)가 같은지 비교
    // todo: jyoon - [refactor] 이벤트 정보 비교 로직 수정 필요(비트 정보가 화면에 보이는 정보만 가지고 있어서 정확한 비교가 안됨)
    if (isEventDetailFetchingRetryDone && isSameEventCount && hasSameEvent) {
      return;
    }

    yield put(setSidePanelSelectedEventList(nextEventList, true));
  }
}

function* _reportRepresentativeTenSecStripHandler(action) {
  const { representativeOnsetIndex, representativeTerminationIndex } =
    action.newInfo;

  /**
   * # report 담기시 10s strip detail에 보여줄 기준
   *
   *   CASE 1. REPORT_SECTION이 ADDITIONAL인 아닌 경우
   *     - 이벤트 중앙 기준으로 좌우 5초씩 10초를 10s strip detail에 보여줍니다.
   */
  const localCenterWaveformIndex = getLocalCenterWaveformIndex(
    representativeOnsetIndex,
    representativeTerminationIndex
  );
  const representativeCenterWaveformIndex =
    getRepresentativeCenterWaveformIndex(
      representativeOnsetIndex,
      representativeTerminationIndex
    );

  const event = { clickedWaveformIndex: localCenterWaveformIndex };
  const { recordingStartMs } = yield select(selectRecordingTime);
  const representativeCenterTimeStamp =
    recordingStartMs + representativeCenterWaveformIndex * 4;
  const tenSecStripParam = getTenSecStripParam(
    event,
    representativeCenterTimeStamp,
    representativeCenterWaveformIndex
  );

  yield put(setTenSecStripRequest(tenSecStripParam));
}

function* _tenSecStripHandler(action) {
  try {
    const { tenSecStrip } = action;

    if (action?.tenSecStrip[TEN_SEC_STRIP.TYPE.RESET]) return;

    const ecgRawList = yield select(selectEcgRawList);
    const beatsNEctopicList = yield select(selectBeatsNEctopicList);

    const constHr = TEN_SEC_SCRIPT_DETAIL.HR;
    const constBeatType = TEN_SEC_SCRIPT_DETAIL.BEAT_TYPE;
    const constWaveformIndex = TEN_SEC_SCRIPT_DETAIL.WAVEFORM_INDEX;

    const constMsUnitPerChartPoint = ECG_CHART_UNIT.MS_UNIT_PER_CHART_POINT;
    const constFiveSec = ECG_CHART_UNIT.FIVE_SEC;
    const constTenSec = ECG_CHART_UNIT.TEN_SEC;
    const constFiveSecWaveformIdx = ECG_CHART_UNIT.FIVE_SEC_WAVEFORM_IDX;
    const constTenSecWaveformIdx = ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX;

    let tenSecStripDetail = {
      onsetMs: undefined,
      terminationMs: undefined,
      onsetWaveformIdx: undefined,
      terminationWaveformIdx: undefined,
      hrAvg: undefined,
      ecgRaw: [],
      beatLabelButtonDataList: [],
    };

    let tenSecStripOnsetMs,
      tenSecStripTerminationMs,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx;

    tenSecStripOnsetMs =
      tenSecStrip.representativeCenterTimeStamp +
      tenSecStrip.centerWaveformIndex * constMsUnitPerChartPoint -
      constFiveSec;

    tenSecStripTerminationMs = tenSecStripOnsetMs + constTenSec;

    tenSecStripOnsetWaveformIdx =
      tenSecStrip.representativeCenterWaveformIndex +
      tenSecStrip.centerWaveformIndex -
      constFiveSecWaveformIdx;

    tenSecStripTerminationWaveformIdx =
      tenSecStripOnsetWaveformIdx + constTenSecWaveformIdx;

    let tenSecStripEcgRaw, filterBeatsNEctopicList;
    let filterHrList, sumHr, tenSecStripHrAvg;
    let beatLabelButtonDataList,
      beatType,
      beatColorType,
      beatTypeList,
      beatWaveformIndexList;

    // 10s strip - ecg raw data
    tenSecStripEcgRaw = _getTenSecStripEcgRaw(
      ecgRawList,
      tenSecStripOnsetWaveformIdx
    );

    // 10s strip detail open 상태에서 event position 이동시 현재 차트리스트(raw)에 없는 시간대면 10s strip이 이동한 position의 데이터를 보여주지 못함.
    if (!tenSecStripEcgRaw) return;

    // 10s strip - detail beat info
    filterBeatsNEctopicList = _getFilterBeatsNEctopicList(
      beatsNEctopicList,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx
    );

    // 10s strip - avg hr
    const mergedBeats = filterBeatsNEctopicList.reduce(
      (acc, cur) => ({
        //
        waveformIndex: [...acc.waveformIndex, ...cur.beats.waveformIndex],
        beatType: [...acc.beatType, ...cur.beats.beatType],
        hr: [...acc.hr, ...cur.beats.hr],
      }),
      { waveformIndex: [], beatType: [], hr: [] }
    );

    tenSecStripHrAvg = getTenSecAvgHrByFromTo(
      mergedBeats,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx
    );

    // 10 strip에 render 할 beat label button data
    beatTypeList = _getTenSecStripInfo(
      filterBeatsNEctopicList,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx,
      constBeatType
    );

    beatWaveformIndexList = _getTenSecStripInfo(
      filterBeatsNEctopicList,
      tenSecStripOnsetWaveformIdx,
      tenSecStripTerminationWaveformIdx,
      constWaveformIndex
    );

    beatLabelButtonDataList = _getBeatLabelButtonDataList({
      beatTypeList,
      beatWaveformIndexList,
    });

    tenSecStripDetail = {
      onsetMs: tenSecStripOnsetMs,
      terminationMs: tenSecStripTerminationMs,
      onsetWaveformIdx: tenSecStripOnsetWaveformIdx,
      terminationWaveformIdx: tenSecStripTerminationWaveformIdx,
      hrAvg: tenSecStripHrAvg,
      ecgRaw: tenSecStripEcgRaw,
      beatLabelButtonDataList,
    };

    yield put(setTenSecStripDetailRequest(tenSecStripDetail));

    function _getTenSecStripEcgRaw(
      ecgRawList,
      startTenSecStripWaveformIdx,
      endTenSecStripWaveformIdx = startTenSecStripWaveformIdx +
        ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX
    ) {
      let result;
      let filterEcgRawList = [];
      const halfTenSecWaveformIdx = ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;
      filterEcgRawList = ecgRawList.filter((v) => {
        return !(
          v.terminationWaveformIndex <= startTenSecStripWaveformIdx ||
          v.onsetWaveformIndex >= endTenSecStripWaveformIdx
        );
      });

      if (filterEcgRawList.length === 1) {
        result = filterEcgRawList[0].ecgData.slice(
          startTenSecStripWaveformIdx % 7500 < 0
            ? 0
            : startTenSecStripWaveformIdx % 7500,
          (startTenSecStripWaveformIdx + 2500) % 7500 < halfTenSecWaveformIdx
            ? 7500
            : (startTenSecStripWaveformIdx + 2500) % 7500
        );
        // 검사 시작 부분의 tenSecStrip 검증
        if (startTenSecStripWaveformIdx % 7500 < 0) {
          result.unshift(
            ...Array.from(
              {
                length: Math.abs(startTenSecStripWaveformIdx % 7500),
              },
              () => 0
            )
          );
        }
        // 검사 제일 마지막 부분의 tenSecStrip 검증
        if ((startTenSecStripWaveformIdx + 2500) % 7500 < halfTenSecWaveformIdx)
          result.push(
            ...Array.from(
              {
                length: halfTenSecWaveformIdx,
              },
              () => 0
            )
          );
      } else if (filterEcgRawList.length === 2) {
        result = [
          ...filterEcgRawList[0].ecgData.slice(
            startTenSecStripWaveformIdx % 7500
          ),
          ...filterEcgRawList[1].ecgData.slice(
            0,
            (startTenSecStripWaveformIdx + 2500) % 7500
          ),
        ];
      }

      return result;
    }
  } catch (error) {
    console.error(error);
  }
}
/**
 * `sidePanelState.selectedEventList` 업데이트 후 배열의 길이가 1일 경우 API 를 통해 정보를 조회한다.
 *
 * 단, Normal, Noise 는 제외
 * @param {{newSelectedEventList, isFromChart}} action
 */
function* _setSelectedEventListHandler(action) {
  try {
    const { newSelectedEventList, isOnlySet } = action;
    if (
      isOnlySet ||
      !isOnlyOneChosen(newSelectedEventList) ||
      isNormalOrNoiseType(newSelectedEventList[0].type)
    ) {
      return;
    }

    const {
      type,
      position,
      timeEventId,
      waveformIndex,
      selectedGlobalMs,
      /**
       * Onset Selection Marker 만 있는 상황에서, 선택된 위치에 Ectopic 이 있을 경우에만 있음
       * _selectionStripHandler 에서 Side Tab 이 Events인 분기 참조
       */
      nearestBeatWaveformIndexWithSelection,
    } = newSelectedEventList[0];
    const sideTabValue = yield select(selectSideTabValue);
    if (sideTabValue === EVENT_GROUP_TYPE.EVENTS) {
      const { data: eventDetailData } = yield select(selectEventDetail);
      yield put(
        enqueueRequest({
          requestStatement: { type: GET_EVENT_DETAIL },
          requestPosition: position,
          beatEventWaveformIndex: waveformIndex,
          getAction: (options = {}) => {
            return getEventDetailRequested({
              ...options,
              eventType: type,
              searchCondition:
                timeEventId || nearestBeatWaveformIndexWithSelection,
              position,
              isSelectedEventUpdate: !PATIENT_EVENT_TYPE_LIST.includes(type),
              selectedGlobalMs,
              inCludedGeminyEvent: eventDetailData?.geminy,
            });
          },
          succeedCallback: () => {},
          failedCallback: () => {},
        })
      );
    } else {
      const selectedMetaInfo = getSidePanelEventData({
        groupType: EVENT_GROUP_TYPE.REPORT,
        key: 'type',
        value: type,
      });
      yield put(
        getReportEventsRequested(selectedMetaInfo.reportSection, position)
      );
    }
  } catch (error) {
    console.error(error);
  }

  /**
   *
   * @param {Array<any>} selectedEventList
   * @returns
   */
  function isOnlyOneChosen(selectedEventList) {
    return selectedEventList.length === 1;
  }
  function isNormalOrNoiseType(eventType) {
    return [EVENT_CONST_TYPES.NORMAL, EVENT_CONST_TYPES.NOISE].includes(
      eventType
    );
  }
}

/** 정렬 기준 변경 시 수반되는 작업 핸들러 */
function* _setSortOrderHandler(action) {
  const { newSortOrderKey } = action;

  // 1. Time Event 의 경우 재 정렬된 정보 목록을 조회
  const targetEventMeta = getEventInfo({ type: newSortOrderKey }).findOne();
  if (targetEventMeta.timeEventType) {
    // todo: jyoon - [refactor] saga function을 action 호출 없이 호출함/ sagafunction에 action 함수를 넣어 호출)
    yield* _getTimeEventsList(
      getTimeEventsListRequested(targetEventMeta.timeEventType)
    );
  }

  // 2. 조회 중인 Event 의 Position 을 1로 재 설정하고, Event Detail 조회 발생 유도
  yield put(
    setSidePanelSelectedEventList([{ type: newSortOrderKey, position: 1 }])
  );
}

function* _getEcgTest(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);

    const {
      data: { result },
    } = yield call(ApiManager.getEcgTestDetail, {
      ecgTestId,
    });

    const thirtySecMs = ECG_CHART_UNIT.TEN_SEC * 3;
    const recordingStartMs = DateUtil.formatMs(result.patchecg.startTimestamp);
    const recordingEndMs = DateUtil.formatMs(result.patchecg.endTimestamp);
    const createAt = new Date().getTime();
    yield put(
      setBasicLeadOff([
        {
          createAt,
          onsetMs: recordingStartMs - thirtySecMs,
          terminationMs: recordingStartMs,
          type: EVENT_CONST_TYPES.LEAD_OFF,
          timeEventId: `${EVENT_CONST_TYPES.LEAD_OFF}-basic-onset`,
          onsetRPeakIndex: null,
          position: -1,
        },
        {
          createAt,
          onsetMs: recordingEndMs,
          terminationMs: recordingEndMs + thirtySecMs,
          type: EVENT_CONST_TYPES.LEAD_OFF,
          timeEventId: `${EVENT_CONST_TYPES.LEAD_OFF}-basic-termination`,
          onsetRPeakIndex: null,
          position: -2,
        },
      ])
    );

    yield put(getDailyHeartRateRequested({ calcIsNoise: false }));
    yield put(getEcgTestSucceed(result));

    const isRawDataOnly = yield select(selectIsRawDataOnly);

    if (action.isInitialized && !isRawDataOnly) {
      // 부가정보 요청은 Test Detail 데이터를 받고 난 후 진행!!
      yield put(patchBeatPostprocessRequested());
      yield put(getTimeEventsListRequested());
      yield put(getBothStatisticsDelegated());
    }
  } catch (error) {
    yield put(getEcgTestFailed(error));
  }
}

function* _patchEcgTest({ ecgTestId, form, callback }) {
  try {
    const {
      data: { result },
    } = yield call(
      ApiManager.patchEcgTest,
      {
        ecgTestId,
        body: form,
      },
      callback
    );

    yield put(patchEcgTestSucceed(result));
  } catch (error) {
    console.error(error);
    yield put(patchEcgTestFailed(error));
  }
}

/**
 * Time Event 목록 데이터 요청
 *
 * Step list
 *    - # Step1 - api call condition(targetTimeEventType이 있는 경우와 아닌 케이스)
 *    - # Step2 - Step1과정에서 받은 timeEvent 데이터를 redux state에 세팅
 *
 * 특징
 *    - cancel token 기능이 api call 과정에 포함 되어 있음
 *    - time event 이벤트 편집을 연속으로 했을 때 timeEvent fetching을 하는데 이 케이스에서 사용됨
 *
 * @param {{type: string, targetTimeEventType?: string, options?: {isWholeUnMark?: boolean, callback?: GeneratorFunction}}} action
 */
let timeEventListCancelMap = {};
function* _getTimeEventsList(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const sortOrder = yield select(selectEventReviewSortOrder);

    const { targetTimeEventType, options } = action;
    const defaultParams = { tid: ecgTestId, isMinimum: true };
    /**********************************************************************************/
    /*  # 10 step of _getTimeEventsList                                               */
    /*    *  >> : fetching time event list main logic                                 */
    /*                                                                                */
    /* step.01 setting fetching time event list                                       */
    /* step.02 cancel token                                                           */
    /* step.03 traceId                                                                */
    /* >> step.04 fetching api                                                        */
    /* step.05 throw error when cancel token is working                               */
    /* step.06 task queue 완료 로직(releaseRequestedInfoAction)                       */
    /* >> step.07 preprocess(timeEvent 데이터를 redux state에 세팅)                   */
    /*   : redux state에 설정 로직                                                    */
    /*       편집한 타입이벤트 타입을 redux state에서 제거(stale한 데이터 제거)       */
    /*       + 편집한 타입이벤트 타입을 api를 통해 새로 받은 것                       */
    /* step.08 yield put(getEventDetailSucceed(p1,p2)) - 이벤트 상세 정보 조회        */
    /* >> step.09 getTimeEventsListSucceed or validation task queue(throw error)      */
    /* step.10 post process                                                           */
    /**********************************************************************************/
    /**********************************************************************************/
    /* # inner function list                                                          */
    /*    * getTimeEventApi                                                           */
    /*    * getFetchingTimeEventListApiList                                           */
    /*    * setTraceId                                                                */
    /*    * callCancelToken                                                           */
    /*    * setCancelToken                                                            */
    /**********************************************************************************/

    // step.01 setting fetching time event list
    let fetchingTimeEventList = [];
    const hasTargetTimeEventType = Boolean(targetTimeEventType);
    if (Array.isArray(targetTimeEventType)) {
      fetchingTimeEventList = targetTimeEventType;
    } else if (TIME_EVENT_TYPE[targetTimeEventType]) {
      fetchingTimeEventList = [targetTimeEventType];
    } else if (!hasTargetTimeEventType) {
      // targetTimeEventType 값이 없는 케이스: 테스트 상세 페이지 진입시
      fetchingTimeEventList = [
        TIME_EVENT_TYPE.AF,
        TIME_EVENT_TYPE.PAUSE,
        TIME_EVENT_TYPE.OTHERS,
        TIME_EVENT_TYPE.AVB_2,
        TIME_EVENT_TYPE.AVB_3,
      ];
    }

    // step.02 cancel token
    yield* callCancelToken({ timeEventListCancelMap, fetchingTimeEventList });
    setCancelToken({
      timeEventListCancelMap,
      fetchingTimeEventList,
      axios,
    });

    // step.03 traceId
    setTraceId(options);

    // step.04 fetching api
    const fetchingTimeEventApiList = getFetchingTimeEventListApiList({
      fetchingTimeEventList,
      sortOrder,
    });
    const responseTimeEventList = yield all(fetchingTimeEventApiList);

    // step.05 throw error when cancel token is working
    for (let responseTimeEvent of responseTimeEventList) {
      const isCancelTokenWorking = responseTimeEvent === undefined; //cancel token 동작 시 API response는 undefined 반환
      if (isCancelTokenWorking) {
        throw new Error('CANCEL TOKEN IS WORKING');
      }
    }

    // step.06 task queue 완료 로직(releaseRequestedInfoAction)
    if (responseTimeEventList.length === 1) {
      const params = fetchingTimeEventList[0];
      const response = responseTimeEventList[0];

      const isCancelTokenWorking = response === undefined; //cancel token 동작 시 API response는 undefined 반환
      const isResponse200 = response && response.status !== 200;
      const isLatestTraceId =
        response?.config?.traceId &&
        uuidManagement[params.eventType] !== response.config.traceId; // traceId가 최신이 아닌 케이스
      if (isCancelTokenWorking || isResponse200 || isLatestTraceId) {
        if (action.options?.releaseRequestedInfoAction) {
          yield put(action.options.releaseRequestedInfoAction());
        }
      }
    }

    // step.07 preprocess(timeEvent 데이터를 redux state에 세팅)
    //  : redux state에 설정 로직
    //      편집한 타입이벤트 타입을 redux state에서 제거(stale한 데이터 제거)
    //      + 편집한 타입이벤트 타입을 api를 통해 새로 받은 것
    const responseList = transformTimeEvents(
      responseTimeEventList.reduce((acc, cur) => [...acc, cur.data.results], [])
    );
    const { leadOff: staleLeadOffList, data: staleTimeEventList } =
      yield select((state) => state.testResultReducer.timeEventsList);

    let freshLeadOffList = [...staleLeadOffList];
    let freshTimeEventList = [...staleTimeEventList];

    if (!hasTargetTimeEventType) {
      freshTimeEventList = responseList;
    } else {
      if (fetchingTimeEventList.includes(TIME_EVENT_TYPE.LEAD_OFF)) {
        freshLeadOffList = mergeLeadOffInfo(staleLeadOffList, responseList);
      } else {
        const eventTypeList = fetchingTimeEventList.map(
          (fetchingTimeEventType) => {
            return getEventInfo({
              timeEventType: fetchingTimeEventType,
            }).findOne()?.type;
          }
        );

        const filterCurrentEventType = staleTimeEventList.filter((value) => {
          return !eventTypeList.includes(value.type);
        });
        freshTimeEventList = [...filterCurrentEventType, ...responseList];
      }
    }

    // step.08 yield put(getEventDetailSucceed(p1,p2)) - 이벤트 상세 정보 조회
    const prevSelectedEventList = yield select(selectSelectedEventList);
    if (prevSelectedEventList.length !== 0) {
      const selectedEventList = freshTimeEventList.filter(
        (v) => v.timeEventId === prevSelectedEventList[0].timeEventId
      );
      if (selectedEventList.length !== 0) {
        const selectedEventDetail = yield select(selectSelectedEventDetail);
        const newSelectedEvent = {
          position: selectedEventList[0].position,
          waveformIndex: selectedEventList[0].waveformIndex,
        };
        const newSelectedEventList = getUpdatedSelectedEventList({
          prevSelectedEventList,
          newSelectedEvent,
          isSelectedEventUpdate: true,
        });

        yield put(
          getEventDetailSucceed(selectedEventDetail, newSelectedEventList)
        );
      }
    }

    // step.09 getTimeEventsListSucceed or validation task queue(throw error)
    const filterTaskOfPostTimeEvent = yield select(
      selectFilterTaskOfPostTimeEvent
    );
    // fetching 하려고하는 TimeEvent가 task queue에 postTimeEvent로 있는 경우 redux에 세팅하지 않는다.
    const filterSameTimeEventType = filterTaskOfPostTimeEvent.filter((task) =>
      fetchingTimeEventList.includes(task.requestStatement.eventType)
    );

    if (filterSameTimeEventType.length > 0) {
      throw new Error(
        'There is a TypeEvent in the postTimeEvent of the task queue that matches the TypeEvent list you are trying to fetching.'
      );
    } else {
      yield put(getTimeEventsListSucceed(freshLeadOffList, freshTimeEventList));
    }

    // step.10 post process
    if (options?.isWholeUnMark) yield put(setEventDetailEditPending(false));
    if (options?.callback) yield call(options.callback);
    if (options?.releaseRequestedInfoAction) {
      yield put(options.releaseRequestedInfoAction());
    }

    // timeEvent Type별로 이벤트 api 호출 & response 반환
    function getTimeEventApi(timeEventType, params) {
      const eventType = getEventInfo({
        timeEventType: timeEventType,
      }).findOne()?.type;
      const queryString = {
        ...params,
        eventType: timeEventType,
      };

      if (sortOrder[eventType]?.queryOrderBy) {
        queryString.ordering = sortOrder[eventType]?.queryOrderBy;
      }

      return call(
        ApiManager.getTimeEventList,
        queryString,
        null, // callback
        timeEventListCancelMap[timeEventType]?.token
      );
    }
    // timeEvent Type별로 api 호출할 param 설정
    function getFetchingTimeEventListApiList({
      fetchingTimeEventList,
      sortOrder,
    }) {
      return fetchingTimeEventList.map((fetchingTimeEvent) => {
        let params = {
          ...defaultParams,
          eventType: fetchingTimeEvent,
        };
        const eventType = getEventInfo({
          timeEventType: fetchingTimeEvent,
        }).findOne()?.type;

        if (eventType === EVENT_CONST_TYPES.PAUSE) {
          return getPauseEventList();
        }

        // set time event fetching order
        if (sortOrder[eventType]) {
          params.ordering = sortOrder[eventType].queryOrderBy;
        }
        return getTimeEventApi(fetchingTimeEvent, params);
      });
    }
    // timeEventListCancelMap에 요청한 이벤트 타입별로 cancelToken을 설정
    // 설정한 cancelToken은 callCancelToken 함수에 의해서 취소 요청 하는데 사용된다.
    function setCancelToken({
      timeEventListCancelMap,
      fetchingTimeEventList,
      axios,
    }) {
      for (let timeEventType of fetchingTimeEventList) {
        timeEventListCancelMap[timeEventType] = axios.CancelToken.source();
      }
    }
    // traceId 설정
    function setTraceId(options) {
      if (options?.traceId) {
        axios.interceptors.request.use(async function (config) {
          if (
            config.url.includes('api/time-events') &&
            config.method === 'get'
          ) {
            if (config.traceId) return config;
            config.traceId = options.traceId;
          }
          return config;
        });
      }
    }
    // setCancelToken함수에 의해서 설정된 cancelToken이 있는 경우 api response를 받지 않는 과정
    function* callCancelToken({
      timeEventListCancelMap,
      fetchingTimeEventList,
    }) {
      for (let timeEventType of fetchingTimeEventList) {
        if (timeEventListCancelMap[timeEventType]) {
          yield call(timeEventListCancelMap[timeEventType].cancel);
        }
      }
    }
  } catch (error) {
    console.error('error: ', error);
    if (action.options?.releaseRequestedInfoAction) {
      yield put(action.options.releaseRequestedInfoAction());
    }
    yield put(getTimeEventsListFailed(error));
  }
}

/**
 * HRV 차트에 필요한 Daily Heart Rate(dhr) 값과 Patient Trigger Events(pte) 정보 요청
 *
 * @param {*} action
 */
function* _getDailyHeartRate(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const [
      {
        data: { results: rawDhr },
      },
      {
        data: { results: pte },
      },
    ] = yield call(
      (params) => {
        return Promise.all([
          ApiManager.getDailyStatChart(params),
          ApiManager.getPatientTriggerEventList({
            tid: params.ecgTestId,
            ordering: 'event_timestamp',
            isMinimum: true,
            calcIsNoise: Boolean(action.calcIsNoise),
          }),
        ]);
      },
      {
        ecgTestId,
      }
    );

    // HR 차트에서 사용할 데이터 셋 구성 진행
    // 1. HR 차트 Line 데이터(PTE 마크를 포함한 데이터)
    /**
     * {
     *  x: 시간,
     *  y: HR Avg,
     *  pteMarker?: {
     *   enabled: 2분 구간 동안 PTE 가 포함됐다면 true
     *  }
     * }
     */
    // 2. 전체 측정 구간에 대한 1시간 단위 밴드(+ 수면 구간 여부) 배열
    const sleeps = [];
    const patientEventTimes = [];
    const patientEvents = [];
    const TWENTY_HOURS_IN_MS = 3600 * 20 * 1000;
    let sleepFrom = null;
    const { recordingStartMs } = yield select(selectRecordingTime);

    pte.forEach((item) => {
      if (Const.PATIENT_EVENT_TYPE.SLEEP === item.eventType) {
        sleepFrom = item.eventTimeMs;
      } else if (Const.PATIENT_EVENT_TYPE.WAKEUP === item.eventType) {
        if (sleepFrom && item.eventTimeMs - sleepFrom < TWENTY_HOURS_IN_MS) {
          sleeps.push([sleepFrom, item.eventTimeMs]);
          sleepFrom = null;
        }
      } else {
        patientEventTimes.push(item.eventTimeMs);
        patientEvents.push({
          representativeStrip: [],
          ...item,
          position: patientEvents.length + 1,
          eventTimeMs: item.eventTimeMs,
          triggerOnsetMs: item.triggerOnsetMs,
          triggerTerminationMs: item.triggerTerminationMs,
          eventTimeWaveformIndex: (item.eventTimeMs - recordingStartMs) / 4,
        });
      }
    });

    const aSecondMs = 1000; // 1초
    const dataPointUnitMs = 2 * 60 * aSecondMs; // 2분
    const bandLastPointMs = 58 * 60 * aSecondMs; // 3480000 = 58분 = 1시간 구간의 마지막 포인트
    const aHourMs = 60 * 60 * aSecondMs; // 1시간

    const isSleep = (fromTimestamp) =>
      sleeps.some(
        (value) =>
          fromTimestamp + aHourMs > value[0] && value[1] > fromTimestamp
      );

    // Daily Heart Rate 데이터 구성이 날자 별로 그룹핑하여 하루치 HR 데이터 배열로 제공되어 2 중 반복문이 필요
    // XXX: HRV 에서 사용하는 Daily Heart Rate 데이터 변조 로직 개선 필요?
    patientEventTimes.reverse();
    let curEventTimestamp = patientEventTimes.pop();
    /** 2. 전체 측정 구간에 대한 1시간 단위 밴드(+ 수면 구간 여부) 배열 */
    const hourlyBands = [];
    let currentBand = null;
    /** 1. HR 차트 Line 데이터(PTE 마크를 포함한 데이터) */
    const heartRatePoints = rawDhr.reduce(
      (acc, cur) =>
        acc.concat(
          cur.data.map((element) => {
            const timestamp = element.timestamp * aSecondMs;
            const isLeadOff = element.isLeadOff;

            if (timestamp % aHourMs === 0) {
              // 구간 시작
              const _isSleep = isSleep(timestamp);
              currentBand = {
                className: `pb-huinno pb-${timestamp}${
                  _isSleep ? ' pb-huinno-sleep' : ''
                }`,
                from: timestamp,
                to: timestamp + aHourMs,
                isSleep: _isSleep,
                atTime: timestamp,
              };
            } else {
              // 구간 내
              if (currentBand?.atTime === null && element.hrsAvgAvg !== null) {
                currentBand.atTime = timestamp;
              }
              if (timestamp % aHourMs === bandLastPointMs) {
                // 구간 끝
                hourlyBands.push(currentBand);
              }
            }

            if (isLeadOff) {
              currentBand.className =
                currentBand.className + ' pb-huinno-lead-off';
            }

            const hr = {
              x: timestamp,
              y: element?.hrsAvgAvg,
            };
            while (curEventTimestamp < hr.x) {
              curEventTimestamp = patientEventTimes.pop();
            }
            if (
              hr.x <= curEventTimestamp &&
              curEventTimestamp < hr.x + dataPointUnitMs
            ) {
              hr.pteMarker = {
                enabled: true,
              };
            }

            return hr;
          })
        ),
      []
    );
    heartRatePoints.sort((a, b) => a.x - b.x);
    yield put(
      getDailyHeartRateSucceed({ heartRatePoints, hourlyBands }, patientEvents)
    );
  } catch (error) {
    console.error(error);
    yield put(getDailyHeartRateFailed(error));
  }
}

/**
 * 비트 이벤트 데이터 요청
 *
 * @param {Object} params - The parameters object.
 * @param {number} params.onsetWaveformIndex - The onset waveform index.
 * @param {number} params.terminationWaveformIndex - The termination waveform index.
 * @param {Object} params.options - The options object.
 * @param {boolean} params.options.isOptimisticEventDataUpdate - Flag indicating whether the pre UI update is needed.
 * @param {boolean} params.options.isWholeUnMark - Flag indicating whether the whole unmark is needed.
 * @param {Object} params.options.optimisticEventDataUpdateOption - The pre UI update options.
 * @param {string} params.options.optimisticEventDataUpdateOption.optimisticEventDataUpdateCase - The pre UI update case.
 * @param {number} params.options.optimisticEventDataUpdateOption.onsetWaveformIndex - The onset waveform index for pre UI update.
 * @param {number} params.options.optimisticEventDataUpdateOption.terminationWaveformIndex - The termination waveform index for pre UI update.
 * @param {string} params.options.optimisticEventDataUpdateOption.beatType - The beat type for pre UI update.
 * @param {Object} params.options.optimisticEventDataUpdateOption.reqBody - The request body for pre UI update.
 * @param {string} params.options.optimisticEventDataUpdateOption.tabType - The tab type for pre UI update.
 */
function* _getBeatsNEctopicList({
  onsetWaveformIndex,
  terminationWaveformIndex,
  options: {
    isOptimisticEventDataUpdate,
    isWholeUnMark,
    updatedBeatsDataValue,
    optimisticEventDataUpdateOption,
    releaseRequestedInfoAction,
  } = {
    isOptimisticEventDataUpdate: false,
    isWholeUnMark: false,
    updatedBeatsDataValue: {
      beatType: [],
      hr: [],
      waveformIndex: [],
    },
    optimisticEventDataUpdateOption: {
      reqTime: null,
      optimisticEventDataUpdateCase: null,
      onsetWaveformIndex: null,
      terminationWaveformIndex: null,
      beatType: null,
      reqBody: null,
      tabType: null,
    },
    releaseRequestedInfoAction: null,
  },
}) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    let calculatedOnsetWI = onsetWaveformIndex < 0 ? 0 : onsetWaveformIndex;
    let calculatedTerminationWaveformIndex = terminationWaveformIndex;

    let updatedBeatsData = {
      beatType: [],
      hr: [],
      waveformIndex: [],
    };

    // 해당 saga function 호출 시점 두가지: 1._getEcgRaw, 2.optimistic update 비트 편집(post, patch, delete)
    if (isOptimisticEventDataUpdate) {
      updatedBeatsData = updatedBeatsDataValue;
      yield call(updatePauseTimeEvents, {
        beatsData: updatedBeatsData,
        onsetWI: calculatedOnsetWI,
        terminationWaveformIndex: calculatedTerminationWaveformIndex,
      });
    } else {
      const {
        data: { result: beatsData },
      } = yield call(ApiManager.getBeatsFilterWaveformIndexRange, {
        ecgTestId,
        onsetWaveformIndex: calculatedOnsetWI,
        terminationWaveformIndex,
      });

      const queueValidation = yield select(selectValidation);

      if (!queueValidation) {
        // if (releaseRequestedInfoAction) {
        //   yield put(releaseRequestedInfoAction());
        // }
        throw new Error('queueValidation is false');
      }

      updatedBeatsData = beatsData;
    }

    /**
     * @returns {Object}
     * @property {BeatsNBeatEventsList} totalFreshBeatsNBeatEventsList
     * @property {BeatsNBeatEventsList} freshBeatsNBeatEventsList
     */
    const { totalFreshBeatsNBeatEventsList, freshBeatsNBeatEventsList } =
      yield* postProcessBeatsNEctopicList(
        calculatedOnsetWI,
        calculatedTerminationWaveformIndex,
        updatedBeatsData
      );

    yield put(getBeatsNEctopicListSucceed(totalFreshBeatsNBeatEventsList));
    if (isWholeUnMark) yield put(setEventDetailEditPending(false));
    if (releaseRequestedInfoAction) {
      yield put(releaseRequestedInfoAction());
    }

    // # 10s strip detail open된 케이스
    const tenSecStrip = yield select(getTenSecStrip); // select 호출 순서 중요
    if (tenSecStrip.main.representativeTimestamp) {
      const hasTenSecStrip =
        freshBeatsNBeatEventsList[
          tenSecStrip.representativeCenterWaveformIndex
        ];

      if (hasTenSecStrip) {
        yield put(setTenSecStripRequest(tenSecStrip));
      }
    }
  } catch (error) {
    console.error('error: ', error);
    if (releaseRequestedInfoAction) yield put(releaseRequestedInfoAction());
    yield put(getBeatsNEctopicListFailed(error));
  }
}

/**
 * @function updatePauseTimeEvents
 * @description Optimistic Beats Update결과에 따라 Pause Time Events를 클라이언트에서 직접 계산하여 업데이트하는 saga 함수
 * @param {Object} action - The action object.
 * @param {Object} action.beatsData - The beats data.
 * @param {number} action.onsetWI - The onset waveform index.
 * @param {number} action.terminationWaveformIndex - The termination waveform index.
 * @returns {void}
 */
function* updatePauseTimeEvents(action) {
  try {
    const { beatsData, onsetWI, terminationWaveformIndex } = action;
    const { recordingStartMs } = yield select(selectRecordingTime);
    const { leadOff, data: oldData } = yield select(
      ({ testResultReducer }) => testResultReducer.timeEventsList
    );
    const sidePanelSelectedValue = yield select(
      (state) => selectSelectedEventList(state)[0]
    );
    const onsetMs = recordingStartMs + onsetWI * 4;
    const terminationMs = recordingStartMs + terminationWaveformIndex * 4;

    const eventTypePause = TIME_EVENT_TYPE.PAUSE;
    const eventConstTypes = EVENT_CONST_TYPES[eventTypePause];
    const sortOrder = yield select(selectEventReviewSortOrder);
    const { queryOrderBy: pauseQueryOrderBy } = sortOrder[eventConstTypes]; // 정렬 정보
    const orderOptionList = pauseQueryOrderBy
      .split(',')
      .map((value) => value.trim());

    const firstWIAfterLeadOffEnd = _findFirstWIAfterLeadOffEnd({
      leadOff,
      beatsData,
      onsetMs,
      terminationMs,
      recordingStartMs,
    });
    // pauseGroups에 추가될 pause event들을 추가
    const pauseGroups = [];
    const pauseWaveformIndexList = _findPauseWaveformIndex({
      hrArray: beatsData.hr,
      firstWIAfterLeadOffEnd,
    });
    for (let i = 0; i < pauseWaveformIndexList.length; i++) {
      const indexOfPauseWaveformIndex = pauseWaveformIndexList[i];

      const pauseOnsetWI =
        beatsData.waveformIndex[indexOfPauseWaveformIndex - 1];
      const pauseTermWI = beatsData.waveformIndex[indexOfPauseWaveformIndex];
      const isEventInUpdateRange =
        pauseOnsetWI <= terminationWaveformIndex && pauseTermWI >= onsetWI;

      if (isEventInUpdateRange) {
        const id = `${TIME_EVENT_TYPE.PAUSE}.${pauseOnsetWI}-${pauseTermWI}`;
        const durationMs = (pauseTermWI - pauseOnsetWI) * 4;
        const onsetMs = recordingStartMs + pauseOnsetWI * 4;
        const terminationMs = recordingStartMs + pauseTermWI * 4;

        const pauseEvent = {
          eventType: eventTypePause,
          id,
          durationMs,
          onsetMs,
          terminationMs,
        };

        pauseGroups.push(pauseEvent);
      }
    }

    // make newTimeEventsList
    const filteredOldData = oldData.filter((event) => {
      if (event.type !== EVENT_CONST_TYPES.PAUSE) return true;

      const isEventInUpdateRange =
        event.onsetMs <= terminationMs && event.terminationMs >= onsetMs;
      return !isEventInUpdateRange;
    });
    const newTimeEventsList = [
      ...filteredOldData,
      ...transformTimeEvents([pauseGroups]),
    ];

    yield put(
      getTimeEventsListSucceed(
        leadOff,
        _updatePauseEventPositions(newTimeEventsList, {
          orderOptionList,
        })
      )
    );

    const isSelectedPauseEventEdited = !pauseGroups.some(
      (pauseEvent) => pauseEvent.id === sidePanelSelectedValue?.timeEventId
    );
    if (isSelectedPauseEventEdited) {
      // 사이드패널에서 조회중인 Pause Event가 수정된 경우 "이벤트 정보가 변경되었습니다." 메시지 노출
      yield put(setEventDetailEdited());
    }
  } catch (error) {
    console.error(error);
  }
  function _findFirstWIAfterLeadOffEnd({
    leadOff,
    beatsData,
    onsetMs,
    terminationMs,
    recordingStartMs,
  }) {
    const relevantLeadOff = leadOff.find(
      (event) =>
        event.onsetMs <= terminationMs && event.terminationMs >= onsetMs
    );

    if (!relevantLeadOff) {
      return -1;
    }

    const leadOffTerminationWI =
      (relevantLeadOff.terminationMs - recordingStartMs) / 4;

    // LeadOff 바로 다음에 위치한 WaveformIndex를 찾는 로직
    const firstWIAfterLeadOff = beatsData.waveformIndex.findIndex(
      (waveformIndex) => waveformIndex >= leadOffTerminationWI
    );

    // 만약 LeadOff 바로 다음에 위치한 WaveformIndex를 찾지 못하면 -1을 반환
    return firstWIAfterLeadOff;
  }
  function _findPauseWaveformIndex({ hrArray, firstWIAfterLeadOffEnd }) {
    // R-R간격이 2초 이상이면 Pause (단, 앞비트와 뒷비트 모두 HR을 가지고 있어야 함)
    const thresholdHeartRateValue = 30;
    return hrArray.reduce((resultIndexList, hr, index) => {
      const isFirstWIAfterLeadOffEnd = index === firstWIAfterLeadOffEnd;
      if (isFirstWIAfterLeadOffEnd) {
        // Lead Off 바로 다음에 위치한 WaveformIndex는 pause 리스트에서 제외
        return resultIndexList;
      }
      if (hr !== null && hr <= thresholdHeartRateValue) {
        resultIndexList.push(index);
      }
      return resultIndexList;
    }, []);
  }
  function _updatePauseEventPositions(events, { orderOptionList = [] }) {
    const pauseEvents = [];
    const otherEvents = [];

    for (let i = 0; i < events.length; i++) {
      if (events[i].type === EVENT_CONST_TYPES.PAUSE) {
        pauseEvents.push(events[i]);
      } else {
        otherEvents.push(events[i]);
      }
    }

    // 1차 정렬기준을 먼저 정렬한 후 2차 정렬기준으로 정렬
    pauseEvents.sort((a, b) => {
      for (const option of orderOptionList) {
        const sortFunction = PAUSE_SORT_DEFAULT.sortFunctions[option];
        const result = sortFunction(a, b);
        if (result !== 0) {
          return result; // 첫 번째 0이 아닌 결과를 반환
        }
      }
      return 0; // 모든 기준이 동일할 경우 0 반환
    });

    const updatedPauseEvents = pauseEvents.map((event, index) => ({
      ...event,
      position: index + 1,
    }));

    return [...otherEvents, ...updatedPauseEvents];
  }
}

/**
 * @generator
 * @function postProcessBeatsNEctopicByRange
 * @param {number} onsetWI
 * @param {number} terminationWaveformIndex
 * @param {OptimisticEventDataUpdateForBeats} updatedBeatsData
 *
 * @returns {Object}
 * @property {BeatsNBeatEventsList} totalFreshBeatsNBeatEventsList
 * @property {BeatsNBeatEventsList} freshBeatsNBeatEventsList
 * @property {number} newOnsetWI
 * @property {number} newTerminationWaveformIndex
 */
export function* postProcessBeatsNEctopicList(
  onsetWI,
  terminationWaveformIndex,
  updatedBeatsData
) {
  const leadOffList = yield select((state) =>
    selectFilteredEpisodeOrLeadOffList(
      state,
      Math.max(onsetWI - ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX, 0),
      terminationWaveformIndex + ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX,
      EVENT_CONST_TYPES.LEAD_OFF
    )
  );
  const afList = yield select((state) =>
    selectFilteredEpisodeOrLeadOffList(
      state,
      Math.max(onsetWI - ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX, 0),
      terminationWaveformIndex + ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX,
      EVENT_CONST_TYPES.AF
    )
  );

  const getOverLappedEventList = getOverlapRangeFilter({
    leadOffList,
    afList,
  });
  /**
   * @returns {BeatsNBeatEventsList}
   */
  const freshBeatsNBeatEventsList = getBeatsNBeatEventsList(
    onsetWI,
    terminationWaveformIndex,
    updatedBeatsData,
    undefined,
    getOverLappedEventList
  );
  const staleBeatsNBeatEventsList = yield select(selectBeatsNEctopicList);
  const totalFreshBeatsNBeatEventsList = getTotalFreshBeatsNBeatEventsList(
    staleBeatsNBeatEventsList,
    freshBeatsNBeatEventsList
  );
  return {
    totalFreshBeatsNBeatEventsList,
    freshBeatsNBeatEventsList,
  };
}

function* _getEcgRaw(action) {
  try {
    const { atTime, isInit, isBeatStrip = false } = action;
    const { recordingStartMs, recordingEndMs } = yield select(
      selectRecordingTime
    );

    const ecgTestId = yield select(selectEcgTestId);
    const isRawDataOnly = yield select(selectIsRawDataOnly);
    const context = {
      recordingStartMs,
      recordingEndMs,
      ecgTestId,
      isRawDataOnly,
      atTime,
    };

    const EventReviewPreSignedUrlHandler = new EventReviewPreSignedUrl(
      action,
      context
    );
    EventReviewPreSignedUrlHandler.validate();
    // # STEP1: get Raw
    const { onsetMsQueryStr, terminationMsQueryStr } =
      EventReviewPreSignedUrlHandler.calculateQueryTimes();

    // Preview Tab 인 케이스
    if (!isRawDataOnly) {
      // Raw 데이터를 요청하는 구간으로 Beats + Ectopic 데이터도 요청
      yield put(
        getBeatsNEctopicListRequested(
          parseInt(Math.max(onsetMsQueryStr - recordingStartMs, 0) / 4),
          parseInt((terminationMsQueryStr - recordingStartMs) / 4)
        )
      );
    }

    // # STEP2: Raw 데이터를 요청하는 구간으로 Beats + Ectopic 데이터도 요청
    const range = EventReviewPreSignedUrlHandler.calculateByteRange(
      onsetMsQueryStr,
      terminationMsQueryStr,
      recordingStartMs
    );
    const rawBlob = yield select(selectRawBlob);
    const isAvailableOPFS = rawBlob !== undefined;
    const rawData = yield EventReviewPreSignedUrlHandler.fetchEcgRawData({
      isAvailableOPFS,
      rawBlob,
      range,
      onsetMsQueryStr,
      terminationMsQueryStr,
      recordingStartMs,
    });

    // Transform and Merge Data
    const transformedData =
      EventReviewPreSignedUrlHandler.transformRawList(rawData);
    const existingData = yield select(selectEcgRawList);
    const mergedData = EventReviewPreSignedUrlHandler.mergeRawDataList(
      transformedData,
      existingData
    );
    const newEcgRawList = mergedData || transformedData;

    yield put(getEcgRawSucceed(rawData, action, newEcgRawList));

    // # STEP3: 10s strip detail open된 케이스
    yield call(_tenSecStripOpenedCase, { newLoadedList: transformedData });

    // # STEP4: call extra init
    //  : init으로 호출 됐을 때 init 지점의 전,후 구간 세팅 로직
    if (isInit) {
      // calculateTimeRanges : backward & forward의 시간 영역을 계산
      const {
        backwardOnsetMsQueryStr,
        backwardTerminationMsQueryStr,
        forwardOnsetMsQueryStr,
        forwardTerminationMsQueryStr,
      } = EventReviewPreSignedUrlHandler.calculateTimeRanges({
        onsetMsQueryStr,
        terminationMsQueryStr,
        recordingStartMs,
        recordingEndMs,
      });

      if (backwardTerminationMsQueryStr > recordingStartMs) {
        if (!isRawDataOnly) {
          yield put(
            getBeatsNEctopicListRequested(
              parseInt(
                Math.max(backwardOnsetMsQueryStr - recordingStartMs, 0) / 4
              ),
              parseInt((backwardTerminationMsQueryStr - recordingStartMs) / 4)
            )
          );
        }
      }
      if (forwardOnsetMsQueryStr < recordingEndMs) {
        if (!isRawDataOnly) {
          yield put(
            getBeatsNEctopicListRequested(
              parseInt(
                Math.max(forwardOnsetMsQueryStr - recordingStartMs, 0) / 4
              ),
              parseInt((forwardTerminationMsQueryStr - recordingStartMs) / 4)
            )
          );
        }
      }

      // OPFS 사용여부에 따라 ecgRawData를 받는다.
      const extraEcgRawData =
        yield EventReviewPreSignedUrlHandler.getExtraEcgRawData({
          recordingEndMs,
          backwardOnsetMsQueryStr,
          backwardTerminationMsQueryStr,
          forwardOnsetMsQueryStr,
          forwardTerminationMsQueryStr,
          recordingStartMs,
          isAvailableOPFS,
          rawBlob,
          ecgTestId,
          isBeatStrip,
        });

      let newBackwardLoadedList, newForwardLoadedList;
      newBackwardLoadedList = EventReviewPreSignedUrlHandler.transformRawList(
        extraEcgRawData.backward,
        recordingStartMs
      );
      newForwardLoadedList = EventReviewPreSignedUrlHandler.transformRawList(
        extraEcgRawData.forward,
        recordingStartMs
      );

      const newBackForwardEcgRawList = [
        ...newBackwardLoadedList,
        ...newEcgRawList,
        ...newForwardLoadedList,
      ];

      yield put(
        getEcgRawInitSucceed(
          rawData,
          {
            atTime: undefined,
            isBackward: true,
            isForward: false,
            isInit: true,
            isJumpToTime: false,
            isScroll: true,
            initAtTimeLocalState: action.initAtTimeLocalState,
            backwardListLength: newBackwardLoadedList.length,
            initExtraSelection: {
              backward: newBackwardLoadedList?.length
                ? {
                    onset: newBackwardLoadedList.at(0)?.onsetMs,
                    termination: newBackwardLoadedList.at(1).terminationMs,
                  }
                : undefined,
              forward: newForwardLoadedList?.length
                ? {
                    onset: newForwardLoadedList.at(0)?.onsetMs,
                    termination: newForwardLoadedList.at(1)?.terminationMs,
                  }
                : undefined,
            },
          },
          newBackForwardEcgRawList
        )
      );
    }
  } catch (error) {
    console.error('Error in _getEcgRaw:', error);
    yield put(getEcgRawFailed(error));
  }
}
function* _tenSecStripOpenedCase({ newLoadedList }) {
  const tenSecStrip = yield select(getTenSecStrip);
  if (tenSecStrip.main.representativeTimestamp) {
    const hasTenSecStrip = newLoadedList.find(
      (v) => v.onsetMs === tenSecStrip.main.representativeTimestamp
    );

    if (hasTenSecStrip) {
      yield put(setTenSecStripRequest(tenSecStrip));
    }
  }
}
function* _getEcgsStatistics() {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      data: { result },
    } = yield call(ApiManager.getEcgsStatistics, ecgTestId);
    yield put(getEcgsStatisticsSucceed(result));
  } catch (error) {
    yield put(getEcgsStatisticsFailed(error));
  }
}

function* _getReportStatistics() {
  try {
    const rid = yield select(selectReportId);
    if (!rid) {
      throw new Error('rid is undefined or null');
    }
    const {
      data: { result },
    } = yield call(ApiManager.getReportsStatistics, rid);
    yield put(getReportsStatisticSucceed(result));
  } catch (error) {
    yield put(getReportStatisticFailed(error));
    throw error;
  }
}

function* _getBothStatistics() {
  const allStatisticsPending = yield select(
    (state) => state.testResultReducer.allStatistics.pending
  );
  const ecgStatisticsPending = yield select(
    (state) => state.testResultReducer.ecgStatistics.pending
  );
  const reportStatisticsPending = yield select(
    (state) => state.testResultReducer.reportStatistics.pending
  );

  if (allStatisticsPending) return;

  // GET_ALL_STATISTICS_REQUESTED 가 처리 중이라면 요청 생략
  if (!ecgStatisticsPending) yield put(getEcgsStatisticsRequest());
  if (!reportStatisticsPending) yield put(getReportsStatisticsRequest());
}

function* _getAllStatistics(action) {
  try {
    const CONST_AF_TIME_EVENT_TYPE = TIME_EVENT_TYPE.AF;
    const selectedEcgTestId = yield select(selectEcgTestId);
    const ecgTestId = action.ecgTestId || selectedEcgTestId;
    const [
      {
        data: { result: ecgStatistics },
      },
      {
        data: { result: reportStatistics },
      },
      {
        data: { results: afMinInfoList },
      },
    ] = yield all([
      call(ApiManager.getEcgsStatistics, ecgTestId),
      call(ApiManager.getReportsStatistics, ecgTestId),
      call(ApiManager.getTimeEventList, {
        tid: ecgTestId,
        isMinimum: true,
        eventType: CONST_AF_TIME_EVENT_TYPE,
      }),
    ]);

    // AF는 30초 미만 이벤트는 리포트에서 제외하기 떄문에
    const responseList = transformTimeEvents([afMinInfoList]);
    const { leadOff: staleLeadOffList, data: staleTimeEventList } =
      yield select((state) => state.testResultReducer.timeEventsList);

    let freshLeadOffList = [...staleLeadOffList];
    let freshTimeEventList = [
      ...staleTimeEventList.filter(
        (value) =>
          value.type !==
          getEventInfo({
            timeEventType: CONST_AF_TIME_EVENT_TYPE,
          }).findOne()?.type
      ),
      ...responseList,
    ];

    yield put(getTimeEventsListSucceed(freshLeadOffList, freshTimeEventList));

    const data = { ecgStatistics, reportStatistics, afMinInfoList };
    yield put(getAllStatisticsSucceed(data, Date.now()));
  } catch (error) {
    console.error(error);
    yield put(getAllStatisticsFailed(error));
  }
}

function* _getEventDetail(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const isUpdateFromChart = yield select(
      ({ testResultReducer: state }) =>
        state.eventReview.sidePanelState.isUpdateFromChart
    );

    const {
      position,
      eventType,
      searchCondition, // beat event: 선택한 waveformIndex, time event: 선택한 이벤트 eventId type
      selectedGlobalMs,
      //
      isSelectedEventUpdate,
      isIgnoreTimeJump,
      isGeminyType,
      inCludedGeminyEvent,
    } = action;
    let iterationFlag = true;
    let newSelectedEvent = null;
    let retry = { times: 5 };
    const channel = yield call(retryGetEventDetail, retry);
    yield put(setEventDetailFetchingRetryTimes(retry.times));

    /******************************************************************************************************************/
    /* # STEP INDEX                                                                                                   */
    /*                                                                                                                */
    /* STEP1. event detail fetching condition 3가지(patient, beat, time event detail)                                 */
    /*   ㄴ condition1. fetching 'patient event' detail                                                               */
    /*   ㄴ condition2. fetching 'beat event' detail                                                                  */
    /*       - PROCESS2.1 [call api] getEctopicListFilterType (get event detail by event position)                    */
    /*                  : CASE - side panel에서 포지션 입력으로 포지션 이동                                           */
    /*       - PROCESS2.2 [call api] getEctopicListFilterWaveformIndexRange (get event detail by waveformIndex)       */
    /*                  : 아래 2가지 유저 케이스에 해당하는 condition                                                 */
    /*                     - CASE1 event marker click                                                                 */
    /*                     - CASE2 move position by prev, next icon on side panel                                     */
    /*           ㄴ PROCESS2.2.1 retry                                                                                */
    /*       - PROCESS2.2.2 retry                                                                                     */
    /*   ㄴ condition3. fetching 'time event' detail                                                                  */
    /*       - PROCESS3.1 [setting] timeEventId                                                                       */
    /*         : 3가지 기준에 의해서 timeEventId를 결정(position, searchCondition(hasTimeEventId), selectedGlobalMs)  */
    /*       - PROCESS3.2 whether having timeEventId or not                                                           */
    /*         - PROCESS3.2.1 retry                                                                                   */
    /*         - PROCESS3.2.2 [call api] getTimeEventDetail                                                           */
    /*       - PROCESS3.3 [setting] position by selected event                                                        */
    /* STEP2. [setting] "responseData" variable by selection event type detail and it depend on step1 condition       */
    /* STEP3. [setting] selected value list redux state at sidePanel                                                  */
    /******************************************************************************************************************/
    try {
      let responseData;
      while (iterationFlag) {
        // STEP1. event detail fetching condition 3가지(patient, beat, time event detail)
        const eventInfo = getEventInfo({ type: eventType }).findOne();
        if (PATIENT_EVENT_TYPE_LIST.includes(eventType)) {
          // condition1. fetching patient event detail
          //  - (Findings 와 HR 의 최신 값 확인을 위함)
          if (!isUpdateFromChart && !isIgnoreTimeJump) {
            const selectedPatientEvent = yield select(
              ({ testResultReducer }) => {
                const { patientEvents } = testResultReducer;

                switch (eventType) {
                  case EVENT_CONST_TYPES.PATIENT_FOUND:
                    return patientEvents.filter(
                      ({ hasFindings }) => hasFindings === true
                    )[position - 1];
                  case EVENT_CONST_TYPES.PATIENT_NOT_FOUND:
                    return patientEvents.filter(
                      ({ hasFindings }) => hasFindings === false
                    )[position - 1];
                  default:
                    return patientEvents.find(
                      (value) => value.position === position
                    );
                }
              }
            );

            // 이벤트 조회시 onset 시점으로 ECG 차트 목록 이동
            yield put(setChartSelectedStrip(selectedPatientEvent.eventTimeMs));
          }

          const getHasFindings = (eventType) => {
            switch (eventType) {
              case EVENT_CONST_TYPES.PATIENT_FOUND:
                return { hasFindings: true };
              case EVENT_CONST_TYPES.PATIENT_NOT_FOUND:
                return { hasFindings: false };
              default:
                return {};
            }
          };

          const {
            data: { results },
          } = yield call(ApiManager.getPatientTriggerEventList, {
            tid: ecgTestId,
            position,
            excludeSleep: true,
            ...getHasFindings(eventType),
          });
          responseData = results[0];

          // 응답된 데이터로 Patient Triggered Event 목록 업데이트
          yield call(_setPatientTriggeredEventList, {
            ...responseData,
          });

          iterationFlag = false;
        } else if (eventInfo?.ectopicType || eventInfo?.geminyType) {
          // condition2. fetching beat event detail
          let ectopicInfo;
          const { beatType, ectopicType, geminyType } = eventInfo;
          const sortOrder = yield select(selectEventReviewSortOrder);
          const { queryOrderBy } = sortOrder[eventType]; // 정렬 정보

          if (position) {
            // PROCESS2.1 [call api] getEctopicListFilterType (get event detail by event position)
            const apiCallFunction = determineApiCallFunction({
              geminyType,
              inCludedGeminyEvent,
            });
            const apiParams = createApiParams({
              ecgTestId,
              beatType,
              position,
              queryOrderBy,
              inCludedGeminyEvent,
              geminyType,
              ectopicType,
            });

            // 포지션 입력으로 포지 이동(get event detail by event position)
            const {
              data: { results },
            } = yield call(apiCallFunction, apiParams);
            ectopicInfo = results[0];
          } else {
            // PROCESS2.2 [call api] getEctopicListFilterWaveformIndexRange (get event detail by waveformIndex)
            //  : 아래 2가지 유저 케이스에 해당하는 condition
            //    - CASE1 event marker click
            //    - CASE2 move position by prev, next icon on side panel

            const {
              data: { results },
            } = yield call(ApiManager.getEctopicListFilterWaveformIndexRange, {
              ecgTestId,
              onsetWaveformIndex: searchCondition, // onset, termination을 같게 하면 클릭한 지점이라는 의미
              terminationWaveformIndex: searchCondition,
              ordering: queryOrderBy,
            });

            if (results.length > 0) {
              ectopicInfo = results[0];
              if (isGeminyType) {
                ectopicInfo.waveformIndex = ectopicInfo.geminy.waveformIndex;
              }

              const ecgStatistics = yield select(
                (state) => state.testResultReducer.ecgStatistics
              );
              const filteredBeatEventSection = getEventInfo({
                beatType: ectopicInfo.beatType,
                ectopicType: ectopicInfo.ectopicType,
              }).findOne()?.eventSection;

              /**
               * # 이벤트 개수 동기화 작업
               *   - event review tab의 차트리스트에서 이벤트 선택시 선택된 이벤트 개수가
               *     위 api(getEctopicListFilterWaveformIndexRange) response값과 다를 경우 statistic의 ecgStatistics를 update
               */
              if (
                ecgStatistics.data[filteredBeatEventSection] !==
                ectopicInfo.totalEventCount
              ) {
                const copyEcgStatistics = rfdcClone(ecgStatistics.data);
                copyEcgStatistics[filteredBeatEventSection] =
                  ectopicInfo.totalEventCount;
                yield put(getEcgsStatisticsSucceed(copyEcgStatistics));
              }
            } else {
              if (retry.times <= 1) {
                yield put(setEventDetailFetchingRetryTimes(retry.times));
                throw new Error(
                  'retryTimes is less than 1(PROCESS2.2.1 retry)'
                );
              }
              // PROCESS2.2.1 retry
              retry.times = yield take(channel); // try retry
              // console.info('retryTimes: ', retryTimes);
              yield put(setEventDetailFetchingRetryTimes(retry.times));
            }
          }

          if (!ectopicInfo) {
            // PROCESS2.2.2 retry
            if (retry.times <= 1) {
              yield put(setEventDetailFetchingRetryTimes(retry.times));
              throw new Error('retryTimes is less than 1(PROCESS2.2.2 retry)');
            }
            retry.times = yield take(channel); // try retry
            yield put(setEventDetailFetchingRetryTimes(retry.times));
            // console.info('retryTimes: ', retryTimes);
          } else {
            iterationFlag = false;
            responseData = {
              ...ectopicInfo,
              onsetWaveformIndex: ectopicInfo.waveformIndex.at(0),
              terminationWaveformIndex: ectopicInfo.waveformIndex.at(-1),
            };

            if (!isUpdateFromChart && !isIgnoreTimeJump) {
              // 이벤트 조회시 onset 시점으로 ECG 차트 목록 이동
              const { recordingStartMs } = yield select(selectRecordingTime);
              yield put(
                setChartSelectedStrip(
                  recordingStartMs + responseData.onsetWaveformIndex * 4
                )
              );
            }

            if (isSelectedEventUpdate) {
              newSelectedEvent = {
                position: responseData.position,
                waveformIndex: responseData.waveformIndex,
              };
            }
          }
        } else {
          // condition3. fetching time event detail
          // PROCESS3.1 [setting] timeEventId
          //  : 3가지 기준에 의해서 timeEventId를 결정(position, searchCondition(hasTimeEventId), selectedGlobalMs)
          let timeEventId = undefined;
          const hasTimeEventId = searchCondition !== undefined; // pre ui update 이후 timeEventId 없는 상태에서 이벤트 상세 조회 case
          if (position) {
            const timeEventInfoList = yield select(
              (state) => state.testResultReducer.timeEventsList.data
            );
            // pause의 조회 조건의 정렬(time, r-r)에 따라 pause event의 position이 달라질 수 있음
            let timeEventInfo = timeEventInfoList.find(
              (timeEventInfo) =>
                timeEventInfo.position === position &&
                timeEventInfo.type === eventType
            );

            const isPauseEvent = eventInfo.type === EVENT_CONST_TYPES.PAUSE;
            if (isPauseEvent) {
              const pauseEvents = timeEventInfoList.filter(
                (event) => event.type === EVENT_CONST_TYPES.PAUSE
              );

              timeEventInfo = pauseEvents[position - 1];
            }

            if (
              !isUpdateFromChart &&
              !isIgnoreTimeJump &&
              timeEventInfo !== undefined
            ) {
              // 이벤트 조회시 onset 시점으로 ECG 차트 목록 이동
              yield put(setChartSelectedStrip(timeEventInfo.onsetMs));
            }
            if (timeEventInfo === undefined) {
              throw new Error('timeEventInfo is undefined');
            }
            timeEventId = timeEventInfo.timeEventId;
          } else if (hasTimeEventId) {
            timeEventId = searchCondition;
          } else if (selectedGlobalMs) {
            // Async event marker ui update & fetching detail continuously
            const timeEventInfoList = yield select(
              (state) => state.testResultReducer.timeEventsList.data
            );

            const filterTimeEventInfoBySelectedGlobalMs =
              timeEventInfoList.filter(
                (timeEventInfo) =>
                  timeEventInfo.onsetMs <= selectedGlobalMs &&
                  selectedGlobalMs <= timeEventInfo.terminationMs &&
                  timeEventInfo.type === eventType
              );
            timeEventId =
              filterTimeEventInfoBySelectedGlobalMs?.[0].timeEventId;
          }

          // PROCESS3.2 whether having timeEventId or not
          if (!timeEventId) {
            if (retry.times <= 1) {
              yield put(setEventDetailFetchingRetryTimes(retry.times));
              throw new Error('retryTimes is less than 1(PROCESS3.2.1 retry)');
            }
            // PROCESS3.2.1 retry
            retry.times = yield take(channel); // try retry
            // console.info('retryTimes: ', retryTimes);
            yield put(setEventDetailFetchingRetryTimes(retry.times));

            const filterTaskOfGetTimeEvent = yield select(
              selectFilterTaskOfGetTimeEvent
            );
            const filterTaskOfPostTimeEvent = yield select(
              selectFilterTaskOfPostTimeEvent
            );
            const hasTaskOfGetTimeEvent = filterTaskOfGetTimeEvent.length > 0;
            const hasTaskOfPostTimeEvent = filterTaskOfPostTimeEvent.length > 0;
            if (hasTaskOfGetTimeEvent || hasTaskOfPostTimeEvent) {
              yield put(schedulingGetEventDetail());
              if (hasTaskOfGetTimeEvent) {
                throw new Error('has task of GetTimeEvent in taskQueue');
              }
              if (hasTaskOfPostTimeEvent) {
                throw new Error('has task of PostTimeEvent in taskQueue');
              }
            }
          } else {
            // PROCESS3.2.2 [call api] getTimeEventDetail
            iterationFlag = false;

            let apiCallParams = { timeEventId };
            let apiCallFunction = ApiManager.getTimeEventDetail;

            if (eventType === EVENT_CONST_TYPES.PAUSE) {
              apiCallFunction = ApiManager.getPauseEventDetail;
              apiCallParams.ecgTestId = ecgTestId;
              apiCallParams.episodeId = timeEventId;
            }

            const {
              data: { result },
            } = yield call(apiCallFunction, apiCallParams);
            responseData = result;

            const filterTimeEventListByTimeEventId = yield select(
              ({ testResultReducer }) =>
                testResultReducer.timeEventsList.data.find(
                  (value) => value.timeEventId === timeEventId
                )
            );

            if (filterTimeEventListByTimeEventId === undefined) {
              throw new Error('filterTimeEventListByTimeEventId is undefined');
            }

            // PROCESS3.3 [setting] position by selected event
            // * 아래 로직은 position 설정을 위한 과정
            //    - 1. 위 api response(result)에 조회 하고자하는 time event의 position 정보가 없음
            //    - 2. testResultReducer.timeEventsList.data에는 position 정보가 있음
            //      - but, no position data of time event List fetching api response
            //      - location of setting time event position
            //          : post process of _getTimeEventsList
            //          : > transformTimeEvents
            //    - 3. 그래서 result의 timeEventId와, testResultReducer.timeEventsList.data의 timeEventId를 비교해 position 정보를 획득
            if (isSelectedEventUpdate) {
              newSelectedEvent = {
                position: filterTimeEventListByTimeEventId.position,
                timeEventId,
              };
            }
          }
        }
      }

      // STEP2. [setting] "responseData" variable by selection event type detail and it depend on step1 condition
      // STEP3. [setting] selected value list redux state at sidePanel
      //  - Events탭 에서 조회 시 selectedValue가 수정될 필요 있음(position, timeEventId, waveformIndex)
      const prevSelectedEventList = yield select(selectSelectedEventList);
      const newSelectedEventList = getUpdatedSelectedEventList({
        prevSelectedEventList,
        newSelectedEvent,
        isSelectedEventUpdate,
      });

      if (action.releaseRequestedInfoAction) {
        yield put(action.releaseRequestedInfoAction());
      }
      yield put(getEventDetailSucceed(responseData, newSelectedEventList));
      // yield put(conditionMet());
    } catch (error) {
      // console.info('retryTimes: ', retryTimes);
      console.error('error during while getEventDetail ', error);
      if (action.releaseRequestedInfoAction) {
        yield put(action.releaseRequestedInfoAction());
      }
    } finally {
      // console.info('retryTimes: ', retryTimes);
      if (!iterationFlag) {
        retry.times = yield take(channel); // try retry
      }
    }
  } catch (error) {
    console.error('error during getEventDetail', error);
    yield put(getEventDetailFailed(error));
    if (action.releaseRequestedInfoAction) {
      yield put(action.releaseRequestedInfoAction());
    }
    // yield put(conditionMet());
  }
  function determineApiCallFunction({ geminyType, inCludedGeminyEvent }) {
    if (!geminyType) return ApiManager.getEctopicListFilterType;
    if (inCludedGeminyEvent) return ApiManager.getGeminyListFilterWaveformIndex;
    return ApiManager.getGeminyListFilterType;
  }

  function createApiParams({
    ecgTestId,
    beatType,
    position,
    queryOrderBy,
    inCludedGeminyEvent,
    geminyType,
    ectopicType,
  }) {
    if (inCludedGeminyEvent) {
      return {
        ecgTestId,
        ordering: queryOrderBy,
        waveformIndex: inCludedGeminyEvent.waveformIndex[0],
      };
    }

    return {
      ecgTestId,
      beatType,
      position,
      ordering: queryOrderBy,
      ...optionalParameter({
        key: 'geminyType',
        value: geminyType,
        condition: isNotNullOrUndefined(geminyType),
      }),
      ...optionalParameter({
        key: 'ectopicType',
        value: ectopicType,
        condition: isNotNullOrUndefined(ectopicType),
      }),
    };
  }
}

function* _getEventDetailRetry() {
  const eventDetailFetchingCondition = yield select(
    selectEventDetailFetchingCondition
  );

  yield put(
    enqueueRequest({
      requestStatement: { type: GET_EVENT_DETAIL },
      requestPosition: eventDetailFetchingCondition.position,
      beatEventWaveformIndex: eventDetailFetchingCondition.waveformIndex,
      getAction: (options = {}) => {
        return getEventDetailRequested({
          ...eventDetailFetchingCondition,
        });
      },
      succeedCallback: () => {},
      failedCallback: () => {},
    })
  );
}

function* fetchPTEEvent(ecgTestId, position, prevSelectedEventList) {
  const {
    data: { results },
  } = yield call(ApiManager.getPatientTriggerEventList, {
    tid: ecgTestId,
    position,
    excludeSleep: true,
    reportIncluded: true,
  });

  const responseData = {
    ...results[0],
    reportSection: REPORT_SECTION.PTE,
  };

  yield put(setChartSelectedStrip(responseData.eventTimeMs));
  yield put(getEventDetailSucceed(responseData, prevSelectedEventList));
  yield call(_setPatientTriggeredEventList, responseData);

  if (responseData.representativeStrip[0]) {
    const { representativeOnsetIndex, representativeTerminationIndex } =
      responseData.representativeStrip[0];
    yield put(
      setRepresentativeStripInfo({
        selectedMs: null,
        representativeOnsetIndex: representativeOnsetIndex,
        representativeTerminationIndex: representativeTerminationIndex,
      })
    );
  } else {
    yield put(resetRepresentativeStripInfo());
  }

  return responseData;
}

function* fetchReportEvent(
  rid,
  reportSection,
  position,
  recordingStartMs,
  prevSelectedEventList
) {
  const {
    data: { results },
  } = yield call(ApiManager.getReportEvents, {
    rid,
    reportSection,
    position,
  });
  if (results.length === 0) return;
  const responseData = results[0];
  const {
    representativeOnsetIndex,
    representativeTerminationIndex,
    timeEvent: timeEventDetail,
  } = responseData;

  const selectedTimeWaveformIndex =
    representativeOnsetIndex +
    Math.floor((representativeTerminationIndex - representativeOnsetIndex) / 2);

  yield put(
    setChartSelectedStrip(recordingStartMs + selectedTimeWaveformIndex * 4)
  );

  if (timeEventDetail?.ectopicType) {
    const ectopicEventType = getEventInfo({
      beatType: BEAT_TYPE[timeEventDetail.eventType],
      ectopicType: timeEventDetail.ectopicType,
    }).findOne()?.type;

    const isGeminyType = Boolean(timeEventDetail?.extra?.geminyType);

    yield put(
      getEventDetailRequested({
        eventType: ectopicEventType,
        searchCondition: timeEventDetail.onsetWaveformIndex,
        position: null,
        //
        isSelectedEventUpdate: false,
        isIgnoreTimeJump: true,
        //
        isGeminyType,
      })
    );
  } else {
    yield put(getEventDetailSucceed(timeEventDetail, prevSelectedEventList));
  }

  yield put(
    setRepresentativeStripInfo(getInitRepresentativeStripInfo(responseData))
  );

  return responseData;
}

function* _getReportEvent(action) {
  try {
    const prevSelectedEventList = yield select(selectSelectedEventList);
    const { reportSection, position } = action;

    if (position < 1) {
      yield put(getEventDetailSucceed(null, prevSelectedEventList));
      return;
    }

    let responseData;

    if (reportSection === REPORT_SECTION.PTE) {
      const ecgTestId = yield select(selectEcgTestId);
      responseData = yield call(
        fetchPTEEvent,
        ecgTestId,
        position,
        prevSelectedEventList
      );
    } else {
      const rid = yield select(selectReportId);
      const { recordingStartMs } = yield select(selectRecordingTime);
      responseData = yield call(
        fetchReportEvent,
        rid,
        reportSection,
        position,
        recordingStartMs,
        prevSelectedEventList
      );
    }

    yield put(getReportEventsSucceed(responseData));
  } catch (error) {
    console.error(error);
    yield put(getReportEventsFailed(error));
  }
}

function* _postReportEvent(action) {
  try {
    const { newReportEventInfo } = action;
    const rid = yield select(selectReportId);
    const param = { ...newReportEventInfo, rid };
    if (!param['timeEventId']) delete param['timeEventId'];
    if (!param['onsetWaveformIndex']) delete param['onsetWaveformIndex'];
    const {
      data: { result },
    } = yield call(ApiManager.postReportEvents, param);
    yield put(postReportEventSucceed(result));
  } catch (error) {
    yield put(postReportEventFailed(error));
  }
}

function* _updateReportEvent(action) {
  try {
    const { reportEventId, newReportEventInfo } = action;
    yield call(ApiManager.updateReportEvents, {
      reportEventId,
      ...newReportEventInfo,
    });
    yield put(updateReportEventSucceed({}, action.isPreUpdate));
    if (action.afterAction) yield put(action.afterAction);
  } catch (error) {
    yield put(updateReportEventFailed(error, action.isPreUpdate));
  }
}

function* _deleteReportEvent(action) {
  try {
    const { reportEventId } = action;

    yield call(ApiManager.deleteReportEvents, { reportEventId });
    yield put(deleteReportEventSucceed());
    if (action.afterAction) yield put(action.afterAction);
  } catch (error) {
    yield put(deleteReportEventFailed(error));
  }
}

function* _getNextReportEventHandler(action) {
  const { editedReportSection } = action;
  const sideTabValue = yield select(selectSideTabValue);
  const selectedValue = yield select(
    (state) => selectSelectedEventList(state)[0]
  );
  const reportStatisticsData = yield select(
    ({ testResultReducer: state }) => state.reportStatistics.data
  );
  /** @type {import('redux/container/fragment/test-result/side-panel/ReportEventEditorFragmentContainer').ReportEvent} */
  const prevReportDetailData = yield select(
    ({ testResultReducer: state }) => state.reportDetail.data
  );

  try {
    yield put(getReportsStatisticsRequest());
    if (sideTabValue !== EVENT_GROUP_TYPE.REPORT || !selectedValue)
      throw new Error('');

    const { position: curPosition } = selectedValue;
    const curSectionNum = reportStatisticsData[editedReportSection];

    if (curPosition <= curSectionNum) {
      // 마지막 Position 이 아닌 Report Event 가 삭제된 경우
      yield call(_getReportEvent, {
        reportSection: editedReportSection,
        position: curPosition,
      });
    } else {
      // 마지막 Position 이 삭제된 경우
      yield call(_getReportEvent, {
        reportSection: editedReportSection,
        position: curPosition - 1,
      });
      yield put(
        setSidePanelSelectedEventList(
          [{ ...selectedValue, position: curPosition - 1 }],
          false,
          true
        )
      );
    }
  } catch (error) {
    yield put(getReportEventsSucceed(prevReportDetailData));
  }
}

function* _updatePteReportInfo(action) {
  try {
    const { isRemove } = action.payload;
    const { recordingStartMs } = yield select(selectRecordingTime);
    const {
      id: pteId,
      eventBy,
      eventTimeMs,
    } = yield select((state) => state.testResultReducer.eventDetail.data);

    let newPTEInfo = {};
    let patientEventInfo = null;

    if (isRemove) {
      // PTE 를 Report 에서 제외하는 경우, Trigger 조건에 해당하는 기본 90초 위치로 초기화
      newPTEInfo.reportIncluded = false;

      const eventTimeWaveformIndex = (eventTimeMs - recordingStartMs) / 4;
      if (eventBy === Const.PATIENT_EVENT_BY.BUTTON) {
        // 버튼 PTE 90초 구간 초기화(PTE 지점으로부터 이전, 이후 45초)
        const fortyFiveSecMs = 45000;
        const fortyFiveSecWaveformLength = fortyFiveSecMs / 4;

        newPTEInfo.triggerOnsetMs = eventTimeMs - fortyFiveSecMs;
        newPTEInfo.triggerTerminationMs = eventTimeMs + fortyFiveSecMs;
        newPTEInfo.triggerOnsetWaveformIndex =
          eventTimeWaveformIndex - fortyFiveSecWaveformLength;
        newPTEInfo.triggerTerminationWaveformIndex =
          eventTimeWaveformIndex + fortyFiveSecWaveformLength;
      } else {
        // 챗봇 PTE 90초 구간 초기화(PTE 지점으로부터 이전 80초, 이후 10초)
        const eightySecMs = 80000;
        const tenSecMs = 10000;
        const eightySecWaveformLength = eightySecMs / 4;
        const tenSecWaveformLength = tenSecMs / 4;

        newPTEInfo.triggerOnsetMs = eventTimeMs - eightySecMs;
        newPTEInfo.triggerTerminationMs = eventTimeMs + tenSecMs;
        newPTEInfo.triggerOnsetWaveformIndex =
          eventTimeWaveformIndex - eightySecWaveformLength;
        newPTEInfo.triggerTerminationWaveformIndex =
          eventTimeWaveformIndex + tenSecWaveformLength;
      }
    } else {
      newPTEInfo.reportIncluded = true;

      const reportEventEditorState = yield select(selectReportEventEditorState);
      const {
        selectedReportSection,
        mainRepresentativeInfo,
        subRepresentativeInfo,
      } = reportEventEditorState;
      newPTEInfo.triggerOnsetMs =
        mainRepresentativeInfo.representativeOnsetIndex * 4 + recordingStartMs;
      newPTEInfo.triggerTerminationMs =
        mainRepresentativeInfo.representativeTerminationIndex * 4 +
        recordingStartMs;
      newPTEInfo.triggerOnsetWaveformIndex =
        mainRepresentativeInfo.representativeOnsetIndex;
      newPTEInfo.triggerTerminationWaveformIndex =
        mainRepresentativeInfo.representativeTerminationIndex;

      // PTE 의 Report Event(보조 Strip) 정보있을 시 구성
      if (!subRepresentativeInfo.isRemoved) {
        const rid = yield select(selectReportId);
        patientEventInfo = {
          rid,
          patientEventId: pteId,
          reportSection: selectedReportSection,
          representativeOnsetIndex:
            subRepresentativeInfo.representativeOnsetIndex,
          representativeTerminationIndex:
            subRepresentativeInfo.representativeTerminationIndex,
          amplitudeRate: subRepresentativeInfo.amplitudeRate,
        };
      }
    }

    // PTE 정보 업데이트 요청, Report Event(보조 Strip) 제거됨
    const {
      data: { result: freshPTE },
    } = yield call(ApiManager.patchPatientTriggerEvent, {
      pteId: pteId,
      ...newPTEInfo,
    });
    // PTE 의 Report Event(보조 Strip) 정보있을 시 생성 요청
    let freshRepresentativeStrip = [];
    if (patientEventInfo) {
      const {
        data: { result: freshPTEReportEvent },
      } = yield call(ApiManager.postReportEvents, patientEventInfo);
      freshRepresentativeStrip.push(freshPTEReportEvent);
    }
    // 삭제인 경우 Report Statistics 중 PTE Section 의 갯수 감소, 반대 경우는 Events Tab 에서만 가능한 시나리오이며 Report Tab 이동 시 Report Statistics 를 조회 하기 때문에 제어 불필요
    if (isRemove) {
      yield put(adjustReportStatistic(REPORT_SECTION.PTE, -1));
    }

    // 응답된 데이터로 Patient Triggered Event 목록 업데이트
    const updatedPTEInfo = {
      ...freshPTE,
      representativeStrip: freshRepresentativeStrip,
    };
    yield call(_setPatientTriggeredEventList, updatedPTEInfo);

    const sideTabValueState = yield select(selectSideTabValue);
    if (sideTabValueState === EVENT_GROUP_TYPE.REPORT) {
      if (patientEventInfo) {
        const { representativeOnsetIndex, representativeTerminationIndex } =
          patientEventInfo;
        yield put(
          setRepresentativeStripInfo({
            selectedMs: null,
            representativeOnsetIndex: representativeOnsetIndex,
            representativeTerminationIndex: representativeTerminationIndex,
          })
        );
      } else {
        yield put(resetRepresentativeStripInfo());
      }
    }
    if (sideTabValueState === EVENT_GROUP_TYPE.REPORT && isRemove) {
      // PTE 정보 조회요청 발생
      yield put(getNextReportEvent(REPORT_SECTION.PTE));
    } else {
      // 응답된 데이터로 조회중인 Event Detail State 업데이트
      // 기존 selectedValue 참조 필요
      const prevSelectedEventList = yield select(selectSelectedEventList);
      yield put(getEventDetailSucceed(updatedPTEInfo, prevSelectedEventList));
      // 응답된 데이터로 조회중인 Report Event Detail State 업데이트
      yield put(
        getReportEventsSucceed({
          ...updatedPTEInfo,
          reportSection: REPORT_SECTION.PTE,
        })
      );
    }

    yield put(updatePTEReportInfoSucceed({}));
  } catch (error) {
    yield put(updatePTEReportInfoFailed(error));
  }
}

function* _getFindingsTemplate() {
  try {
    const ecgTestId = yield select(selectEcgTestId);

    const { data } = yield call(ApiManager.getEcgTestFindingsTemplate, {
      ecgTestId,
    });
    yield put(getFindingsTemplateSucceed(data));
  } catch (error) {
    console.error(error);
    yield put(getFindingsTemplateFailed(error));
  }
}

/** 업데이트된 데이터로 Patient Triggered Event 목록 업데이트 */
function* _setPatientTriggeredEventList(updatedPTEInfo) {
  /** @type {Array<import('component/hook/useGetPatientEvents').PatientTriggeredEventInfo>} */
  const patientEventList = yield select(
    ({ testResultReducer: state }) => state.patientEvents
  );

  const stalePTEInfo = patientEventList.find(
    (value) => value.id === updatedPTEInfo.id
  );
  const freshPatientList = rfdcClone(
    patientEventList.filter((value) => value.id !== updatedPTEInfo.id)
  );

  freshPatientList.push({
    ...updatedPTEInfo,
    position: stalePTEInfo.position,
    eventTimeWaveformIndex: stalePTEInfo.eventTimeWaveformIndex,
  });
  freshPatientList.sort((a, b) => a.position - b.position);
  yield put(setPatientTriggeredEventList(freshPatientList));
}

function* _postBeats(action) {
  try {
    const {
      reqBody,
      onsetWaveformIndex,
      terminationWaveformIndex,
      suffix,
      tabType,
    } = action;
    const ecgTestId = yield select(selectEcgTestId);
    const requestAt = new Date().getTime();
    const requestStatement = {
      ecgTestId,
      requestType: suffix,
      reqBody: reqBody,
    };

    /**
     * # Process Step
     * Step1. optimisticEventDataUpdate
     * Step2. setting redux state; optimisticEventData
     * Step3. Call api; Edit Beat Event Type
     */
    const { searchOnsetRequest, searchTerminationRequest } =
      getSearchBeatsNEctopicListRangeAfterUpdateEvent(
        onsetWaveformIndex,
        terminationWaveformIndex
      );
    const reqOption = {
      isWholeUnMark: null,
      isOptimisticEventDataUpdate: true,
      optimisticEventDataUpdateOption: {
        reqTime: Date.now(),
        optimisticEventDataUpdateCase: optimisticEventDataUpdateCase.postBeats,
        reqBody,
        tabType,
        onsetWaveformIndex,
        terminationWaveformIndex,
      },
    };
    /**
     * @type {import('@type/optimisticUpdate/type').OptimisticEventDataUpdateForBeats}
     */
    let updatedBeatsData = {
      beatType: [],
      hr: [],
      waveformIndex: [],
    };

    // Step1. optimisticEventDataUpdate;
    updatedBeatsData = yield* optimisticBeatEventUpdateFn(
      reqOption.optimisticEventDataUpdateOption
    );
    reqOption.updatedBeatsDataValue = updatedBeatsData.result;
    const isValidationSuccessful =
      typeof updatedBeatsData.isValidationSuccessful === 'boolean' &&
      updatedBeatsData?.isValidationSuccessful;

    if (isValidationSuccessful === false) {
      yield delay(0); // ExceedBpmLimitDialog를 위한 delay

      let error = new Error('optimistic update');
      Object.assign(error, {
        classification: EventEditErrorClass.OPTIMISTIC_EVENT_UPDATE,
        ...updatedBeatsData,
      });
      throw error;
    } else {
      // Step2. setting redux state; optimisticEventData
      yield put(
        getBeatsNEctopicListRequested(
          searchOnsetRequest,
          searchTerminationRequest,
          reqOption
        )
      );
      // Step3. Call api; Edit Beat Event Type
      yield put(
        enqueueRequest({
          requestStatement,
          succeedCallback,
          failedCallback,
        })
      );
    }

    function* succeedCallback({ data }) {
      yield put(
        postBeatsSucceed(data, {
          requestAt,
          validResult: validateBeatEditResponse(reqBody, data.result),
          editTargetBeatType: reqBody.beatType,
        })
      );
      yield put(getBothStatisticsDelegated());
    }
    function* failedCallback(error) {
      yield put(postBeatsFailed(error));
    }
  } catch (error) {
    yield put(postBeatsFailed(error));
  }
}

function* _patchBeats(action) {
  try {
    const {
      reqBody,
      onsetWaveformIndex,
      terminationWaveformIndex,
      suffix,
      tabType,
    } = action;
    const ecgTestId = yield select(selectEcgTestId);
    const isWholeUnMark = yield select(selectIsWholeUnMark);
    const requestAt = new Date().getTime();
    const requestStatement = {
      ecgTestId,
      requestType: suffix,
      reqBody: reqBody,
    };

    if (isWholeUnMark) yield put(setEventDetailEditPending(true));

    /**
     * # Process Step
     * Step1. optimisticEventDataUpdate
     * Step2. setting redux state; optimisticEventData
     * Step3. Call api; Edit Beat Event Type
     */
    const { searchOnsetRequest, searchTerminationRequest } =
      getSearchBeatsNEctopicListRangeAfterUpdateEvent(
        onsetWaveformIndex,
        terminationWaveformIndex
      );
    const reqOption = {
      isWholeUnMark,
      isOptimisticEventDataUpdate: true,
      optimisticEventDataUpdateOption: {
        reqTime: Date.now(),
        optimisticEventDataUpdateCase:
          optimisticEventDataUpdateOptionMap[action.tabType],
        reqBody,
        tabType,
        onsetWaveformIndex,
        terminationWaveformIndex,
      },
    };
    /**
     * @type {import('@type/optimisticUpdate/type').OptimisticEventDataUpdateForBeats}
     */
    let updatedBeatsData = {
      beatType: [],
      hr: [],
      waveformIndex: [],
    };

    // Step1. optimisticEventDataUpdate;
    updatedBeatsData = yield* optimisticBeatEventUpdateFn(
      reqOption.optimisticEventDataUpdateOption
    );
    reqOption.updatedBeatsDataValue = updatedBeatsData.result;
    const isValidationSuccessful =
      typeof updatedBeatsData.isValidationSuccessful === 'boolean' &&
      updatedBeatsData?.isValidationSuccessful;

    if (isValidationSuccessful === false) {
      let error = new Error('optimistic update');
      Object.assign(error, {
        classification: EventEditErrorClass.OPTIMISTIC_EVENT_UPDATE,
        ...updatedBeatsData,
      });
      throw error;
    } else {
      yield put(setEventDetailEdited());
      // Step2. setting redux state; optimisticEventData
      yield put(
        getBeatsNEctopicListRequested(
          searchOnsetRequest,
          searchTerminationRequest,
          reqOption
        )
      );
      // Step3. Call api; Edit Beat Event Type
      yield put(
        enqueueRequest({
          requestStatement,
          succeedCallback,
          failedCallback,
        })
      );
    }

    function* succeedCallback({ data }) {
      if (isWholeUnMark) yield put(setEventDetailEdited());

      yield put(
        patchBeatsSucceed(data, tabType, {
          requestAt,
          validResult: validateBeatEditResponse(reqBody, data.result),
          editTargetBeatType: reqBody.beatType,
        })
      );
      yield put(getBothStatisticsDelegated());
    }
    function* failedCallback(error) {
      yield put(patchBeatsFailed(error));
    }
  } catch (error) {
    yield put(patchBeatsFailed(error));
  }
}

function* _deleteBeats(action) {
  try {
    const {
      reqBody,
      onsetWaveformIndex,
      terminationWaveformIndex,
      suffix,
      tabType,
    } = action;
    const ecgTestId = yield select(selectEcgTestId);
    const requestStatement = {
      ecgTestId,
      requestType: suffix,
      reqBody: reqBody,
    };

    /**
     * # Process Step
     * Step1. optimisticEventDataUpdate
     * Step2. setting redux state; optimisticEventData
     * Step3. Call api; Edit Beat Event Type
     */
    const { searchOnsetRequest, searchTerminationRequest } =
      getSearchBeatsNEctopicListRangeAfterUpdateEvent(
        onsetWaveformIndex,
        terminationWaveformIndex
      );
    const reqOption = {
      isWholeUnMark: null,
      isOptimisticEventDataUpdate: true,
      optimisticEventDataUpdateOption: {
        reqTime: Date.now(),
        optimisticEventDataUpdateCase:
          optimisticEventDataUpdateCase.deleteBeats,
        reqBody,
        tabType,
        onsetWaveformIndex,
        terminationWaveformIndex,
      },
    };
    /**
     * @type {import('@type/optimisticUpdate/type').OptimisticEventDataUpdateForBeats}
     */
    let updatedBeatsData = {
      beatType: [],
      hr: [],
      waveformIndex: [],
    };

    // Step1. optimisticEventDataUpdate;
    updatedBeatsData = yield* optimisticBeatEventUpdateFn(
      reqOption.optimisticEventDataUpdateOption
    );
    reqOption.updatedBeatsDataValue = updatedBeatsData.result;
    const isValidationSuccessful =
      typeof updatedBeatsData.isValidationSuccessful === 'boolean' &&
      updatedBeatsData?.isValidationSuccessful;

    if (isValidationSuccessful === false) {
      let error = new Error('optimistic update');
      Object.assign(error, {
        classification: EventEditErrorClass.OPTIMISTIC_EVENT_UPDATE,
        ...updatedBeatsData,
      });
    } else {
      // Step2. setting redux state; optimisticEventData
      yield put(
        getBeatsNEctopicListRequested(
          searchOnsetRequest,
          searchTerminationRequest,
          reqOption
        )
      );
      // Step3. Call api; Edit Beat Event Type
      yield put(
        enqueueRequest({
          requestStatement,
          succeedCallback,
          failedCallback,
        })
      );
    }

    function* succeedCallback({ status }) {
      if (status !== StatusCode.OK) {
        yield put(deleteBeatsFailed({}, action));
        return;
      }
      yield put(deleteBeatsSucceed(reqBody));
      yield put(getBothStatisticsDelegated());
    }
    function* failedCallback(error) {
      yield put(deleteBeatsFailed(error));
    }
  } catch (error) {
    yield put(deleteBeatsFailed(error));
  }
}

/**
 * Generator function to post a time event.
 *
 * @param {Object} action - The action object.
 * @param {string} action.tid - The transaction ID.
 * @param {number} action.onsetWaveformIndex - The onset waveform index.
 * @param {number} action.terminationWaveformIndex - The termination waveform index.
 * @param {string} action.eventType - The time event type.
 * @param {boolean} action.isRemove - Flag indicating whether the event is to be removed
 */
export const uuidManagement = {};
function* _postTimeEvent(action) {
  try {
    const updateReqOption = {
      tid: action.tid,
      reqTime: Date.now(),
      type: POST_TIME_EVENT,
      //
      isRemove: action.isRemove,
      //
      // eventType: api에서 사용, timeEventType:  optimistic update에서 사용
      eventType: action.eventType,
      timeEventType: action.eventType,
      onsetWaveformIndex: action.onsetWaveformIndex,
      terminationWaveformIndex: action.terminationWaveformIndex,
      onsetWaveformIndexes: [action.onsetWaveformIndex],
      terminationWaveformIndexes: [action.terminationWaveformIndex],
    };

    const isAvBlockEventTypeResult = checkIfIsAvBlockEventType(
      action.eventType
    );
    if (isAvBlockEventTypeResult) {
      // av block time event는 group type을 설정해야 함
      updateReqOption.groupType = TIME_EVENT_TYPE.AV_BLOCK;
    }

    const isWholeUnMark = yield select(selectIsWholeUnMark);
    if (isWholeUnMark) yield put(setEventDetailEditPending(true));

    /**
     * # Process Step
     * Step1. optimisticEventDataUpdate
     * Step2. Call api; edit time event Type
     * step3. Call api; fetching TimeEventList
     * step4. postProcess; edit event validation
     */
    // Step1. optimisticEventDataUpdate
    const calls = yield call(preProcessTimeEventEdit, {
      updateReqOption,
      getTimeEventsListSucceed, // optimistic update data setting을 위한 action creator
    });

    // Step2. Call api; edit time event Type
    yield put(
      enqueueRequest({
        calls,
        requestStatement: updateReqOption,
        succeedCallback,
        failedCallback,
      })
    );

    function* succeedCallback({ responseData }) {
      const responseOne = responseData.at(0);
      const data = responseOne?.data ?? { result: null };

      // // timeEventId update process start
      // // - time event update response에 timeEventId가 있음
      // // - 이 timeEventId로 async event marker update로 생성했던 eventList에 id값을 update해 줌으로
      // // - 조회를 원활하게 하는 로직
      // const responseList = data.results;
      // if (!!responseList && responseList.length > 0) {
      //   // yield* _updateEventId(responseList);
      // }

      if (isWholeUnMark) yield put(setEventDetailEdited());
      yield put(postTimeEventSucceed(data));

      const eventTypeToRequest = AV_BLOCK_LIST.includes(
        updateReqOption.timeEventType
      )
        ? AV_BLOCK_LIST
        : [updateReqOption.timeEventType];

      // step3. Call api; fetching TimeEventList
      yield put(
        enqueueRequest({
          requestStatement: {
            ...updateReqOption,
            type: GET_TIME_EVENTS_LIST,
          },
          getAction: (options = {}) => {
            return getTimeEventsListRequested(eventTypeToRequest, {
              ...options,
              traceId: responseData[0].config.traceId,
              isWholeUnMark,
              callback: _callback,
            });
          },
          succeedCallback: () => {},
          failedCallback: () => {},
        })
      );
      yield put(getBothStatisticsDelegated());

      // step4. postProcess; edit event validation
      function* _callback() {
        if (
          updateReqOption.isRemove === false ||
          updateReqOption.eventType !== EventSection.AF
        ) {
          return;
        }

        yield put(
          enqueueRequest({
            requestStatement: {
              ...updateReqOption,
              postProcessEditedTimeEvent,
              type: POST_PROCESS_EDITED_TIME_EVENT,
            },
          })
        );
        // yield call(postProcessEditedTimeEvent, {
        //   params: updateReqOption,
        // });
        // }
      }
    }
    function* failedCallback(error) {
      console.error(error);
      yield delay(0);
      yield put(postTimeEventFailed(error));
    }
  } catch (error) {
    console.error(error);
    yield delay(0);
    yield put(postTimeEventFailed(error));
  }

  function* _updateEventId(responseList) {
    const { leadOff: staleLeadOffList, data: staleTimeEventList } =
      yield select((state) => state.testResultReducer.timeEventsList);

    const freshLeadOffList = [...staleLeadOffList];
    const eventType = getEventInfo({
      timeEventType: responseList[0].eventType,
    }).findOne()?.type;

    const convertResponseDataToMapType = {};
    for (let response of responseList) {
      convertResponseDataToMapType[response.onsetMs] = response;
    }

    const updatedStaleTimeEventList = rfdcClone(staleTimeEventList).map(
      (staleTimeEvent) => {
        const isSameAsEditedType = staleTimeEvent.type === eventType;
        const responseTimeEvent =
          convertResponseDataToMapType[staleTimeEvent.onsetMs];
        const isEditedEvent =
          responseTimeEvent &&
          staleTimeEvent.onsetMs === responseTimeEvent.onsetMs;
        if (isSameAsEditedType && isEditedEvent) {
          staleTimeEvent.timeEventId = responseTimeEvent.id;
          return staleTimeEvent;
        }
        return staleTimeEvent;
      }
    );

    const filterTaskOfPostTimeEvent = yield select(
      selectFilterTaskOfPostTimeEvent
    );
    if (filterTaskOfPostTimeEvent.length === 0) {
      yield put(
        getTimeEventsListSucceed(freshLeadOffList, updatedStaleTimeEventList)
      );
    }
  }
}

function* _requestMoveEctopicPositionRequested(action) {
  try {
    const {
      eventType,
      beatType,
      ectopicType,
      geminyType,
      currentValue,
      isNext,
    } = action.params;
    const ecgTestId = yield select(selectEcgTestId);
    const sortOrder = yield select(selectEventReviewSortOrder);
    const { queryOrderBy: ordering } = sortOrder[eventType]; // 정렬 정보

    const apiMethod =
      geminyType === undefined
        ? ApiManager.getEctopicListFilterType
        : ApiManager.getGeminyListFilterType;

    const params = {
      ecgTestId,
      beatType,
      currentValue,
      isNext,
      ordering,
      ...(geminyType === undefined ? { ectopicType } : { geminyType }),
    };

    const {
      data: { results },
    } = yield call(apiMethod, params);

    const ectopicInfo = results[0];
    const responseData = {
      ...ectopicInfo,
      onsetWaveformIndex: ectopicInfo.waveformIndex.at(0),
      terminationWaveformIndex: ectopicInfo.waveformIndex.at(-1),
    };
    const { recordingStartMs } = yield select(selectRecordingTime);
    yield put(
      setChartSelectedStrip(recordingStartMs + results[0].waveformIndex[0] * 4)
    );

    const prevSelectedEventList = yield select(selectSelectedEventList);
    const newSelectedEvent = {
      position: responseData.position,
      waveformIndex: responseData.waveformIndex,
    };
    const newSelectedEventList = getUpdatedSelectedEventList({
      prevSelectedEventList,
      newSelectedEvent,
      isSelectedEventUpdate: true,
    });

    yield put(getEventDetailSucceed(responseData, newSelectedEventList));

    //  update total event (comparing with ecg statistics data and fresh ectopicInfo)
    const ecgStatistics = yield select(
      (state) => state.testResultReducer.ecgStatistics
    );
    const filteredBeatEventSection = getEventInfo({
      beatType: ectopicInfo.beatType,
      ectopicType: ectopicInfo.ectopicType,
    }).findOne()?.eventSection;

    /**
     * # 이벤트 개수 동기화 작업
     *   - event review tab의 차트리스트에서 포지션 이동시 이동한 이벤트가
     *     위 api(getEctopicListFilterType) response값과 다를 경우 statistic의 ecgStatistics를 update
     */
    if (
      ecgStatistics.data[filteredBeatEventSection] !==
        ectopicInfo.totalEventCount &&
      geminyType === undefined
    ) {
      const copyEcgStatistics = rfdcClone(ecgStatistics.data);
      copyEcgStatistics[filteredBeatEventSection] = ectopicInfo.totalEventCount;
      yield put(getEcgsStatisticsSucceed(copyEcgStatistics));
    }
  } catch (error) {
    console.error('error: ', error);
  }
}

function* _requestPrintReport(action) {
  try {
    const { data } = yield call(ApiManager.requestPrintReport, {
      tid: action.tid,
      ...action.request,
    });
    const { error, result } = data;

    yield put(requestPrintReportSucceed(result));
    return;
  } catch (error) {
    yield put(requestPrintReportFailed(error));
  }
}

/**
 * optimisticEventDataUpdate에서 Beat Event Type에서만 사용한는 Fn 입니다.
 * 비트 업데이트 이후 api(filter/waveform-index-range)로 변경한 구간에 대한 업데이트 된 beat를 다시 받는 과정을 하지 않고
 * 비트 업데이트 요청한 데이터 "optimisticEventDataUpdateOption"을 이용하여 변경한 구간에 대한 beat를 update 시키는 과정을 합니다.
 *
 * @param {*} updateReqOption
 * @returns
 */
function* optimisticBeatEventUpdateFn(updateReqOption) {
  let result = {
    hr: [],
    beatType: [],
    waveformIndex: [],
  };

  const updateEventCommandMap = {
    [optimisticEventDataUpdateCase.postBeats]: PostBeatCommand,
    [optimisticEventDataUpdateCase.patchBeatsByWaveformIndexList]:
      PatchBeatByWaveformIndexListCommand,
    [optimisticEventDataUpdateCase.patchBeatsByRangeList]:
      PatchBeatByRangeListCommand,
    [optimisticEventDataUpdateCase.deleteBeats]: DeleteBeatCommand,
  };

  const staleBeatsNBeatEventsList = yield select(selectBeatsNEctopicList);
  // optimistic update 할 구간을 재설정(updateReqOption.onsetWaveformIndex, updateReqOption.terminationWaveformIndex의 전훌로 확장해서 구간을 재설정)
  const filterBeatsNEctopicList = _getFilterBeatsNEctopicList(
    staleBeatsNBeatEventsList,
    updateReqOption.onsetWaveformIndex,
    updateReqOption.terminationWaveformIndex
  );

  let eventUpdateCommandInst = new updateEventCommandMap[
    updateReqOption.optimisticEventDataUpdateCase
  ]({
    updateReqOption,
    filterBeatsNEctopicList,
  });
  yield eventUpdateInst.execute(eventUpdateCommandInst);
  result = eventUpdateCommandInst.getResult();
  eventUpdateCommandInst = null;

  return result;
}

function* getPauseEventList() {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const sortOrder = yield select(selectEventReviewSortOrder);
    const { recordingStartMs } = yield select(selectRecordingTime);

    const {
      data: {
        results: {
          onsetWaveformIndex: onsetWaveformIndexLst,
          terminationWaveformIndex: terminationWaveformIndexList,
        },
      },
    } = yield call(ApiManager.getPauseWaveformIndexes, {
      ecgTestId,
      ordering: sortOrder[EVENT_CONST_TYPES.PAUSE]?.queryOrderBy,
    });

    if (onsetWaveformIndexLst.length !== terminationWaveformIndexList.length) {
      throw new Error(
        'Pair of Pause onset & termination array lengths are different.'
      );
    }

    const results = onsetWaveformIndexLst.map((onsetWaveformIndex, i) => {
      const terminationIndex = terminationWaveformIndexList[i];
      return {
        id: `${TIME_EVENT_TYPE.PAUSE}.${onsetWaveformIndex}-${terminationIndex}`,
        groupType: null,
        eventType: TIME_EVENT_TYPE.PAUSE,
        onsetMs: recordingStartMs + onsetWaveformIndex * 4,
        terminationMs: recordingStartMs + terminationIndex * 4,
        durationMs: (terminationIndex - onsetWaveformIndex) * 4,
        position: undefined,
      };
    });

    return { data: { results } };
  } catch (error) {
    console.error(error);
  }
}

function retryGetEventDetail(retry) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      retry.times -= 1;
      // console.info('retryGetEventDetail > times: ', retry.times);
      if (retry.times > 0) {
        emitter(retry.times);
      } else {
        // this causes the channel to close
        emitter(END);
      }
    }, 700);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

// ### list saga declared ###
// :: TAB :: general
// :: TAB :: event review (ECG chart list)
// :: TAB :: event review (selection feature)
// :: TAB :: event review (10s strip)
// :: TAB :: event review (Arrhythmia Edit visualize)
// ---
// :: TAB :: event review (side Panel - Manage Side Panel State)
// :: TAB :: event review (side Panel - order)
// :: TAB :: event review (side Panel - Representative Report Strip State Management)
// :: TAB :: event review (side panel - statistics data)
// :: TAB :: event review (side panel > events panel - get single events)
// :: TAB :: event review (side panel > report panel - move next report)
// :: TAB :: event review (side panel > report panel - edit report events)
//   - retrieve report
//   - create report
//   - update report
//   - delete report
// :: TAB :: event review - beat edit
//   - create beat event
//   - update beat event
//   - delete beat event
//   - create, delete time event
// :: TAB :: event review (side panel > events panel - move ectopic position)
// :: create Report ::
// :: For DevMode ::

export function* saga() {
  // :: TAB :: general
  yield takeLatest(GET_FINDINGS_TEMPLATE_REQUESTED, _getFindingsTemplate);

  // :: TAB :: event review (ECG chart list)
  yield takeLatest(INITIALIZE, _initializeState);
  yield debounce(200, GET_ECG_TEST_REQUESTED, _getEcgTest);
  yield takeLatest(PATCH_ECG_TEST_REQUESTED, _patchEcgTest);
  yield takeLatest(GET_DAILY_HEART_RATE_REQUESTED, _getDailyHeartRate);
  yield takeLatest(GET_ECGRAW_INIT_REQUESTED, _getEcgRaw);
  yield takeLatest(GET_ECGRAW_REQUESTED, _getEcgRaw);
  yield takeLatest(GET_ECGRAW_BACKWARD_REQUESTED, _getEcgRaw);
  yield takeLatest(GET_ECGRAW_FORWARD_REQUESTED, _getEcgRaw);
  // :: TAB :: event review (selection feature)
  yield takeLatest(SET_SELECTION_STRIP, _selectionStripHandler);
  // :: TAB :: event review (10s strip)
  yield takeLatest(SET_TENSEC_STRIP, _tenSecStripHandler);

  // :: TAB :: event review (Arrhythmia Edit visualize)
  yield takeEvery(GET_TIME_EVENTS_LIST_REQUESTED, _getTimeEventsList);
  yield takeEvery(GET_BEATS_N_ECTOPIC_LIST_REQUESTED, _getBeatsNEctopicList);
  // :: TAB :: event review (side Panel - Manage Side Panel State)
  yield takeLatest(
    SET_SIDE_PANEL_SELECTED_VALUE_LIST,
    _setSelectedEventListHandler
  );

  // :: TAB :: event review (side Panel - order)
  yield takeLatest(SET_SORT_ORDER, _setSortOrderHandler);
  // :: TAB :: event review (side Panel - Representative Report Strip State Management)
  yield takeLatest(
    SET_REPRESENTATIVE_STRIP_INFO,
    _reportRepresentativeTenSecStripHandler
  );
  // :: TAB :: event review (side panel - statistics data)
  yield takeLatest(GET_ALL_STATISTICS_REQUESTED, _getAllStatistics);
  yield takeLatest(GET_BOTH_STATISTICS_DELEGATED, _getBothStatistics);
  yield takeLatest(GET_ECGS_STATISTICS_REQUESTED, _getEcgsStatistics);
  yield takeLatest(GET_REPORTS_STATISTICS_REQUESTED, _getReportStatistics);
  // :: TAB :: event review (side panel > events panel - get single events)
  yield takeLatest(GET_EVENT_DETAIL_REQUESTED, _getEventDetail);
  yield takeLatest(GET_EVENT_DETAIL_RETRY_REQUESTED, _getEventDetailRetry);
  // :: TAB :: event review (side panel > report panel - move next report)
  yield takeLatest(GET_NEXT_REPORT_EVENT, _getNextReportEventHandler); // move next report
  // :: TAB :: event review (side panel > report panel - edit report events)
  yield takeLatest(GET_REPORT_EVENTS_REQUESTED, _getReportEvent); // retrieve report
  yield takeLatest(POST_REPORT_EVENT_REQUESTED, _postReportEvent); // create report
  yield takeLatest(UPDATE_REPORT_EVENT_REQUESTED, _updateReportEvent); // update report
  yield takeLatest(DELETE_REPORT_EVENT_REQUESTED, _deleteReportEvent); // delete report
  yield takeEvery(UPDATE_PTE_REPORT_INFO_REQUESTED, _updatePteReportInfo); // update PTE report info
  // :: TAB :: event review - beat edit
  yield takeEvery(POST_BEATS_REQUESTED, _postBeats); // create beat event(10s, 30s strip detail)
  yield takeEvery(PATCH_BEATS_REQUESTED, _patchBeats); // update beat event(10s strip detail)
  yield takeEvery(DELETE_BEATS_REQUESTED, _deleteBeats); // delete beat event(10  strip detail)
  yield takeEvery(POST_TIME_EVENT_REQUESTED, _postTimeEvent); // create, delete time event(30s strip detail))
  // :: TAB :: event review (side panel > events panel - move ectopic position)
  yield takeLatest(
    REQUEST_MOVE_ECTOPIC_POSITION_REQUESTED,
    _requestMoveEctopicPositionRequested
  );
  // :: create Report ::
  yield takeLatest(REQUEST_PRINT_REPORT_REQUESTED, _requestPrintReport);

  // :: For DevMode ::
  yield takeLatest(SET_SELECTION_STRIP, _devMode);

  // yield takeEvery(
  //   // '*'
  //   // [POST_BEATS_REQUESTED,POST_BEATS_SUCCEED,POST_BEATS_FAILED]
  //   isActionOfInterest,
  //   function* logger(action) {
  //     const state = yield select();

  //     console.log('🅰️ action', action.type);
  //     console.log('ℹ️ state after', state.testResultReducer);
  //   }
  // );

  // function isActionOfInterest(action) {
  //   return /^memo\-web\/test\-result\/SET/.test(action.type);
  // }
}
