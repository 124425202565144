import { forwardRef, memo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import {
  CHART_EDIT_CONST,
  SELECTION_MARKER_TYPE,
} from 'constant/ChartEditConst';
import {
  CLASS_NAME_CHART_BASE,
  CHART_CONST,
  LONG_TERM_CHART,
} from 'constant/ChartConst';
import { CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED } from 'constant/EventConst';

import ChartUtil, { ECGChartCommonOption } from 'util/ChartUtil';

const CONST_CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED =
  CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED;

// EcgChartListContainer 와 동기 필요
const CHART_MARGIN_TOP = LONG_TERM_CHART.MARGIN_TOP;
const CHART_MARGIN_BOTTOM = LONG_TERM_CHART.MARGIN_BOTTOM;
const LINE_WIDTH = LONG_TERM_CHART.LINE_WIDTH;
const getChartHeightFromWidth = (width) => width * 0.04;

/**
 *
 * @param {{ecgChartData: TransformedRawType}} props
 * @returns
 */
function LongTermHighcharts(props, ref) {
  const {
    // props
    ecgChartData,
    plotWidth,
    // ref
    chartRef,
    // state
    theme,
    thirtySecAmplitudeRate,
  } = props;

  const { ecgData } = ecgChartData;
  // console.log('🚀 ~ LongTermHighcharts ~ ecgData:', ecgData);
  const beatTypeZones = [];

  const generateChartOptions = ({ ecgData = [], beatTypeZones, theme }) => {
    return {
      chart: {
        spacing: [
          LINE_WIDTH / 2 + CHART_MARGIN_TOP,
          LINE_WIDTH, // 차트 우측 plotBorder 잘리는 현상 해소
          LINE_WIDTH / 2 + CHART_MARGIN_BOTTOM,
          LINE_WIDTH / 2,
        ],
        plotBorderColor: theme.color.MEDIUM,
        plotBorderWidth: LINE_WIDTH / 2,
        events: {
          click: function (event) {}, // LongTermHighcharts.jsx > mousedown event에 해당 로직 이동
          load: function () {
            // 30 초 차트 Grid
            const chart = this;
            const { x, y, width, height } = chart.plotBox;
            const chartHeight = height;
            const chartWidth = width;
            const xPath = [];
            const yPath = [];

            const xIntervalLength = 5 * 30;
            const yIntervalLength = 6;
            const yInterval = chartHeight / yIntervalLength;
            const xInterval = chartWidth / xIntervalLength;

            for (let i = 1; i < yIntervalLength; i++) {
              yPath.push('M', x, i * yInterval + y, 'H', chartWidth + x);
            }
            for (let i = 0; i < xIntervalLength; i++) {
              xPath.push('M', i * xInterval + x, y, 'V', chartHeight + y);
            }
            const lineWidth = 0.5;

            chart.renderer
              .path(yPath.concat('z'))
              .attr({
                'stroke-width': lineWidth,
                stroke: theme.color.COOL_GRAY_40,
              })
              .add();
            chart.renderer
              .path(xPath.concat('z'))
              .attr({
                'stroke-width': lineWidth,
                stroke: theme.color.COOL_GRAY_40,
              })
              .add();
          },
          load: function () {
            // 30 초 차트 Grid
            const chart = this;
            const { x, y, width, height } = chart.plotBox;
            const chartHeight = height;
            const chartWidth = width;
            const xPath = [];
            const yPath = [];

            const xIntervalLength = 5 * 30;
            const yIntervalLength = 6;
            const yInterval = chartHeight / yIntervalLength;
            const xInterval = chartWidth / xIntervalLength;

            for (let i = 1; i < yIntervalLength; i++) {
              yPath.push('M', x, i * yInterval + y, 'H', chartWidth + x);
            }
            for (let i = 0; i < xIntervalLength; i++) {
              xPath.push('M', i * xInterval + x, y, 'V', chartHeight + y);
            }
            const lineWidth = 0.5;

            chart.renderer
              .path(yPath.concat('z'))
              .attr({
                'stroke-width': lineWidth,
                stroke: theme.color.COOL_GRAY_40,
              })
              .add();
            chart.renderer
              .path(xPath.concat('z'))
              .attr({
                'stroke-width': lineWidth,
                stroke: theme.color.COOL_GRAY_40,
              })
              .add();
          },
          render: function () {
            const chart = this;

            ChartUtil.renderBorderDecoration(chart);

            !this.plotBackground.element.highchartInst &&
              (this.plotBackground.element.highchartInst = this);

            if (!chart.mouseTracker) {
              chart.mouseTracker = chart.renderer
                .g()
                .attr({
                  class: `${CHART_EDIT_CONST.MOUSE_TRACKER}-g`,
                  zIndex: 3,
                })
                .add();
              chart.renderer
                .path()
                .attr({
                  class: CHART_EDIT_CONST.MOUSE_TRACKER,
                  fill: 'none',
                  d: `M0 ${CHART_MARGIN_TOP + 1} V${
                    chart.yAxis[0].height + CHART_MARGIN_TOP + 1
                  }`,
                  stroke: 'rgb(66, 106, 255)',
                  'stroke-width': 2,
                  'stroke-dasharray': '2,2',
                  visibility: 'hidden',
                })
                .css({
                  'z-index': 5,
                  transform: 'translateX(1px)',
                })
                .add(chart.mouseTracker);
            }

            if (ChartUtil.checkRenderedChart(chart)) return;
          },
        },
      },
      xAxis: {
        lineWidth: 0,
        minPadding: 0,
        maxPadding: 0,
        min: 0, // ecg data raw: # down sampling
        max: CHART_CONST.XAXIS_MAX, // ecg data raw: # down sampling
        // max: CHART_CONST.XAXIS_MAX / 2, // ecg data raw: # down sampling
        tickLength: 0,
        tickInterval: 2500,
        gridLineWidth: 0,
        labels: {
          padding: 0,
          enabled: false,
        },
      },
      yAxis: {
        lineWidth: 0,
        min: -1 / thirtySecAmplitudeRate,
        max: 2 / thirtySecAmplitudeRate,
        tickAmount: 7,
        gridLineWidth: 0,
        title: {
          enabled: false,
        },
        labels: {
          padding: 0,
          enabled: false,
        },
      },
      series: [
        {
          data: ecgData,
          lineWidth: LINE_WIDTH - 0.1,
          color: theme.color.ECG_BLACK,
          zoneAxis: 'x',
          zones: beatTypeZones,
          enableMouseTracking: false,
          zIndex: 1,
          className: `huinno-30sec-data ${CLASS_NAME_CHART_BASE}`,
        },
        // ...ecgChartGridSeries30,
      ],
      plotOptions: {
        series: {
          dataSorting: null,
          events: {
            click: function (event) {}, // LongTermHighcharts.jsx > mousedown event에 해당 로직 이동
          },
          enableMouseTracking: false, // 모든 마우스 이벤트 제거, performance 향샹 기대 가능하다고 함
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false, // hover 시 마우스 포인터와 가까운 포인트 강조 효과 제거
              },
            },
          },
          states: {
            inactive: {
              enabled: false,
            },
            hover: {
              enabled: true,
              halo: null, // hover 시 마우스 포인터와 가까운 포인트 주변 후광(?) 효과 제거
              lineWidthPlus: 0,
            },
          },
          animation: false, // animation 제거(렌더 시간 단축!!!)
        },
      },
      ...ECGChartCommonOption,
    };
  };

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={generateChartOptions({ ecgData, beatTypeZones, theme })}
      allowChartUpdate={true}
      immutable={false}
      updateArgs={[false, false, false]}
      containerProps={{
        className: 'chartContainer',
        style: {
          width: plotWidth + LINE_WIDTH,
          height:
            getChartHeightFromWidth(plotWidth) +
            CHART_MARGIN_TOP +
            CHART_MARGIN_BOTTOM +
            LINE_WIDTH,
        },
      }}
    />
  );
}

export default memo(forwardRef(LongTermHighcharts), (prevProps, nextProps) => {
  return prevProps.thirtySecAmplitudeRate === nextProps.thirtySecAmplitudeRate;
});

export function hasOnsetMarker() {
  return (
    window[
      CHART_EDIT_CONST.SELECTION_MARKER +
        SELECTION_MARKER_TYPE.ONSET +
        CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP
    ].length > 0
  );
}
